import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import AttributeItem from "../attribute_item/attribute_item";
import IconBtn from "../icon_btn/icon_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import SiteMapSection, {emptySiteMapSection} from "../../models/site_map_section";
import SingleImageWithLinkComponent from "../single_image_with_link/single_image_with_link";
import SingleImageWithLink, {emptySingleImageWithLink} from "../../models/single_image_with_link";

export default function SiteMapSectionComponent(props: {
  section: SiteMapSection;
  index: number;
  onRemove: Function;
}) {
  const [section, setSection] = useState(props.section);

  const addNewBook = () => {
    const prev = section;
    if (prev) {
      prev.section_items.push(new SingleImageWithLink(emptySingleImageWithLink));
      setSection({ ...prev });
    }
  };
  const removeBook = (index: number) => {
    const prev = section;
    if (prev) {
      prev.section_items.splice(index, 1);
      setSection(new SiteMapSection(emptySiteMapSection));
      setTimeout(() => {
        setSection({ ...prev });
      }, 0.01);
    }
  };

  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <MultiLangEdit label={"Title"} isShowLabel value={section.section_title} />

      <AttributeItem removeMargin title={"Sitemap Section Items"} onClick={addNewBook}>
        {section.section_items.map((book, index) => (
            <SingleImageWithLinkComponent 
                key={index}
                index={index}
                item={book}
                onRemove={removeBook}
            />
        ))}
      </AttributeItem>
    </Card>
  );
}
