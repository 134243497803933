import { Card, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import MapLocationModel from "../../models/map_location_component";
import IconBtn from "../icon_btn/icon_btn";
import LeafletMapView from "../map/leaflet_map";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import Select from "react-select";
import { countries } from '../country_select/countries'

export default function MapLocationComponent(props: {
  item: MapLocationModel;
  index?: number;
  onRemove?: Function;
}) {

  const [location, setLocation] = useState({
    lat: props.item.latitude ? props.item.latitude : 0,
    lon: props.item.longitude ? props.item.longitude : 0,
  });

  const [selectedCountry, setSelectedCountry] = useState({
    label: props.item.country_selection ? props.item.country_selection : '-',
    value: props.item.country_selection ? props.item.country_selection : '-'
  });

  const changeLocation = (location: any) => {
    props.item.latitude = location.lat;
    props.item.longitude = location.lon;
    setLocation({ lat: location.lat, lon: location.lon });
  };
  return (
    <Card
      elevation={5}
      style={{
        margin: "10px 0px",
        paddingTop: "30px",
        position: "relative",
      }}
    >
      {props.onRemove && (
        <IconBtn
          icon={Remove}
          onClick={() => {
            if (props.onRemove && props.index != null) {
              props.onRemove(props.index);
            }
          }}
        />
      )}

      <span style={{ fontSize: '0.875rem', paddingLeft: '10px', fontWeight: 'bold' }}>Country</span>
      <div style={{
        width: '50%',
        padding: '10px',
        marginTop: '10px'
      }}>
        <Select
          classNamePrefix="select country"
          isSearchable={true}
          name="country"
          options={countries}
          value={{ "label": selectedCountry.label, "value": selectedCountry.value }}
          onChange={(selected) => {
            if (selected) {
              setSelectedCountry({ "value": selected.label, "label": selected.label })
              props.item.country_selection = selected.label
            }
          }}
        />
      </div>

      <MultiLangEdit value={props.item.description} label="Description" isShowLabel />
      <MultiLangEdit value={props.item.country} label="Country Details" isShowLabel />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            type="number"
            label="Latitude"
            variant="outlined"
            value={location.lat}
            onChange={(event) => {
              const prev = location;
              prev.lat = Number(event.target.value);
              setLocation({ ...prev });
              props.item.latitude = Number(event.target.value);
            }}
            InputLabelProps={{
              shrink: location.lat !== null,
            }}
          />
          <TextField
            style={{
              width: "100%",
            }}
            type="number"
            label="Longitude"
            variant="outlined"
            value={location.lon}
            onChange={(event) => {
              const prev = location;
              prev.lon = Number(event.target.value);
              setLocation({ ...prev });
              props.item.longitude = Number(event.target.value);
            }}
            InputLabelProps={{
              shrink: location.lon !== null,
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <LeafletMapView location={location} setLocation={changeLocation} />
        </div>
      </div>
    </Card>
  );
}
