import SocialWallItemModel from "../social_wall_item";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class SocialWallPageModel {
  id: number;
  social_wall_items: SocialWallItemModel[] = [];
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.social_wall_items.forEach((x: any) =>
      this.social_wall_items.push(new SocialWallItemModel(x))
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptySocialWallPage = {
  social_wall_items: [],
  seo: emptySEOComponent,
};
