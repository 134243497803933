import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import CertificateItem from "../../models/certificate_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import TextBoxItem from "../text_box_item/text_box_item";

export default function CertificateItemComponent(props: {
  certificate: CertificateItem;
  onRemove: Function
}) {

  const [pageData, setPageData] = useState<CertificateItem>(props.certificate);

  const onFileUrlChange = (val: any) => {
    const prev = pageData;
    if (prev) {
      prev.file_url = val;
      setPageData({ ...prev });
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div style={{ width: '40px', height: '40px', position: 'relative', marginRight: '20px' }}>
          <IconBtn icon={Remove} onClick={props.onRemove} />
        </div>

        <IconUploadBtn
          mediaUrl={props.certificate.image_url}
          isEditable
          mediaType="IMAGE"
          getFileUrl={(url: string) => {
            props.certificate.image_url = url;
          }}
          height={150}
        />
        <MultiLangEdit
          value={props.certificate.description}
          label="Description"
          isShowLabel
        />
      </div>
      <div style={{
        marginLeft: '10px',
        fontWeight: 'bold',
        color: '#716666',
        textAlign: 'center',
      }}>
        File Url
      </div>
      <TextBoxItem label="File URL" onVideoLinkChange={onFileUrlChange} value={pageData.file_url}></TextBoxItem>
      <MultiLangEdit
        value={props.certificate.sub_heading}
        label="Sub Heading"
        isShowLabel
      />
    </div>
  );
}
