import HeaderWithContent, { emptyHeaderWithContent } from "../header_with_content";
import ImageWithLink from "../image_with_link";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class OurTeaCategoriesTeaByMoods {
  id: number;
  items: ImageWithLink[]=[];
  header_image: string;
  title: MultiLanguage;
  header_descrpiton: HeaderWithContent;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.items.forEach((x:any) => this.items.push(new ImageWithLink(x)));
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.header_image = data.header_image;
    this.header_descrpiton = new HeaderWithContent(data.header_descrpiton ? data.header_descrpiton : emptyHeaderWithContent);
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  
  }
}

export const emptyOurTeaCategoriesTeaByMoods = {
  title: emptyMultiLang,
  header_descrpiton: emptyHeaderWithContent,
  items: [],
  seo: emptySEOComponent,
};
