
import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class CareerUserItem{
    id:number
    name:MultiLanguage
    description:MultiLanguage
    image_url:string

    constructor(data:any){
        this.id=data.id
        this.name=new MultiLanguage(data.name ? data.name : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.image_url = data.image_url
    }
}

export const emptyCareerUserItem = {
    name: new MultiLanguage(emptyMultiLang),
    description: new MultiLanguage(emptyMultiLang),
}