import { FiberManualRecordOutlined } from "@material-ui/icons";
import CafeAndTeaMakerPage from "../../pages/tea_inspired/cafe_and_the_tea_maker";
import CertificatesAndAwardPage from "../../pages/dilmah_family/certificate_and_award_page";
import DilmahConservationPage from "../../pages/our_purpose/dilmah_conservation";
import DilmahGlobalTimelinePage from "../../pages/dilmah_family/dilmah_global_timeline_page";
import DilmahInHospitalityPage from "../../pages/tea_inspired/dilmah_in_hospitality";
import DilmahPodcastPage from "../../pages/all_about_tea/dilmah_podcast_page";
import DilmahRealHighTeaPage from "../../pages/tea_inspired/dilmah_real_high_tea";
import DilmahStoryPage from "../../pages/dilmah_family/dilmah_story";
import FactsOfTeaPage from "../../pages/all_about_tea/facts_of_tea_page";
import FoundersMessagePage from "../../pages/dilmah_family/founders_message";
import HowToBrewTeaPage from "../../pages/all_about_tea/how_to_brew_tea";
import LeisureAndHospitalityPage from "../../pages/dilmah_family/leisure_and_hospitality_page";
import MixologistAndTeaMakerPage from "../../pages/tea_inspired/mixologist_and_tea_maker";
import MJFFoundationPage from "../../pages/our_purpose/mjf_foundation";
import OurValuesPage from "../../pages/dilmah_family/our_values_page";
import SchoolOfTeaPage from "../../pages/all_about_tea/school_of_tea_page";
import SchoolOfTeaELearnPage from "../../pages/all_about_tea/school_of_tea_e_learn_page";
import SustainabilityPage from "../../pages/our_purpose/sustainability";
import TeaAndHealthPage from "../../pages/all_about_tea/tea_and_health_page";
import TeaGardensPage from "../../pages/dilmah_family/tea_gardens_page";
import TeaInspirationPage from "../../pages/tea_inspired/tea_inspiration";
import TeaInspiredEBooksPage from "../../pages/all_about_tea/tea_inspired_e_books";
import TeaInFiveSensesPage from "../../pages/tea_inspired/tea_in_five_senses";
import TestimonialsPage from "../../pages/dilmah_family/testimonials_page";
import WhatWeDoPage from "../../pages/dilmah_family/what_we_do_page";
import HomePage from "../../pages/home/home";
import ContactUsPage from "../../pages/other/contact_us";
import CareersPage from "../../pages/other/careers";
import SocialWallPage from "../../pages/sections/social_wall";
import MediaPage from "../../pages/media/media";
import TeaInspiredImagesPage from "../../pages/tea_inspired/images";
import MediaImagesPage from "../../pages/media/media_images";
import TeaBlogPage from "../../pages/sections/tea_blog";
import TeaBlogCategoriesPage from "../../pages/sections/tea_blog_categories";
import ImageUploadPage from "../../pages/other/image_upload";
import PremiumCeylonTea from '../../pages/tea_ranges/premium_ceylon_tea'
import CeylonGold from '../../pages/tea_ranges/ceylon_gold'
import GourmetTea from '../../pages/tea_ranges/gourmet_tea'
import CeylonGreenTea from '../../pages/tea_ranges/ceylon_green_tea'
import FunFlavoredCeylonTea from '../../pages/tea_ranges/fun_flavored_ceylon_tea'
import RealTeaCordial from '../../pages/tea_ranges/real_tea_cordial'
import Organic from '../../pages/tea_ranges/organic'
import Arana from '../../pages/tea_ranges/arana'
import SilverJubileeGourmet from '../../pages/tea_ranges/silver_jubilee_gourmet'
import ExceptionalTea from '../../pages/tea_ranges/exceptional_tea'
import CommemerativeTea from '../../pages/tea_ranges/commemorative_tea'
import VividTea from '../../pages/tea_ranges/vivid_tea'
import WatteBoutiqueTea from '../../pages/tea_ranges/watte_boutique_tea'
import InspirationSelection from '../../pages/tea_ranges/inspiration_selection';
import NaturalGreenTea from '../../pages/tea_ranges/natural_green_tea'
import TeamakersReserve from '../../pages/tea_ranges/teamakers_reserve'
import TeamakersPrivateReserve from '../../pages/tea_ranges/teamakers_private_reserve';
import TSeriesGourmet from "../../pages/tea_ranges/t_series_gourmet";
import ElixirOfCeylonTea from "../../pages/tea_ranges/elixir_of_ceylon_tea";
import NaturalInfusion from "../../pages/tea_ranges/natural_infusion";
import TeaFormats from "../../pages/other/tea_formats"
import DilmhaTeaLoungePage from "../../pages/tea_inspired/dilmha_tea_lounge";
import OurTeaCategoriesPage from "../../pages/our_product/our_tea_categories";
import GifrOfTeaPage from "../../pages/our_product/gift_of_teas";
import TeaWaresPage from "../../pages/our_product/tea_wares";
import DilmhaAccessoriesPage from "../../pages/our_product/dilmah_accessories";
import WhereToBuyPage from "../../pages/our_product/where_to_buy";
import TermsAndConditionPage from "../../pages/our_policics/terms_and_condition";
import PrivacyAndCookiePolicyPage from '../../pages/our_policics/privacy_and_cookie_policy';
import AntiBribaryAndCorruptionPolicyPage from "../../pages/our_policics/anti_bribary_and_corruption_policy";
import BusinessEthicsPolicyPage from "../../pages/our_policics/business_ethics_policy";
import HumanRightsPolicyPage from "../../pages/our_policics/human_rights_policy";
import DilmahPodcastCategoriesPage from '../../pages/all_about_tea/dilmah_podcast_categories'
import BannerCustomizationPage from "../../pages/media/banner_custormzation";
import DilmahQuizePage from "../../pages/our_product/dilmah_quiz";
import IngredientsPage from "../../pages/our_product/ingredient_details";
import CookieDisclaimerPage from "../../pages/our_policics/cookie_disclaimer";
import TeamakersPhilosophyPage from "../../pages/tea_inspired/teamakers_philosophy";
import ALifeDevotedToTeaPage from "../../pages/tea_inspired/a_life_devoted_to_tea";
import SiteMapPage from "../../pages/other/site_map";
import MrDilmahWishSubmitView from "../../pages/tea_inspired/mrdilmah_wish_submit_view";
import DilmahTradePage from "../../pages/other/dilmah_trade";
import EBooksPage from "../../pages/tea_inspired/e_book";
import TeaInspiredHomePage from "../../pages/tea_inspired/tea_inspired_home";
import VideoUploadPage from "../../pages/other/video_upload";
import FileUploadPage from "../../pages/other/file_upload";
import CeylonGoldenPekoe from "../../pages/tea_ranges/ceylon_golden_pekoe";
import CeylonIcedTea from "../../pages/tea_ranges/ceylon_iced_tea";

const nestedRouteNameObject1 = {
  home: {
    name: "Home",
    route: "/home/home",
    component: HomePage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  foundersMessage: {
    name: "Founders Message",
    route: "/family/founder",
    component: FoundersMessagePage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahStory: {
    name: "Dilmah Story",
    route: "/family/story",
    component: DilmahStoryPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  ourValues: {
    name: "Our Values",
    route: "/family/values",
    component: OurValuesPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  certificatesAndAwards: {
    name: "Certificates",
    route: "/family/certificates",
    component: CertificatesAndAwardPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teaGardens: {
    name: "Tea Gardens",
    route: "/family/tea_gardens",
    component: TeaGardensPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  whatWeDo: {
    name: "What We Do",
    route: "/family/what_we_do",
    component: WhatWeDoPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  leisureAndHospitality: {
    name: "Leisure Hospitality",
    route: "/family/leisure_hospitality",
    component: LeisureAndHospitalityPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  globalTimeLine: {
    name: "Global Timeline",
    route: "/family/global_timeline",
    component: DilmahGlobalTimelinePage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  schoolOfTea: {
    name: "School Of Tea",
    route: "/all_about/school_of_tea",
    component: SchoolOfTeaPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  schoolOfTeaELearn: {
    name: "School Of Tea - E",
    route: "/all_about/school_of_tea_e",
    component: SchoolOfTeaELearnPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teaAndHealth: {
    name: "Tea And Health",
    route: "/all_about/tea_and_health",
    component: TeaAndHealthPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  testimonials: {
    name: "Testimonials",
    route: "/family/testimonials",
    component: TestimonialsPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teaInspired: {
    name: "Tea Inspired E Books",
    route: "/all_about/tea_inspired_e_books",
    component: TeaInspiredEBooksPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  howToBrewTeatsOfTea: {
    name: "How To Brew Tea",
    route: "/all_about/how_to_brew_tea",
    component: HowToBrewTeaPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  factsOfTea: {
    name: "Facts Of Tea",
    route: "/all_about/facts_of_tea",
    component: FactsOfTeaPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahPodcast: {
    name: "Dilmah Podcast",
    route: "/all_about/dilmah_podcast",
    component: DilmahPodcastPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahPodcastCategories: {
    name: "Dilmah Podcast Categories",
    route: "/all_about/dilmah_podcast_categories",
    component: DilmahPodcastCategoriesPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  sustainability: {
    name: "Sustainability",
    route: "/our_purpose/sustainability",
    component: SustainabilityPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahConservation: {
    name: "Dilmah Conservation",
    route: "/our_purpose/dilmah_conservation",
    component: DilmahConservationPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  mjfFoundation: {
    name: "MJF Foundation",
    route: "/our_purpose/mjf_foundation",
    component: MJFFoundationPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  ourTeaCategories: {
    name: "Our Tea Categories",
    route: "/our_product/our_tea_categories",
    component: OurTeaCategoriesPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  giftOfTeas: {
    name: "Gift Of Teas",
    route: "/our_product/gift_of_teas",
    component: GifrOfTeaPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teaWares: {
    name: "Tea Wares",
    route: "/our_product/tea_wares",
    component: TeaWaresPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmhaAccessories: {
    name: "Tea Accessories",
    route: "/our_product/dilmah_accessories",
    component: DilmhaAccessoriesPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  whereToBuy: {
    name: "Where to Buy",
    route: "/our_product/where_to_buy",
    component: WhereToBuyPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  quiz: {
    name: "Dilmah Quiz",
    route: "/our_product/dilmah_quiz",
    component: DilmahQuizePage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  ingredients: {
    name: "Ingredients",
    route: "/our_product/ingredients",
    component: IngredientsPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahInHospitality: {
    name: "Dilmah In Hospitality",
    route: "/tea_inspired/dilmah_in_hospitality",
    component: DilmahInHospitalityPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  eBooks: {
    name: "E Books",
    route: "/all_about/e_books",
    component: EBooksPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teamakersPhilosophy: {
    name: "Teamakers Philosophy",
    route: '/tea_inspired/teamakers_philosophy',
    component: TeamakersPhilosophyPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  aLifeDevotedToTea: {
    name: "A Life Devoted To Tea",
    route: '/tea_inspired/a_life_devoted_to_tea',
    component: ALifeDevotedToTeaPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teaInspiredHome: {
    name: "Tea Inspired Home Page",
    route: '/tea_inspired/home',
    component: TeaInspiredHomePage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  mrDilmahWishSubmits: {
    name: "Mr.Dilmah Wish Submits",
    route: '/tea_inspired/mr_dilmah_wish_submits',
    component: MrDilmahWishSubmitView,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahPremiumCeylonTeaSelection: {
    name: "Premium Ceylon Tea",
    route: "/tea_ranges/dilmah_premium_ceylon_tea",
    component: PremiumCeylonTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahCeylonGold: {
    name: "Ceylon Gold",
    route: "/tea_ranges/dilmah_ceylon_gold",
    component: CeylonGold,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahGourmetTea: {
    name: "Gourmet Tea",
    route: "/tea_ranges/gourmet_tea",
    component: GourmetTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahCeylonGreenTea: {
    name: "Ceylon Green Tea",
    route: "/tea_ranges/dilmah_ceylon_green_tea",
    component: CeylonGreenTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahCeylonIcedTea: {
    name: "Ceylon Iced Tea",
    route: "/tea_ranges/dilmah_ceylon_iced_tea",
    component: CeylonIcedTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahCeylonGoldenPekoe: {
    name: "Ceylon Golden Pekoe",
    route: "/tea_ranges/dilmah_ceylon_golden_pekoe",
    component: CeylonGoldenPekoe,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahFunFlavoredCeylonTea: {
    name: "Fun Flavored Tea",
    route: "/tea_ranges/dilmah_fun_flavored_ceylon_tea",
    component: FunFlavoredCeylonTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahRealTeaCordial: {
    name: "Real Tea Cordial",
    route: "/tea_ranges/dilmah_real_tea_cordial",
    component: RealTeaCordial,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahOrganic: {
    name: "Organics",
    route: "/tea_ranges/dilmah_organics",
    component: Organic,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahArana: {
    name: "Arana",
    route: "/tea_ranges/dilmah_arana",
    component: Arana,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahSilverJubileeGourmet: {
    name: "Silver Jubilee Gourmet",
    route: "/tea_ranges/dilmah_silver_jubilee_gourmet",
    component: SilverJubileeGourmet,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahExceptionalTeaSelection: {
    name: "Exceptional Tea Selection",
    route: "/tea_ranges/dilmah_exceptional_tea_selection",
    component: ExceptionalTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahCommemorativeTea: {
    name: "Commemerative Teas",
    route: "/tea_ranges/dilmah_commemorative_teas",
    component: CommemerativeTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahVividTea: {
    name: "Dilmah Vivid Tea",
    route: "/tea_ranges/dilmah_vivid_tea",
    component: VividTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahWatteBoutiqueTea: {
    name: "Dilmah Watte Boutique Tea",
    route: "/tea_ranges/dilmah_watte_boutique_tea",
    component: WatteBoutiqueTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahInspirationSelection: {
    name: "Dilmah Inspiration Selection",
    route: "/tea_ranges/dilmah_inspiration_selection",
    component: InspirationSelection,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahNaturalGreenTea: {
    name: "Dilmah Natural Green Tea",
    route: "/tea_ranges/dilmah_natural_green-tea",
    component: NaturalGreenTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahTeamakersReserve: {
    name: "Teamakers Reserve",
    route: "/tea_ranges/dilmah_teamakers_reserve",
    component: TeamakersReserve,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahTeamakersPrivateReserve: {
    name: "Teamakers Private Reserve",
    route: "/tea_ranges/dilmah_teamakers_private_reserve",
    component: TeamakersPrivateReserve,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  tSeriesGourmet: {
    name: "T Series Gourmet",
    route: "/tea_ranges/t_series_gourmet",
    component: TSeriesGourmet,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  elixir: {
    name: "Elixir Of Ceylon Tea",
    route: "/tea_ranges/elixir_of_ceylon_tea",
    component: ElixirOfCeylonTea,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  naturalInfusion: {
    name: "Natural Infusion",
    route: "/tea_ranges/natural_infusion",
    component: NaturalInfusion,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teaInspiration: {
    name: "Tea Inspiration",
    route: "/tea_inspired/tea_inspiration",
    component: TeaInspirationPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahRealHighTea: {
    name: "Dilmah Real High Tea",
    route: "/tea_inspired/dilmah_react_high_tea",
    component: DilmahRealHighTeaPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahTeaLounge: {
    name: "Dilmah Tea Lounge",
    route: "/tea_inspired/dilmah_tea_lounge",
    component: DilmhaTeaLoungePage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  mixologistAndTeaMaker: {
    name: "Mixologist And Tea Maker",
    route: "/tea_inspired/mixologist_and_tea_maker",
    component: MixologistAndTeaMakerPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  cafeAndTeaMaker: {
    name: "Cafe And Tea Maker",
    route: "/tea_inspired/cafe_and_tea_maker",
    component: CafeAndTeaMakerPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teaInFiveSenses: {
    name: "Tea In Five Senses",
    route: "/tea_inspired/tea_in_five_senses",
    component: TeaInFiveSensesPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  images: {
    name: "Tea Inspired Images",
    route: "/tea_inspired/images",
    component: TeaInspiredImagesPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  contactUs: {
    name: "Contact Us",
    route: "/other/contact_us",
    component: ContactUsPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  dilmahTrade: {
    name: "Dilmah Trade",
    route: "/other/dilmah_trade",
    component: DilmahTradePage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  careersPage: {
    name: "Careers",
    route: "/other/careers",
    component: CareersPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  imageUpload: {
    name: "Image Upload",
    route: "/other/image_upload",
    component: ImageUploadPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  videoUpload: {
    name: "Video Upload",
    route: "/other/video_upload",
    component: VideoUploadPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  fileUpload: {
    name: "File Upload",
    route: "/other/file_upload",
    component: FileUploadPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  teaFormats: {
    name: "Tea Formats",
    route: "/other/tea_formats",
    component: TeaFormats,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  siteMap: {
    name: "Site Map",
    route: "/other/site_map",
    component: SiteMapPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  socialWall: {
    name: "Social Wall",
    route: "/section/social_wall",
    component: SocialWallPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },

  teaBlog: {
    name: "Tea Blog",
    route: "/section/tea_blog",
    component: TeaBlogPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },

  teaBlogCategories: {
    name: "Tea Blog Categories",
    route: "/section/tea_blog_categories",
    component: TeaBlogCategoriesPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },

  media: {
    name: "Media",
    route: "/media/media",
    component: MediaPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  mediaImages: {
    name: "Media Images",
    route: "/media/media_images",
    component: MediaImagesPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  bannerCustormzation: {
    name: "Banner Customization",
    route: "/media/banner_customization",
    component: BannerCustomizationPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  termsAndConditions: {
    name: "Terms and Conditions",
    route: "/our_policics/terms_and_conditions",
    component: TermsAndConditionPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  privacyAndCookiePolicy: {
    name: "Privacy and Cookie Policy",
    route: "/our_policics/privacy_and_cookie_policy",
    component: PrivacyAndCookiePolicyPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  antiBribaryAndCorruptionPolicy: {
    name: "Anti Bribary and Corruption Policy",
    route: "/our_policics/anti_bribary_and_corruption_policy",
    component: AntiBribaryAndCorruptionPolicyPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  businessEthicsPolicy: {
    name: "Business Ethics Policy",
    route: "/our_policics/business_ethics_policy",
    component: BusinessEthicsPolicyPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  cookieDisclaimer: {
    name: "Cookie Disclaimer",
    route: "/our_policics/cookie_disclaimer",
    component: CookieDisclaimerPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },
  humanRightsPolicy: {
    name: "Human Rights Policy",
    route: "/our_policics/human_rights_policy",
    component: HumanRightsPolicyPage,
    children: [],
    nestedLevel: 1,
    icon: FiberManualRecordOutlined,
    isHide: false,
  },

};

export default nestedRouteNameObject1;
