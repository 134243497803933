import ElixirItem from "../elixir_item";
import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class ElixirOfCeylonTeaOurElixirModal {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header:MultiLanguage
    topic:MultiLanguage
    elixir_topic:MultiLanguage
    elixir_items:ElixirItem[]=[]
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.topic = new MultiLanguage(data.topic?data.topic:emptyMultiLang)
        this.elixir_topic = new MultiLanguage(data.elixir_topic?data.elixir_topic:emptyMultiLang)
        data.elixir_items.forEach((x:any)=>this.elixir_items.push(new ElixirItem(x)))
    }
}

export const emptyElixirOfCeylonTeaOurElixir = {
    header:emptyMultiLang,
    topic:emptyMultiLang,
    elixir_topic:emptyMultiLang,
    elixir_items:[]
}