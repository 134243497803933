import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import FeaturedVideoModel from "../featured_video";

export default class TSeriesGourmetSeasonalFlushModel {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header: MultiLanguage
    title_content: ContentModel
    quote_content: ContentModel
    videos: ContentModel[] = []
    featured_videos: FeaturedVideoModel[] = []
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.title_content = new ContentModel(data.title_content ? data.title_content : emptyContent)
        this.quote_content = new ContentModel(data.quote_content ? data.quote_content : emptyContent)
        data.videos.forEach((x: any) => this.videos.push(new ContentModel(x)))
        data.featured_videos.forEach((x: any) => this.featured_videos.push(new FeaturedVideoModel(x)));
    }
}

export const emptyTSeriesGourmetSeasonalFlush = {
    title_content: emptyContent,
    quote_content: emptyContent,
    header: emptyMultiLang,
    videos: [],
    featured_videos: [],
}