import { Card, ListItem, Switch, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import DynamicContentSectionModal, {
  emptyDynamicContentSection,
} from "../../models/dynamic_content_section_modal";
import MultiLanguage, { emptyMultiLang } from "../../models/multi_language";
import SubContentSectionModal, {
  emptySubContentSection,
} from "../../models/sub_content_section";
import AttributeItem from "../attribute_item/attribute_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import SubContentSection from "../sub_content_section/sub_content_section";
import useStyles from "./dynamic_content_section_styles";

export const dynamicTypes = ["basic","cards", "bullets", "icons", "centered","video"];

export default function DynamicContentSection(props: {
  content: DynamicContentSectionModal;
  index?: number;
  onRemove?: Function;
}) {
  const classes = useStyles();




  const changeHasHeader = (value: boolean) => {
    setContent((val) => ({ ...val, has_header: value }));
    props.content.has_header = value;
  };

  const [content, setContent] = useState(props.content);

  const changeHasLink = (value: boolean) => {
    setContent((val) => ({ ...val, has_link: value }));
    props.content.has_link = value;
  };

  const changeType = (value: any) => {
    setContent((val) => ({ ...val, type: value }));
    props.content.type = value;
  };

  const getImageUrl = (url: string) => {
    props.content.image_url = url;
  };

  const addNewHeading = () => {
    const prev = content;
    if (prev) {
      prev.headers.push(new MultiLanguage(emptyMultiLang));
      setContent({ ...prev });
    }
  };
  const removeHeading = (index: number) => {
    const prev = content;
    if (prev) {
      prev.headers.splice(index, 1);
      setContent(new DynamicContentSectionModal(emptyDynamicContentSection));
      setTimeout(() => {
        setContent({ ...prev });
      }, 0.01);
    }
  };

  const addNewSubContent = () => {
    const prev = content;
    if (prev) {
      prev.sub_contents.push(
        new SubContentSectionModal(emptySubContentSection)
      );
      setContent({ ...prev });
    }
  };
  const removeSubContent = (index: number) => {
    const prev = content;
    if (prev) {
      prev.sub_contents.splice(index, 1);
      setContent(new DynamicContentSectionModal(emptyDynamicContentSection));
      setTimeout(() => {
        setContent({ ...prev });
      }, 0.01);
    }
  };
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      {props.onRemove && (
        <IconBtn
          icon={Remove}
          onClick={() => {
            if (props.onRemove && props.index != null) {
              props.onRemove(props.index);
            }
          }}
        />
      )}

      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={content.image_url}
          mediaType={"IMAGE"}
          getFileUrl={getImageUrl}
        />
      </div> 
      <TextField
        style={{
          width: "200px",
          marginBottom: "20px",
          marginLeft: "20px",
        }}
        label="Rule Number"
        variant="outlined"
        inputProps={{
          pattern: "[0-9]*",
        }}
        value={props.content.rule_number ? props.content.rule_number : ''}
        onChange={(event) => {
          const text = parseInt(event.target.value);
          setContent((val) => ({ ...val, rule_number: text }));
          props.content.rule_number = text;
        }}
      >
      </TextField>
      <TextField
        style={{
          width: "200px",
          marginBottom: "20px",
          marginLeft: "20px",
        }}
        label="Type"
        variant="outlined"
        value={props.content.type}
        select
        onChange={(event) => {
          changeType(event.target.value);
        }}
      >
        {dynamicTypes.map((type, index) => (
          <ListItem
            style={{ textTransform: "capitalize" }}
            key={index}
            value={type}
          >
            {type}
          </ListItem>
        ))}
      </TextField>
      <div className={classes.row}>
        <div>Has Header</div>
        <Switch
          checked={content.has_header}
          onClick={() => {
            changeHasHeader(!content.has_header);
          }}
        ></Switch>
      </div>
      {content.has_header && (
        <AttributeItem title={"Headers"} onClick={addNewHeading} removeMargin>
          {content.headers.map((header, index) => (
            <MultiLangEdit
              key={index}
              label={"Header"}
              isShowLabel
              value={header}
              onRemove={removeHeading}
              index={index}
            />
          ))}
        </AttributeItem>
      )}

      <MultiLangEdit label={"Content"} isShowLabel value={content.content} />
      <div className={classes.row}>
        <div>Has Link</div>
        <Switch
          checked={content.has_link}
          onClick={() => {
            changeHasLink(!content.has_link);
          }}
        ></Switch>
      </div>
      {content.has_link && (
        <TextField
          style={{ margin: "5px 10px", width: "100%" }}
          variant="outlined"
          label="Link"
          value={content.link}
          disabled={!content.has_link}
          onChange={(event) => {
            const text = event.target.value;
            setContent((val) => ({ ...val, link: text }));
            props.content.link = text;
          }}
        />
      )}
      <MultiLangEdit
        label={"Link Text"}
        isShowLabel
        value={content.link_text}
      />

      <AttributeItem
        title={"Sub Contents"}
        onClick={addNewSubContent}
        removeMargin
      >
        {content.sub_contents.map((header, index) => (
          <SubContentSection
            content={header}
            index={index}
            key={index}
            onRemove={removeSubContent}
          />
        ))}
      </AttributeItem>
    </Card>
  );
}
