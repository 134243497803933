import ContentModel, { emptyContent } from "../content_model";
import Slider from "../slider";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class MJFFoundationModel {
  id: number;
  sliders: Slider[] = [];
  vision: ContentModel;
  sdg_s: ContentModel[] = [];
  videos: ContentModel[] = [];
  purpose: ContentModel;
  contributions: ContentModel[] = [];
  seo: SEOComponent;
  constructor(data: any) {
    this.id = data.id;
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    this.vision = new ContentModel(data.vision ? data.vision : emptyContent);
    data.sdg_s.forEach((x: any) => this.sdg_s.push(new ContentModel(x)));
    data.videos.forEach((x: any) => this.videos.push(new ContentModel(x)));
    this.purpose = new ContentModel(data.purpose ? data.purpose : emptyContent);
    data.contributions.forEach((x: any) =>
      this.contributions.push(new ContentModel(x))
    );
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
  }
}

export const emptyMJFFoundation = {
  sliders: [],
  vision: emptyContent,
  sdg_s: [],
  videos: [],
  purpose: emptyContent,
  contributions: [],
  seo: emptySEOComponent
};
