import React, { useEffect, useState, useCallback } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import Testimonials, {
  emptyTestimonials,
} from "../../models/pages/testimonials";
import TestimonialItemModel, {
  emptyTestimonialItem,
} from "../../models/testimonial_item";
import TestimonialItem from "../../components/testimonial_item/testimonial_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import { updateSeoItem, updateTestimonialItemArray } from "../../services/api_service/components_update";
import { countTestimonialItemsGraph, testimonialItemsGraph, testimonialsGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";
import { graphQuery } from "../../services/api_service/api_service";
import SimpleRemoveDialog from "../../components/simple_testimonial_dialogs/simple_remove_dialog";
import { deleteContentTypeCall } from "../../services/api_service/api_service";
import SimpleAddDialog from "../../components/simple_testimonial_dialogs/simple_add_dialog";

export default function TestimonialsPage() {
  const [pageData, setPageData] = useState<Testimonials>();
  const [testimonialItems, setTestimonialItems] = useState<TestimonialItemModel[]>([])
  const [allRecordsCount, setAllRecordsCount] = useState(0);
  const [open, setOpen] = useState(false)
  const [newDialogOpen, setNewDialogOpen] = useState(false)
  const [indexToDelete, setIndexToDelete] = useState(-1)
  const [testimonialToCreate, setTestimonialToCreate] = useState<TestimonialItemModel>(new TestimonialItemModel(emptyTestimonialItem))
  const { showSideMessage } = SideMessageService();
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1)
  const [paginationPageLimit, setPaginationPageLimit] = useState(2)
  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  const getPageData = (country: string) => {
    getPageDataCall(country, testimonialsGraph, "testimonialsPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new Testimonials(data.data ? data.data.page : emptyTestimonials)
          );
        }
      }
    );
  }

  const getTestimonialdata = useCallback(
    () => {
      setPaginationPageLimit(10)
      graphQuery(testimonialItemsGraph(paginationPageLimit, paginationCurrentPage)).then((data) => {
        if (data?.success) {
          const p: TestimonialItemModel[] = [];
          data.data.testimonialItems.forEach((x: any) =>
            p.push(new TestimonialItemModel(x))
          );
          setTestimonialItems(p);
        }
      });

      graphQuery(countTestimonialItemsGraph).then((data) => {
        if (data?.success) {
          setAllRecordsCount(Math.ceil(data.data.testimonialItems.length/paginationPageLimit));
        }
      });
    },
    [setTestimonialItems, paginationCurrentPage, paginationPageLimit]
  );

  useEffect(() => {
    getPageData(selectedCountry.value);
    getTestimonialdata()
  }, [selectedCountry, paginationCurrentPage, paginationPageLimit, getTestimonialdata]);

  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );

  const onSave = async (pageData: Testimonials) => {
    if (pageData) {
      setLoading(true);
      var dataToSave: any = pageData;
      dataToSave.testimonial_items = await updateTestimonialItemArray(
        testimonialItems
      );
      dataToSave.seo = await updateSeoItem(pageData.seo)

      await updateContentTypeCall(dataToSave, "testimonials").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "testimonials-pages"
          ).then((data) => {
            if (data?.success) {
              getTestimonialdata()
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewTestimonial = () => {
    setNewDialogOpen(false)
    let testimonialList = testimonialItems;
    testimonialList.unshift(testimonialToCreate);
    setTestimonialItems(testimonialList)
    if(pageData){
      onSave(clonedeep(pageData))
    }
    setPaginationCurrentPage(1)
  }

  const removeTestimonial = () => {
    deleteContentTypeCall(indexToDelete, "testimonial-items").then((data) => {
      if(data?.success){
        getTestimonialdata()
        getPageData(selectedCountry.value);
        showSideMessage(true);
        setOpen(false)
      } else {
        showSideMessage(false);
      }
    })
  }

  const setRemoveDialogOpen=(index:number)=> {
    setIndexToDelete(index)
    setOpen(true)
  }

  const setRemoveDialogClose = ()=> {
    setIndexToDelete(-1)
    setOpen(false)
  }

  const setAddDialogOpen = () => {
    setNewDialogOpen(true)
  }

  const setAddDialogClose = () => {
    setNewDialogOpen(false)
    let newTestimonial = new TestimonialItemModel(emptyTestimonialItem)
    setTestimonialToCreate(newTestimonial)
  }

  const goToNextPage = () => {
    setPaginationCurrentPage((paginationCurrentPage) => paginationCurrentPage + 1);
  }

  const goToPreviousPage = () => {
    if(paginationCurrentPage !== 0){
      setPaginationCurrentPage((paginationCurrentPage) => paginationCurrentPage - 1);
    }
  }

  const onPageChange = (pageNumber: number) => {
    setPaginationCurrentPage(pageNumber)
  }

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.testimonials}
      parentRoutes={[routeNameObjects.familyPassion]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <SimpleRemoveDialog 
            title={"Remove Testimonial"} 
            description={"Are you sure you want to remove this testimonial?"}
            isOpen={open}
            onSuccess={removeTestimonial}
            onFailure={setRemoveDialogClose}
          />
          <SimpleAddDialog 
            title={"Add a new testimonial"}
            isOpen={newDialogOpen}
            onSuccess={addNewTestimonial}
            onFailure={setAddDialogClose}
            testimonialItem={testimonialToCreate}
          />
          <AttributeItem title={"Testimonial Items"} onClick={setAddDialogOpen} onNext={goToNextPage} onPrevious={goToPreviousPage} 
          currentPage={paginationCurrentPage} onPageChange={onPageChange} totalPages={allRecordsCount}>
            {testimonialItems.map((content, index) => (
              <TestimonialItem
                key={index}
                testimonialItem={content}
                index={index}
                onRemove={() => {setRemoveDialogOpen(content.id)}}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
