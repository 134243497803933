import { Button, IconButton } from "@material-ui/core";
import {
  AddAPhotoRounded,
  AttachFileOutlined,
  Edit,
  FileCopyRounded,
  Remove,
  VideoLibraryRounded,
} from "@material-ui/icons";
import React, { useState } from "react";
import useStyles from "./icon_upload_btn_style";
import { v4 } from "uuid";
import clsx from "clsx";
import { uploadFileCall } from "../../services/api_service/api_service";
import IconBtn from "../icon_btn/icon_btn";

export default function IconUploadBtn(props: {
  mediaUrl: string;
  mediaType: string;
  allowVideo?: boolean;
  isEditable?: boolean;
  height?: number;
  index?: number;
  allowAnyFile?: boolean;
  getFileUrl: Function;
  onRemove?: Function;
}) {
  const id = v4();
  const classes = useStyles();

  const AddIcon = props.allowAnyFile
    ? AttachFileOutlined
    : props.allowVideo
    ? VideoLibraryRounded
    : AddAPhotoRounded;

  const processUrl = (u: string) => {
    // var isMount = false;
    // const newBase =
    //   "https://dilmah-web-dev.s3.ap-southeast-1.amazonaws.com/assets";
    // if (u) {
    //   if (u.startsWith("http://localhost:1337/uploads")) {
    //     u = `${newBase}/${u.split("/").slice(4).join("/")}`;
    //   } else if (u.startsWith("/uploads")) {
    //     u = `${newBase}/${u.split("/").slice(2).join("/")}`;
    //   }
    // }
    // if(!isMount){
    //   console.log(u)
    //   props.getFileUrl(u)
    // }
    // isMount = true
    return u;
  };

  const [url, setUrl] = useState(processUrl(props.mediaUrl));

  const onFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      uploadFileCall(event.target.files[0]).then((data) => {
        if (data?.success) {
          console.log(data.data);
          const url = `${data.data[0].url}`;
          if (props.getFileUrl) {
            props.getFileUrl(url);
          }
        }
      });

      var reader = new FileReader();
      reader.onload = function (e) {
        if (e.target?.result) {
          setUrl(e.target.result.toString());
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  return (
    <div style={{ position: "relative" }}>
      {props.onRemove && (
        <div style={{ zIndex: 1, position: "absolute", right: 0, top: 10 }}>
          <IconBtn
            icon={Remove}
            onClick={() => {
              if (props.onRemove) {
                props.onRemove(props.index);
              }
            }}
          />
        </div>
      )}
      {url ? (
        <div className={classes.imageOuter}>
          {props.mediaType === "IMAGE" ? (
            <img
              height={props.height ? props.height : 100}
              width={props.height ? props.height : 100}
              className={clsx(classes.image, classes.imageShadow)}
              src={url}
              alt={"icon"}
            />
          ) : props.mediaType === "VIDEO" ? (
            <video
              height={props.height ? props.height : 100}
              width={props.height ? props.height : 100}
              className={clsx(classes.image, classes.imageShadow)}
              src={url}
              autoPlay
              loop
              muted
            />
          ) : (
            <FileCopyRounded
              style={{
                height: props.height ? props.height : 100,
                width: props.height ? props.height : 100,
              }}
              className={classes.image}
            />
          )}
          {props.isEditable && (
            <IconButton
              className={classes.iconBtn}
              style={{
                height: props.height ? props.height / 4 : "30px",
                width: props.height ? props.height / 4 : "30px",
              }}
              onClick={() => {
                document.getElementById(id)?.click();
              }}
            >
              <Edit
                style={{
                  height: props.height ? props.height / 5 : "30px",
                  width: props.height ? props.height / 5 : "30px",
                }}
              />
            </IconButton>
          )}
        </div>
      ) : (
        <Button
          onClick={() => {
            if (props.isEditable) {
              document.getElementById(id)?.click();
            }
          }}
          className={classes.addIconBtn}
        >
          <AddIcon
            style={{
              height: props.height ? props.height : "80px",
              width: props.height ? props.height : "80px",
            }}
            className={classes.addIcon}
          />
        </Button>
      )}

      <input
        style={{ display: "none" }}
        type="file"
        id={id}
        accept={
          props.allowAnyFile
            ? "*"
            : props.allowVideo
            ? "video/*,image/*"
            : "image/*"
        }
        onChange={(event) => {
          onFileChange(event);
        }}
      />
    </div>
  );
}
