import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class WatteTeaRangeEstateModel {
    id: number;
    title: MultiLanguage;
    header_image_url: string;
    banner_image_url: string;
    intro_image_url: string;
    description: MultiLanguage;
    constructor(data: any) {
        this.id = data.id;
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
        this.header_image_url = data.header_image_url
        this.banner_image_url = data.banner_image_url
        this.intro_image_url = data.intro_image_url
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang);
    }
}

export const emptyWatteTeaRangeEstateModel = {
    title: emptyMultiLang,
    description: emptyMultiLang
};