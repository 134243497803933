import { Card, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import ExploreDilmahItemModel from "../../models/explore_dilmah_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function ExploreDilmahItem(props: {
  onRemove: Function;
  index: number;
  explore: ExploreDilmahItemModel;
}) {
  const [link, setLink] = useState(props.explore.link);
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.explore.image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.explore.image_url = url;
          }}
        />
      </div>

      <MultiLangEdit isShowLabel label="Title" value={props.explore.title} />

      <TextField
        style={{
          width: "98%",
          marginLeft:"1%",
          marginBottom: "20px",
        }}
        type="text"
        label="Link"
        variant="outlined"
        value={link}
        onChange={(event) => {
          setLink(event.target.value);
          props.explore.link = event.target.value;
        }}
      />
    </Card>
  );
}
