import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class DilmahTeaBlogModel {
  id: number;
  header: MultiLanguage;
  long_description: MultiLanguage;
  short_description: MultiLanguage;
  image_url: string;
  date: string;
  is_featured: boolean;
  tea_blog_category: string;
  author: MultiLanguage;
  share_count:number;

  constructor(data: any) {
    this.id = data.id;
    this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang);
    this.long_description = new MultiLanguage(
      data.long_description ? data.long_description : emptyMultiLang
    );
    this.short_description = new MultiLanguage(
      data.short_description ? data.short_description : emptyMultiLang
    );
    this.image_url = data.image_url;
    this.date = data.date;
    this.is_featured = data.is_featured;
    this.tea_blog_category = data.tea_blog_category
      ? data.tea_blog_category.id
      : "";
    this.author = new MultiLanguage(data.author ? data.author : emptyMultiLang);
    this.share_count=data.share_count?data.share_count:0
  }
}

export const emptyDilmahTeaBlog = {
  header: emptyMultiLang,
  description: emptyMultiLang,
  is_featured: false,
  author: emptyMultiLang,
  share_count:0
};
