import React from "react";
import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import VenueMarkers from "./VenueMarkers";

export default function LeafletMapView(props) {
  const selected = { lat: props.location.lat, lng: props.location.lon };
  const zoom = 12;

  function mapClicked(map) {
    props.setLocation({ lat: map.latlng.lat, lon: map.latlng.lng });
  }

  return (
    <Map
      onclick={mapClicked}
      center={{
        lat: selected.lat ? selected.lat : 6.927079,
        lng: selected.lng ? selected.lng : 79.861244,
      }}
      zoom={zoom}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

      {selected.lat && (
        <VenueMarkers
          venues={[
            {
              geometry: [selected.lat, selected.lng],
            },
          ]}
        />
      )}
    </Map>
  );
}
