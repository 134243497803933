import MultiLanguage, { emptyMultiLang } from "./multi_language"
import SEOComponent, { emptySEOComponent } from "./pages/seo_component"

export default class ImageWithLink {
    id: number
    image_url: string
    title: MultiLanguage
    link: string
    header_image_url: string
    description: MultiLanguage
    title_text: string
    seo: SEOComponent;
    constructor(data: any) {
        this.id = data.id
        this.image_url = data.image_url
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.link = data.link
        this.header_image_url = data.header_image_url
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.title_text = data.title_text ? data.title_text : ''
        this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
    }
}

export const emptyImageWithLink = {
    title: emptyMultiLang,
    description: emptyMultiLang,
    seo: emptySEOComponent,
}