import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles({
  addIcon:{
    position:'absolute',
    right:'5px',
    
    height:'25px',
    width:'25px',
    border:`2px solid ${myColors.drawerSideBarColor}`,
    backgroundColor:'white',
    color:myColors.drawerSideBarColor,
    '&:hover':{
      backgroundColor:myColors.drawerSideBarColor,
      color:'white',
      border:`2px solid white`,
    },
  }
});

export default useStyles;
