import { Card, MenuItem, Switch, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import DilmahTeaBlogModel from "../../models/dilmah_tea_blog";
import TeaBlogCategoryModal from "../../models/tea_blog_category";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import useStyles from "./dilmah_tea_blog_styles";
import { useStoreState } from "easy-peasy";
import { StoreModel } from "../../store models/model";

export default function DilmahTeaBlogItem(props: {
  blog: DilmahTeaBlogModel;
  index: number;
  onRemove: Function;
  categories: TeaBlogCategoryModal[];

}) {
  const classes = useStyles();
  const [date, setDate] = useState(props.blog.date);
  const [languageValue, setLanguageValue] = useState<string>('Global')

  const [isFeatured, setIsFeatured] = useState(props.blog.is_featured);

  const [selectedCategory, setSelectedCategory] = useState<number>(
    Number(props.blog.tea_blog_category)
  );

  const selectedLanguageIndex = useStoreState(
    (state: StoreModel) => state.currentData.selectedLanguageIndex
  );

  useEffect(() => {
    let languages: any[] = []
    props.categories.forEach((multilangItem) => {
      Object.keys(multilangItem.name)
        .slice(1)
        .forEach((k) => {
          languages.push(k);
        });
    })
    let langVal = languages[selectedLanguageIndex]
    setLanguageValue(langVal)
    console.log(props.categories)

  }, [selectedLanguageIndex])



  const onDateChange = (u: any) => {
    setDate(u);
    props.blog.date = u;
  };

  const onCategoryChange = (u: any) => {
    setSelectedCategory(u);
    props.blog.tea_blog_category = u;
  };

  const changeIsFeatured = (val: boolean) => {
    setIsFeatured(val);
    props.blog.is_featured = val;
  };
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.blog.image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.blog.image_url = url;
          }}
        />
      </div>
      <div className={classes.row}>
        <div>Is Featured</div>
        <Switch
          checked={isFeatured}
          onClick={() => changeIsFeatured(!isFeatured)}
        ></Switch>
        <TextField
          style={{ marginLeft: "10px", width: "120px", marginBottom: "20px" }}
          label="Share Count"
          variant="outlined"
          value={props.blog.share_count}
          InputProps={{ readOnly: true }}
        />
      </div>

      <TextField
        style={{ marginLeft: "10px", width: "200px", marginBottom: "20px" }}
        label="Category"
        variant="outlined"
        value={selectedCategory}
        onChange={(event) => onCategoryChange(event.target.value)}
        InputLabelProps={{ shrink: true }}
        select
      >
        {props.categories.map((cat, index) => (
          <MenuItem value={cat.id} key={index}>
            {Object.values(cat.name).slice(1)[selectedLanguageIndex]}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        style={{ marginLeft: "1%", width: "98%" }}
        type="date"
        label="Date"
        variant="outlined"
        value={date}
        onChange={(event) => onDateChange(event.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <MultiLangEdit isShowLabel label="Header" value={props.blog.header} />
      <MultiLangEdit
        isShowLabel
        label="Short Description"
        value={props.blog.short_description}
      />

      <MultiLangEdit
        isShowLabel
        label="Long Description"
        value={props.blog.long_description}
        isMarkdown
      />

      <MultiLangEdit isShowLabel label="Author" value={props.blog.author} />
    </Card>
  );
}
