import { Card, Switch, TextField } from "@material-ui/core";
import React, { useState } from "react";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import useStyles from "./seo_component_styles";
import SEOComponentModel from "../../models/pages/seo_component";

export default function SEOComponent(props: { seoItem: SEOComponentModel}) {
  const classes = useStyles();

  const [seoComponent, setSeoComponent] = useState<SEOComponentModel>(props.seoItem);

  const getImageUrl = (url:string)=>{
    props.seoItem.share_image_url=url
  }

  const changePreventIndexing = (newVal: any) => {
    setSeoComponent((sl) => ({ ...sl, prevent_indexing: newVal }));
    props.seoItem.prevent_indexing = newVal
  };

  const onMetaTitleChange = (val:any)=>{
    setSeoComponent((sl) => ({ ...sl, meta_title: val }));
    props.seoItem.meta_title = val
  }

  const onMetaDescriptionChange = (val:any)=>{
    setSeoComponent((sl) => ({ ...sl, meta_description: val }));
    props.seoItem.meta_description = val
  }

  const onShareImageAltChange = (val:any)=>{
    setSeoComponent((sl) => ({ ...sl, share_image_alt: val }));
    props.seoItem.share_image_alt = val
  }

  const onKeywordsChange = (val:any)=>{
    setSeoComponent((sl) => ({ ...sl, keywords: val }));
    props.seoItem.keywords = val
  }

  return (
    <Card elevation={5} style={{ marginTop: "10px", position:'relative', paddingTop:'30px' }}>
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={seoComponent.share_image_url}
          mediaType={"IMAGE"}
          getFileUrl={getImageUrl}
        />
        <div className={classes.row}>
            <TextField
            style={{ margin: "0px 20px " }}
            fullWidth
            label="Meta Title"
            variant="outlined"
            value={seoComponent.meta_title}
            onChange={ (event)=> onMetaTitleChange(event.target.value)}
            />
        </div>
        <div className={classes.row}>
            <TextField
            style={{ margin: "0px 20px " }}
            fullWidth
            label="Meta Description"
            variant="outlined"
            value={seoComponent.meta_description}
            onChange={ (event)=> onMetaDescriptionChange(event.target.value)}
            />
        </div>
        <div className={classes.row}>
            <TextField
            style={{ margin: "0px 20px " }}
            fullWidth
            label="Keywords"
            variant="outlined"
            value={seoComponent.keywords}
            onChange={ (event)=> onKeywordsChange(event.target.value)}
            />
        </div>
        <div className={classes.row}>
            <TextField
            style={{ margin: "0px 20px " }}
            fullWidth
            label="Share Image Alt"
            variant="outlined"
            value={seoComponent.share_image_alt}
            onChange={ (event)=> onShareImageAltChange(event.target.value)}
            />
        </div>
        <div className={classes.switch}>
            Prevent Indexing
        <Switch
          checked={seoComponent.prevent_indexing}
          onClick={() => changePreventIndexing(!seoComponent.prevent_indexing)}
        ></Switch>
        </div>
      </div>
    </Card>
  );
}
