import { EcoOutlined, BusinessOutlined, ContactMailOutlined, DevicesOtherOutlined, EmojiFoodBeverageOutlined, FolderOpenOutlined, FreeBreakfastOutlined, HomeOutlined, PeopleOutline, PermMediaOutlined } from "@material-ui/icons";
import LoginPage from "../../pages/login/login_page";
import ContactUsSubmitView from "../../pages/other/contact_us_submit_view";
import nestedRouteNameObject1 from "./nested_route_name_objects1";

const routeNameObjects = {
  login: {
    name: "Login",
    route: "/",
    component: LoginPage,
    children: [],
    nestedLevel: 0,
    icon: HomeOutlined,
    isHide: true,
  },
  home: {
    name: "Home",
    route: "/home",
    component: null,
    children: [
      nestedRouteNameObject1.home,
    ],
    nestedLevel: 0,
    icon: HomeOutlined,
    isHide: false,
  },

  familyPassion: {
    name: "Dilmah Family",
    route: "/family",
    component: null,
    children: [
      nestedRouteNameObject1.foundersMessage,
      nestedRouteNameObject1.dilmahStory,
      nestedRouteNameObject1.ourValues,
      nestedRouteNameObject1.certificatesAndAwards,
      nestedRouteNameObject1.teaGardens,
      nestedRouteNameObject1.whatWeDo,
      nestedRouteNameObject1.leisureAndHospitality,
      nestedRouteNameObject1.globalTimeLine,
      nestedRouteNameObject1.testimonials
    ],
    nestedLevel: 0,
    icon: PeopleOutline,
    isHide: false,
  },

  allAboutTea: {
    name: "All About Tea",
    route: "/all_about",
    component: null,
    children: [
      nestedRouteNameObject1.schoolOfTea,
      nestedRouteNameObject1.schoolOfTeaELearn,
      nestedRouteNameObject1.howToBrewTeatsOfTea,
      nestedRouteNameObject1.teaAndHealth,
      nestedRouteNameObject1.teaInspired,
      nestedRouteNameObject1.factsOfTea,
      nestedRouteNameObject1.dilmahPodcast,
      nestedRouteNameObject1.dilmahPodcastCategories
    ],
    nestedLevel: 0,
    icon: EmojiFoodBeverageOutlined,
    isHide: false,
  },

  ourPurpose: {
    name: "Our Purpose",
    route: "/our_purpose",
    component: null,
    children: [
      nestedRouteNameObject1.sustainability,
      nestedRouteNameObject1.dilmahConservation,
      nestedRouteNameObject1.mjfFoundation
    ],
    nestedLevel: 0,
    icon: BusinessOutlined,
    isHide: false,
  },

  ourProduct: {
    name: "Our Product",
    route: "/our_product",
    component: null,
    children: [
      nestedRouteNameObject1.ourTeaCategories,
      nestedRouteNameObject1.giftOfTeas,
      nestedRouteNameObject1.teaWares,
      nestedRouteNameObject1.dilmhaAccessories,
      nestedRouteNameObject1.whereToBuy,
      nestedRouteNameObject1.quiz,
      nestedRouteNameObject1.ingredients
    ],
    nestedLevel: 0,
    icon: BusinessOutlined,
    isHide: false,
  },

  teaRanges: {
    name: "Tea Ranges",
    route: "/tea_ranges",
    component: null,
    children: [
      nestedRouteNameObject1.dilmahPremiumCeylonTeaSelection,
      nestedRouteNameObject1.dilmahCeylonGold,
      nestedRouteNameObject1.dilmahCeylonGreenTea,
      nestedRouteNameObject1.dilmahFunFlavoredCeylonTea,
      nestedRouteNameObject1.dilmahGourmetTea,
      nestedRouteNameObject1.dilmahRealTeaCordial,
      nestedRouteNameObject1.dilmahOrganic,
      nestedRouteNameObject1.dilmahArana,
      nestedRouteNameObject1.dilmahSilverJubileeGourmet,
      nestedRouteNameObject1.dilmahExceptionalTeaSelection,
      nestedRouteNameObject1.dilmahCommemorativeTea,
      nestedRouteNameObject1.dilmahVividTea,
      nestedRouteNameObject1.dilmahWatteBoutiqueTea,
      nestedRouteNameObject1.dilmahInspirationSelection,
      nestedRouteNameObject1.dilmahNaturalGreenTea,
      nestedRouteNameObject1.dilmahTeamakersReserve,
      nestedRouteNameObject1.dilmahTeamakersPrivateReserve,
      nestedRouteNameObject1.tSeriesGourmet,
      nestedRouteNameObject1.elixir,
      nestedRouteNameObject1.naturalInfusion,
      nestedRouteNameObject1.dilmahCeylonGoldenPekoe,
      nestedRouteNameObject1.dilmahCeylonIcedTea
    ],
    nestedLevel: 0,
    icon: EcoOutlined,
    isHide: false,
  },

  teaInspired: {
    name: "Tea Inspired",
    route: "/tea_inspired",
    component: null,
    children: [
      nestedRouteNameObject1.teaInspiredHome,
      nestedRouteNameObject1.dilmahInHospitality,
      nestedRouteNameObject1.teaInspiration,
      nestedRouteNameObject1.dilmahRealHighTea,
      nestedRouteNameObject1.dilmahTeaLounge,
      nestedRouteNameObject1.mixologistAndTeaMaker,
      nestedRouteNameObject1.cafeAndTeaMaker,
      nestedRouteNameObject1.teaInFiveSenses,
      nestedRouteNameObject1.images,
      nestedRouteNameObject1.teamakersPhilosophy,
      nestedRouteNameObject1.aLifeDevotedToTea,
      nestedRouteNameObject1.mrDilmahWishSubmits,
      nestedRouteNameObject1.eBooks
    ],
    nestedLevel: 0,
    icon: FreeBreakfastOutlined,
    isHide: false,
  },

  media: {
    name: "Media",
    route: "/media",
    component: null,
    children: [
      nestedRouteNameObject1.media,
      nestedRouteNameObject1.mediaImages,
      nestedRouteNameObject1.bannerCustormzation
    ],
    nestedLevel: 0,
    icon: PermMediaOutlined,
    isHide: false,
  },
  other: {
    name: "Other",
    route: "/other",
    component: null,
    children: [
      nestedRouteNameObject1.contactUs,
      nestedRouteNameObject1.careersPage,
      nestedRouteNameObject1.imageUpload,
      nestedRouteNameObject1.teaFormats,
      nestedRouteNameObject1.siteMap,
      nestedRouteNameObject1.dilmahTrade,
      nestedRouteNameObject1.videoUpload,
      nestedRouteNameObject1.fileUpload
    ],
    nestedLevel: 0,
    icon: DevicesOtherOutlined,
    isHide: false,
  },

  sections: {
    name: "Sections",
    route: "/section",
    component: null,
    children: [
      nestedRouteNameObject1.socialWall,
      nestedRouteNameObject1.teaBlog,
      nestedRouteNameObject1.teaBlogCategories,
    ],
    nestedLevel: 0,
    icon: FolderOpenOutlined,
    isHide: false,
  },

  ourPlocics: {
    name: "Our Policics",
    route: "/our_policics",
    component: null,
    children: [
      nestedRouteNameObject1.termsAndConditions,
      nestedRouteNameObject1.privacyAndCookiePolicy,
      nestedRouteNameObject1.antiBribaryAndCorruptionPolicy,
      nestedRouteNameObject1.businessEthicsPolicy,
      nestedRouteNameObject1.humanRightsPolicy,
      nestedRouteNameObject1.cookieDisclaimer
    ],
    nestedLevel: 0,
    icon: FolderOpenOutlined,
    isHide: false,
  },

  contactUsSubmits: {
    name: "Contact Us Submits",
    route: "/contact_us_submits",
    component: ContactUsSubmitView,
    children: [],
    nestedLevel: 0,
    icon: ContactMailOutlined,
    isHide: false,
  },

};

export default routeNameObjects;
