import ImageItem from "./image_item"
import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class TimeLineElement{
    id:number
    year:number
    title:MultiLanguage
    content:MultiLanguage
    images: ImageItem[] = []

    constructor(data:any){
        this.id=data.id
        this.year=data.year
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.content = new MultiLanguage(data.content ? data.content : emptyMultiLang)
        data.images.forEach((x:any)=>this.images.push(new ImageItem(x)))
    }
}

export const emptyTimeLine = {
    year:'2020',
    title: new MultiLanguage(emptyMultiLang),
    content: new MultiLanguage(emptyMultiLang),
    images:[new ImageItem({image_url:''})]
}