import DilmahTeaBlogModel from "../dilmah_tea_blog";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class TeaBlogPageModal {
  id: number;
  dilmah_tea_blogs: DilmahTeaBlogModel[] = [];
  header_tea_blog: string;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.dilmah_tea_blogs.forEach((x: any) =>
      this.dilmah_tea_blogs.push(new DilmahTeaBlogModel(x))
    );
    this.header_tea_blog = data.header_tea_blog ? data.header_tea_blog.id : "";
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyTeaBlogPage = {
  dilmah_tea_blogs: [],
  seo: emptySEOComponent,
};
