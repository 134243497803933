import ContentModel, {emptyContent} from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class TeamakersPrivateReserveAboutModel {
    id: number
    header_image_url: string
    sub_header_image_url: string
    contents:ContentModel[] = []
    header:MultiLanguage
    initial_content: ContentModel
    constructor(data: any) {
        this.id = data.id
        data.contents.forEach((x: any) => this.contents.push(new ContentModel(x)))
        this.header_image_url = data.header_image_url
        this.sub_header_image_url = data.sub_header_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.initial_content = new ContentModel(data.initial_content? data.initial_content : emptyContent)
    }
}

export const emptyTemakersPrivateReserveAbout = {
    contents: [],
    header:emptyMultiLang,
    initial_content: emptyContent
}