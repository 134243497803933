import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import ImageWithTitle from "../image_with_title";

export default class NaturalInfusionEveryMoodModal {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header:MultiLanguage
    content:ContentModel
    mood_image_list:ImageWithTitle[]=[]

    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.content = new ContentModel(data.content?data.content:emptyContent)
        data.mood_image_list.forEach((x:any) => this.mood_image_list.push(new ImageWithTitle(x)))
        
    }
}

export const emptyNaturalInfusionEveryMood = {
    header:emptyMultiLang,
    content:emptyContent,
    mood_image_list:[]
}