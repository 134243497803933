import { TextareaAutosize } from "@material-ui/core";
import React from "react";
import useStyles from "./multi_lang_style";

export default function TextBoxItem(props: {
    label?: string;
    onVideoLinkChange: Function
    value?: string
}) {
    const classes = useStyles();
    const handleTextChange = (text: string) => {
        props.onVideoLinkChange(text)
    }

    return (
        <div
            style={{
                borderRadius: "10px",
                border: "1px solid #bcbcbc",
                margin: "10px",
            }}
        >
            <div style={{ flexGrow: 1, margin: "10px" }}>

                <TextareaAutosize
                    className={classes.textArea}
                    onChange={(event) => {
                        handleTextChange(event.target.value);
                    }}
                    value={props.value ? props.value : ""}
                />
            </div>
        </div>
    );
}
