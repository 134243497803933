import { Card, Switch, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import Slider from "../../models/slider";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import useStyles from "./slider_item_styles";

export default function SliderItem(props: { slider: Slider, isImageSlider?: boolean, index:number, onRemove:Function}) {
  const classes = useStyles();

  const [slider, setSlider] = useState<Slider>(props.slider);


  const changeHasVideo = (newVal: any) => {
    setSlider((sl) => ({ ...sl, slider_has_video: newVal }));
    props.slider.slider_has_video = newVal
    if(newVal === false){
      props.slider.video_url = ''
      setSlider((sl) => ({ ...sl, video_url: '' }));
    }
  };

  const changeHasLink = (newVal: any) => {
    setSlider((sl) => ({ ...sl, slider_has_link: newVal }));
    props.slider.slider_has_link = newVal
    if(newVal === false){
      props.slider.link = ''
      setSlider((sl) => ({ ...sl, link: '' }));
    }
  };

  const onUrlChange = (val:any)=>{
    setSlider((sl) => ({ ...sl, video_url: val }));
    props.slider.video_url = val
  }

  const onLinkChange = (val:any)=>{
    setSlider((sl) => ({ ...sl, link: val }));
    props.slider.link = val
  }

  const getImageUrl = (url:string)=>{
    props.slider.image_url=url
  }

  return (
    <Card elevation={5} style={{ marginTop: "10px", position:'relative', paddingTop:'30px' }}>
      <IconBtn icon={Remove}  onClick={()=>{
        props.onRemove(props.index)
      }}/>
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={slider.image_url}
          mediaType={"IMAGE"}
          getFileUrl={getImageUrl}
        />
      </div>
      {!props.isImageSlider && (
        <div className={classes.row}>
        <Switch
          checked={slider.slider_has_video}
          onClick={() => changeHasVideo(!slider.slider_has_video)}
        ></Switch>
        <TextField
          style={{ margin: "0px 20px " }}
          fullWidth
          label="Video Url"
          variant="outlined"
          disabled={!slider.slider_has_video}
          value={slider.video_url}
          onChange={ (event)=> onUrlChange(event.target.value)}
        />
      </div>
      )}
      <div className={classes.row}>
        <Switch
          checked={slider.slider_has_link}
          onClick={() => changeHasLink(!slider.slider_has_link)}
        ></Switch>
        <TextField
          style={{ margin: "0px 20px " }}
          fullWidth
          label="Link"
          variant="outlined"
          disabled={!slider.slider_has_link}
          value={slider.link}
          onChange={ (event)=> onLinkChange(event.target.value)}
        />
      </div>
      <MultiLangEdit label="Header" value={slider.header}/>
    </Card>
  );
}
