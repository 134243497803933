import ContentModel, { emptyContent } from "../content_model";
import Slider from "../slider";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class DilmahConservationModel {
  id: number;
  sliders: Slider[] = [];
  our_work: ContentModel[] = [];
  sustainability_components: ContentModel[] = [];
  title_content:ContentModel;
  seo: SEOComponent
  constructor(data: any) {
    this.id = data.id;
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    data.our_work.forEach((x: any) => this.our_work.push(new ContentModel(x)));
    data.sustainability_components.forEach((x: any) =>
      this.sustainability_components.push(new ContentModel(x))
    );
    this.title_content = new ContentModel(data.title_content?data.title_content : emptyContent)
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
  }
}

export const emptyDilmahConservation = {
  sliders: [],
  our_work: [],
  sustainability_components: [],
  title_content:emptyContent,
  seo: emptySEOComponent
};
