export default class SEOComponent {
    id:number
    meta_title:string
    meta_description:string
    share_image_alt:string
    share_image_url:string
    keywords:string
    prevent_indexing:boolean
    constructor(data:any){
        this.id=data.id
        this.meta_title = data.meta_title
        this.meta_description = data.meta_description
        this.share_image_alt = data.share_image_alt
        this.share_image_url = data.share_image_url
        this.keywords=data.keywords
        this.prevent_indexing = data.prevent_indexing
    }
}

export const emptySEOComponent = {
}