import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React from "react";
import CareerUserItem from "../../models/career_user_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function CareerUserItemComponent(props: {
  onRemove: Function;
  index: number;
  careerUser: CareerUserItem;
}) {
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.careerUser.image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.careerUser.image_url = url;
          }}
        />
      </div>

      <MultiLangEdit isShowLabel label="Name" value={props.careerUser.name} />
      <MultiLangEdit isShowLabel label="Description" value={props.careerUser.description} />
     
    </Card>
  );
}
