import { Card, TextField } from "@material-ui/core";
import React, { useState } from "react";
import MediaCoverModel from "../../models/media_cover";
import AttributeItem from "../attribute_item/attribute_item";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import SEOComponent from "../seo_component/seo_component";

export default function MediaCoverItem(props: {
  cover: MediaCoverModel;
}) {
  const [link, setLink] = useState(props.cover.link);

  const onUrlChange = (u: any) => {
    setLink(u);
    props.cover.link = u;
  };
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.cover.image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.cover.image_url = url;
          }}
        />
      </div>
      <TextField
        style={{ marginLeft: "1%", width: "98%" }}
        label="Link"
        variant="outlined"
        value={link}
        onChange={(event) => onUrlChange(event.target.value)}
      />
      <MultiLangEdit isShowLabel label="Title" value={props.cover.title} />
      <MultiLangEdit isShowLabel label="Header" value={props.cover.header} />
      <MultiLangEdit
        isShowLabel
        label="Description"
        value={props.cover.description}
      />
      <AttributeItem title={"SEO Component"}>
          <SEOComponent seoItem={props.cover.seo} />
      </AttributeItem>
    </Card>
  );
}
