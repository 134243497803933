export default class MrDilmahWishSubmitModal {
    id: number;
    name: string;
    email: string;
    country: string;
    wish_type: string;
    letter_content: string;
    letter_designation: string;
    letter_user_image_url: string;
    is_approved: boolean;

    constructor(data: any) {
      this.id = data.id;
      this.name = data.name;
      this.email = data.email;
      this.country = data.country;
      this.wish_type = data.wish_type;
      this.letter_content = data.letter_content;
      this.letter_designation = data.letter_designation;
      this.letter_user_image_url = data.letter_user_image_url;
      this.is_approved = data.is_approved;
    }
  }
  
  