import React, { useCallback, useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import { graphQuery } from "../../services/api_service/api_service";
import {
  mrDilmahWishSubmitGraph,
  countMrDilmahWishSubmitGraph,
} from "../../services/api_service/graphql_queries";
import MrDilmahWishSubmitModal from "../../models/pages/mr_dilmah_wish_submit";
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Chip,
  Switch,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Cancel,
  Done,
} from "@material-ui/icons";
import { updateContentTypeCall } from "../../services/api_service/api_service";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
const mdParser = new MarkdownIt();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  header: {
    fontWeight: "bold",
  },
  chipSuccess: {
    backgroundColor: "#90ee90",
  },
  chipFailure: {
    backgroundColor: "#ffcccb",
  },
  box: {
    border: "1px solid #c5d0e0",
    borderRadius: "20px",
    padding: "30px",
  },
});

export default function MrDilmahWishSubmitView() {
  const rowsPerPageSet = [10, 25, 100];
  const [pageData, setPageData] = useState<MrDilmahWishSubmitModal[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageSet[0]);
  const [allRecordsCount, setAllRecordsCount] = useState(0);

  const getPageData = useCallback(
    (page: number) => {
      graphQuery(mrDilmahWishSubmitGraph(rowsPerPage, page + 1)).then(
        (data) => {
          if (data?.success) {
            const p: MrDilmahWishSubmitModal[] = [];
            data.data.mrDilmahWishSubmits.forEach((x: any) =>
              p.push(new MrDilmahWishSubmitModal(x))
            );
            setPageData(p);
          }
        }
      );

      graphQuery(countMrDilmahWishSubmitGraph).then((data) => {
        if (data?.success) {
          setAllRecordsCount(data.data.mrDilmahWishSubmits.length);
        }
      });
    },
    [setPageData, rowsPerPage]
  );

  useEffect(() => {
    getPageData(pageNumber);
  }, [pageNumber, getPageData]);

  const classes = useRowStyles();
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.mrDilmahWishSubmits}
      parentRoutes={[routeNameObjects.teaInspired]}
    >
      <TableContainer style={{ marginTop: "50px" }} component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Name</TableCell>
              <TableCell className={classes.header}>Country</TableCell>
              <TableCell className={classes.header}>Email</TableCell>
              <TableCell className={classes.header}>Wish Type</TableCell>
              <TableCell className={classes.header}>Is Approved</TableCell>
              <TableCell width={100} />
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((contact, index) => (
              <Row
                key={index}
                row={contact}
                getPageData={getPageData}
                rowsPerPage={rowsPerPage}
                pageNumber={pageNumber}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageSet}
        component="div"
        count={allRecordsCount}
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        onChangePage={(event: unknown, newPage: number) => {
          setPageNumber(newPage);
        }}
        onChangeRowsPerPage={(event: any) => {
          setRowsPerPage(event.target.value);
          setPageNumber(0);
        }}
      />
    </CommonPage>
  );
}

function Row(props: {
  row: MrDilmahWishSubmitModal;
  getPageData: Function;
  pageNumber: number;
  rowsPerPage: number;
}) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const [state, setState] = React.useState({
    row: props.row,
  });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let row = state.row;
    row.is_approved = event.target.checked;
    setState({ row: row });
    onUpdate();
  };

  const onUpdate = async () => {
    const dataToSave: MrDilmahWishSubmitModal = state.row;
    await updateContentTypeCall(dataToSave, "mr-dilmah-wish-submits").then(
      (data) => {
        if (data?.success) {
          props.getPageData(props.pageNumber);
        }
      }
    );
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.country}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.wish_type}</TableCell>
        <TableCell>
          {row.is_approved ? (
            <Chip
              icon={<Done />}
              color="primary"
              size="small"
              label="Approved"
              className={classes.chipSuccess}
            ></Chip>
          ) : (
            <Chip
              icon={<Cancel />}
              color="secondary"
              size="small"
              label="Approval Pending"
              className={classes.chipFailure}
            ></Chip>
          )}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} className={classes.box}>
              <Typography variant="body2" gutterBottom component="div">
                <strong>Designation:</strong> {row.letter_designation}
              </Typography>
              <Typography variant="body2" gutterBottom component="div">
                <strong>Content:</strong>
                <MdEditor
                  view={{
                    html: true,
                    md: false,
                    menu: false,
                  }}
                  renderHTML={(text) => mdParser.render(row.letter_content)}
                />
              </Typography>
              <Typography variant="body2" gutterBottom component="div">
                <strong>Approval state:</strong>
              </Typography>
              <Switch
                checked={state.row.is_approved}
                onChange={handleChange}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
