import CareerUserItem from "../../models/career_user_item";
import CertificateAndAwardSection from "../../models/certificate_and_award_section";
import CertificateItem from "../../models/certificate_item";
import ContactUsComponentModel from "../../models/contact_us_component";
import ContentModel from "../../models/content_model";
import ContentWithImagesModel from "../../models/content_with_images";
import DilmahTeaBlogModel from "../../models/dilmah_tea_blog";
import DynamicContentSectionModal from "../../models/dynamic_content_section_modal";
import ElixirItem from "../../models/elixir_item";
import ExploreDilmahItemModel from "../../models/explore_dilmah_item";
import EBookElement from "../../models/e_book_element";
import EBookSection from "../../models/e_book_section";
import FeaturedVideoModel from "../../models/featured_video";
import HeaderWithContent from "../../models/header_with_content";
import ImageItem from "../../models/image_item";
import ImageWithLink from "../../models/image_with_link";
import ImageWithTitle from "../../models/image_with_title";
import MapLocationModel from "../../models/map_location_component";
import MediaCoverModel from "../../models/media_cover";
import MultiLanguage, { toMultilanguage } from "../../models/multi_language";
import ElixirOfCeylonTeaBrewingGuidelineModal from "../../models/pages/elixir_of_ceylon_tea_brewing_guideline";
import ElixirOfCeylonTeaElixirRecipesModal from "../../models/pages/elixir_of_ceylon_tea_elixir_recipes";
import ElixirOfCeylonTeaOurElixirModal from "../../models/pages/elixir_of_ceylon_tea_our_elixir";
import ElixirOfCeylonTeaRealTeaModal from "../../models/pages/elixir_of_ceylon_tea_real_tea";
import NaturalInfusionEveryMoodModal from "../../models/pages/natural_infusion_every_mood";
import NaturalInfusionOurInfusionsModal from "../../models/pages/natural_infusion_our_infusions";
import NaturalInfusionOurIngredientsModal from "../../models/pages/natural_infusion_our_ingredients";
import OurTeaCategoriesTeaByFlavors from "../../models/pages/our_tea_categories_tea_by_flavors";
import OurTeaCategoriesTeaByMoods from "../../models/pages/our_tea_categories_tea_by_moods";
import OurTeaCategoriesTeaFormats from "../../models/pages/our_tea_categories_tea_formats";
import OurTeaCategoriesTeaRange from "../../models/pages/our_tea_categories_tea_range";
import OurTeaCategoriesTeaTypes from "../../models/pages/our_tea_categories_tea_types";
import TeamakersPrivateReserveAboutModel from "../../models/pages/tea_maker_private_reserve_about";
import TeamakersPrivateReserveTeaModel from "../../models/pages/tea_maker_private_reserve_tea";
import TeamakersPrivateReserveTeaLocationModel from "../../models/pages/tea_maker_private_reserve_tea_location";
import TSeriesGourmetRareTeas from "../../models/pages/t_series_gourmet_rare_teas";
import TSeriesGourmetSeasonalFlush from "../../models/pages/t_series_gourmet_seasonal_flush";
import TSeriesGourmetTeas from "../../models/pages/t_series_gourmet_teas";
import PhotographerModal from "../../models/photographer";
import PodcastSection from "../../models/podcast_section";
import QuoteContentModel from "../../models/quote_content_model";
import Slider from "../../models/slider";
import SocialWallItemModel from "../../models/social_wall_item";
import SubContentSectionModal from "../../models/sub_content_section";
import TeaBlogCategoryModal from "../../models/tea_blog_category";
import TeaLocationModel from "../../models/tea_location.ts";
import TeaStateItemModel from "../../models/tea_state_item_model";
import TestimonialItemModel from "../../models/testimonial_item";
import TimeLineElement from "../../models/time_line_element";
import { updateContentTypeCall } from "./api_service";
import DilmahPodcastCategoryModal from '../../models/dilmah_podcast_category'
import QuizModel from "../../models/quiz_model";
import WatteTeaRangeEstateModel from "../../models/pages/watte_tea_range_estate";
import WatteTeaRangeRegionModel from "../../models/pages/watte_tea_range_region";
import CeylonIcedTeaGlassBottleModel from "../../models/pages/ceylon_iced_tea_glass_bottle";
import CeylonIcedTeaTetraPackModel from "../../models/pages/ceylon_iced_tea_tetra_pack";
import WatteTeaRegionsModel from "../../models/pages/watte_tea_resgion_model";
import IngredientItemModel from "../../models/ingredient_item";
import SiteMapSection from "../../models/site_map_section";
import SingleImageWithLink from "../../models/single_image_with_link";
import DilmahTradePremiumRetailers from "../../models/pages/dilmah_trade_premium_retailers";
import DilmahTradeFineFoodAndGourmet from "../../models/pages/dilmah_trade_fine_food_and_gourmet";
import DilmahTradeHighQualityCasualDining from "../../models/pages/dilmah_trade_high_quality_casual_dining";
import DilmahTradeFineDining from "../../models/pages/dilmah_trade_fine_dining";
import DilmahTradeOurProducts from "../../models/pages/dilmah_trade_our_products";
import SEOComponent from "../../models/pages/seo_component";
import { isTemplateExpression } from "typescript";

export const updateContentItem = async (content: ContentModel) => {
  var cn: any = content;

  cn.header = await updateMultiLangItem(content.header);
  cn.content = await updateMultiLangItem(content.content);
  cn.link_text = await updateMultiLangItem(content.link_text);
  cn.sub_heading = await updateMultiLangItem(content.sub_heading);

  const updatedContent = await updateContentTypeCall(cn, "contents");
  if (updatedContent?.success) {
    return updatedContent.data.id;
  } else {
    return null;
  }
};

export const updateContentArray = async (contents: ContentModel[]) => {
  var contentIds = [];
  for (var j = 0; j < contents.length; j++) {
    const id = await updateContentItem(contents[j]);
    if (id) {
      contentIds.push(id);
    }
  }
  return contentIds;
};



export const updateQuoteContentItem = async (content: QuoteContentModel) => {
  var cn: any = content;

  cn.header = await updateMultiLangItem(content.header);
  cn.content = await updateMultiLangItem(content.content);
  cn.link_text = await updateMultiLangItem(content.link_text);
  cn.sub_heading = await updateMultiLangItem(content.sub_heading);
  cn.quote = await updateMultiLangItem(content.quote);
  cn.author = await updateMultiLangItem(content.author);


  const updatedContent = await updateContentTypeCall(cn, "content-with-quotes");
  if (updatedContent?.success) {
    return updatedContent.data.id;
  } else {
    return null;
  }
};

export const updateQuoteContentArray = async (contents: QuoteContentModel[]) => {
  var contentIds = [];
  for (var j = 0; j < contents.length; j++) {
    const id = await updateQuoteContentItem(contents[j]);
    if (id) {
      contentIds.push(id);
    }
  }
  return contentIds;
};

export const updateDynamicContentSection = async (
  content: DynamicContentSectionModal
) => {
  var cn: any = content;

  cn.headers = await updateMultiLangItemArray(content.headers);
  cn.content = await updateMultiLangItem(content.content);
  cn.link_text = await updateMultiLangItem(content.link_text);
  cn.sub_contents = await updateSubContentSectionArray(content.sub_contents);

  const updatedContent = await updateContentTypeCall(
    cn,
    "dynamic-content-sections"
  );
  if (updatedContent?.success) {
    return updatedContent.data.id;
  } else {
    return null;
  }
};

export const updateDynamicContentSectionArray = async (
  contents: DynamicContentSectionModal[]
) => {
  var contentIds = [];
  for (var j = 0; j < contents.length; j++) {
    const id = await updateDynamicContentSection(contents[j]);
    if (id) {
      contentIds.push(id);
    }
  }
  return contentIds;
};

export const updateSubContentSection = async (
  content: SubContentSectionModal
) => {
  var cn: any = content;

  cn.header = await updateMultiLangItem(content.header);
  cn.contents = await updateMultiLangItemArray(content.contents);

  const updatedContent = await updateContentTypeCall(
    cn,
    "sub-content-sections"
  );
  if (updatedContent?.success) {
    return updatedContent.data.id;
  } else {
    return null;
  }
};

export const updateSubContentSectionArray = async (
  contents: SubContentSectionModal[]
) => {
  var contentIds = [];
  for (var j = 0; j < contents.length; j++) {
    const id = await updateSubContentSection(contents[j]);
    if (id) {
      contentIds.push(id);
    }
  }
  return contentIds;
};

export const updateQuizContent = async (
  content: QuizModel
) => {
  var cn: any = content;

  cn.question = await updateMultiLangItem(content.question);
  cn.question_header = await updateMultiLangItem(content.question_header);
  cn.anwsers = await updateMultiLangItemArray(content.anwsers);

  const updatedContent = await updateContentTypeCall(
    cn,
    "quizzes"
  );
  if (updatedContent?.success) {
    return updatedContent.data.id;
  } else {
    return null;
  }
};

export const updateQuizContentArray = async (
  contents: QuizModel[]
) => {
  var contentIds = [];
  for (var j = 0; j < contents.length; j++) {
    const id = await updateQuizContent(contents[j]);
    if (id) {
      contentIds.push(id);
    }
  }
  return contentIds;
};

export const updateSliderItem = async (slider: Slider) => {
  var sl: any = slider;
  sl.header = await updateMultiLangItem(slider.header);
  const updatedSlider = await updateContentTypeCall(sl, "small-sliders");
  if (updatedSlider?.success) {
    return updatedSlider.data.id;
  } else {
    return null;
  }
};

export const updateSeoItem = async (seo: SEOComponent) => {
  let s: any = seo;
  const updatedSeo = await updateContentTypeCall(s, "seos")
  if (updatedSeo?.success) {
    return updatedSeo.data.id;
  } else {
    return null;
  }
}

export const updateSliderArray = async (sliders: Slider[]) => {
  var sliderIds = [];
  for (var i = 0; i < sliders.length; i++) {
    const id = await updateSliderItem(sliders[i]);
    if (id) {
      sliderIds.push(id);
    }
  }
  return sliderIds;
};

export const updateMultiLangItem = async (lang: MultiLanguage) => {
  const updatedLang = await updateContentTypeCall(
    toMultilanguage(lang),
    "multi-language-items"
  );
  if (updatedLang?.success) {
    return updatedLang.data.id;
  } else {
    return null;
  }
};

export const updateMultiLangItemArray = async (langs: MultiLanguage[]) => {
  var mulIds = [];
  for (var i = 0; i < langs.length; i++) {
    const id = await updateMultiLangItem(langs[i]);
    if (id) {
      mulIds.push(id);
    }
  }
  return mulIds;
};

export const updateCertificateItem = async (
  certificateItem: CertificateItem
) => {
  const certItem: any = certificateItem;
  certItem.description = await updateMultiLangItem(certificateItem.description);
  certItem.sub_heading = await updateMultiLangItem(certificateItem.sub_heading);
  const updatedCertificate = await updateContentTypeCall(
    certItem,
    "certificate-or-awards"
  );
  if (updatedCertificate?.success) {
    return updatedCertificate.data.id;
  } else {
    return null;
  }
};

export const updateCertificateItemArray = async (
  certificates: CertificateItem[]
) => {
  var certificateIds = [];
  for (var i = 0; i < certificates.length; i++) {
    const id = await updateCertificateItem(certificates[i]);
    if (id) {
      certificateIds.push(id);
    }
  }
  return certificateIds;
};

export const updateCertificateSection = async (
  section: CertificateAndAwardSection
) => {
  const sec: any = section;
  sec.header = await updateMultiLangItem(section.header);
  sec.description = await updateMultiLangItem(section.description);
  sec.sub_header = await updateMultiLangItem(section.sub_header);
  sec.certificate_or_awards = await updateCertificateItemArray(
    section.certificate_or_awards
  );
  const updatedSection = await updateContentTypeCall(
    sec,
    "certificate-and-award-sections"
  );
  if (updatedSection?.success) {
    return updatedSection.data.id;
  } else {
    return null;
  }
};

export const updateCertificateSectionArray = async (
  sectionArray: CertificateAndAwardSection[]
) => {
  var sectionIds = [];
  for (var i = 0; i < sectionArray.length; i++) {
    const id = await updateCertificateSection(sectionArray[i]);
    if (id) {
      sectionIds.push(id);
    }
  }
  return sectionIds;
};

export const updateImageItemArray = async (images: ImageItem[]) => {
  var imageIds = [];
  for (var i = 0; i < images.length; i++) {
    const id = await updateContentTypeCall(images[i], "image-items");
    if (id?.data) {
      imageIds.push(id.data.id);
    }
  }
  return imageIds;
};

export const updateTimeLineElement = async (element: TimeLineElement) => {
  const tl: any = element;
  tl.images = await updateImageItemArray(element.images);
  tl.title = await updateMultiLangItem(element.title);
  tl.content = await updateMultiLangItem(element.content);

  const updated = await updateContentTypeCall(tl, "time-line-elements");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateTimeLineElementArray = async (
  elements: TimeLineElement[]
) => {
  var elementIds = [];
  for (var i = 0; i < elements.length; i++) {
    const id = await updateTimeLineElement(elements[i]);
    if (id) {
      elementIds.push(id);
    }
  }
  return elementIds;
};

export const updatePodcastSection = async (section: PodcastSection) => {
  const se: any = section;
  se.contents = await updateContentArray(section.contents);
  se.title = await updateMultiLangItem(section.title);
  const updated = await updateContentTypeCall(se, "podcast-sections");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updatePodcastSectionArray = async (sections: PodcastSection[]) => {
  var sectionIds = [];
  for (var i = 0; i < sections.length; i++) {
    const id = await updatePodcastSection(sections[i]);
    if (id) {
      sectionIds.push(id);
    }
  }
  return sectionIds;
};

export const updateSiteMapSection = async (section: SiteMapSection) => {
  const se: any = section;
  se.section_title = await updateMultiLangItem(section.section_title);
  se.section_items = await updateSingleImageWithLinkItemArray(section.section_items)
  const updated = await updateContentTypeCall(se, "site-map-sections");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateSiteMapSectionArray = async (sections: SiteMapSection[]) => {
  var sectionIds = [];
  for (var i = 0; i < sections.length; i++) {
    const id = await updateSiteMapSection(sections[i]);
    if (id) {
      sectionIds.push(id);
    }
  }
  return sectionIds;
};

export const updateTeaStateItem = async (state: TeaStateItemModel) => {
  const st: any = state;
  st.name = await updateMultiLangItem(state.name);
  st.tour_link_multilang = await updateMultiLangItem(state.tour_link_multilang)
  st.description = await updateMultiLangItem(state.description);
  st.images = await updateImageItemArray(state.images);
  const updated = await updateContentTypeCall(st, "tea-state-items");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateTeaStateItemArray = async (items: TeaStateItemModel[]) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateTeaStateItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateEBookItem = async (item: EBookElement) => {
  const e: any = item;
  e.content = await updateMultiLangItem(item.content);
  e.title = await updateMultiLangItem(item.title);
  e.book_images = await updateImageItemArray(item.book_images);
  const updated = await updateContentTypeCall(e, "e-book-elements");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateEBookItemArray = async (items: EBookElement[]) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateEBookItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateEBookSection = async (section: EBookSection) => {
  const sec: any = section;
  sec.title = await updateMultiLangItem(section.title);
  sec.e_book_elements = await updateEBookItemArray(section.e_book_elements);
  const updated = await updateContentTypeCall(sec, "e-book-sections");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateEBookSectionArray = async (items: EBookSection[]) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateEBookSection(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateTestimonialItem = async (item: TestimonialItemModel) => {
  const tes: any = item;
  tes.content = await updateMultiLangItem(item.content);
  tes.name = await updateMultiLangItem(item.name);
  tes.short_description = await updateMultiLangItem(item.short_description);
  tes.title = await updateMultiLangItem(item.title);
  const updated = await updateContentTypeCall(tes, "testimonial-items");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateTestimonialItemArray = async (
  items: TestimonialItemModel[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateTestimonialItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateContactDetailsItem = async (
  content: ContactUsComponentModel
) => {
  var cn: any = content;
  cn.address = await updateMultiLangItem(content.address);
  const updatedContent = await updateContentTypeCall(
    cn,
    "contact-us-components"
  );
  if (updatedContent?.success) {
    return updatedContent.data.id;
  } else {
    return null;
  }
};


export const updateMapLocationItem = async (
  content: MapLocationModel
) => {
  var cn: any = content;
  cn.description = await updateMultiLangItem(content.description);
  cn.country = await updateMultiLangItem(content.country)
  const updatedContent = await updateContentTypeCall(
    cn,
    "map-location-components"
  );
  if (updatedContent?.success) {
    return updatedContent.data.id;
  } else {
    return null;
  }
};

export const updateMapLocationItemArray = async (content: MapLocationModel[]) => {
  var itemIds = [];
  for (var i = 0; i < content.length; i++) {
    const id = await updateMapLocationItem(content[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateCareerUserItem = async (item: CareerUserItem) => {
  const tes: any = item;
  tes.name = await updateMultiLangItem(item.name);
  tes.description = await updateMultiLangItem(item.description);
  const updated = await updateContentTypeCall(tes, "career-user-items");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateCareerUserItemArray = async (items: CareerUserItem[]) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateCareerUserItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateExploreDilmahItem = async (item: ExploreDilmahItemModel) => {
  const tes: any = item;
  tes.title = await updateMultiLangItem(item.title);
  const updated = await updateContentTypeCall(tes, "explore-dilmah-items");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateExploreDilmahItemArray = async (
  items: ExploreDilmahItemModel[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateExploreDilmahItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateSocialWallItem = async (item: SocialWallItemModel) => {
  const tes: any = item;
  tes.description = await updateMultiLangItem(item.description);
  const updated = await updateContentTypeCall(tes, "social-wall-items");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateSocialWallItemArray = async (
  items: SocialWallItemModel[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateSocialWallItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateIngredientItem = async (item: IngredientItemModel) => {
  const tes: any = item;
  tes.title = await updateMultiLangItem(item.title);
  tes.research_links = await updateMultiLangItem(item.research_links)
  tes.about_ingredient = await updateHeaderWithContentItem(item.about_ingredient)
  tes.medicinal_claims = await updateHeaderWithContentItem(item.medicinal_claims)
  tes.possible_side_effects = await updateHeaderWithContentItem(item.possible_side_effects)
  tes.video_item = await updateContentItem(item.video_item)
  const updated = await updateContentTypeCall(tes, "ingredients-items");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateIngredientItemArray = async (
  items: IngredientItemModel[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateIngredientItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateFeaturedVideoItem = async (item: FeaturedVideoModel) => {
  const tes: any = item;
  tes.description = await updateMultiLangItem(item.description);
  tes.header = await updateMultiLangItem(item.header);
  const updated = await updateContentTypeCall(tes, "featured-videos");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateFeaturedVideoItemArray = async (
  items: FeaturedVideoModel[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateFeaturedVideoItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateMediaCover = async (item: MediaCoverModel) => {
  const tes: any = item;
  tes.title = await updateMultiLangItem(item.title);
  tes.description = await updateMultiLangItem(item.description);
  tes.header = await updateMultiLangItem(item.header);
  tes.seo = await updateSeoItem(item.seo);
  const updated = await updateContentTypeCall(tes, "media-covers");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateTeaBlogItem = async (item: DilmahTeaBlogModel) => {
  const tes: any = item;
  tes.short_description = await updateMultiLangItem(item.short_description);
  tes.long_description = await updateMultiLangItem(item.long_description);
  tes.header = await updateMultiLangItem(item.header);
  tes.author = await updateMultiLangItem(item.author);
  const updated = await updateContentTypeCall(tes, "dilmah-tea-blogs");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateTeaBlogItemArray = async (items: DilmahTeaBlogModel[]) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateTeaBlogItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};
 
export const updatePhotographerItem = async (item: PhotographerModal) => {
  const tes: any = item;
  tes.description = await updateMultiLangItem(item.description);
  tes.name = await updateMultiLangItem(item.name);
  tes.images = await updateImageItemArray(item.images);
  tes.seo = await updateSeoItem(item.seo);
  const updated = await updateContentTypeCall(tes, "photographers");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updatePhotographerItemArray = async (
  items: PhotographerModal[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updatePhotographerItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateTeaBlogCategoryItem = async (item: TeaBlogCategoryModal) => {
  const tes: any = item;
  tes.name = await updateMultiLangItem(item.name);
  const updated = await updateContentTypeCall(tes, "tea-blog-categories");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateTeaBlogCategoryItemArray = async (
  items: TeaBlogCategoryModal[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateTeaBlogCategoryItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateContentWithImagesItem = async (
  item: ContentWithImagesModel
) => {
  const tes: any = item;
  tes.short_description = await updateMultiLangItem(item.short_description);
  tes.long_description = await updateMultiLangItem(item.long_description);
  tes.title = await updateMultiLangItem(item.title);
  tes.images = await updateImageItemArray(item.images);
  tes.seo = await updateSeoItem(item.seo);
  const updated = await updateContentTypeCall(tes, "content-with-images");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateContentWithImagesItemArray = async (
  items: ContentWithImagesModel[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateContentWithImagesItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateTeaMakersPrivateAbout = async (
  about: TeamakersPrivateReserveAboutModel
) => {
  const abt: any = about;
  abt.header = await updateMultiLangItem(about.header);
  abt.contents = await updateContentArray(about.contents);
  abt.initial_content = await updateContentItem(about.initial_content)
  const updatedAbout = await updateContentTypeCall(
    abt,
    "tea-makers-private-reserve-abouts"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateTeaMakersPrivateTeas = async (
  about: TeamakersPrivateReserveTeaModel
) => {
  const abt: any = about;
  abt.header = await updateMultiLangItem(about.header);
  abt.sub_content = await updateContentItem(about.sub_content);
  const updatedAbout = await updateContentTypeCall(
    abt,
    "tea-maker-private-reserve-teas"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateTeaLocationItem = async (state: TeaLocationModel) => {
  const st: any = state;
  st.title = await updateMultiLangItem(state.title);
  st.description = await updateMultiLangItem(state.description);
  st.image_text_item = await updateMultiLangItem(state.image_text_item)
  const updated = await updateContentTypeCall(st, "tea-locations");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateTeaLocationItemArray = async (items: TeaLocationModel[]) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateTeaLocationItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateTeaMakersPrivateTeaLocation = async (
  about: TeamakersPrivateReserveTeaLocationModel
) => {
  const abt: any = about;
  abt.header = await updateMultiLangItem(about.header);
  abt.topic = await updateMultiLangItem(about.topic);
  abt.tea_locations = await updateTeaLocationItemArray(about.tea_locations);

  const updatedAbout = await updateContentTypeCall(
    abt,
    "tea-makers-private-reserve-tea-locations"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateTSeriesGourmetTeas = async (data: TSeriesGourmetTeas) => {
  const d: any = data;
  d.header = await updateMultiLangItem(data.header);
  d.content = await updateContentItem(data.content);
  const updatedAbout = await updateContentTypeCall(d, "t-series-gourmet-teas");
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateTSeriesGourmetRareTeas = async (
  data: TSeriesGourmetRareTeas
) => {
  const d: any = data;
  d.header = await updateMultiLangItem(data.header);
  d.content = await updateContentItem(data.content);
  const updatedAbout = await updateContentTypeCall(
    d,
    "t-series-gourmet-rare-teas"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateTSeriesGourmetSeasonalFlush = async (
  data: TSeriesGourmetSeasonalFlush
) => {
  const d: any = data;
  d.header = await updateMultiLangItem(data.header);
  d.title_content = await updateContentItem(data.title_content);
  d.quote_content = await updateContentItem(data.quote_content);
  d.videos = await updateContentArray(data.videos);
  const updatedAbout = await updateContentTypeCall(
    d,
    "t-series-gourmet-seasonal-flushes"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateElixirItem = async (item: ElixirItem) => {
  const tes: any = item;
  tes.title = await updateMultiLangItem(item.title);
  tes.description = await updateMultiLangItem(item.description);
  const updated = await updateContentTypeCall(tes, "elixir-items");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateElixirItemArray = async (items: ElixirItem[]) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateElixirItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateImageWithTitleItem = async (item: ImageWithTitle) => {
  const tes: any = item;
  tes.title = await updateMultiLangItem(item.title);
  const updated = await updateContentTypeCall(tes, "image-with-titles");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateImageWithTitleItemArray = async (
  items: ImageWithTitle[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateImageWithTitleItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateImageWithLinkItem = async (item: ImageWithLink) => {
  const tes: any = item;
  tes.seo = await updateSeoItem(item.seo);
  tes.title = await updateMultiLangItem(item.title);
  tes.description = await updateMultiLangItem(item.description)
  const updated = await updateContentTypeCall(tes, "image-with-links");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateImageWithLinkItemArray = async (
  items: ImageWithLink[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateImageWithLinkItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateSingleImageWithLinkItem = async (item: SingleImageWithLink) => {
  const tes: any = item;
  tes.title = await updateMultiLangItem(item.title);

  const updated = await updateContentTypeCall(tes, "single-image-with-links");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateSingleImageWithLinkItemArray = async (
  items: SingleImageWithLink[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateSingleImageWithLinkItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
}

export const updateHeaderWithContentItem = async (item: HeaderWithContent) => {
  const tes: any = item;
  tes.title = await updateMultiLangItem(item.title);
  tes.description = await updateMultiLangItem(item.description);
  const updated = await updateContentTypeCall(tes, "header-with-contents");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateHeaderWithContentItemArray = async (
  items: HeaderWithContent[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateHeaderWithContentItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

export const updateElixirOfCeylonTeaOurElixir = async (
  data: ElixirOfCeylonTeaOurElixirModal
) => {
  const d: any = data;
  d.header = await updateMultiLangItem(data.header);
  d.elixir_topic = await updateMultiLangItem(data.elixir_topic);
  d.topic = await updateMultiLangItem(data.topic);
  d.elixir_items = await updateElixirItemArray(data.elixir_items);
  const updatedAbout = await updateContentTypeCall(
    d,
    "elixir-of-ceylon-tea-our-elixirs"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateElixirOfCeylonTeaBrewingGuideline = async (
  data: ElixirOfCeylonTeaBrewingGuidelineModal
) => {
  const d: any = data;
  d.content_1 = await updateContentItem(data.content_1);
  d.content_2 = await updateContentItem(data.content_2);
  d.header = await updateMultiLangItem(data.header);
  d.images = await updateImageWithTitleItemArray(data.images);
  d.topic = await updateMultiLangItem(data.topic);
  d.videos = await updateContentArray(data.videos);
  const updatedAbout = await updateContentTypeCall(
    d,
    "elixir-of-ceylon-tea-brewing-guidelines"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateElixirOfCeylonTeaRealTea = async (
  data: ElixirOfCeylonTeaRealTeaModal
) => {
  const d: any = data;

  d.contents = await updateContentArray(data.contents);
  d.header = await updateMultiLangItem(data.header);
  d.topic = await updateMultiLangItem(data.topic);
  d.title_content = await updateContentItem(data.title_content);
  const updatedAbout = await updateContentTypeCall(
    d,
    "elixir-of-ceylon-tea-real-teas"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateElixirOfCeylonTeaElixirRecipes = async (
  data: ElixirOfCeylonTeaElixirRecipesModal
) => {
  const d: any = data;

  d.header = await updateMultiLangItem(data.header);
  d.topic = await updateMultiLangItem(data.topic);
  const updatedAbout = await updateContentTypeCall(
    d,
    "elixir-of-ceylon-tea-elixir-recipes"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateNaturalInfusionOurInfusions = async (
  data: NaturalInfusionOurInfusionsModal
) => {
  const d: any = data;
  d.header = await updateMultiLangItem(data.header);
  d.topic = await updateMultiLangItem(data.topic);
  d.content = await updateContentItem(data.content);
  const updatedAbout = await updateContentTypeCall(
    d,
    "natural-infusion-our-infusions"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateNaturalInfusionOurIngredients = async (
  data: NaturalInfusionOurIngredientsModal
) => {
  const d: any = data;
  d.header = await updateMultiLangItem(data.header);
  d.topic = await updateMultiLangItem(data.topic);
  d.content = await updateContentItem(data.content);
  d.video_content = await updateContentItem(data.video_content)
  d.herbs_descriptions = await updateHeaderWithContentItemArray(
    data.herbs_descriptions
  );
  d.image_with_titles = await updateImageWithTitleItemArray(
    data.image_with_titles
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "natural-infusion-our-ingredients"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateNaturalInfusionEveryMood = async (
  data: NaturalInfusionEveryMoodModal
) => {
  const d: any = data;
  d.header = await updateMultiLangItem(data.header);
  d.content = await updateContentItem(data.content);
  d.mood_image_list = await updateImageWithTitleItemArray(
    data.mood_image_list
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "natural-infusion-every-moods"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateOurTeaCategoriesTeaRange = async (
  data: OurTeaCategoriesTeaRange
) => {
  const d: any = data;
  d.seo = await updateSeoItem(data.seo);
  d.title = await updateMultiLangItem(data.title);
  d.header_descrpiton = await updateHeaderWithContentItem(data.header_descrpiton);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "our-tea-categori-tea-ranges"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateOurTeaCategoriesTeaTypes = async (
  data: OurTeaCategoriesTeaTypes
) => {
  const d: any = data;
  d.seo = await updateSeoItem(data.seo);
  d.title = await updateMultiLangItem(data.title);
  d.header_descrpiton = await updateHeaderWithContentItem(data.header_descrpiton);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "our-tea-categori-tea-types"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateOurTeaCategoriesTeaByFlavor = async (
  data: OurTeaCategoriesTeaByFlavors
) => {
  const d: any = data;
  d.seo = await updateSeoItem(data.seo);
  d.title = await updateMultiLangItem(data.title);
  d.header_descrpiton = await updateHeaderWithContentItem(data.header_descrpiton);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "our-tea-categori-tea-by-flavors"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateOurTeaCategoriesTeaFormats = async (
  data: OurTeaCategoriesTeaFormats
) => {
  const d: any = data;
  d.seo = await updateSeoItem(data.seo);
  d.title = await updateMultiLangItem(data.title);
  d.header_descrpiton = await updateHeaderWithContentItem(data.header_descrpiton);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "our-tea-categori-tea-formats"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateOurTeaCategoriesTeaByMood = async (
  data: OurTeaCategoriesTeaByMoods
) => {
  const d: any = data;
  d.seo = await updateSeoItem(data.seo);
  d.title = await updateMultiLangItem(data.title);
  d.header_descrpiton = await updateHeaderWithContentItem(data.header_descrpiton);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "our-tea-categori-tea-by-moods"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateDilmahPodcastItem = async (item: DilmahPodcastCategoryModal) => {
  const tes: any = item;
  tes.name = await updateMultiLangItem(item.name);
  const updated = await updateContentTypeCall(tes, "dilmah-podcast-categories");
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
};

export const updateDilmahPodcastItemArray = async (
  items: DilmahPodcastCategoryModal[]
) => {
  var itemIds = [];
  for (var i = 0; i < items.length; i++) {
    const id = await updateDilmahPodcastItem(items[i]);
    if (id) {
      itemIds.push(id);
    }
  }
  return itemIds;
};

//############################################

export const updateCeylonIcedTeaGlassBottle = async (
  data: CeylonIcedTeaGlassBottleModel
) => {
  const d: any = data
  d.title = await updateMultiLangItem(data.title)
  d.description = await updateMultiLangItem(data.description)
  const updated = await updateContentTypeCall(d, "ceylon-iced-tea-glass-bottles")
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
}

export const updateCeylonIcedTeaTetraPack = async (
  data: CeylonIcedTeaTetraPackModel
) => {
  const d: any = data
  d.title = await updateMultiLangItem(data.title)
  d.description = await updateMultiLangItem(data.description)
  const updated = await updateContentTypeCall(d, "ceylon-iced-tea-tetra-packs")
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
}

//############################################

export const updateWatteBoutiqueTeaEstate = async (
  data: WatteTeaRangeEstateModel
) => {
  const d: any = data
  d.title = await updateMultiLangItem(data.title)
  d.description = await updateMultiLangItem(data.description)

  const updated = await updateContentTypeCall(d, "watte-boutique-estate-teas")
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
}

export const 

updateWatteBoutiqueTeaRegion = async (
  data: WatteTeaRangeRegionModel
) => {
  const d: any = data
  d.title = await updateMultiLangItem(data.title)
  d.description = await updateMultiLangItem(data.description)
  d.topic = await updateMultiLangItem(data.topic)
  d.tea_locations = await updateTeaLocationItemArray(data.tea_locations);

  const updated = await updateContentTypeCall(d, "watte-boutique-region-teas")
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
}

export const updateWatteBoutiqueTeaRegions = async (
  data: WatteTeaRegionsModel
) => {
  const d: any = data
  d.header = await updateMultiLangItem(data.header)
  d.topic = await updateMultiLangItem(data.topic)
  d.tea_locations = await updateTeaLocationItemArray(data.tea_locations);

  const updated = await updateContentTypeCall(d, "watte-boutique-regions")
  if (updated?.success) {
    return updated.data.id;
  } else {
    return null;
  }
}

export const updateDilmahTradeFineFoodAndGourmet = async (
  data: DilmahTradeFineFoodAndGourmet
) => {
  const d: any = data;
  d.header_description = await updateMultiLangItem(data.header_description);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "dilmah-trade-fine-food-and-gourmets"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateDilmahHighQualityCasualDining = async (
  data: DilmahTradeHighQualityCasualDining
) => {
  const d: any = data;
  d.header_description = await updateMultiLangItem(data.header_description);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "dilmah-trade-high-quality-casual-dinings"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateDilmahTradeFineDining = async (
  data: DilmahTradePremiumRetailers
) => {
  const d: any = data;
  d.header_description = await updateMultiLangItem(data.header_description);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "dilmah-trade-premium-retailers"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};

export const updateDilmahTradePremiumRetailers = async (
  data: DilmahTradeFineDining
) => {
  const d: any = data;
  d.header_description = await updateMultiLangItem(data.header_description);
  d.items = await updateImageWithLinkItemArray(
    data.items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "dilmah-trade-fine-dinings"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};


export const updateDilmahTradeOurProducts = async (
  data: DilmahTradeOurProducts
) => {
  const d: any = data;
  d.our_products_items = await updateImageWithLinkItemArray(
    data.our_products_items
  );
  const updatedAbout = await updateContentTypeCall(
    d,
    "dilmah-trade-our-products"
  );
  if (updatedAbout?.success) {
    return updatedAbout.data.id;
  } else {
    return null;
  }
};