import TimeLineElement from "../time_line_element"
import SEOComponent, {emptySEOComponent} from "./seo_component"

export default class DilmahGlobalTimeLineModel {
    id:number
    time_line:TimeLineElement[] = []
    seo : SEOComponent
    constructor(data:any){
        this.id=data.id
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
        data.time_line.forEach((x:any)=>this.time_line.push(new TimeLineElement(x)))
    }
}

export const emptyDilmahGlobalTimeline = {
    time_line :[],
    seo: emptySEOComponent
}
