import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class HeaderWithContent {
    id: number
    title: MultiLanguage
    description: MultiLanguage
    constructor(data: any) {
        this.id = data.id
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
    }
}

export const emptyHeaderWithContent = {
    title: emptyMultiLang,
    description: emptyMultiLang
}