import React, {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Card,
    TextField
  } from "@material-ui/core";
import TestimonialItemModel from '../../models/testimonial_item';
import IconUploadBtn from '../icon_upload_btn/icon_upload_btn';
import MultiLangEdit from '../multi_lang_edit/multi_lang_edit';

  export default function SimpleAddDialog(props:{
      onSuccess: Function;
      onFailure: Function;
      title: string;
      isOpen: boolean;
      testimonialItem: TestimonialItemModel;
  }) {  

    const [date,setDate] = useState(props.testimonialItem.date)

    const onDateChange = (u:any)=>{
      setDate(u)
      props.testimonialItem.date = u
    }
    return (
      <div>
        <Dialog
          open={props.isOpen}
          onClose={()=> {
              props.onFailure()
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">
            {props.title}
          </DialogTitle>
          <DialogContent>
            <Card
                elevation={5}
                style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
            >
                <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                height={150}
                isEditable
                mediaUrl={props.testimonialItem.image_url}
                mediaType={"IMAGE"}
                getFileUrl={(url: any) => {
                    props.testimonialItem.image_url = url;
                }}
                />
            </div>
            <TextField
                style={{ marginLeft: "1%", width:'300px' }}
                type="date"
                label="Date"
                variant="outlined"
                value={date}
                onChange={ (event)=> onDateChange(event.target.value)}
                InputLabelProps={{
                    shrink:true
                }}
                />
            <MultiLangEdit
                isShowLabel
                label="Name"
                value={props.testimonialItem.name}
            />
            <MultiLangEdit
                isShowLabel
                label="Title"
                value={props.testimonialItem.title}
            />
            <MultiLangEdit
                isShowLabel
                label="Short Description"
                value={props.testimonialItem.short_description}
            />
            <MultiLangEdit
                isShowLabel
                label="Content"
                value={props.testimonialItem.content}
            />
            </Card>
          </DialogContent>

          <DialogActions>
            <Button onClick={()=> {
                props.onFailure()
            }}>Disagree</Button>
            <Button onClick={()=> {
                props.onSuccess()
            }} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }