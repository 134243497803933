import ContentModel, { emptyContent } from "../content_model";
import SingleImageWithLink, {
  emptySingleImageWithLink,
} from "../single_image_with_link";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class TeaInspiredHome {
  id: number;
  teamakers_philosophy: SingleImageWithLink;
  years_in_tea: SingleImageWithLink;
  commemorative_teas: SingleImageWithLink;
  wish_dilmah: SingleImageWithLink;
  letters_to_dilmah: SingleImageWithLink;
  books: SingleImageWithLink;
  banner_section: ContentModel;
  video_section: ContentModel;
  view_library_link: string;
  view_all_letters_link: string;
  submit_letter_link: string;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.teamakers_philosophy = new SingleImageWithLink(
      data.teamakers_philosophy
        ? data.teamakers_philosophy
        : emptySingleImageWithLink
    );
    this.years_in_tea = new SingleImageWithLink(
      data.years_in_tea ? data.years_in_tea : emptySingleImageWithLink
    );
    this.commemorative_teas = new SingleImageWithLink(
      data.commemorative_teas
        ? data.commemorative_teas
        : emptySingleImageWithLink
    );
    this.wish_dilmah = new SingleImageWithLink(
      data.wish_dilmah ? data.wish_dilmah : emptySingleImageWithLink
    );
    this.letters_to_dilmah = new SingleImageWithLink(
      data.letters_to_dilmah ? data.letters_to_dilmah : emptySingleImageWithLink
    );
    this.books = new SingleImageWithLink(
      data.books ? data.books : emptySingleImageWithLink
    );
    this.banner_section = new ContentModel(
      data.banner_section ? data.banner_section : emptyContent
    );
    this.video_section = new ContentModel(
      data.video_section ? data.video_section : emptyContent
    );
    this.view_library_link = data.view_library_link
      ? data.view_library_link
      : "";
    this.view_all_letters_link = data.view_all_letters_link
      ? data.view_all_letters_link
      : "";
    this.submit_letter_link = data.submit_letter_link
      ? data.submit_letter_link
      : "";
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyTeaInspiredHome = {
  teamakers_philosophy: emptySingleImageWithLink,
  years_in_tea: emptySingleImageWithLink,
  wish_dilmah: emptySingleImageWithLink,
  commemorative_teas: emptySingleImageWithLink,
  letters_to_dilmah: emptySingleImageWithLink,
  books: emptySingleImageWithLink,
  banner_section: emptyContent,
  video_section: emptyContent,
  seo: emptySEOComponent,
};
