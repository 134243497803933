import React from "react";
import useStyles from "./page_name_styles";
import clsx from 'clsx'

interface Props{
    title: String;
    isCenter?:boolean
}
export default function PageName(props: Props) {
    const classes = useStyles();
  return (
  <div>
      <div  className={clsx(props.isCenter ?classes.center: classes.namefield)}>{props.title}</div>
      {/* <div className={classes.line}></div> */}
  </div>
  );
}
