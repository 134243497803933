import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24,
    backgroundColor: myColors.appBarColor,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 290,
    width: `calc(100% - ${290}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  greyIcon: {
    color: myColors.appBarTextColor,
    margin: "0 3px",
    transition:'1s',
    "&:hover":{
      color:`${myColors.drawerSideBarColor} !important`
    }
  },
  titleCase: {
    textTransform: "capitalize",
  },
  iconRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor:'pointer',
    
  },
  mainIcon:{
    height:'30px',
    width:'30px',
    marginRight:'20px'
  },
  notificationIcon:{
    marginLeft:'20px'
  },
  
}));

export default useStyles;
