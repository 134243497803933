import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import ContentItem from "../../components/content_item/content_item";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import ContentModel, { emptyContent } from "../../models/content_model";
import clonedeep from "lodash.clonedeep";
import ALifeDevotedToTea, {
  emptyALifeDevotedToTea,
} from "../../models/pages/a_life_devoted_to_tea";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateContentArray,
  updateContentItem,
  updateMultiLangItem,
  updateSeoItem,
} from "../../services/api_service/components_update";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import { aLifeDevotedToTeaGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import SEOComponent from "../../components/seo_component/seo_component";

export default function ALifeDevotedToTeaPage() {
  const [story, setStory] = useState<ALifeDevotedToTea>();

  const { showSideMessage } = SideMessageService();

  const getDilmahStory = (country: string) => {
    getPageDataCall(
      country,
      aLifeDevotedToTeaGraph,
      "aLifeDevotedToTeaPages"
    ).then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setStory(undefined);
        setStory(
          new ALifeDevotedToTea(
            data.data ? data.data.page : emptyALifeDevotedToTea
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getDilmahStory(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (story: ALifeDevotedToTea) => {
    if (story) {
      setLoading(true);
      var storyData: any = story;
      storyData.image_contents = await updateContentArray(story.image_contents);
      storyData.banner_title = await updateMultiLangItem(story.banner_title);
      storyData.intro_content = await updateContentItem(story.intro_content);
      storyData.seo = await updateSeoItem(story.seo);

      await updateContentTypeCall(storyData, "a-life-devoted-to-teas").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "a-life-devoted-to-tea-pages"
            ).then((data) => {
              if (data?.success) {
                getDilmahStory(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const addNewContent = () => {
    const prev = story;
    if (prev) {
      prev.image_contents.push(new ContentModel(emptyContent));

      setStory({ ...prev });
    }
  };

  const removeContent = (index: number) => {
    const prev = story;
    if (prev) {
      prev.image_contents.splice(index, 1);
      setStory(new ALifeDevotedToTea(emptyALifeDevotedToTea));
      setTimeout(() => {
        setStory({ ...prev });
      }, 0.01);
    }
  };

  const setBannerImage = (url: string) => {
    const prev = story;
    if (prev) {
      prev.banner_image_url = url;
      setStory({ ...prev });
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.aLifeDevotedToTea}
      parentRoutes={[routeNameObjects.teaInspired]}
    >
      {story ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(story))}
              />
            }
          />
          <AttributeItem title={"Banner Title"}>
            <MultiLangEdit value={story.banner_title} />
          </AttributeItem>
          <AttributeItem title={"Banner Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setBannerImage}
                mediaType="IMAGE"
                mediaUrl={story.banner_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Intro Content"}>
            <ContentItem content={story.intro_content} />
          </AttributeItem>
          <AttributeItem title={"Image Content"} onClick={addNewContent}>
            {story.image_contents.map((content, index) => (
              <ContentItem
                key={index}
                content={content}
                index={index}
                onRemove={removeContent}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={story.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
