import ImageWithLink from "../image_with_link";
import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class DilmahTradeFineFoodAndGourmet {
    id: number;
    items: ImageWithLink[]=[];
    header_description: MultiLanguage

    constructor(data:any){
        this.id = data.id;
        data.items.forEach((x:any) => this.items.push(new ImageWithLink(x)));
        this.header_description= new MultiLanguage(data.header_description? data.header_description : emptyMultiLang)
    }
}

export const emptyDilmahTradeFineFoodAndGourmet = {
    items:[],
    header_description:emptyMultiLang
}