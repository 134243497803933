import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateMultiLangItem,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { teaformatsGraph } from "../../services/api_service/graphql_queries";
import TeaFormatModel, {
  emptyTeaFormatModel,
} from "../../models/pages/tea_format";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import SEOComponent from "../../components/seo_component/seo_component";

export default function TeaFormats() {
  const [pageData, setPageData] = useState<TeaFormatModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, teaformatsGraph, "teaFormatPages").then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new TeaFormatModel(data.data ? data.data.page : emptyTeaFormatModel)
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: TeaFormatModel) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.title = await updateMultiLangItem(pageData.title);
      dataToSave.seo = await updateSeoItem(pageData.seo);

      await updateContentTypeCall(dataToSave, "tea-formats").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "tea-format-pages"
          ).then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const setStringAndTeaBagsorCupBags = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.string_and_tea_bags_or_cup_bags = url;
      setPageData({ ...prev });
    }
  };

  const setIndividuallyFoilWrappedBags = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.individually_foil_wrapped_bags = url;
      setPageData({ ...prev });
    }
  };

  const setAvailableInBulkFoodServicePacks = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.available_in_bulk_food_service_packs = url;
      setPageData({ ...prev });
    }
  };

  const setPetBottles = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.pet_bottles = url;
      setPageData({ ...prev });
    }
  };

  const setGlassBottles = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.glass_bottles = url;
      setPageData({ ...prev });
    }
  };

  const setPotBagsorTaglessBags = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.pot_bags_or_tagless_bags = url;
      setPageData({ ...prev });
    }
  };

  const setBottleWithPumpDispenser = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.bottle_with_pump_dispenser = url;
      setPageData({ ...prev });
    }
  };

  const setTeaSachets = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.tea_sachets = url;
      setPageData({ ...prev });
    }
  };

  const setLuxuryLeafTeaBags = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.luxury_leaf_tea_bags = url;
      setPageData({ ...prev });
    }
  };

  const setIndividuallyPaperWrappedBags = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.individually_paper_wrapped_bags = url;
      setPageData({ ...prev });
    }
  };

  const setLooseLeafTea = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.loose_leaf_tea = url;
      setPageData({ ...prev });
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.teaFormats}
      parentRoutes={[routeNameObjects.other]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"String & Tea Bags / Cup Bags Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setStringAndTeaBagsorCupBags}
                mediaType="IMAGE"
                mediaUrl={pageData.string_and_tea_bags_or_cup_bags}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Individually Foil Wrapped Bags Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setIndividuallyFoilWrappedBags}
                mediaType="IMAGE"
                mediaUrl={pageData.individually_foil_wrapped_bags}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Available In Buld Food Service Packs Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setAvailableInBulkFoodServicePacks}
                mediaType="IMAGE"
                mediaUrl={pageData.available_in_bulk_food_service_packs}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Pet Bottles Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setPetBottles}
                mediaType="IMAGE"
                mediaUrl={pageData.pet_bottles}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Glass Bottles Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setGlassBottles}
                mediaType="IMAGE"
                mediaUrl={pageData.individually_foil_wrapped_bags}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Pot Bags / Tagless Bags Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setPotBagsorTaglessBags}
                mediaType="IMAGE"
                mediaUrl={pageData.pot_bags_or_tagless_bags}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Bottle with Pump Dispenser Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setBottleWithPumpDispenser}
                mediaType="IMAGE"
                mediaUrl={pageData.bottle_with_pump_dispenser}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Tea Sachets Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setTeaSachets}
                mediaType="IMAGE"
                mediaUrl={pageData.tea_sachets}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Luxury Leaf Tea Bags Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setLuxuryLeafTeaBags}
                mediaType="IMAGE"
                mediaUrl={pageData.luxury_leaf_tea_bags}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Individually Paper Wrapped Bags Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setIndividuallyPaperWrappedBags}
                mediaType="IMAGE"
                mediaUrl={pageData.individually_paper_wrapped_bags}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Loose Leaf Tea Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setLooseLeafTea}
                mediaType="IMAGE"
                mediaUrl={pageData.loose_leaf_tea}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
