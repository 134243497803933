import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  sideMessage:{
    backgroundColor: "white",
    position: "absolute",
    right: 20,
    bottom: 20,
    flexDirection: "row",
    display: "flex",
    padding: 10,
    alignItems: "center",
    border: "2px solid green",
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
  },
  successIcon:{
    color: "white",
    borderRadius: "50%",
    height: 50,
    width: 50,
    marginRight: 10,
  },
  message:{ fontSize: 17, fontWeight: "bold" }
}));

export default useStyles;
