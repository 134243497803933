import axios, { Method, AxiosResponse } from "axios";
import store from "../../store models/store_model";
import CommonResponse from "../../models/common_response";

var connectivity = require("connectivity");

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const showAlertDialog = store.dispatch.message.showMessage;

const headerTypes = {
  ApplicationJson: "ApplicationJson",
  Authorized: "Authorized",
};

const performRequest = async (
  method: Method,
  url: string,
  data: any,
  headerType: string,
  needLoading?: boolean
) => {
  const setLoading = store.dispatch.loader.showLoader;
  const token = store.getState().tokenModel.token;
  var isConnected: Boolean = connectivity();
  const headers = {
    applicationJson: {
      "Content-Type": "application/json",
    },
    authorized: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  var header;
  switch (headerType) {
    case headerTypes.ApplicationJson:
      header = headers.applicationJson;
      break;
    case headerTypes.Authorized:
      header = headers.authorized;
      break;

    default:
      header = headers.applicationJson;
      break;
  }

  if (isConnected) {
    if (needLoading) {
      setLoading(true);
    }

    return await axios({
      baseURL: baseUrl,
      url: url,
      method: method,
      data: data,
      headers: header,
    })
      .then((response: AxiosResponse) => {
        if (needLoading) {
          setLoading(false);
        }
        console.log(response.data);
        if (response.status === 200) {
          return new CommonResponse({
            success: true,
            data: response.data,
            message: response.statusText,
            status: response.status,
          });
        }
        showAlertDialog({
          head: response.data.title,
          body: response.data.message,
        });
        // alert(response.statusText);
        return new CommonResponse({
          success: false,
          data: response.data,
          message: response.statusText,
          status: response.status,
        });
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err.response);
        if (err.response && err.response.data.title) {
          console.log(err.response.data);
          showAlertDialog({
            head: err.response.data.title,
            body: err.response.data.message,
          });
        } else {
          showAlertDialog({ head: "Something went wrong!", body: err.message });
        }

        // alert(err);
        return new CommonResponse({
          success: false,
          data: err.response ? err.response.data : null,
          message: err,
          status: err.response ? err.response.status : 500,
        });
      });
  } else {
    showAlertDialog({
      head: "No Internet Connection",
      body: "Please connect to internet and try again!",
    });
    return;
  }
};

const performCreateOrUpdate = async (data: any, route: string) => {
  if (data.id) {
    return await performRequest(
      "PUT",
      `/${route}/${data.id}`,
      data,
      headerTypes.Authorized,
      false
    );
  } else {
    return await performRequest(
      "POST",
      `/${route}`,
      data,
      headerTypes.Authorized,
      false
    );
  }
};

const performDelete = async (index: number, route: string) => {
  return await performRequest(
    "DELETE",
    `/${route}/${index}`,
    {},
    headerTypes.Authorized,
    false
  );
};

export const graphQuery = async (query: string) => {
  return await performRequest(
    "POST",
    "/graphql",
    JSON.stringify({
      query: query,
    }),
    headerTypes.Authorized,
    true
  ).then((data) => {
    console.log(data);
    if (data?.success) {
      data.data = data.data.data;
      return data;
    }
  });
};

export const loginCall = async (email: string, password: string) => {
  return await performRequest(
    "POST",
    "/auth/local",
    {
      identifier: email,
      password: password,
    },
    headerTypes.ApplicationJson,
    true
  );
};

export const getPageDataCall = async (
  country: string,
  graph: Function,
  pageName: string
) => {
  return await graphQuery(graph(country)).then((data) => {
    if (data?.success) {
      data.data = data.data[pageName] ? data.data[pageName][0] : null;
      return data;
    }
  });
};

export const getPaginatedPageDataCall = async (
  country: string,
  limit: number,
  page: number,
  graph: Function,
  pageName: string
) => {
  return await graphQuery(graph(limit, page)).then((data) => {
    if (data?.success) {
      data.data = data.data[pageName] ? data.data[pageName][0] : null;
      return data;
    }
  });
};

export const updateContentTypeCall = async (data: any, endPoint: string) => {
  return await performCreateOrUpdate(data, endPoint);
};

export const deleteContentTypeCall = async (
  index: number,
  endPoint: string
) => {
  return await performDelete(index, endPoint);
};

export const uploadFileCall = async (file: any) => {
  const formData = new FormData();
  formData.append("files", file);
  formData.append("path", "assets");
  return await performRequest(
    "POST",
    "/upload",
    formData,
    headerTypes.Authorized,
    true
  );
};
