import Slider from "../slider";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class MediaImagesModal {
  id: number;
  sliders: Slider[] = [];
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyMediaImagesPage = {
  sliders: [],
  seo: emptySEOComponent,
};
