import CertificateItem, { emptyCertificate } from "./certificate_item";
import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class CertificateAndAwardSection {
  id: number;
  header: MultiLanguage;
  description: MultiLanguage;
  sub_header: MultiLanguage;
  certificate_or_awards: CertificateItem[] = [];
  constructor(data: any) {
    this.id = data.id;
    this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang);
    this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang);
    data.certificate_or_awards.forEach((x: any) =>
      this.certificate_or_awards.push(new CertificateItem(x))
    );
    this.sub_header = new MultiLanguage(data.sub_header ? data.sub_header : emptyMultiLang);
  }
}

export const emptyCertificateAndAwardSection = {
  header: emptyMultiLang,
  description: emptyMultiLang,
  sub_header: emptyMultiLang,
  certificate_or_awards: [emptyCertificate]
}
