import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme) => ({
  showCOntent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
  },
  text:{
      color:myColors.drawerColor,
      cursor: "pointer",

  },
  arrow:{
      color:myColors.drawerSideBarColor,
      cursor: "pointer",
  },
  ab: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }
}));

export default useStyles;
