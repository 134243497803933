import SEOComponent, { emptySEOComponent } from "./pages/seo_component";
import SiteMapSection from "./site_map_section";

export default class SiteMap {
  id: number;
  site_map_sections: SiteMapSection[] = [];
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);

    data.site_map_sections.forEach((x: any) =>
      this.site_map_sections.push(new SiteMapSection(x))
    );
  }
}

export const emptySiteMap = {
  site_map_sections: [],
  seo: emptySEOComponent,
};
