import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class TeaFormatModel {
  id: number;
  title: MultiLanguage;
  string_and_tea_bags_or_cup_bags: string;
  individually_foil_wrapped_bags: string;
  pet_bottles: string;
  available_in_bulk_food_service_packs: string;
  glass_bottles: string;
  pot_bags_or_tagless_bags: string;
  bottle_with_pump_dispenser: string;
  tea_sachets: string;
  luxury_leaf_tea_bags: string;
  individually_paper_wrapped_bags: string;
  loose_leaf_tea: string;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.string_and_tea_bags_or_cup_bags = data.string_and_tea_bags_or_cup_bags;
    this.individually_foil_wrapped_bags = data.individually_foil_wrapped_bags;
    this.available_in_bulk_food_service_packs =
      data.available_in_bulk_food_service_packs;
    this.pet_bottles = data.pet_bottles;
    this.glass_bottles = data.glass_bottles;
    this.pot_bags_or_tagless_bags = data.pot_bags_or_tagless_bags;
    this.bottle_with_pump_dispenser = data.bottle_with_pump_dispenser;
    this.tea_sachets = data.tea_sachets;
    this.luxury_leaf_tea_bags = data.luxury_leaf_tea_bags;
    this.individually_paper_wrapped_bags = data.individually_paper_wrapped_bags;
    this.loose_leaf_tea = data.loose_leaf_tea;
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyTeaFormatModel = {
  title: emptyMultiLang,
  seo: emptySEOComponent,
};
