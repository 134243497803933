import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import CertificateItem, {
  emptyCertificate,
} from "../../models/certificate_item";
import CertificateItemComponent from "../certificate_item/certificate_item";
import IconBtn from "../icon_btn/icon_btn";

export default function CertificateList(props: {
  certificates: CertificateItem[];
}) {
  const [items, setItems] = useState(props.certificates);

  const addItem = () => {
    props.certificates.push(new CertificateItem(emptyCertificate));
    setItems([]);
    setTimeout(() => {
      setItems(props.certificates);
    }, 0.01);
  };

  const removeItem = (index: number) => {
    props.certificates.splice(index,1)
    setItems([]);
    setTimeout(() => {
      setItems(props.certificates);
    }, 0.01);
  };
  return (
    <div
      style={{
        paddingTop: "30px",
        position: "relative",
        margin: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        paddingBottom:'10px'
      }}
    >
      <IconBtn icon={Add} onClick={addItem} />
      {items.map((item, index) => (
        <CertificateItemComponent
          key={index}
          certificate={item}
          onRemove={() => {
            removeItem(index);
          }}
        />
      ))}
    </div>
  );
}
