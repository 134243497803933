import EBookSection from "../e_book_section";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class TeaInspiredEBook{
    id:number
    e_book_sections: EBookSection[] = []
    title:MultiLanguage
    header_image_url:string
    seo: SEOComponent
    constructor(data:any){
        this.id=data.id
        data.e_book_sections.forEach((x:any)=>this.e_book_sections.push(new EBookSection(x)))
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.header_image_url = data.header_image_url
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyTeaInspiredEBook = {
    e_book_sections : [],
    title: emptyMultiLang,
    seo: emptySEOComponent
}