import { Card, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import TestimonialItemModel from "../../models/testimonial_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function TestimonialItem(props: {
  onRemove: Function;
  index: number;
  testimonialItem: TestimonialItemModel;
}) {

  const [date,setDate] = useState(props.testimonialItem.date)

  const onDateChange = (u:any)=>{
    setDate(u)
    props.testimonialItem.date = u
  }
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.testimonialItem.image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.testimonialItem.image_url = url;
          }}
        />
      </div>
      <TextField
          style={{ marginLeft: "1%", width:'300px' }}
          type="date"
          label="Date"
          variant="outlined"
          value={date}
          onChange={ (event)=> onDateChange(event.target.value)}
          InputLabelProps={{
            shrink:true
          }}
        />
      <MultiLangEdit
        isShowLabel
        label="Name"
        value={props.testimonialItem.name}
      />
      <MultiLangEdit
        isShowLabel
        label="Title"
        value={props.testimonialItem.title}
      />
      <MultiLangEdit
        isShowLabel
        label="Short Description"
        value={props.testimonialItem.short_description}
      />
      <MultiLangEdit
        isShowLabel
        label="Content"
        value={props.testimonialItem.content}
      />
    </Card>
  );
}
