import { Card, Switch, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import ContentModel from "../../models/content_model";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import useStyles from "./content_item_style";

export default function ContentItem(props: {
  content: ContentModel;
  index?: number;
  onRemove?: Function;
  isMarkdown?: boolean;
  hasLogo?: boolean;
}) {
  const classes = useStyles();

  const changeHasHeader = (value: boolean) => {
    setContent((val) => ({ ...val, has_header: value }));
    props.content.has_header = value;
  };

  const changeHasSubHeader = (value: boolean) => {
    setContent((val) => ({ ...val, has_sub_heading: value }));
    props.content.has_sub_heading = value;
  };

  const [content, setContent] = useState(props.content);

  const changeHasLink = (value: boolean) => {
    setContent((val) => ({ ...val, has_link: value }));
    props.content.has_link = value;
  };

  const changeHasVideo = (value: boolean) => {
    setContent((val) => ({ ...val, has_video: value }));
    props.content.has_video = value;
  };

  const getImageUrl = (url: string) => {
    props.content.image_url = url;
  };

  const getlogoUrl = (url: string) => {
    props.content.logo_url = url;
  };
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      {props.onRemove && (
        <IconBtn
          icon={Remove}
          onClick={() => {
            if (props.onRemove && props.index != null) {
              props.onRemove(props.index);
            }
          }}
        />
      )}

      <div style={{ textAlign: "center" }}>
        <div className={classes.title} style={{ display: !props.hasLogo ? "none" : 'inline-block' }}>Main Image</div>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={content.image_url}
          mediaType={"IMAGE"}
          getFileUrl={getImageUrl}
        />
      </div>
      {props.hasLogo && (
        <div style={{ textAlign: "center" }}>
          <div className={classes.title}>Logo</div>
          <IconUploadBtn
            height={150}
            isEditable
            mediaUrl={content.logo_url}
            mediaType={"IMAGE"}
            getFileUrl={getlogoUrl}
          />
        </div>
      )}

      <div className={classes.row}>
        <div>Has Header</div>
        <Switch
          checked={content.has_header}
          onClick={() => {
            changeHasHeader(!content.has_header);
          }}
        ></Switch>
      </div>
      {content.has_header && (
        <MultiLangEdit label={"Header"} isShowLabel value={content.header} />
      )}

      <div className={classes.row}>
        <div>Has Sub Heading</div>
        <Switch
          checked={content.has_sub_heading}
          onClick={() => {
            changeHasSubHeader(!content.has_sub_heading);
          }}
        ></Switch>
      </div>
      {content.has_sub_heading && (
        <MultiLangEdit
          label={"Sub Heading"}
          isShowLabel
          value={content.sub_heading}
        />
      )}

      <MultiLangEdit label={"Content"} isShowLabel value={content.content} isMarkdown={props.isMarkdown} />
      <div className={classes.row}>
        <div>Has Link</div>
        <Switch
          checked={content.has_link}
          onClick={() => {
            changeHasLink(!content.has_link);
          }}
        ></Switch>
      </div>
      {content.has_link && (
        <TextField
          style={{ margin: "5px 10px", width: "100%" }}
          variant="outlined"
          label="Link"
          value={content.link}
          disabled={!content.has_link}
          onChange={(event) => {
            const text = event.target.value;
            setContent((val) => ({ ...val, link: text }));
            props.content.link = text;
          }}
        />
      )}
      {content.has_link && (
        <MultiLangEdit
          label={"Link Text"}
          isShowLabel
          value={content.link_text}
        />)}

      <div className={classes.row}>
        <div>Has Video</div>
        <Switch
          checked={content.has_video}
          onClick={() => {
            changeHasVideo(!content.has_video);
          }}
        ></Switch>
      </div>
      {content.has_video && (
        <TextField
          style={{ margin: "5px 10px", width: "100%" }}
          variant="outlined"
          label="Video Url"
          value={content.video_url}
          disabled={!content.has_video}
          onChange={(event) => {
            const text = event.target.value;
            setContent((val) => ({ ...val, video_url: text }));
            props.content.video_url = text;
          }}
        />
      )}
    </Card>
  );
}
