import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateContentWithImagesItemArray,
  updateMultiLangItem,
  updatePhotographerItemArray,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { teaInspiredImagesGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import TeaInspiredImages, {
  emptyTeaInspiredImages,
} from "../../models/pages/tea_inspired_images";
import PhotographerItem from "../../components/photographer_item/photographer_item";
import PhotographerModal, {
  emptyPhotographer,
} from "../../models/photographer";
import ContentWithImagesItem from "../../components/content_with_images/content_with_images";
import ContentWithImagesModel, {
  emptyContentWithImages,
} from "../../models/content_with_images";
import SEOComponent from "../../components/seo_component/seo_component";
export default function TeaInspiredImagesPage() {
  const [pageData, setPageData] = useState<TeaInspiredImages>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(
      country,
      teaInspiredImagesGraph,
      "teaInspiredImagePages"
    ).then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new TeaInspiredImages(
            data.data ? data.data.page : emptyTeaInspiredImages
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: TeaInspiredImages) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.photographers = await updatePhotographerItemArray(
        pageData.photographers
      );
      dataToSave.title = await updateMultiLangItem(pageData.title);

      dataToSave.albums = await updateContentWithImagesItemArray(
        pageData.albums
      );
      dataToSave.tea_inspired_me = await updateContentWithImagesItemArray(
        pageData.tea_inspired_me
      );
      dataToSave.artists = await updateContentWithImagesItemArray(
        pageData.artists
      );
      dataToSave.seo = await updateSeoItem(pageData.seo);

      dataToSave.photographers_seo = await updateSeoItem(pageData.photographers_seo);

      dataToSave.albums_seo = await updateSeoItem(pageData.albums_seo);

      dataToSave.tea_inspired_me_seo = await updateSeoItem(pageData.tea_inspired_me_seo);

      dataToSave.artists_seo = await updateSeoItem(pageData.artists_seo);

      await updateContentTypeCall(
        dataToSave,
        "tea-inspire-images-page-items"
      ).then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "tea-inspired-image-pages"
          ).then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewContent = () => {
    const prev = pageData;
    if (prev) {
      prev.photographers.push(new PhotographerModal(emptyPhotographer));
      setPageData({ ...prev });
    }
  };

  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const removeContent = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.photographers.splice(index, 1);
      setPageData(new TeaInspiredImages(emptyTeaInspiredImages));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewAlbum = () => {
    const prev = pageData;
    if (prev) {
      prev.albums.push(new ContentWithImagesModel(emptyContentWithImages));
      setPageData({ ...prev });
    }
  };

  const removeAlbum = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.albums.splice(index, 1);
      setPageData(new TeaInspiredImages(emptyTeaInspiredImages));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewTeaInspiredMe = () => {
    const prev = pageData;
    if (prev) {
      prev.tea_inspired_me.push(
        new ContentWithImagesModel(emptyContentWithImages)
      );
      setPageData({ ...prev });
    }
  };

  const removeTeaInspiredMe = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.tea_inspired_me.splice(index, 1);
      setPageData(new TeaInspiredImages(emptyTeaInspiredImages));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewArtist = () => {
    const prev = pageData;
    if (prev) {
      prev.artists.push(new ContentWithImagesModel(emptyContentWithImages));
      setPageData({ ...prev });
    }
  };

  const removeArtist = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.artists.splice(index, 1);
      setPageData(new TeaInspiredImages(emptyTeaInspiredImages));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.images}
      parentRoutes={[routeNameObjects.teaInspired]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Title"}>
            <MultiLangEdit value={pageData.title} />
          </AttributeItem>
          <AttributeItem title={"Photographers"} onClick={addNewContent}>
            {pageData.photographers.map((content, index) => (
              <PhotographerItem
                key={index}
                photographer={content}
                index={index}
                onRemove={removeContent}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"Photographers SEO"}>
            <SEOComponent seoItem={pageData.photographers_seo} />
          </AttributeItem>

          <AttributeItem title={"Albums"} onClick={addNewAlbum}>
            {pageData.albums.map((content, index) => (
              <ContentWithImagesItem
                key={index}
                content={content}
                index={index}
                onRemove={removeAlbum}
              />
            ))}
          </AttributeItem>

          
          <AttributeItem title={"Albums SEO"}>
            <SEOComponent seoItem={pageData.albums_seo} />
          </AttributeItem>

          <AttributeItem
            title={"Tea Inspired Me"}
            onClick={addNewTeaInspiredMe}
          >
            {pageData.tea_inspired_me.map((content, index) => (
              <ContentWithImagesItem
                key={index}
                content={content}
                index={index}
                onRemove={removeTeaInspiredMe}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"Tea Inspired Me SEO"}>
            <SEOComponent seoItem={pageData.tea_inspired_me_seo} />
          </AttributeItem>

          <AttributeItem title={"Artists"} onClick={addNewArtist}>
            {pageData.artists.map((content, index) => (
              <ContentWithImagesItem
                key={index}
                content={content}
                index={index}
                onRemove={removeArtist}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"Artists SEO"}>
            <SEOComponent seoItem={pageData.artists_seo} />
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
 