import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class ImageWithTitle {
    id: number
    image_url: string
    title: MultiLanguage
    constructor(data: any) {
        this.id = data.id
        this.image_url = data.image_url
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
    }
}

export const emptyImageWithTitle = {
    title: emptyMultiLang,
}