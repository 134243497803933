const multiLang = `id 
                  text_global 
                  text_en 
                  text_du 
                  text_uk 
                  text_mo 
                  text_ru 
                  text_li 
                  text_hu 
                  text_ma 
                  text_ch 
                  text_th 
                  text_sw 
                  text_fr 
                  text_ar`;

const slider = `  id 
                  slider_has_video
                  slider_has_link
                  link
                  video_url 
                  image_url 
                  header{ ${multiLang} }`;

const seo = `     id 
                  meta_title
                  meta_description 
                  keywords
                  share_image_alt
                  share_image_url
                  prevent_indexing 
                  `;

const content = ` id
                  has_logo
                  logo_url
                  has_header
                  has_link
                  link
                  header{${multiLang}}
                  content{${multiLang}}
                  link_text{${multiLang}}
                  image_url
                  sub_heading{${multiLang}}
                  has_sub_heading
                  has_video
                  video_url`;

const contentQuote = ` id
                  has_header
                  has_link
                  link
                  header{${multiLang}}
                  content{${multiLang}}
                  link_text{${multiLang}}
                  quote{${multiLang}}
                  author{${multiLang}}
                  image_url
                  sub_heading{${multiLang}}
                  has_sub_heading
                  has_video
                  video_url`;

const headerWithContent = ` id
                            title{${multiLang}}
                            description{${multiLang}}`;

const timeLine = `id
                  year
                  title{${multiLang}}
                  content{${multiLang}}
                  images{
                    id
                    image_url
                  }`;

const subContents = ` id
                  has_header
                  header{${multiLang}}
                  image_url
                  contents{${multiLang}}`;

// const podcastCategory = ` id
//                   name{${multiLang}}`;

const podcast = ` id
                  header{${multiLang}}
                  long_description{${multiLang}}
                  image_url
                  soundcloud_link`;

const dynamicContentSection = ` id
                  rule_number
                  type
                  has_header
                  content{${multiLang}}
                  image_url
                  has_link
                  link_text{${multiLang}}
                  link
                  headers{${multiLang}}
                  sub_contents{${subContents}}`;

const contactDetails = ` id
                  contact_no_1
                  contact_no_2
                  email
                  latitude
                  longitude
                  address{${multiLang}}`;

const mapLocation = ` id
                  latitude
                  longitude
                  description{${multiLang}}
                  country{${multiLang}}
                  country_selection`;

const careerUserItem = ` id
                  image_url
                  name{${multiLang}}
                  description{${multiLang}}`;

const exploreDilmahItem = ` id
                  image_url
                  title{${multiLang}}
                  link`;

const socialWallItem = ` id
                  image_url
                  description{${multiLang}}
                  link
                  icon_type`;

const featuredVideoItem = ` id
                  image_url
                  video_url
                  description{${multiLang}}
                  header{${multiLang}}`;

const mediaCover = ` id
                  image_url
                  link
                  title{${multiLang}}
                  description{${multiLang}}
                  header{${multiLang}}
                  seo{${seo}}`;

const teaBlogCategory = ` id
                  name{${multiLang}}`;

const teaBlog = ` id
                  image_url
                  date
                  is_featured
                  share_count
                  short_description{${multiLang}}
                  long_description{${multiLang}}
                  author{${multiLang}}
                  header{${multiLang}}
                  tea_blog_category{${teaBlogCategory}}`;

const photographer = ` id
                  image_url
                  description{${multiLang}}
                  name{${multiLang}}
                  images{
                    id
                    image_url
                  }
                  seo{${seo}}`;

const ContentWithImages = ` id
                  cover_image_url
                  short_description{${multiLang}}
                  long_description{${multiLang}}
                  title{${multiLang}}
                  images{
                    id
                    image_url
                  }
                  seo{${seo}}`;

const teaLocation = ` id
                  latitude
                  longitude
                  title{${multiLang}}
                  description{${multiLang}}
                  image_url
                  image_text_item{${multiLang}}`;

const elixirItem = ` id
                  image_url
                  title{${multiLang}}
                  description{${multiLang}}`;

const imageWithTitle = ` id
                  image_url
                  title{${multiLang}}`;

const imageWithLink = ` id
                  image_url
                  header_image_url
                  title{${multiLang}}
                  description{${multiLang}}
                  title_text
                  link
                  seo{${seo}}`;

const singleImageWithLink = `
                  id
                  image_url
                  title{${multiLang}}
                  link
                  `;

const teaMakerPrivateAbout = ` id
                  header_image_url
                  sub_header_image_url
                  header{${multiLang}}
                  initial_content{${content}}
                  contents{${content}}`;

const teaMakerPrivateTeas = ` id
                  header_image_url
                  sub_header_image_url
                  header{${multiLang}}
                  sub_content{${content}}`;

const teaMakerPrivateTeaLocations = ` id
                  header_image_url
                  sub_header_image_url
                  header{${multiLang}}
                  topic{${multiLang}}
                  tea_locations{${teaLocation}}`;

const tSeriesGourmetTeas = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  content{${content}}`;

const tSeriesGourmetRareTeas = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  content{${content}}`;

const tSeriesGourmetSeasonalFlush = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  title_content{${content}}
                  quote_content{${content}}
                  videos{${content}}
                  featured_videos{${featuredVideoItem}}
                  `;

const elixirOfCeylonTeaOurElixir = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  topic{${multiLang}}
                  elixir_topic{${multiLang}}
                  elixir_items{${elixirItem}}`;

const elixirOfCeylonTeaBrewingGuideline = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  topic{${multiLang}}
                  content_1{${content}}
                  content_2{${content}}
                  videos{${content}}
                  images{${imageWithTitle}}`;

const elixirOfCeylonTeaRealTea = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  topic{${multiLang}}
                  title_content{${content}}
                  contents{${content}}`;

const elixirOfCeylonTeaElixirRecipes = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  topic{${multiLang}}`;

const naturalInfusionsOurInfusions = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  content{${content}}
                  topic{${multiLang}}`;

const naturalInfusionsOurIngredients = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  content{${content}}
                  video_content{${content}}
                  topic{${multiLang}}
                  herbs_descriptions{${headerWithContent}}
                  image_with_titles{${imageWithTitle}}`;

const ourTeaCategoriesTeaRange = ` id
                  header_image
                  title{${multiLang}}
                  items{${imageWithLink}}
                  header_descrpiton{${headerWithContent}}
                  seo{${seo}}`;

const ourTeaCategoriesTeaTypes = ` id
                  header_image
                  title{${multiLang}}
                  items{${imageWithLink}}
                  header_descrpiton{${headerWithContent}}
                  seo{${seo}}`;

const ourTeaCategoriesTeaFormats = ` id
                  header_image
                  title{${multiLang}}
                  items{${imageWithLink}}
                  header_descrpiton{${headerWithContent}}
                  seo{${seo}}`;

const ourTeaCategoriesTeaByFlovor = ` id
                  header_image
                  title{${multiLang}}
                  items{${imageWithLink}}
                  header_descrpiton{${headerWithContent}}
                  seo{${seo}}`;

const ourTeaCategoriesTeaByMoods = ` id
                  header_image
                  title{${multiLang}}
                  items{${imageWithLink}}
                  header_descrpiton{${headerWithContent}}
                  seo{${seo}}`;

const naturalInfusionsEveryMood = ` id
                  header_image_url
                  header_sub_image_url
                  header{${multiLang}}
                  content{${content}}
                  mood_image_list{${imageWithTitle}}`;

const quiz = ` id
                  question_image_url
                  question_header{${multiLang}}
                  question{${multiLang}}
                  anwsers{${multiLang}}`;

const ingredientItem = `id
                  header_image_url
                  intro_image_url
                  title{${multiLang}}
                  about_ingredient{${headerWithContent}}
                  medicinal_claims{${headerWithContent}}
                  possible_side_effects{${headerWithContent}}
                  research_links{${multiLang}}
                  video_item{${content}}
                  `;

const dilmahTradeFineDining = `id
                          header_description{${multiLang}}
                          items{${imageWithLink}}
                          `;
const dilmahTradeFineFoodAndGourmet = `id
                          header_description{${multiLang}}
                          items{${imageWithLink}}
                          `;
const dilmahTradeHighQualityCasualDining = `id
                          header_description{${multiLang}}
                          items{${imageWithLink}}
                          `;
const dilmahTradePremiumRetailers = `id
                          header_description{${multiLang}}
                          items{${imageWithLink}}
                          `;
const dilmahTradeOurProduct = `id
                          banner_image_url
                          our_products_items{${imageWithLink}}
                          `;

export const foundersMessageGraph = (country: string) => `query{
    foundersMessagePages(where:{
      country:"${country}"
    }){
      id
      country
      page{
        id
        founder_name{ ${multiLang} }
        sliders{${slider}}
        contents{${content}}
        title_content{${content}}
        video_url
        signature_url
        seo{${seo}}
      }
    }
  }`;

export const teamakersPhilosophyGraph = (country: string) => `query{
    teamakersPhilosophyPages(where:{
      country:"${country}"
    }){
        id
        country
        page{
          id
          contents{${content}}
          banner_image_url
          title_content{${content}}
          banner_title{${multiLang}}
          seo{${seo}}
        }
      }
    }`;

export const aLifeDevotedToTeaGraph = (country: string) => `query{
  aLifeDevotedToTeaPages(where:{
    country:"${country}"
  }){
      id
      country
      page{
        id
        image_contents{${content}}
        intro_content{${content}}
        banner_image_url
        banner_title{${multiLang}}
        seo{${seo}}
      }
    }
  }`;

export const dilmahStoryGraph = (country: string) => `query{
  dilmahStoryPages(where:{
    country:"${country}"
  }){
      id
      country
      page{
        id
        contents{${content}}
        video_url
        title_content{${content}}
        seo{${seo}}
      }
    }
  }`;

export const ourValuesGraph = (country: string) => `query {
  ourValuesPages(where:{
    country:"${country}"
  }) {
    id
    country
    page{
      id
      contents {${content}}
      sliders {${slider}}
      title_content{${content}}
      video_content{${content}}
      seo{${seo}}
    }
  }
}`;

export const certificatesAndAwardsGraph = (country: string) => `query {
  certificatesAndAwardsPages (where:{
    country:"${country}"
  }) {
    id
    country
    page{
      id
      header {${multiLang}}
      description {${multiLang}}
      award_btn_text {${multiLang}}
      certificate_btn_text {${multiLang}}
      seo{${seo}}
      certificates {
        id
        header {${multiLang}}
        description {${multiLang}}
        sub_header {${multiLang}}
        certificate_or_awards {
          id
          description {${multiLang}}
          image_url
          file_url
          sub_heading {${multiLang}}
        }
      }
      awards {
        id
        header {${multiLang}}
        description {${multiLang}}
        sub_header {${multiLang}}
        certificate_or_awards {
          id
          description {${multiLang}}
          image_url
          file_url
          sub_heading {${multiLang}}
        }
      }
    }
  }
}
`;

export const ourTeaGardensGraph = (country: string) => `query {
  ourTeaGardensPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header {${multiLang}}
      description {${multiLang}}
      seo{${seo}}
      tea_state_items {
        id
        type
        video_link
        longitude
        latitude
        name {${multiLang}}
        description {${multiLang}}
        video_thumbnail_url
        tour_
        images {
          id
          image_url
        }
        cover_image_url
        tour_link_multilang{${multiLang}}
      }
    }
  }
}
`;

export const whatWeDoGraph = (country: string) => `query {
  whatWeDoPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      contents{${content}}
      seo{${seo}}
    }
  }
}
`;

export const leisureAndHospitalityGraph = (country: string) => `query {
  leisureAndHospitalityPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      contents{${content}}
      seo{${seo}}
    }
  }
}
`;

export const globalTimeLineGraph = (country: string) => `query {
  dilmahGlobalTimelinePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      time_line{${timeLine}}
      seo{${seo}}
    }
  }
}
`;

export const schoolOfTeaGraph = (country: string) => `query {
	schoolOfTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      contents{${content}}
      configurable_video_url
      seo{${seo}}
    }
  }
}
`;

export const schoolOfTeaELearnGraph = (country: string) => `query {
	schoolOfTeaELearningPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      contents{${content}}
      seo{${seo}}
    }
  }
}
`;

export const teaAndHealthGraph = (country: string) => `query {
	teaAndHealthPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      contents{${content}}
      seo{${seo}}
    }
  }
}
`;

export const testimonialsGraph = (
  country: string,
  page: number,
  limit: number
) => `query {
  testimonialsPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      seo{${seo}}
    }
  }
}
`;

export const testimonialItemsGraph = (limit: number, page: number) => `query {
  testimonialItems(sort:"id:DESC",limit:${limit},start:${(page - 1) * limit}){
    id
    name{${multiLang}}
    title{${multiLang}}
    content{${multiLang}}
    short_description{${multiLang}}
    date
    image_url
  }
}`;

export const countTestimonialItemsGraph = `query {
  testimonialItems(sort:"id:DESC"){
    id
  }
}`;

export const howToBrewTeaGraph = (country: string) => `query {
	howToBrewTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      content{${content}}
      video_url
      dynamic_content_sections{${dynamicContentSection}}
      seo{${seo}}
    }
  }
}
`;

export const factsOfTeaGraph = (country: string) => `query {
	factsOfTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      contents{${content}}
      seo{${seo}}
    }
  }
}
`;

export const teaInspiredEBooksGraph = (country: string) => `query {
	teaInspiredEBooksPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      seo{${seo}}
      e_book_sections{
        id
        title{${multiLang}}
        e_book_elements{
          id
          title{${multiLang}}
          content{${multiLang}}
          image_url
          book_url
          book_images{
            image_url
          }
        }
      }
    }
  }
}
`;

export const dilmahPodcastGraph = (country: string) => `query {
	dilmahPodcastPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      feature_podcast{${content}}
      seo{${seo}}
      podcast_sections{
        id
        title{${multiLang}}
        contents{${content}}
        podcasts{${podcast}}
      }
    }
  }
}`;

export const siteMapGraph = (country: string) => `query {
	siteMapPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      site_map_sections{
        id
        section_title{${multiLang}}
        section_items{${singleImageWithLink}}
      }
      seo{${seo}}
    }
  }
}`;

export const sustainabilityGraph = (country: string) => `query{
  sustainabilityPages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      seo{${seo}}
      annual_report{ ${content} }
      sliders{${slider}}
      contents{${content}}
    }
  }
}`;

export const dilmahConservationGraph = (country: string) => `query{
  dilmahConservationPages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      sliders{${slider}}
      our_work{${content}}
      sustainability_components{${content}}
      title_content{${content}}
      seo{${seo}}
    }
  }
}`;

export const mjfFoundationGraph = (country: string) => `query{
  mjfFoundationPages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      sliders{${slider}}
      vision{${content}}
      sdg_s{${content}}
      videos{${content}}
      purpose{${content}}
      contributions{${content}}
      seo{${seo}}
    }
  }
}`;

export const dilmahInHospitalityGraph = (country: string) => `query {
	hospitalityPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      invitation{${content}}
      mid_title{${content}}
      mid_quote{${content}}
      end_quote_with_link{${content}}
      values{${content}}
      hospitality{${content}}
      sliders{${slider}}
      seo{${seo}}
    }
  }
}
`;

export const teaInspirationGraph = (country: string) => `query {
	teaInspirationPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      sliders{${slider}}
      content_1{${content}}
      content_2{${content}}
      show_us_link
      seo{${seo}}
    }
  }
}
`;

export const cookieDisclaimerGraph = (country: string) => `query {
	cookieDisclaimerPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      description{${multiLang}}
      seo{${seo}}
    }
  }
}
`;

export const dilmahRealHighTeaGraph = (country: string) => `query {
	dilmahRealHighTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      sliders{${slider}}
      content_1{${content}}
      content_2{${content}}
      seo{${seo}}
    }
  }
}
`;

export const mixologistAndTeaMakerGraph = (country: string) => `query {
	mixologistAndTheTeaMakerPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      sliders{${slider}}
      content_1{${content}}
      content_2{${content}}
      seo{${seo}}
    }
  }
}
`;

export const cafeAndTeaMakerGraph = (country: string) => `query {
	cafeAndTheTeaMakerPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      sliders{${slider}}
      content_1{${content}}
      content_2{${content}}
      seo{${seo}}
    }
  }
}
`;

export const teaInFiveSensesGraph = (country: string) => `query {
	teaInAllSensesPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      sliders{${slider}}
      contents{${content}}
      seo{${seo}}
    }
  }
}
`;

export const homeGraph = (country: string) => `query{
  homePages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      sliders{${slider}}
      mobile_sliders{${slider}}
      contents{${content}}
      explore_dilmah_items{${exploreDilmahItem}}
      featured_videos{${featuredVideoItem}}
      configurable_video_url
      seo{${seo}}
    }
  }
}`;

export const socialWallPageGraph = (country: string) => `query{
  socialWallPages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      social_wall_items{${socialWallItem}}
      
    }
    
  }
}`;

export const ingredientPageGraph = (country: string) => `query{
  ingredientPages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      ingredients_items{${ingredientItem}}
      seo{${seo}}
    }
  }
}`;

export const contactUsGraph = (country: string) => `query {
	contactUsPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header_image_url
      header{${multiLang}}
      content{${content}}
      contact_details{${contactDetails}}
      agreement_text{${multiLang}}
      seo{${seo}}
    }
  }
}
`;

export const careersGraph = (country: string) => `query {
	careerPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      sliders{${slider}}
      title_content{${content}}
      intern_content{${content}}
      vacancy_btn_text{${multiLang}}
      submit_cv_btn_text{${multiLang}}
      submit_cv_btn_intern_text{${multiLang}}
      career_user_items{${careerUserItem}}
      seo{${seo}}
    }
  }
}
`;

export const mediaGraph = (country: string) => `query{
  mediaPages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      latest_news_cover{${mediaCover}}
      events_cover{${mediaCover}}
      tea_blog_cover{${mediaCover}}
      dilmah_in_press_cover{${mediaCover}}
      seo{${seo}}
    }
  }
}`;

export const teaInspiredImagesGraph = (country: string) => `query {
	teaInspiredImagePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      photographers{${photographer}}
      albums{${ContentWithImages}}
      tea_inspired_me{${ContentWithImages}}
      artists{${ContentWithImages}}
      seo{${seo}}
      photographers_seo{${seo}}
      albums_seo{${seo}}
      tea_inspired_me_seo{${seo}}
      artists_seo{${seo}}
    }
  }
}
`;

export const ourTeaCategoriesGraph = (country: string) => `query {
	ourTeaCategoriesPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      header_descrpiton{${headerWithContent}}
      tea_ranges{${ourTeaCategoriesTeaRange}}
      tea_types{${ourTeaCategoriesTeaTypes}}
      tea_formats{${ourTeaCategoriesTeaFormats}}
      tea_by_flavors{${ourTeaCategoriesTeaByFlovor}}
      tea_by_moods{${ourTeaCategoriesTeaByMoods}}
      seo{${seo}}
    }
  }
}
`;

export const dilmahTradeGraph = (country: string) => `query {
	dilmahTradePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      banner_image_url
      our_product{${dilmahTradeOurProduct}}
      header_content{${content}}
      premium_retailers{${dilmahTradePremiumRetailers}}
      fine_food_and_gourmet{${dilmahTradeFineFoodAndGourmet}}
      high_quality_casual_dining{${dilmahTradeHighQualityCasualDining}}
      fine_dining{${dilmahTradeFineDining}}
      seo{${seo}}
    }
  }
}
`;

export const GiftOfTeasGraph = (country: string) => `query {
	giftOfTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      header_descrpiton{${headerWithContent}}
      seo{${seo}}
    }
  }
}
`;

export const TeaWareGraph = (country: string) => `query {
	teawarePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      header_descrpiton{${headerWithContent}}
      seo{${seo}}
    }
  }
}
`;

export const AccessoriesGraph = (country: string) => `query {
	dilmahAccessoriesPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      header_descrpiton{${headerWithContent}}
      seo{${seo}}
    }
  }
}
`;
export const WhereToBuyGraph = (country: string) => `query {
	whereToBuyPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      header_descrpiton{${headerWithContent}}
      map_location{${mapLocation}}
      country{${multiLang}}
      seo{${seo}}
    }
  }
}
`;

export const DilmahQuizGraph = (country: string) => `query {
	dilmahQuizPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      quiz{${quiz}}
      seo{${seo}}
    }
  }
}
`;

export const teaLoungeGraph = (country: string) => `query {
	teaLoungePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header_image_url
      title{${multiLang}}
      content_1{${contentQuote}}
      content_2{${contentQuote}}
      seo{${seo}}
    }
  }
}
`;

export const mediaImagePageGraph = (country: string) => `query{
  mediaImagesPages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      sliders{${slider}}
      seo{${seo}}
    }
  }
}`;

export const teaBlogGraph = (country: string) => `query{
  teaBlogPages(where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      dilmah_tea_blogs{${teaBlog}}
      header_tea_blog{${teaBlog}}
      seo{${seo}}
    }
  }
}`;

export const teaBlogCategoriesGraph = `query {
  teaBlogCategories{
    id
    name{${multiLang}}
  }
}`;

export const contactUsSubmitsGraph = (limit: number, page: number) => `query {
  contactUsSubmits(sort:"id:DESC",limit:${limit},start:${(page - 1) * limit}){
    id
    name
    email
    contact_no
    company
    subject
    comment
    country
    other_subject
  }
}`;

export const countContactUsSubmitsGraph = `query {
  contactUsSubmits(sort:"id:DESC"){
    id
  }
}`;

export const PremiumCeylonTeaGraph = (country: string) => `query {
	premiumCeylonTeasPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const ceylonGoldGraph = (country: string) => `query {
	ceylonGoldPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const gourmetGraph = (country: string) => `query {
	gourmetTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const ceylonGreenTeaGraph = (country: string) => `query {
	ceylonGreenTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

const glassBottle = ` id
                  header_image_url
                  intro_image_url
                  banner_image_url
                  title{${multiLang}}
                  description{${multiLang}}
                  `;

const tetraPack = ` id
                    header_image_url
                    intro_image_url
                    banner_image_url
                    title{${multiLang}}
                    description{${multiLang}}`;

export const ceylonIcedTeaGraph = (country: string) => `query {
	ceylonIcedTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header_image_url
      banner_image_url
      intro_image_url
      title{${multiLang}}
      glass_bottle{${glassBottle}}
      tetra_pack{${tetraPack}}
      seo{${seo}}
    }
  }
}
`; 

// export const ceylonIcedTeaGraph = (country: string) => `query {
// 	ceylonIcedTeaPages (where:{
//     country:"${country}"
//   }){
//     id
//     country
//     page{
//       id
//       title{${multiLang}}
//       header_image_url
//       banner_image_url 
//       intro_image_url
//       seo{${seo}}
//     }
//   }
// }
// `;

export const ceylonGoldenPekoeGraph = (country: string) => `query {
	ceylonGoldenPekoePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const funFlavoredCeylonTeaGraph = (country: string) => `query {
	funFlavoredCeylonTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const realTeaCordialGraph = (country: string) => `query {
	dilmahRealTeaCordialPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const organicGraph = (country: string) => `query {
	dilmahOrganicPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const aranaGraph = (country: string) => `query {
	dilmahAranaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const silverJubileeGourmetGraph = (country: string) => `query {
	silverJubileeGourmetPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const exceptionalTeaGraph = (country: string) => `query {
	exceptionalTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const commemorativeTeaGraph = (country: string) => `query {
	commemorativeTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const vividTeaGraph = (country: string) => `query {
	vividTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

const regionTeas = ` id
                  header_image_url
                  intro_image_url
                  banner_image_url
                  title{${multiLang}}
                  description{${multiLang}}
                  topic{${multiLang}}
                  tea_locations{${teaLocation}}
                  `;

const estateTeas = ` id
                    header_image_url
                    intro_image_url
                    banner_image_url
                    title{${multiLang}}
                    description{${multiLang}}`;

const regions = ` id
                  header_image_url
                  sub_header_image_url
                  header{${multiLang}}
                  topic{${multiLang}}
                  tea_locations{${teaLocation}}
                  `;

export const watteBoutiqueTeaGraph = (country: string) => `query {
	watteBoutiqueTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header_image_url
      banner_image_url
      header{${multiLang}}
      title{${multiLang}}
      description{${multiLang}}
      links{${exploreDilmahItem}}
      region_teas{${regionTeas}}
      estate_teas{${estateTeas}}
      watte_boutique_region{${regions}}
      seo{${seo}}
    }
  }
}
`; 

export const inspirationSelectionGraph = (country: string) => `query {
	inspirationSelectionPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const naturalGreenTeaGraph = (country: string) => `query {
	naturalGreenTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const teamakersReserveGraph = (country: string) => `query {
	teamakersReservePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      banner_image_url
      intro_image_url
      seo{${seo}}
    }
  }
}
`;

export const teamakersPrivateReserveGraph = (country: string) => `query {
	teaMakersPrivateReservePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header_image_url
      sub_header_image_url
      content{${content}}
      links{${exploreDilmahItem}}
      header{${multiLang}}
      about{${teaMakerPrivateAbout}}
      our_teas{${teaMakerPrivateTeas}}
      tea_locations{${teaMakerPrivateTeaLocations}}
      seo{${seo}}
    }
  }
}
`;

export const tSeriesGourmetGraph = (country: string) => `query {
	tSeriesGourmetPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header_image_url
      header_sub_image_url
      header{${multiLang}}
      content{${content}}
      links{${exploreDilmahItem}}
      gourmet_teas{${tSeriesGourmetTeas}}
      very_special_rare_teas{${tSeriesGourmetRareTeas}}
      seasonal_flush{${tSeriesGourmetSeasonalFlush}}
      seo{${seo}}
    }
  }
}
`;

export const elixirOfCeylonTeaGraph = (country: string) => `query {
	elixirOfCeylonTeaPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header_image_url
      header_sub_image_url
      header{${multiLang}}
      topic{${multiLang}}
      content{${content}}
      links{${exploreDilmahItem}}
      our_elixir{${elixirOfCeylonTeaOurElixir}}
      brewing_guideline{${elixirOfCeylonTeaBrewingGuideline}}
      real_tea{${elixirOfCeylonTeaRealTea}}
      elixir_recipes{${elixirOfCeylonTeaElixirRecipes}}
      seo{${seo}}
    }
  }
}
`;

export const naturalInfusionGraph = (country: string) => `query {
	naturalInfusionsPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      header_image_url
      header_sub_image_url
      header{${multiLang}}
      content{${content}}
      links{${exploreDilmahItem}}
      our_infusions{${naturalInfusionsOurInfusions}}
      our_ingredients{${naturalInfusionsOurIngredients}}
      for_every_mood{${naturalInfusionsEveryMood}}
      seo{${seo}}
    }
  }
}
`;

export const teaformatsGraph = (country: string) => `query {
	teaFormatPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      string_and_tea_bags_or_cup_bags
      individually_foil_wrapped_bags
      available_in_bulk_food_service_packs
      pet_bottles
      glass_bottles
      pot_bags_or_tagless_bags
      bottle_with_pump_dispenser
      tea_sachets
      luxury_leaf_tea_bags
      individually_paper_wrapped_bags
      loose_leaf_tea
      seo{${seo}}
    }
  }
}
`;

export const termsAndConditionsGrahp = (country: string) => `query {
	termsAndConditionsPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_image_url
      header_description_1{${multiLang}}
      header_description_2{${multiLang}}
      policy_content{${headerWithContent}}
      seo{${seo}}
    }
  }
}
`;

export const privacyAndCookiePolicyGraph = (country: string) => `query {
	privacyAndCookiePolicyPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      note{${multiLang}}
      header_image_url
      policy_content1{${headerWithContent}}
      policy_content2{${headerWithContent}}
      what_we_collect_content{${multiLang}}
      mobile_app_policy_content{${multiLang}}
      seo{${seo}}
    }
  }
}
`;

export const antiBribaryAndCorruptionPolicyGraph = (country: string) => `query {
	antiBribaryAndCorruptionPolicyPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      note{${multiLang}}
      header_image_url
      policy_content1{${headerWithContent}}
      policy_content2{${headerWithContent}}
      policy_content1_description{${multiLang}}
      policy_content2_description{${multiLang}}
      seo{${seo}}
    }
  }
}
`;

export const businessEthicsPolicyGraph = (country: string) => `query {
	businessEthicsPolicyPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      note{${multiLang}}
      header_image_url
      policy_content1{${headerWithContent}}
      policy_content2{${headerWithContent}}
      policy_content1_description{${multiLang}}
      policy_content2_description{${multiLang}}
      seo{${seo}}
    }
  }
}
`;

export const humanRightsPolicyGraph = (country: string) => `query {
	humanRightsPolicyPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      note{${multiLang}}
      header_image_url
      policy_content1{${headerWithContent}}
      policy_content2{${headerWithContent}}
      policy_content1_description{${multiLang}}
      policy_content2_description{${multiLang}}
      seo{${seo}}
    }
  }
}
`;

export const dilmahPodcastCategoriesGraph = `query {
  dilmahPodcastCategories{
    id
    name{${multiLang}}
  }
}`;

export const bannerCustormzationGraph = (country: string) => `query {
	bannerCustomizationPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      audio_title{${multiLang}}
      audio_list_header_image_url
      audio_seo{${seo}}
      video_title{${multiLang}}
      video_list_header_image_url
      video_seo{${seo}}
      image_title{${multiLang}}
      image_list_header_image_url
      image_seo{${seo}}
      blog_title{${multiLang}}
      blog_list_header_image_url
      seo{${seo}}
    }
  }
}
`;

export const mrDilmahWishSubmitGraph = (limit: number, page: number) => `query {
  mrDilmahWishSubmits(sort:"id:DESC",limit:${limit},start:${
  (page - 1) * limit
}){
    id
    name
    country
    email
    wish_type
    letter_content
    letter_designation
    letter_user_image_url
    is_approved
  }
}`;

export const countMrDilmahWishSubmitGraph = `query {
  mrDilmahWishSubmits(sort:"id:DESC"){
    id
  }
}`;

export const eBooksGraph = (country: string) => `query {
	eBookPages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      title{${multiLang}}
      header_description{${multiLang}}
      header_image_url
      e_book_elements{
        id
        title{${multiLang}}
        content{${multiLang}}
        image_url
        book_url
        book_images{
          image_url
        }
      }
      seo{${seo}}
    }
  }
}
`;

export const teaInspiredHomeGraph = (country: string) => `query {
	teaInspiredHomePages (where:{
    country:"${country}"
  }){
    id
    country
    page{
      id
      teamakers_philosophy{${singleImageWithLink}}
      years_in_tea{${singleImageWithLink}}
      commemorative_teas{${singleImageWithLink}}
      letters_to_dilmah{${singleImageWithLink}}
      books{${singleImageWithLink}}
      wish_dilmah{${singleImageWithLink}}
      banner_section{${content}}
      video_section{${content}}
      view_library_link
      view_all_letters_link
      submit_letters_link
      seo{${seo}}
    }
  }
}
`;
