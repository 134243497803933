import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import Slider from "../slider";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class FoundersMessage{
    id:number
    sliders:Slider[]=[]
    contents:ContentModel[]=[]
    founder_name:MultiLanguage
    title_content:ContentModel
    video_url:string;
    signature_url:string
    seo: SEOComponent
    constructor(data:any){
        this.id=data.id
        data.sliders.forEach((x:any)=>this.sliders.push(new Slider(x)))
        data.contents.forEach((x:any)=>this.contents.push(new ContentModel(x)))
        this.founder_name =  new MultiLanguage(data.founder_name ? data.founder_name : emptyMultiLang) 
        this.title_content = new ContentModel(data.title_content?data.title_content:emptyContent)
        this.video_url = data.video_url
        this.signature_url = data.signature_url
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyFoundersMessage = {
    sliders:[],
    contents:[],
    founder_name:emptyMultiLang,
    title_content:emptyContent,
}