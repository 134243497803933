import { Card, ListItem, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import TeaStateItemModel from "../../models/tea_state_item_model";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import ImageList from "../image_list/image_list";
import LeafletMapView from "../map/leaflet_map";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function TeaStateItem(props: {
  item: TeaStateItemModel;
  onRemove: Function;
}) {
  const [type, setType] = useState(props.item.type);
  const [link, setLink] = useState(props.item.video_link);
  const [tourLink, setTourLink] = useState(props.item.tour_)
  const [location, setLocation] = useState({
    lat: props.item.latitude ? props.item.latitude : 0,
    lon: props.item.longitude ? props.item.longitude : 0,
  });

  const changeLocation = (location: any) => {
    props.item.latitude = location.lat;
    props.item.longitude = location.lon;
    setLocation({ lat: location.lat, lon: location.lon });
  };
  return (
    <Card
      elevation={5}
      style={{
        margin: "10px 0px",
        paddingTop: "30px",
        position: "relative",
      }}
    >
      <IconBtn icon={Remove} onClick={props.onRemove} />
      <div style={{ textAlign: "center", marginBottom: "20px", display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <div>
          <IconUploadBtn
            mediaUrl={props.item.cover_image_url}
            getFileUrl={(url: string) => {
              props.item.cover_image_url = url;
            }}
            mediaType="IMAGE"
            isEditable
            height={150}
          />
          <div>Cover Image</div>
        </div>
        <div>
          <IconUploadBtn
            mediaUrl={props.item.video_thumbnail_url}
            getFileUrl={(url: string) => {
              props.item.video_thumbnail_url = url;
            }}
            mediaType="IMAGE"
            isEditable
            height={150}
          />
          <div>Video Thumbnail</div>
        </div>
      </div>

      <MultiLangEdit value={props.item.name} label="Name" isShowLabel />
      <MultiLangEdit
        value={props.item.description}
        label="Description"
        isShowLabel
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            label="Type"
            variant="outlined"
            value={type}
            select
            onChange={(event) => {
              setType(event.target.value);
              props.item.type = event.target.value;
            }}
          >
            {["High Elevation", "Mid Elevation", "Low Elevation"].map((type, index) => (
              <ListItem key={index} value={type}>
                {type}
              </ListItem>
            ))}
          </TextField>
          <TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            type="text"
            label="Video Link"
            variant="outlined"
            value={link}
            onChange={(event) => {
              setLink(event.target.value);
              props.item.video_link = event.target.value;
            }}
          />
          <TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            type="number"
            label="Latitude"
            variant="outlined"
            value={location.lat}
            onChange={(event) => {
              const prev = location;
              prev.lat = Number(event.target.value);
              setLocation({ ...prev });
              props.item.latitude = Number(event.target.value);
            }}
            InputLabelProps={{
              shrink: location.lat !== null,
            }}
          />
          <TextField
            style={{
              width: "100%",
            }}
            type="number"
            label="Longitude"
            variant="outlined"
            value={location.lon}
            onChange={(event) => {
              const prev = location;
              prev.lon = Number(event.target.value);
              setLocation({ ...prev });
              props.item.longitude = Number(event.target.value);
            }}
            InputLabelProps={{
              shrink: location.lon !== null,
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <LeafletMapView location={location} setLocation={changeLocation} />
        </div>
      </div>
      {/* <TextField
        style={{
          width: "100%",
          marginBottom: "20px",
          padding: '10px'
        }}
        type="text"
        label="Tour Link"
        variant="outlined"
        value={tourLink}
        onChange={(event) => {
          setTourLink(event.target.value);
          console.log(event.target.value, "tour link passed")
          props.item.tour_ = event.target.value;
        }}
      /> */}
      <MultiLangEdit
        value={props.item.tour_link_multilang}
        label="Tour Link"
        isShowLabel
      />
      <ImageList images={props.item.images} />
    </Card>
  );
}
