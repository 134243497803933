import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import SideMessageService from "../../services/side_message_service";

import CountrySelect from "../../components/country_select/country_select";
import { countries } from "../../components/country_select/countries";

import {
  updateMediaCover,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { mediaGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import MediaPageModel, { emptyMediaPage } from "../../models/pages/media";
import MediaCoverItem from "../../components/media_cover/media_cover";
import SEOComponent from "../../components/seo_component/seo_component";

export default function MediaPage() {
  const [pageData, setPageData] = useState<MediaPageModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, mediaGraph, "mediaPages").then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new MediaPageModel(data.data ? data.data.page : emptyMediaPage)
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageDataToSave: MediaPageModel) => {
    if (pageDataToSave) {
      setLoading(true);
      var dataToSend: any = pageDataToSave;
      dataToSend.dilmah_in_press_cover = await updateMediaCover(
        pageDataToSave.dilmah_in_press_cover
      );
      dataToSend.events_cover = await updateMediaCover(
        pageDataToSave.events_cover
      );
      dataToSend.latest_news_cover = await updateMediaCover(
        pageDataToSave.latest_news_cover
      );
      dataToSend.tea_blog_cover = await updateMediaCover(
        pageDataToSave.tea_blog_cover
      );
      dataToSend.seo = await updateSeoItem(pageDataToSave.seo);

      await updateContentTypeCall(dataToSend, "media-page-items").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "media-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.media}
      parentRoutes={[routeNameObjects.media]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Latest News Cover"}>
            <MediaCoverItem cover={pageData.latest_news_cover} />
          </AttributeItem>

          <AttributeItem title={"Events Cover"}>
            <MediaCoverItem cover={pageData.events_cover} />
          </AttributeItem>

          <AttributeItem title={"Dilmah Tea Blog Cover"}>
            <MediaCoverItem cover={pageData.tea_blog_cover} />
          </AttributeItem>

          <AttributeItem title={"Dilmah In Press Cover"}>
            <MediaCoverItem cover={pageData.dilmah_in_press_cover} />
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
