import { Card, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import SingleImageWithLink from "../../models/single_image_with_link";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function SingleImageWithLinkComponent(props: {
  item: SingleImageWithLink;
  index?: number;
  onRemove?: Function;
}) {

  const [imageUrl, setUrl] = useState(props.item.image_url);

  const getImageUrl = (url: string) => {
    props.item.image_url = url;
    setUrl(url)
  };

  const [item, setItem] = useState(props.item);

  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      {props.onRemove && (
        <IconBtn
          icon={Remove}
          onClick={() => {
            if (props.onRemove && props.index != null) {
              props.onRemove(props.index);
            }
          }}
        />
      )}
      <div style={{ textAlign: "center" }}>
        <div style={{
          marginLeft: '10px',
          fontWeight: 'bold',
          color: '#716666',
          textAlign: 'center',
        }}>Item Image</div>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={imageUrl}
          mediaType={"IMAGE"}
          getFileUrl={getImageUrl}
        />
      </div>
      <MultiLangEdit label={"Title"} isShowLabel value={props.item.title} />
      <TextField
        style={{ margin: "5px 10px", width: "98%" }}
        variant="outlined"
        label="Link"
        value={item.link}
        onChange={(event) => {
          const text = event.target.value;
          setItem((val) => ({ ...val, link: text }));
          props.item.link = text;
        }}
      />
    </Card>
  );
}
