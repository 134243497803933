import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class ElixirOfCeylonTeaRealTeaModal {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header:MultiLanguage
    topic:MultiLanguage
    title_content:ContentModel
    contents:ContentModel[]=[]
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.topic = new MultiLanguage(data.topic?data.topic:emptyMultiLang)
        this.title_content = new ContentModel(data.title_content?data.title_content:emptyContent)
        data.contents.forEach((x:any)=>this.contents.push(new ContentModel(x)))
    }
}

export const emptyElixirOfCeylonTeaRealTea = {
    header:emptyMultiLang,
    topic:emptyMultiLang,
    title_content:emptyContent,
    contents:[]
}