import React, { useEffect, useState } from "react";

import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import { TextField, Card } from "@material-ui/core";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import ContentItem from "../../components/content_item/content_item";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateContentItem,
  updateMultiLangItem,
  updateSliderArray,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { teaInspirationGraph } from "../../services/api_service/graphql_queries";
import TeaInspirationModel, {
  emptyTeaInspiration,
} from "../../models/pages/tea_inspiration";
import Slider, { emptySlider } from "../../models/slider";
import SliderItem from "../../components/slider_item/slider_item";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";
export default function TeaInspirationPage() {
  const [pageData, setPageData] = useState<TeaInspirationModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, teaInspirationGraph, "teaInspirationPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new TeaInspirationModel(
              data.data ? data.data.page : emptyTeaInspiration
            )
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: TeaInspirationModel) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.sliders = await updateSliderArray(pageData.sliders);
      dataToSave.title = await updateMultiLangItem(pageData.title);
      dataToSave.content_1 = await updateContentItem(pageData.content_1);
      dataToSave.content_2 = await updateContentItem(pageData.content_2);
      dataToSave.seo = await updateSeoItem(pageData.seo);

      await updateContentTypeCall(dataToSave, "tea-inspirations").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "tea-inspiration-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const addNewSlider = () => {
    const prev = pageData;
    if (prev) {
      prev.sliders.push(new Slider(emptySlider));
      setPageData({ ...prev });
    }
  };

  const removeSlider = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.sliders.splice(index, 1);
      setPageData(new TeaInspirationModel(emptyTeaInspiration));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.teaInspiration}
      parentRoutes={[routeNameObjects.teaInspired]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"Sliders"} onClick={addNewSlider}>
            {pageData.sliders.map((slider, index) => (
              <SliderItem
                key={index}
                slider={slider}
                index={index}
                onRemove={removeSlider}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"Title"}>
            <MultiLangEdit value={pageData.title} />
          </AttributeItem>
          <AttributeItem title={"Content 1"}>
            <ContentItem content={pageData.content_1} />
          </AttributeItem>
          <AttributeItem title={"Content 2"}>
            <ContentItem content={pageData.content_2} />
            <Card
              elevation={5}
              style={{
                marginTop: "10px",
                position: "relative",
                paddingTop: "30px",
                paddingRight: "20px",
              }}
            >
              <div
                style={{
                  marginLeft: "10px",
                  fontWeight: "bold",
                  color: "#716666",
                }}
              >
                Show Us Link
              </div>
              <TextField
                style={{ margin: "5px 10px", width: "100%" }}
                variant="outlined"
                label="Link"
                value={pageData.show_us_link ? pageData.show_us_link : ""}
                onChange={(event) => {
                  const text = event.target.value;
                  let prev = { ...pageData };
                  prev.show_us_link = text;
                  setPageData({ ...prev });
                }}
              />
            </Card>
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
