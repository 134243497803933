import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useStoreState, useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import { MessageTypes } from "../../store models/model/message";

export default function MyDialogBox() {
  const open = useStoreState((state: StoreModel) => state.message.isOpen);
  const head = useStoreState((state: StoreModel) => state.message.head);
  const body = useStoreState((state: StoreModel) => state.message.body);
  const type = useStoreState((state: StoreModel) => state.message.type);
  const color = useStoreState((state: StoreModel) => state.message.color );
  const onConfirm = useStoreState(
    (state: StoreModel) => state.message.onConfirm
  );

  const closeDialogBox = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.hideMessage
  );

  const handleClose = () => {
    closeDialogBox();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{color:color}}>{head}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      {type === MessageTypes.confirmation ? (
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus>
            cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm();
            }}
            color="primary"
            autoFocus
          >
            confirm
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
