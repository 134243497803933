import MultiLanguage, { emptyMultiLang } from "../multi_language";
import TeaLocationModel from "../tea_location.ts";


export default class WatteTeaRangeRegionModel {
    id: number;
    title: MultiLanguage;
    header_image_url: string;
    banner_image_url: string;
    intro_image_url: string;
    description: MultiLanguage;
    tea_locations:TeaLocationModel[]=[]
    topic:MultiLanguage

    constructor(data: any) {
        this.id = data.id;
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
        this.header_image_url = data.header_image_url
        this.banner_image_url = data.banner_image_url
        this.intro_image_url = data.intro_image_url
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang);
        this.topic = new MultiLanguage(data.topic?data.topic:emptyMultiLang)
        data.tea_locations.forEach((x:any)=>this.tea_locations.push(new TeaLocationModel(x)))
    }
}

export const emptyWatteTeaRangeRegionModel = {
    title: emptyMultiLang,
    description: emptyMultiLang,
    topic:emptyMultiLang,
    tea_locations:[]
};