import ContentModel, { emptyContent } from "../content_model";
import DynamicContentSectionModal from "../dynamic_content_section_modal";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class HowToBrewTea {
  id: number;
  title: MultiLanguage;
  content: ContentModel;
  header_image_url: string;
  video_url: string;
  dynamic_content_sections: DynamicContentSectionModal[] = [];
  seo: SEOComponent;
  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.content = new ContentModel(
      data.content ? data.content : emptyContent
    );
    this.header_image_url = data.header_image_url;
    this.video_url = data.video_url;
    data.dynamic_content_sections.forEach((x: any) =>
      this.dynamic_content_sections.push(new DynamicContentSectionModal(x))
    );
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
  }
}

export const emptyHowToBrewTea = {
  title: emptyMultiLang,
  content: emptyContent,
  dynamic_content_sections: [],
  seo: emptySEOComponent
};
