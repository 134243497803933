import ContentModel from "../content_model"
import SEOComponent, {emptySEOComponent} from "./seo_component"

export default class LeisureAndHospitality {
    id:number
    contents:ContentModel[] = []
    seo: SEOComponent
    constructor(data:any){
        this.id=data.id
        data.contents.forEach((x:any)=>this.contents.push(new ContentModel(x)))
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyLeisureAndHospitality = {
    contents : [],
    seo: emptySEOComponent
}