import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React from "react";
import CertificateAndAwardSection from "../../models/certificate_and_award_section";
import CertificateList from "../certificate_list/certificate_list";
import IconBtn from "../icon_btn/icon_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function CertificateSection(props: {
  section: CertificateAndAwardSection;
  onRemove: Function;
}) {
  return (
    <Card
      elevation={5}
      style={{ margin: "10px 0px", paddingTop: "30px", position: "relative" }}
    >
      <IconBtn icon={Remove} onClick={props.onRemove} />
      <MultiLangEdit isShowLabel label="Header" value={props.section.header} />
      <MultiLangEdit isShowLabel label="Description" value={props.section.description} />
      <CertificateList certificates={props.section.certificate_or_awards} />
    </Card>
  );
}
