import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import SliderItem from "../../components/slider_item/slider_item";
import SEOComponent from "../../components/seo_component/seo_component";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import ContentItem from "../../components/content_item/content_item";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import Slider, { emptySlider } from "../../models/slider";
import ContentModel, { emptyContent } from "../../models/content_model";
import clonedeep from "lodash.clonedeep";
import SideMessageService from "../../services/side_message_service";

import CountrySelect from "../../components/country_select/country_select";
import { countries } from "../../components/country_select/countries";

import {
  updateContentArray,
  updateExploreDilmahItemArray,
  updateFeaturedVideoItemArray,
  updateSeoItem,
  updateSliderArray,
} from "../../services/api_service/components_update";
import { homeGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import HomeModel, { emptyHome } from "../../models/pages/home";
import ExploreDilmahItem from "../../components/explore_dilmah_item/explore_dilmah_item";
import ExploreDilmahItemModel, {
  emptyExploreDilmahItem,
} from "../../models/explore_dilmah_item";
import FeaturedVideoItem from "../../components/featured_video_item/featured_video_item";
import TextBoxItem from "../../components/text_box_item/text_box_item";
import FeaturedVideoModel, {
  emptyFeaturedVideo,
} from "../../models/featured_video";

export default function HomePage() {
  const [pageData, setPageData] = useState<HomeModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, homeGraph, "homePages").then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(new HomeModel(data.data ? data.data.page : emptyHome));
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageDataToSave: HomeModel) => {
    if (pageDataToSave) {
      console.log(pageDataToSave);
      setLoading(true);
      var dataToSend: any = pageDataToSave;
      dataToSend.sliders = await updateSliderArray(pageDataToSave.sliders);
      dataToSend.mobile_sliders = await updateSliderArray(
        pageDataToSave.mobile_sliders
      );
      dataToSend.contents = await updateContentArray(pageDataToSave.contents);
      dataToSend.explore_dilmah_items = await updateExploreDilmahItemArray(
        pageDataToSave.explore_dilmah_items
      );
      dataToSend.featured_videos = await updateFeaturedVideoItemArray(
        pageDataToSave.featured_videos
      );
      dataToSend.seo = await updateSeoItem(pageDataToSave.seo);

      await updateContentTypeCall(dataToSend, "homes").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "home-pages"
          ).then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewSlider = () => {
    const prev = pageData;
    if (prev) {
      prev.sliders.push(new Slider(emptySlider));
      setPageData({ ...prev });
    }
  };

  const removeSlider = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.sliders.splice(index, 1);
      setPageData(new HomeModel(emptyHome));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewMobileSlider = () => {
    const prev = pageData;
    if (prev) {
      prev.mobile_sliders.push(new Slider(emptySlider));
      setPageData({ ...prev });
    }
  };

  const removeMobileSlider = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.mobile_sliders.splice(index, 1);
      setPageData(new HomeModel(emptyHome));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewContent = () => {
    const prev = pageData;
    if (prev) {
      prev.contents.push(new ContentModel(emptyContent));
      setPageData({ ...prev });
    }
  };

  const removeContent = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.contents.splice(index, 1);
      setPageData(new HomeModel(emptyHome));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewExploreDilmah = () => {
    const prev = pageData;
    if (prev) {
      prev.explore_dilmah_items.push(
        new ExploreDilmahItemModel(emptyExploreDilmahItem)
      );
      setPageData({ ...prev });
    }
  };

  const removeExploreDilmah = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.explore_dilmah_items.splice(index, 1);
      setPageData(new HomeModel(emptyHome));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewVideo = () => {
    const prev = pageData;
    if (prev) {
      prev.featured_videos.push(new FeaturedVideoModel(emptyFeaturedVideo));
      setPageData({ ...prev });
    }
  };

  const removeVideo = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.featured_videos.splice(index, 1);
      setPageData(new HomeModel(emptyHome));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const handleVideoLinkChange = (link: string) => {
    const prev = pageData;
    if (prev) {
      prev.configurable_video_url = link;
      setPageData({ ...prev });
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.home}
      parentRoutes={[routeNameObjects.home]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Sliders"} onClick={addNewSlider}>
            {pageData.sliders.map((slider, index) => (
              <SliderItem
                key={index}
                slider={slider}
                index={index}
                onRemove={removeSlider}
              />
            ))}
          </AttributeItem>
          <AttributeItem
            title={"Mobile Image Sliders"}
            onClick={addNewMobileSlider}
          >
            {pageData.mobile_sliders.map((slider, index) => (
              <SliderItem
                key={index}
                slider={slider}
                index={index}
                onRemove={removeMobileSlider}
                isImageSlider={true}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"Content"} onClick={addNewContent}>
            {pageData.contents.map((content, index) => (
              <ContentItem
                key={index}
                content={content}
                index={index}
                onRemove={removeContent}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"Explore Dilmah"} onClick={addNewExploreDilmah}>
            {pageData.explore_dilmah_items.map((content, index) => (
              <ExploreDilmahItem
                key={index}
                explore={content}
                index={index}
                onRemove={removeExploreDilmah}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"Featured Videos"} onClick={addNewVideo}>
            {pageData.featured_videos.map((content, index) => (
              <FeaturedVideoItem
                key={index}
                video={content}
                index={index}
                onRemove={removeVideo}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"Configurable Video"}>
            <TextBoxItem
              label="Configurable Video"
              onVideoLinkChange={handleVideoLinkChange}
              value={pageData.configurable_video_url}
            ></TextBoxItem>
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
