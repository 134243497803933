import React from "react";
import { Button } from "@material-ui/core";
import useStyles from "./add_item_style";

interface Props {
  title: string;
  onClick: Function;
  width?:number;
}
export default function AddItemBtn(props: Props) {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={classes.button}
      style={{
        width: props.width ? props.width:'200px',
      }}
      onClick={() => props.onClick()}
    >
      {props.title}
    </Button>
  );
}
