import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import MultiLanguage, { emptyMultiLang } from "../../models/multi_language";
import QuizModel, { emptyQuizModel } from "../../models/quiz_model";
import AttributeItem from "../attribute_item/attribute_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function DilmahQuizItem(props: {
  onRemove: Function;
  index: number;
  content: QuizModel;
}) {

  const [content, setContent] = useState(props.content);

  const addNewAnswers = () => {
    const prev = content;
    if (prev) {
      prev.anwsers.push(
        new MultiLanguage(emptyMultiLang)
      );
      setContent({ ...prev });
    }
  };

  const removeAnswers = (index: number) => {
    const prev = content;
    if (prev) {
      prev.anwsers.splice(index, 1);
      setContent(new QuizModel(emptyQuizModel));
      setTimeout(() => {
        setContent({ ...prev });
      }, 0.01);
    }
  };

  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.content.question_image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.content.question_image_url = url;
          }}
        />
      </div>

      <MultiLangEdit isShowLabel label="Question Header" value={props.content.question_header} />
      <MultiLangEdit isShowLabel label="Question" value={props.content.question} />
      
      <AttributeItem
        title={"Anwsers"}
        onClick={addNewAnswers}
      >
        {content.anwsers.map((value, index) => (
          <MultiLangEdit
            value={value}
            index={index}
            onRemove={removeAnswers}
          />
        ))}
      </AttributeItem>
      
    </Card>
  );
}
