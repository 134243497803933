import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class TeaLocationModel {
    id: number
    longitude: number
    latitude: number
    title: MultiLanguage
    description: MultiLanguage
    image_url: string
    image_text_item: MultiLanguage
    constructor(data: any) {
        this.id = data.id
        this.longitude = data.longitude
        this.latitude = data.latitude
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.image_url = data.image_url
        this.image_text_item = new MultiLanguage(data.image_text_item ? data.image_text_item : emptyMultiLang)
    }
}

export const emptyTeaLocation = {
    title: emptyMultiLang,
    description: emptyMultiLang,
    image_text_item: emptyMultiLang
}