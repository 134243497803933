import ImageWithTitle from "../image_with_title";
import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import HeaderWithContent from "../header_with_content";


export default class NaturalInfusionOurIngredientsModal {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header:MultiLanguage
    topic:MultiLanguage
    herbs_descriptions:HeaderWithContent[]=[]
    content:ContentModel
    video_content: ContentModel
    image_with_titles:ImageWithTitle[]=[]

    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.topic = new MultiLanguage(data.topic?data.topic:emptyMultiLang)
        data.herbs_descriptions.forEach((x:any) => this.herbs_descriptions.push(new HeaderWithContent(x)))
        this.content = new ContentModel(data.content?data.content:emptyContent)
        this.video_content = new ContentModel(data.video_content?data.video_content:emptyContent)
        data.image_with_titles.forEach((x:any) => this.image_with_titles.push(new ImageWithTitle(x)))
    }
}

export const emptyNaturalInfusionOurIngredients = {
    header:emptyMultiLang,
    topic:emptyMultiLang,
    herbs_descriptions: [],
    content:emptyContent,
    image_with_titles: [],
}