import ContentModel, { emptyContent } from "../content_model";
import ExploreDilmahItemModel from "../explore_dilmah_item";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import ElixirOfCeylonTeaBrewingGuidelineModal, { emptyElixirOfCeylonTeaBrewingGuideline } from "./elixir_of_ceylon_tea_brewing_guideline";
import ElixirOfCeylonTeaElixirRecipesModal, { emptyElixirOfCeylonTeaElixirRecipes } from "./elixir_of_ceylon_tea_elixir_recipes";
import ElixirOfCeylonTeaOurElixirModal, { emptyElixirOfCeylonTeaOurElixir } from "./elixir_of_ceylon_tea_our_elixir";
import ElixirOfCeylonTeaRealTeaModal, { emptyElixirOfCeylonTeaRealTea } from "./elixir_of_ceylon_tea_real_tea";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class ElixirOfCeylonTeaModel {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header: MultiLanguage
    topic: MultiLanguage
    content: ContentModel
    links: ExploreDilmahItemModel[] = []
    seo: SEOComponent
    our_elixir: ElixirOfCeylonTeaOurElixirModal
    real_tea: ElixirOfCeylonTeaRealTeaModal
    elixir_recipes: ElixirOfCeylonTeaElixirRecipesModal
    brewing_guideline: ElixirOfCeylonTeaBrewingGuidelineModal

    
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.topic = new MultiLanguage(data.topic ? data.topic : emptyMultiLang)
        this.content = new ContentModel(data.content ? data.content : emptyContent)
        data.links.forEach((x: any) => this.links.push(new ExploreDilmahItemModel(x)))
        this.our_elixir = new ElixirOfCeylonTeaOurElixirModal(data.our_elixir ? data.our_elixir : emptyElixirOfCeylonTeaOurElixir)
        this.real_tea = new ElixirOfCeylonTeaRealTeaModal(data.real_tea ? data.real_tea : emptyElixirOfCeylonTeaRealTea)
        this.elixir_recipes = new ElixirOfCeylonTeaElixirRecipesModal(data.elixir_recipes ? data.elixir_recipes : emptyElixirOfCeylonTeaElixirRecipes)
        this.brewing_guideline = new ElixirOfCeylonTeaBrewingGuidelineModal(data.brewing_guideline ? data.brewing_guideline : emptyElixirOfCeylonTeaBrewingGuideline)
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyElixirOfCeylonTea = {
    content: emptyContent,
    header: emptyMultiLang,
    topic: emptyMultiLang,
    links: [],
    our_elixir: emptyElixirOfCeylonTeaOurElixir,
    real_tea: emptyElixirOfCeylonTeaRealTea,
    elixir_recipes: emptyElixirOfCeylonTeaElixirRecipes,
    brewing_guideline:emptyElixirOfCeylonTeaBrewingGuideline,
    seo: emptySEOComponent
}