import { Card, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import TeaLocationModel from "../../models/tea_location.ts";
import IconBtn from "../icon_btn/icon_btn";
import LeafletMapView from "../map/leaflet_map";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import AttributeItem from '../../components/attribute_item/attribute_item'


export default function TeaLocationItem(props: {
  item: TeaLocationModel;
  onRemove: Function;
}) {
  const [location, setLocation] = useState({
    lat: props.item.latitude ? props.item.latitude : 0,
    lon: props.item.longitude ? props.item.longitude : 0,
  });

  const [imageUrl, setUrl] = useState(props.item.image_url);
  const getImageUrl = (url: string) => {
    props.item.image_url = url;
    setUrl(url)
  };

  const changeLocation = (location: any) => {
    props.item.latitude = location.lat;
    props.item.longitude = location.lon;
    setLocation({ lat: location.lat, lon: location.lon });
  };
  return (
    <Card
      elevation={5}
      style={{
        margin: "10px 0px",
        paddingTop: "30px",
        position: "relative",
      }}
    >
      <IconBtn icon={Remove} onClick={props.onRemove} />
      <MultiLangEdit value={props.item.title} label="Title" isShowLabel />
      <MultiLangEdit
        value={props.item.description}
        label="Description"
        isShowLabel
      />
      <div style={{ textAlign: "center" }}>
        <AttributeItem title="Bottom image and text">
          <IconUploadBtn
            height={150}
            isEditable
            mediaUrl={imageUrl}
            mediaType={"IMAGE"}
            getFileUrl={getImageUrl}
          />
          <MultiLangEdit
            value={props.item.image_text_item}
            label="Description"
            isShowLabel
          />
        </AttributeItem>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            type="number"
            label="Latitude"
            variant="outlined"
            value={location.lat}
            onChange={(event) => {
              const prev = location;
              prev.lat = Number(event.target.value);
              setLocation({ ...prev });
              props.item.latitude = Number(event.target.value);
            }}
            InputLabelProps={{
              shrink: location.lat !== null,
            }}
          />
          <TextField
            style={{
              width: "100%",
            }}
            type="number"
            label="Longitude"
            variant="outlined"
            value={location.lon}
            onChange={(event) => {
              const prev = location;
              prev.lon = Number(event.target.value);
              setLocation({ ...prev });
              props.item.longitude = Number(event.target.value);
            }}
            InputLabelProps={{
              shrink: location.lon !== null,
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <LeafletMapView location={location} setLocation={changeLocation} />
        </div>
      </div>
    </Card>
  );
}
