import { Action, action } from "easy-peasy";

export interface CurrentData {
  selectedLanguageIndex: number;
  setSelectedLanguageIndex: Action<CurrentData, number>;
}

const currentData: CurrentData = {
  selectedLanguageIndex: 0,

  setSelectedLanguageIndex: action((state, payload) => {
    state.selectedLanguageIndex = payload;
  }),
};

export default currentData;
