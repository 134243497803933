import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React from "react";
import PhotographerModal from "../../models/photographer";
import AttributeItem from "../attribute_item/attribute_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import ImageList from "../image_list/image_list";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import SEOComponent from "../seo_component/seo_component";

export default function PhotographerItem(props: {
  onRemove: Function;
  index: number;
  photographer: PhotographerModal;
}) {
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.photographer.image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.photographer.image_url = url;
          }}
        />
      </div>

      <MultiLangEdit isShowLabel label="Name" value={props.photographer.name} />
      <MultiLangEdit isShowLabel label="Description" value={props.photographer.description} />
      <ImageList images={props.photographer.images} />

      <AttributeItem title={"SEO"}>
          <SEOComponent seoItem={props.photographer.seo} />
      </AttributeItem>
      
    </Card>
  );
}
