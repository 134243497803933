import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import TeaInspiredEBook, {
  emptyTeaInspiredEBook,
} from "../../models/pages/tea_inspired_e_book";
import EBookSection, { emptyEBookSection } from "../../models/e_book_section";
import EBookSectionComponent from "../../components/e_book_section/e_book_section";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateEBookSectionArray,
  updateMultiLangItem,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { teaInspiredEBooksGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";
export default function TeaInspiredEBooksPage() {
  const [pageData, setPageData] = useState<TeaInspiredEBook>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(
      country,
      teaInspiredEBooksGraph,
      "teaInspiredEBooksPages"
    ).then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new TeaInspiredEBook(
            data.data ? data.data.page : emptyTeaInspiredEBook
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: TeaInspiredEBook) => {
    if (pageData) {
      setLoading(true);
      var dataToSave: any = pageData;
      dataToSave.e_book_sections = await updateEBookSectionArray(
        pageData.e_book_sections
      );
      dataToSave.title = await updateMultiLangItem(pageData.title);
      dataToSave.seo = await updateSeoItem(pageData.seo)

      await updateContentTypeCall(dataToSave, "tea-inspired-e-books").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "tea-inspired-e-books-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const addNewSection = () => {
    const prev = pageData;
    if (prev) {
      prev.e_book_sections.push(new EBookSection(emptyEBookSection));
      setPageData({ ...prev });
    }
  };

  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const removeSection = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.e_book_sections.splice(index, 1);
      setPageData(new TeaInspiredEBook(emptyTeaInspiredEBook));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.teaInspired}
      parentRoutes={[routeNameObjects.allAboutTea]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Title"}>
            <MultiLangEdit value={pageData.title} />
          </AttributeItem>
          <AttributeItem title={"E Book Sections"} onClick={addNewSection}>
            {pageData.e_book_sections.map((section, index) => (
              <EBookSectionComponent
                key={index}
                section={section}
                index={index}
                onRemove={removeSection}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
