import ImageItem from "./image_item";
import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class EBookElement {
  id: number;
  image_url: string;
  book_url: string;
  title: MultiLanguage;
  content: MultiLanguage;
  book_images:ImageItem[] = []
  constructor(data: any) {
    this.id = data.id;
    this.image_url = data.image_url;
    this.book_url = data.book_url;
    this.title = new MultiLanguage(data.title);
    this.content = new MultiLanguage(data.content);
    data.book_images.forEach((i:any)=>this.book_images.push(new ImageItem(i)))
  }
}

export const emptyEBookElement = {
  image_url: "",
  book_url: "",
  title: emptyMultiLang,
  content: emptyMultiLang,
  book_images:[]
};
