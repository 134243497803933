import CertificateAndAwardSection from "../certificate_and_award_section";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class CertificatesAndAwards {
  id:number
  certificates: CertificateAndAwardSection[] = [];
  awards: CertificateAndAwardSection[] = [];
  header: MultiLanguage;
  description: MultiLanguage;
  award_btn_text: MultiLanguage;
  certificate_btn_text: MultiLanguage;
  seo: SEOComponent
  constructor(data: any) {
    this.id=data.id
    data.certificates.forEach((x: any) =>
      this.certificates.push(new CertificateAndAwardSection(x))
    );
    data.awards.forEach((x: any) =>
      this.awards.push(new CertificateAndAwardSection(x))
    );
    this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang);
    this.description = new MultiLanguage(
      data.description ? data.description : emptyMultiLang
    );
    this.award_btn_text = new MultiLanguage(
      data.award_btn_text ? data.award_btn_text : emptyMultiLang
    );
    this.certificate_btn_text = new MultiLanguage(
      data.certificate_btn_text ? data.certificate_btn_text : emptyMultiLang
    );
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
  }
}

export const emptyCertificatePage = {
    certificates:[],
    awards:[],
    seo: emptySEOComponent
}