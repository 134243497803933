import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles({
  modelLabel: {
    width: "500px",
    backgroundColor: "grey",
    color: "white",
    padding: "5px 0px",
    textAlign: "center",
  },
  greyLabel: {
    color: "grey",
  },

  smallBtn: {
    padding: "2px",
  },

  tableRow: {
    alignItems: "center",
  },

  addBtn: {
    backgroundColor: "white",
    color: myColors.drawerSideBarColor,
    border: `2px solid ${myColors.drawerSideBarColor}`,
    "&:focus,&:hover": {
      backgroundColor: myColors.drawerSideBarColor,
      color: "white",
    },
  },
  removeBtn: {
    backgroundColor: "white",
    color: myColors.drawerSideBarColor,
    border: `2px solid ${myColors.drawerSideBarColor}`,
    "&:focus,&:hover": {
      backgroundColor: myColors.drawerSideBarColor,
      color: "white",
    },
  },
  tabSelected: { backgroundColor: myColors.drawerSideBarColor},
  tabStyle: {
    color: myColors.drawerColor,
    fontSize: "11px",
    fontWeight: "bold",
    height: "30px",
    minHeight: "30px",
    backgroundColor: "white",
    border: "0.5px solid grey",
    borderRadius: "10px",
    marginRight: "3px",
    minWidth:'100px'
  },
  tabStyleSelected: {
    backgroundColor: "#ffffffe6",
  },
  listPadding: {
    padding: "10px 3px",
    overflowY: "auto",
  },
  tabAppBar: {
    position: "relative",
    paddingRight: "50px",
    backgroundColor: "#9a9a9a80",
    width:'100%'
  },
  outerCard: { width: "100%", marginTop: "20px" },
  tabRow: { minHeight: "30px", height: "30px" },
  textArea: {
    width: "100%",
    minHeight: "70px",
    borderRadius: "7px",
    border: `2px solid ${myColors.drawerColor}`,
    color: "#2a2a2a",
    fontSize: "16px",
    fontFamily: "sans-serif",
    padding: "3px",
  },
  addRowIconWrap: {
    top: "-2px",
    position: "absolute",
    right: "0px",
  },
  textAreaLabel: {
    float: "left",
    margin: "7px",
    fontWeight: "bold",
  },
  contentWrapper: {
    border: `1px solid ${myColors.appBarColor}`,
    borderRadius: "5px",
    margin: "10px 0px",
    padding: "10px",
  },
  title:{
    marginLeft: '10px',
    fontWeight: 'bold',
    color: '#716666',
    textAlign: 'center',
  }
});

export default useStyles;
