import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import TeaGardens, { emptyTeaGarden } from "../../models/pages/tea_garden";
import TeaStateItemModel, {
  emptyTeaStateItem,
} from "../../models/tea_state_item_model";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import AttributeItem from "../../components/attribute_item/attribute_item";
import TeaStateItem from "../../components/tea_state_item/tea_state_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateMultiLangItem,
  updateSeoItem,
  updateTeaStateItemArray,
} from "../../services/api_service/components_update";
import { ourTeaGardensGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";
export default function TeaGardensPage() {
  const [garden, setGarden] = useState<TeaGardens>();

  const { showSideMessage } = SideMessageService();

  const getGardens = (country: string) => {
    getPageDataCall(country, ourTeaGardensGraph, "ourTeaGardensPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setGarden(undefined);
          setGarden(
            new TeaGardens(data.data ? data.data.page : emptyTeaGarden)
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getGardens(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (gardenData: TeaGardens) => {
    if (gardenData) {
      setLoading(true);
      const garden: any = gardenData;
      garden.header = await updateMultiLangItem(gardenData.header);
      garden.description = await updateMultiLangItem(gardenData.description);
      garden.tea_state_items = await updateTeaStateItemArray(
        gardenData.tea_state_items
      );
      garden.seo = await updateSeoItem(gardenData.seo)

      await updateContentTypeCall(garden, "our-tea-gardens").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "our-tea-gardens-pages"
          ).then((data) => {
            if (data?.success) {
              getGardens(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewState = () => {
    const prev = garden;
    if (prev) {
      prev.tea_state_items.push(new TeaStateItemModel(emptyTeaStateItem));
      setGarden({ ...prev });
    }
  };

  const removeState = (index: number) => {
    const prev = garden;
    if (prev) {
      prev.tea_state_items.splice(index, 1);
      setGarden(new TeaGardens(emptyTeaGarden));
      setTimeout(() => {
        setGarden({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.teaGardens}
      parentRoutes={[routeNameObjects.familyPassion]}
    >
      {garden ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(garden))}
              />
            }
          />

          <AttributeItem title="Tea State Items" onClick={addNewState}>
            {garden.tea_state_items.map((item, index) => (
              <TeaStateItem
                key={index}
                item={item}
                onRemove={() => {
                  removeState(index);
                }}
              />
            ))}
          </AttributeItem>
          <AttributeItem title="Header">
            <MultiLangEdit value={garden.header} />
          </AttributeItem>
          <AttributeItem title="Description">
            <MultiLangEdit value={garden.description} />
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={garden.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
