
import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class ExploreDilmahItemModel{
    id:number
    image_url:string
    title:MultiLanguage
    link:string

    constructor(data:any){
        this.id=data.id
        this.image_url = data.image_url
        this.title=new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.link = data.link
    }
}

export const emptyExploreDilmahItem = {
    title: emptyMultiLang,
}