import ContentModel, { emptyContent } from "../content_model";
import ExploreDilmahItemModel from "../explore_dilmah_item";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import TeamakersPrivateReserveAboutModel, { emptyTemakersPrivateReserveAbout } from "./tea_maker_private_reserve_about";
import TeamakersPrivateReserveTeaModel, { emptyTemakersPrivateReserveTea } from "./tea_maker_private_reserve_tea";
import TeamakersPrivateReserveTeaLocationModel, { emptyTemakersPrivateReserveTeaLocation } from "./tea_maker_private_reserve_tea_location";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class TeamakersPrivateReserveModel {
    id: number
    header_image_url: string
    sub_header_image_url: string
    content: ContentModel
    links: ExploreDilmahItemModel[] = []
    header: MultiLanguage
    about: TeamakersPrivateReserveAboutModel
    our_teas: TeamakersPrivateReserveTeaModel
    tea_locations: TeamakersPrivateReserveTeaLocationModel
    seo: SEOComponent
    constructor(data: any) {
        this.id = data.id
        this.content = new ContentModel(data.content ? data.content : emptyContent)
        data.links.forEach((x: any) => this.links.push(new ExploreDilmahItemModel(x)))
        this.header_image_url = data.header_image_url
        this.sub_header_image_url = data.sub_header_image_url
        this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.about = new TeamakersPrivateReserveAboutModel(data.about ? data.about : emptyTemakersPrivateReserveAbout)
        this.our_teas = new TeamakersPrivateReserveTeaModel(data.our_teas ? data.our_teas : emptyTemakersPrivateReserveTea)
        this.tea_locations = new TeamakersPrivateReserveTeaLocationModel(data.tea_locations ? data.tea_locations : emptyTemakersPrivateReserveTeaLocation)
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyTemakersPrivateReserve = {
    content: emptyContent,
    header: emptyMultiLang,
    links: [],
    about: emptyTemakersPrivateReserveAbout,
    our_teas: emptyTemakersPrivateReserveTea,
    tea_locations:emptyTemakersPrivateReserveTeaLocation,
    seo: emptySEOComponent
}