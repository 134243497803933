
import MultiLanguage, { emptyMultiLang } from "./multi_language"
import HeaderWithContent, { emptyHeaderWithContent } from "./header_with_content"
import ContentModel, { emptyContent } from "./content_model"

export default class IngredientItemModel{
    id:number
    title:MultiLanguage
    header_image_url:string
    intro_image_url: string
    about_ingredient: HeaderWithContent
    medicinal_claims: HeaderWithContent
    possible_side_effects: HeaderWithContent
    research_links: MultiLanguage
    video_item : ContentModel

    constructor(data:any){
        this.id=data.id
        this.title=new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.header_image_url = data.header_image_url
        this.intro_image_url = data.intro_image_url
        this.about_ingredient = new HeaderWithContent(data.about_ingredient ? data.about_ingredient : emptyHeaderWithContent)
        this.medicinal_claims = new HeaderWithContent(data.medicinal_claims ? data.medicinal_claims : emptyHeaderWithContent)
        this.possible_side_effects = new HeaderWithContent(data.possible_side_effects ? data.possible_side_effects : emptyHeaderWithContent)
        this.research_links = new MultiLanguage(data.research_links ? data.research_links : emptyMultiLang)
        this.video_item = new ContentModel(data.video_item ? data.video_item : emptyContent)
    }
}

export const emptyIngredientItem = {
    title: emptyMultiLang,
    about_ingredient: emptyHeaderWithContent,
    medicinal_claims: emptyHeaderWithContent,
    possible_side_effects: emptyHeaderWithContent,
    research_links: emptyMultiLang,
    video_item: emptyContent
}