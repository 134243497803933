import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import TimeLineItem from "../../components/time_line_item/time_line_item";
import TimeLineElement, { emptyTimeLine } from "../../models/time_line_element";
import DilmahGlobalTimeLineModel, {
  emptyDilmahGlobalTimeline,
} from "../../models/pages/dilmah_global_timeline";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import { updateSeoItem, updateTimeLineElementArray } from "../../services/api_service/components_update";
import { globalTimeLineGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";

export default function DilmahGlobalTimelinePage() {
  const [pageData, setPageData] = useState<DilmahGlobalTimeLineModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country,globalTimeLineGraph,'dilmahGlobalTimelinePages').then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new DilmahGlobalTimeLineModel(
            data.data ? data.data.page : emptyDilmahGlobalTimeline
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
const setLoading = useStoreActions((actions:Actions<StoreModel>)=>actions.loader.showLoader)
  const onSave = async (pageData: DilmahGlobalTimeLineModel) => {
    if (pageData) {
setLoading(true)
      var dataToSave: any = pageData
      dataToSave.time_line = await updateTimeLineElementArray(pageData.time_line);
      dataToSave.seo = await updateSeoItem(pageData.seo)
      
      await updateContentTypeCall(dataToSave,'dilma-global-timelines').then((data) => {
        if (data?.success) {
          updateContentTypeCall({
            id: currentPageId,
            country: selectedCountry.value,
            page: data.data.id,
          },'dilmah-global-timeline-pages').then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewTimeLine = () => {
    const prev = pageData;
    if (prev) {
      prev.time_line.push(new TimeLineElement(emptyTimeLine));
      setPageData({ ...prev });
    }
  };

  const removeTimeLine = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.time_line.splice(index, 1);
      setPageData(new DilmahGlobalTimeLineModel(emptyDilmahGlobalTimeline));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.globalTimeLine}
      parentRoutes={[routeNameObjects.familyPassion]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Time Line"} onClick={addNewTimeLine}>
            {pageData.time_line.map((timeLineItem, index) => (
              <TimeLineItem
                key={index}
                timeLineItem={timeLineItem}
                index={index}
                onRemove={removeTimeLine}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
