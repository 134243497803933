import { Card, Switch } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import MultiLanguage, { emptyMultiLang } from "../../models/multi_language";
import SubContentSectionModal, {
  emptySubContentSection,
} from "../../models/sub_content_section";
import AttributeItem from "../attribute_item/attribute_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import useStyles from "./sub_content_section_styles";

export default function SubContentSection(props: {
  content: SubContentSectionModal;
  index?: number;
  onRemove?: Function;
}) {
  const classes = useStyles();

  const changeHasHeader = (value: boolean) => {
    setContent((val) => ({ ...val, has_header: value }));
    props.content.has_header = value;
  };

  const [content, setContent] = useState(props.content);

  const getImageUrl = (url: string) => {
    props.content.image_url = url;
  };

  const addNewContent = () => {
    const prev = content;
    if (prev) {
      prev.contents.push(new MultiLanguage(emptyMultiLang));
      setContent({ ...prev });
    }
  };
  const removeContent = (index: number) => {
    const prev = content;
    if (prev) {
      prev.contents.splice(index, 1);
      setContent(new SubContentSectionModal(emptySubContentSection));
      setTimeout(() => {
        setContent({ ...prev });
      }, 0.01);
    }
  };
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      {props.onRemove && (
        <IconBtn
          icon={Remove}
          onClick={() => {
            if (props.onRemove && props.index != null) {
              props.onRemove(props.index);
            }
          }}
        />
      )}

      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={content.image_url}
          mediaType={"IMAGE"}
          getFileUrl={getImageUrl}
        />
      </div>
      <div className={classes.row}>
        <div>Has Header</div>
        <Switch
          checked={content.has_header}
          onClick={() => {
            changeHasHeader(!content.has_header);
          }}
        ></Switch>
      </div>
      {content.has_header && (
        <MultiLangEdit label={"Header"} isShowLabel value={content.header} />
      )}

      <AttributeItem title={"Contents"} onClick={addNewContent} removeMargin>
        {content.contents.map((header, index) => (
          <MultiLangEdit
            key={index}
            label={"Content"}
            isShowLabel
            value={header}
            onRemove={removeContent}
            index={index}
          />
        ))}
      </AttributeItem>
    </Card>
  );
}
