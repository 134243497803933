import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import ContentItem from "../../components/content_item/content_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateContentItem,
  updateSingleImageWithLinkItem,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import TeaInspiredHome, {
  emptyTeaInspiredHome,
} from "../../models/pages/tea_inspired_home";
import { teaInspiredHomeGraph } from "../../services/api_service/graphql_queries";
import SingleImageWithLinkComponent from "../../components/single_image_with_link/single_image_with_link";
import TextBoxItem from "../../components/text_box_item/text_box_item";
import SEOComponent from "../../components/seo_component/seo_component";

export default function TeaInspiredHomePage() {
  const [pageData, setPageData] = useState<TeaInspiredHome>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, teaInspiredHomeGraph, "teaInspiredHomePages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new TeaInspiredHome(
              data.data ? data.data.page : emptyTeaInspiredHome
            )
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: TeaInspiredHome) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.teamakers_philosophy = await updateSingleImageWithLinkItem(
        pageData.teamakers_philosophy
      );
      dataToSave.years_in_tea = await updateSingleImageWithLinkItem(
        pageData.years_in_tea
      );
      dataToSave.commemorative_teas = await updateSingleImageWithLinkItem(
        pageData.commemorative_teas
      );
      dataToSave.letters_to_dilmah = await updateSingleImageWithLinkItem(
        pageData.letters_to_dilmah
      );
      dataToSave.books = await updateSingleImageWithLinkItem(pageData.books);
      dataToSave.wish_dilmah = await updateSingleImageWithLinkItem(
        pageData.wish_dilmah
      );
      dataToSave.banner_section = await updateContentItem(
        pageData.banner_section
      );
      dataToSave.video_section = await updateContentItem(
        pageData.video_section
      );
      dataToSave.seo = await updateSeoItem(pageData.seo);

      await updateContentTypeCall(dataToSave, "tea-inspire-homes").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "tea-inspired-home-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const hadnleViewLibraryChange = (link: string) => {
    const prev = pageData;
    if (prev) {
      prev.view_library_link = link;
      setPageData({ ...prev });
    }
  };

  const handleViewAllLettersChange = (link: string) => {
    const prev = pageData;
    if (prev) {
      prev.view_all_letters_link = link;
      setPageData({ ...prev });
    }
  };

  const handleSubmitLettersChange = (link: string) => {
    const prev = pageData;
    if (prev) {
      prev.submit_letter_link = link;
      setPageData({ ...prev });
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.teaInspiredHome}
      parentRoutes={[routeNameObjects.teaInspired]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"Teamakers Philosophy"}>
            <SingleImageWithLinkComponent
              item={pageData.teamakers_philosophy}
            />
          </AttributeItem>
          <AttributeItem title={"70 Years in Tea"}>
            <SingleImageWithLinkComponent item={pageData.years_in_tea} />
          </AttributeItem>
          <AttributeItem title={"Commemorative Teas"}>
            <SingleImageWithLinkComponent item={pageData.commemorative_teas} />
          </AttributeItem>
          <AttributeItem title={"Letters To Mr.Dilmah"}>
            <SingleImageWithLinkComponent item={pageData.letters_to_dilmah} />
          </AttributeItem>
          <AttributeItem title={"Wish Mr.Dilmah"}>
            <SingleImageWithLinkComponent item={pageData.wish_dilmah} />
          </AttributeItem>
          <AttributeItem title={"Books"}>
            <SingleImageWithLinkComponent item={pageData.books} />
          </AttributeItem>
          <AttributeItem title={"Video Section"}>
            <ContentItem content={pageData.video_section} />
          </AttributeItem>
          <AttributeItem title={"Banner Section"}>
            <ContentItem content={pageData.banner_section} />
          </AttributeItem>
          <AttributeItem title={"View Library Button"}>
            <TextBoxItem
              onVideoLinkChange={hadnleViewLibraryChange}
              value={pageData.view_library_link}
            />
          </AttributeItem>
          <AttributeItem title={"View All Letters Button"}>
            <TextBoxItem
              onVideoLinkChange={handleViewAllLettersChange}
              value={pageData.view_all_letters_link}
            />
          </AttributeItem>
          <AttributeItem title={"Submite Letters"}>
            <TextBoxItem
              onVideoLinkChange={handleSubmitLettersChange}
              value={pageData.submit_letter_link}
            />
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
