
import MultiLanguage, { emptyMultiLang } from "./multi_language"
import SEOComponent, { emptySEOComponent } from "./pages/seo_component"

export default class MediaCoverModel{
    id:number
    header:MultiLanguage
    description:MultiLanguage
    title:MultiLanguage
    image_url:string
    link:string
    seo: SEOComponent;

    constructor(data:any){
        this.id=data.id
        this.header=new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.image_url = data.image_url
        this.link = data.link
        this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
    }
}

export const emptyMediaCover = {
    header:emptyMultiLang,
    description: emptyMultiLang,
    title:emptyMultiLang,
    seo: emptySEOComponent,
}