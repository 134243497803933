import MultiLanguage, { emptyMultiLang } from "./multi_language"
import SingleImageWithLink from "./single_image_with_link"

export default class SiteMapSection{
    id: number;
    section_title: MultiLanguage;
    section_items: SingleImageWithLink[] = [];

    constructor(data: any){
        this.id = data.id
        this.section_title = new MultiLanguage(data.section_title)
        data.section_items.forEach((x: any) =>
            this.section_items.push(new SingleImageWithLink(x))
        );
    }
}

export const emptySiteMapSection = {
    section_title: emptyMultiLang,
    section_items: []
}
