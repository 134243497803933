import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import ContentModel, { emptyContent } from "../../models/content_model";
import PodcastSection, { emptyPodcastSection } from "../../models/podcast_section";
import AttributeItem from "../attribute_item/attribute_item";
import ContentItem from "../content_item/content_item";
import IconBtn from "../icon_btn/icon_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function PodcastSectionComponent(props: {
  section: PodcastSection;
  index: number;
  onRemove: Function;
}) {
  const [section, setSection] = useState(props.section);

  const addNewBook = () => {
    const prev = section;
    if (prev) {
      prev.contents.push(new ContentModel(emptyContent));
      setSection({ ...prev });
    }
  };
  const removeBook = (index: number) => {
    const prev = section;
    if (prev) {
      prev.contents.splice(index, 1);
      setSection(new PodcastSection(emptyPodcastSection));
      setTimeout(() => {
        setSection({ ...prev });
      }, 0.01);
    }
  };

  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <MultiLangEdit label={"Title"} isShowLabel value={section.title} />

      <AttributeItem removeMargin title={"Podcast Items"} onClick={addNewBook}>
        {section.contents.map((book, index) => (
          <ContentItem
            key={index}
            index={index}
            content={book}
            onRemove={removeBook}
          />
        ))}
      </AttributeItem>
    </Card>
  );
}
