
import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class TestimonialItemModel{
    id:number
    name:MultiLanguage
    title:MultiLanguage
    content:MultiLanguage
    short_description:MultiLanguage
    date:string
    image_url:string

    constructor(data:any){
        this.id=data.id
        this.name=new MultiLanguage(data.name ? data.name : emptyMultiLang)
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.content = new MultiLanguage(data.content ? data.content : emptyMultiLang)
        this.short_description = new MultiLanguage(data.short_description ? data.short_description : emptyMultiLang)
        this.date = data.date
        this.image_url = data.image_url
    }
}

export const emptyTestimonialItem = {
    title: new MultiLanguage(emptyMultiLang),
    content: new MultiLanguage(emptyMultiLang),
    short_description: new MultiLanguage(emptyMultiLang),
    name: new MultiLanguage(emptyMultiLang),
}