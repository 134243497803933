import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  graphQuery,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import SideMessageService from "../../services/side_message_service";

import CountrySelect from "../../components/country_select/country_select";
import { countries } from "../../components/country_select/countries";

import {
  updateTeaBlogItemArray,
  updateSeoItem,
} from "../../services/api_service/components_update";
import {
  teaBlogCategoriesGraph,
  teaBlogGraph,
} from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import DilmahTeaBlogItem from "../../components/dilmah_tea_blog/dilmah_tea_blog";
import DilmahTeaBlogModel, {
  emptyDilmahTeaBlog,
} from "../../models/dilmah_tea_blog";
import TeaBlogPageModal, {
  emptyTeaBlogPage,
} from "../../models/pages/tea_blog";
import TeaBlogCategoryModal from "../../models/tea_blog_category";
import { ListItem, TextField } from "@material-ui/core";
import SEOComponent from "../../components/seo_component/seo_component";
export default function TeaBlogPage() {
  const [pageData, setPageData] = useState<TeaBlogPageModal>();

  const [teaBlogCategories, setTeaBlogCategories] = useState<
    TeaBlogCategoryModal[]
  >([]);

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    graphQuery(teaBlogCategoriesGraph).then((data) => {
      if (data?.success) {
        const t: TeaBlogCategoryModal[] = [];
        data.data["teaBlogCategories"].forEach((x: any) =>
          t.push(new TeaBlogCategoryModal(x))
        );
        setTeaBlogCategories(t);
      }
    });
    getPageDataCall(country, teaBlogGraph, "teaBlogPages").then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new TeaBlogPageModal(data.data ? data.data.page : emptyTeaBlogPage)
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageDataToSave: TeaBlogPageModal) => {
    if (pageDataToSave) {
      setLoading(true);
      var dataToSend: any = pageDataToSave;

      dataToSend.dilmah_tea_blogs = await updateTeaBlogItemArray(
        pageDataToSave.dilmah_tea_blogs
      );
      dataToSend.seo = await updateSeoItem(pageDataToSave.seo);

      await updateContentTypeCall(dataToSend, "tea-blog-page-items").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "tea-blog-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const addNewTeaBlog = () => {
    const prev = pageData;
    if (prev) {
      prev.dilmah_tea_blogs.push(new DilmahTeaBlogModel(emptyDilmahTeaBlog));
      setPageData({ ...prev });
    }
  };

  const removeTeaBlog = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.dilmah_tea_blogs.splice(index, 1);
      setPageData(new TeaBlogPageModal(emptyTeaBlogPage));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.teaBlog}
      parentRoutes={[routeNameObjects.sections]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"Header Tea Blog"}>
            <TextField
              style={{
                width: "300px",
              }}
              variant="outlined"
              value={pageData.header_tea_blog}
              select
              onChange={(event) => {
                const prev = pageData;
                if (prev) {
                  prev.header_tea_blog = event.target.value;
                  setPageData({ ...prev });
                }
              }}
            >
              {pageData.dilmah_tea_blogs.map((type, index) => (
                <ListItem key={index} value={type.id}>
                  {type.header.getAvailableText()}
                </ListItem>
              ))}
            </TextField>
          </AttributeItem>

          <AttributeItem title={"Dilmah Tea Blogs"} onClick={addNewTeaBlog}>
            {pageData.dilmah_tea_blogs.map((slider, index) => (
              <DilmahTeaBlogItem
                key={index}
                blog={slider}
                index={index}
                onRemove={removeTeaBlog}
                categories={teaBlogCategories}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
