import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {
  routeNames,
  RouteNames,
} from "../services/route_name/route_names";

export default function Routings() {
  return (
    <Router>
      <Switch>
        <Fragment>
          {routeNames.map((route, index) => (
            <MyRoute route={route} key={index} />
          ))}
        </Fragment>
      </Switch>
    </Router>
  );
}

interface MyRouteProps {
  route: RouteNames;
}

function MyRoute(props: MyRouteProps) {
  return (
    <div>
      {props.route.component ? (
        <Route
          exact
          path={props.route.route}
          component={props.route.component}
        />
      ) : (
        <div />
      )}
      {props.route.children.map((nested, index) => (
        <MyRoute key={index} route={nested} />
      ))}
    </div>
  );
}
