import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import ContentItem from "../../components/content_item/content_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateSeoItem,updateContentItem, updateExploreDilmahItemArray, updateMultiLangItem, updateTSeriesGourmetRareTeas, updateTSeriesGourmetSeasonalFlush, updateTSeriesGourmetTeas, updateFeaturedVideoItemArray
} from "../../services/api_service/components_update";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import ExploreDilmahItem from "../../components/explore_dilmah_item/explore_dilmah_item";
import ExploreDilmahItemModel, { emptyExploreDilmahItem } from "../../models/explore_dilmah_item";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import TSeriesGourmetModel, { emptyTSeriesGourmet } from "../../models/pages/t_series_gourmet";
import { tSeriesGourmetGraph } from "../../services/api_service/graphql_queries";
import FeaturedVideoItem from "../../components/featured_video_item/featured_video_item";
import FeaturedVideoModel, { emptyFeaturedVideo } from "../../models/featured_video";
import SEOComponent from "../../components/seo_component/seo_component";

export default function TSeriesGourmet() {
  const [pageData, setPageData] = useState<TSeriesGourmetModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, tSeriesGourmetGraph, "tSeriesGourmetPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new TSeriesGourmetModel(data.data ? data.data.page : emptyTSeriesGourmet)
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: TSeriesGourmetModel) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.header = await updateMultiLangItem(pageData.header)
      dataToSave.content = await updateContentItem(pageData.content);
      dataToSave.links = await updateExploreDilmahItemArray(pageData.links)
      dataToSave.seo = await updateSeoItem(pageData.seo)
      dataToSave.gourmet_teas = await updateTSeriesGourmetTeas(pageData.gourmet_teas)
      dataToSave.very_special_rare_teas = await updateTSeriesGourmetRareTeas(pageData.very_special_rare_teas)
      dataToSave.seasonal_flush.featured_videos = await updateFeaturedVideoItemArray(pageData.seasonal_flush.featured_videos)
      dataToSave.seasonal_flush = await updateTSeriesGourmetSeasonalFlush(pageData.seasonal_flush)

      await updateContentTypeCall(dataToSave, "t-series-gourmets").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "t-series-gourmet-pages"
          ).then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };



  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };


  const setTeasHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.gourmet_teas.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setTeasHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.gourmet_teas.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setRareTeasHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.very_special_rare_teas.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setRareTeasHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.very_special_rare_teas.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };


  const setSeasonalFlushHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.seasonal_flush.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setSeasonalFlushHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.seasonal_flush.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };



  const addNewLink = () => {
    const prev = pageData;
    if (prev) {
      prev.links.push(new ExploreDilmahItemModel(emptyExploreDilmahItem));
      setPageData({ ...prev });
    }
  };

  const removeLink = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.links.splice(index, 1);
      setPageData(new TSeriesGourmetModel(emptyTSeriesGourmet));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewVideo = () => {
    const prev = pageData;
    if (prev) {
      prev.seasonal_flush.featured_videos.push(new FeaturedVideoModel(emptyFeaturedVideo));
      setPageData({ ...prev });
    }
  };

  const removeVideo = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.seasonal_flush.featured_videos.splice(index, 1);
      setPageData(new TSeriesGourmetModel(emptyTSeriesGourmet));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.tSeriesGourmet}
      parentRoutes={[routeNameObjects.teaRanges]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Header Sub Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderSubImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_sub_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Header"}>
            <MultiLangEdit
              value={pageData.header}
            />
          </AttributeItem>
          <AttributeItem title={"Content"}>
            <ContentItem
              content={pageData.content}
            />
          </AttributeItem>
          <AttributeItem title={"Links"} onClick={addNewLink}>
            {pageData.links.map((link, index) => (
              <ExploreDilmahItem
                key={index}
                explore={link}
                index={index}
                onRemove={removeLink}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={'Gourmet Teas'}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setTeasHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.gourmet_teas.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setTeasHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.gourmet_teas.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit isShowLabel label="header" value={pageData.gourmet_teas.header} />
            <AttributeItem title={'Content'} removeMargin >
              <ContentItem content={pageData.gourmet_teas.content} />
            </AttributeItem>
          </AttributeItem>


          <AttributeItem title={'Very Special Rare Teas'}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setRareTeasHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.very_special_rare_teas.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setRareTeasHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.very_special_rare_teas.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit isShowLabel label="header" value={pageData.very_special_rare_teas.header} />
            <AttributeItem title={'Content'} removeMargin >
              <ContentItem content={pageData.very_special_rare_teas.content} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={'Seasonal Flush'}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setSeasonalFlushHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.seasonal_flush.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setSeasonalFlushHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.seasonal_flush.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit isShowLabel label="header" value={pageData.seasonal_flush.header} />
            <AttributeItem title={'Title Content'} removeMargin >
              <ContentItem content={pageData.seasonal_flush.title_content} />
            </AttributeItem>
            <AttributeItem title={'Quote Content'} removeMargin >
              <ContentItem content={pageData.seasonal_flush.quote_content} />
            </AttributeItem>
          </AttributeItem>
          <AttributeItem title={"Seasonal Flush - Featured Videos"} onClick={addNewVideo}>
            {pageData.seasonal_flush.featured_videos.map((content, index) => (
              <FeaturedVideoItem
                key={index}
                video={content}
                index={index}
                onRemove={removeVideo}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}