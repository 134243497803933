import ContentModel from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class FactsOfTea{
    id:number
    contents: ContentModel[] = []
    title:MultiLanguage
    header_image_url:string
    seo: SEOComponent
    constructor(data:any){
        this.id=data.id
        data.contents.forEach((x:any)=>this.contents.push(new ContentModel(x)))
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.header_image_url = data.header_image_url
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyFactsOfTea = {
    contents : [],
    title: emptyMultiLang,
    seo: emptySEOComponent
}