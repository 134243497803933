import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import ImageWithTitle, {
  emptyImageWithTitle,
} from "../../models/image_with_title";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import ContentItem from "../../components/content_item/content_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateSeoItem,
  updateContentItem,
  updateExploreDilmahItemArray,
  updateMultiLangItem,
  updateNaturalInfusionEveryMood,
  updateNaturalInfusionOurInfusions,
  updateNaturalInfusionOurIngredients,
} from "../../services/api_service/components_update";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import ExploreDilmahItem from "../../components/explore_dilmah_item/explore_dilmah_item";
import ExploreDilmahItemModel, {
  emptyExploreDilmahItem,
} from "../../models/explore_dilmah_item";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import { naturalInfusionGraph } from "../../services/api_service/graphql_queries";
import NaturalInfusionModel, {
  emptyNaturalInfusion,
} from "../../models/pages/natural_infusion";
import ImageWithTitleComponent from "../../components/image_with_title/image_with_title";
import HeaderWithContentComponent from "../../components/header_with_content/header_with_content";
import HeaderWithContent, { emptyHeaderWithContent } from "../../models/header_with_content";
import SEOComponent from "../../components/seo_component/seo_component";

export default function NaturalInfusion() {
  const [pageData, setPageData] = useState<NaturalInfusionModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(
      country,
      naturalInfusionGraph,
      "naturalInfusionsPages"
    ).then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new NaturalInfusionModel(
            data.data ? data.data.page : emptyNaturalInfusion
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: NaturalInfusionModel) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.header = await updateMultiLangItem(pageData.header);
      dataToSave.content = await updateContentItem(pageData.content);
      dataToSave.links = await updateExploreDilmahItemArray(pageData.links);
      dataToSave.seo = await updateSeoItem(pageData.seo)
      dataToSave.our_infusions = await updateNaturalInfusionOurInfusions(
        pageData.our_infusions
      );
      dataToSave.our_ingredients = await updateNaturalInfusionOurIngredients(
        pageData.our_ingredients
      );
      dataToSave.for_every_mood = await updateNaturalInfusionEveryMood(
        pageData.for_every_mood
      );

      await updateContentTypeCall(dataToSave, "natural-infusions").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "natural-infusions-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setInfusionsHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_infusions.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setInfusionsHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_infusions.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setIngredientsHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_ingredients.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setIngredientsHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_ingredients.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setEveryMoodHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.for_every_mood.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setEveryMoodHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.for_every_mood.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };

  const addNewLink = () => {
    const prev = pageData;
    if (prev) {
      prev.links.push(new ExploreDilmahItemModel(emptyExploreDilmahItem));
      setPageData({ ...prev });
    }
  };

  const removeLink = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.links.splice(index, 1);
      setPageData(new NaturalInfusionModel(emptyNaturalInfusion));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithText = () => {
    const prev = pageData;
    if (prev) {
      prev.our_ingredients.image_with_titles.push(
        new ImageWithTitle(emptyImageWithTitle)
      );
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithText = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.our_ingredients.image_with_titles.splice(index, 1);
      setPageData(new NaturalInfusionModel(emptyNaturalInfusion));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewHerbDescriptionContent = () => {
    const prev = pageData;
    if (prev) {
      prev.our_ingredients.herbs_descriptions.push(
        new HeaderWithContent(emptyHeaderWithContent)
      );
      setPageData({ ...prev });
    }
  };

  const removeNewHerbDescriptionContent = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.our_ingredients.herbs_descriptions.splice(index, 1);
      setPageData(new NaturalInfusionModel(emptyNaturalInfusion));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextToMoodSection = () => {
    const prev = pageData;
    if (prev) {
      prev.for_every_mood.mood_image_list.push(
        new ImageWithTitle(emptyImageWithTitle)
      );
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextMoodSection = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.for_every_mood.mood_image_list.splice(index, 1);
      setPageData(new NaturalInfusionModel(emptyNaturalInfusion));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.naturalInfusion}
      parentRoutes={[routeNameObjects.teaRanges]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Header Sub Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderSubImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_sub_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Header"}>
            <MultiLangEdit value={pageData.header} />
          </AttributeItem>
          <AttributeItem title={"Content"}>
            <ContentItem content={pageData.content} />
          </AttributeItem>
          <AttributeItem title={"Links"} onClick={addNewLink}>
            {pageData.links.map((link, index) => (
              <ExploreDilmahItem
                key={index}
                explore={link}
                index={index}
                onRemove={removeLink}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"Our Infusions"}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setInfusionsHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.our_infusions.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setInfusionsHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.our_infusions.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit
              isShowLabel
              label="Header"
              value={pageData.our_infusions.header}
            />
            <AttributeItem title={"Content"} removeMargin>
              <ContentItem content={pageData.our_infusions.content} />
            </AttributeItem>
            <MultiLangEdit
              isShowLabel
              label="Topic"
              value={pageData.our_infusions.topic}
            />
          </AttributeItem>

          <AttributeItem title={"Our Ingredients"}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setIngredientsHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.our_ingredients.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setIngredientsHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.our_ingredients.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit
              isShowLabel
              label="Header"
              value={pageData.our_ingredients.header}
            />
            <AttributeItem title={"Content"} removeMargin>
              <ContentItem content={pageData.our_ingredients.content} />
            </AttributeItem>
            <MultiLangEdit
              isShowLabel
              label="Topic"
              value={pageData.our_ingredients.topic}
            />
            <AttributeItem title={"Video Content"} removeMargin>
              <ContentItem content={pageData.our_ingredients.video_content} />
            </AttributeItem>
            <AttributeItem
              title={"Herbs Description"}
              removeMargin
              onClick={addNewHerbDescriptionContent}
            >
              {pageData.our_ingredients.herbs_descriptions.map((item, index) => (
                <HeaderWithContentComponent
                  item={item}
                  index={index}
                  onRemove={removeNewHerbDescriptionContent}
                />
              ))}
            </AttributeItem>
            <AttributeItem
              title={"Ingredients Images"}
              removeMargin
              onClick={addNewImageWithText}
            >
              {pageData.our_ingredients.image_with_titles.map((item, index) => (
                <ImageWithTitleComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithText}
                />
              ))}
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"For Every Mood"}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setEveryMoodHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.for_every_mood.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setEveryMoodHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.for_every_mood.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit
              isShowLabel
              label="header"
              value={pageData.for_every_mood.header}
            />
            <AttributeItem title={"Content"} removeMargin>
              <ContentItem content={pageData.for_every_mood.content} />
            </AttributeItem>
            <AttributeItem
              title={"Mood Images"}
              removeMargin
              onClick={addNewImageWithTextToMoodSection}
            >
              {pageData.for_every_mood.mood_image_list.map((item, index) => (
                <ImageWithTitleComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextMoodSection}
                />
              ))}
            </AttributeItem>
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
