import { TextareaAutosize, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MultiLanguage from "../../models/multi_language";
import useStyles from "./multi_lang_style";
import SwipeableViews from "react-swipeable-views";
import Select from "react-select";
import { useStoreState } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import IconBtn from "../icon_btn/icon_btn";
import { Remove } from "@material-ui/icons";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";

import "react-markdown-editor-lite/lib/index.css";

const mdParser = new MarkdownIt();


export default function MultiLangEdit(props: {
  label?: string;
  value: any;
  isShowLabel?: boolean;
  onRemove?: Function;
  index?: number;
  isMarkdown?: boolean;
}) {
  const classes = useStyles();
  const theme = useTheme();
  
  const selectedLanguageIndex = useStoreState(
    (state: StoreModel) => state.currentData.selectedLanguageIndex
  );
  const [selectedTab, setSelectedTab] = useState(selectedLanguageIndex);
  const handleChangeTab = (newValue: number) => {
    setSelectedTab(newValue);
  };

  const [lang, setLang] = useState<MultiLanguage>(props.value);

  const changeText = (text: any, index: number) => {
    const prev: any = lang;
    const key = Object.keys(prev).slice(1)[index];
    prev[key] = text;
    setLang({ ...prev });
    props.value[key] = text;
  };

  const languages: { label: string; value: string }[] = [];
  Object.keys(lang)
    .slice(1)
    .forEach((k) => {
      languages.push({
        label: k,
        value: k,
      });
    });

  useEffect(() => {
    setSelectedTab(selectedLanguageIndex);
  }, [selectedLanguageIndex]);

  function handleEditorChange(data: { html: any; text: any }, index: number) {
    console.log(data)
    const prev: any = lang;
    const key = Object.keys(prev).slice(1)[index];
    prev[key] = data.text;
    setLang({ ...prev });
    props.value[key] = data.text;
  }

  return (
    <div
      style={{
        borderRadius: "10px",
        border: "1px solid #bcbcbc",
        margin: "10px",
      }}
    >
      <div style={{ position: "relative" }}>
        {props.isShowLabel && (
          <div className={classes.title}> {props.label}</div>
        )}
        {props.onRemove && (
          <IconBtn
            icon={Remove}
            onClick={() => {
              if (props.onRemove && props.index != null) {
                props.onRemove(props.index);
              }
            }}
          />
        )}
      </div>
      <div style={{ flexGrow: 1, margin: "10px" }}>
        <div
          style={{
            width: "400px",
          }}
        >
          <Select
            classNamePrefix="select language"
            isSearchable={true}
            name="language"
            options={languages}
            value={languages[selectedTab]}
            onChange={(selected) => {
              const index = languages.findIndex((v) => v === selected);
              handleChangeTab(index);
            }}
            maxMenuHeight={100}
          />
        </div>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={selectedTab}
        >
          {Object.values(lang)
            .slice(1)
            ?.map((lang, index) => (
              <div
                key={index}
                className={classes.listPadding}
                hidden={selectedTab !== index}
              >
                {props.isMarkdown ? (
                  <MdEditor
                  canView={{
                    fullScreen:false,
                    both:true,
                    html:true,
                    hideMenu:true,
                    md:true,
                    menu:true
                  }}
                    value={lang}
                    renderHTML={(text) => mdParser.render(text)}
                    onChange={(val) => {
                      handleEditorChange(val, index);
                    }}
                  />
                ) : (
                  <TextareaAutosize
                    className={classes.textArea}
                    value={lang ? lang : ""}
                    onChange={(event) => {
                      changeText(event.target.value, index);
                    }}
                  />
                )}
              </div>
            ))}
        </SwipeableViews>
      </div>
    </div>
  );
}
