import React from "react";
import { Drawer, Divider, List } from "@material-ui/core";
import useStyles from "./my_drawer_style";
import MainItemList from "../items_list/listItems";
import MyNavigator from "../../services/navigation";

interface Props {
  currentRoute: string;
}

export default function MyDrawer(props: Props) {
  const {pushNamed} = MyNavigator()
  const classes = useStyles();
  const goToHome=()=>{
    pushNamed('/home/home')
  }
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper:classes.drawerPaper,
      }}
    >
      <div onClick={goToHome} className={classes.toolbarIcon}>
        <div className={classes.ibiza} >Dilmah</div>
      </div>
      <Divider />
      <List style={{overflowY:'auto', overflowX:'hidden'}}>
        <MainItemList currentRoute={props.currentRoute}/>
      </List>
    </Drawer>
  );
}
