import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
  } from "@material-ui/core";

  export default function SimpleRemoveDialog(props:{
      onSuccess: Function;
      onFailure: Function;
      title: string;
      description: string;
      isOpen: boolean;
  }) {  
    return (
      <div>
        <Dialog
          open={props.isOpen}
          onClose={()=> {
              props.onFailure()
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {props.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> {
                props.onFailure()
            }}>Disagree</Button>
            <Button onClick={()=> {
                props.onSuccess()
            }} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }