import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import { graphQuery } from "../../services/api_service/api_service";
import clonedeep from "lodash.clonedeep";
import SideMessageService from "../../services/side_message_service";

import { dilmahPodcastCategoriesGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import DilmahPodcastCategoryModal, {
  emptyDilmahPodcastCategory,
} from "../../models/dilmah_podcast_category";
import DilmahPodcastCategoryItem from "../../components/dilmah_podcast/dilmah_podcast_category";
import { updateDilmahPodcastItemArray } from "../../services/api_service/components_update";
export default function DilmahPodcastCategoriesPage() {
  const [dilmahPodcastCategories, setDilmahPodcastCategories] = useState<
    DilmahPodcastCategoryModal[]
  >([]);

  const { showSideMessage } = SideMessageService();

  const getPageData = () => {
    graphQuery(dilmahPodcastCategoriesGraph).then((data) => {
      if (data?.success) {
        const t: DilmahPodcastCategoryModal[] = [];
        data.data["dilmahPodcastCategories"].forEach((x: any) =>
          t.push(new DilmahPodcastCategoryModal(x))
        );
        setDilmahPodcastCategories(t);
      }
    });
  };

  useEffect(() => {
    getPageData();
  }, []);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageDataToSave: DilmahPodcastCategoryModal[]) => {
    console.log(pageDataToSave);
    setLoading(true);
    updateDilmahPodcastItemArray(pageDataToSave);
    getPageData();
    showSideMessage(true);
    setLoading(false);
  };

  const addNewPodcastCategory = () => {
    const prev = dilmahPodcastCategories;
    if (prev) {
      prev.push(new DilmahPodcastCategoryModal(emptyDilmahPodcastCategory));
      setDilmahPodcastCategories([...prev]);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.dilmahPodcastCategories}
      parentRoutes={[routeNameObjects.allAboutTea]}
    >
      <div>
        <AddItemBtn
          title={"Save"}
          onClick={() => onSave(clonedeep(dilmahPodcastCategories))}
        />

        <AttributeItem
          title={"Dilmah Podcast Categories"}
          onClick={addNewPodcastCategory}
        >
          {dilmahPodcastCategories.map((c, index) => (
            <DilmahPodcastCategoryItem key={index} category={c} />
          ))}
        </AttributeItem>
        {/* <AttributeItem title={"SEO Component"}>
                    <SEOComponent seoItem={dilmahPodcastCategories.seo} />
                </AttributeItem> */}
      </div>
    </CommonPage>
  );
}
