import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React from "react";
import ContentWithImagesModel from "../../models/content_with_images";
import AttributeItem from "../attribute_item/attribute_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import ImageList from "../image_list/image_list";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import SEOComponent from "../seo_component/seo_component";

export default function ContentWithImagesItem(props: {
  onRemove: Function;
  index: number;
  content: ContentWithImagesModel;
}) {
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.content.cover_image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.content.cover_image_url = url;
          }}
        />
      </div>

      <MultiLangEdit isShowLabel label="Title" value={props.content.title} />
      <MultiLangEdit isShowLabel label="Short Description" value={props.content.short_description} />
      <MultiLangEdit isShowLabel label="Long Description" value={props.content.long_description} />
      <ImageList images={props.content.images} />

      <AttributeItem title={"SEO"}>
          <SEOComponent seoItem={props.content.seo} />
      </AttributeItem>
      
    </Card>
  );
}
