import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class MapLocationModel {
    id: number
    longitude: number
    latitude: number
    description: MultiLanguage
    country: MultiLanguage
    country_selection: string
    constructor(data: any) {
        this.id = data.id
        this.longitude = data.longitude
        this.latitude = data.latitude
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.country = new MultiLanguage(data.country ? data.country : emptyMultiLang)
        this.country_selection = data.country_selection
    }
}

export const emptyMapLocationModel = {
    description: emptyMultiLang,
    country: emptyMultiLang
}