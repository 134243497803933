import MultiLanguage, { emptyMultiLang } from "../multi_language";
import QuoteContentModel, { emptyQuoteContent } from "../quote_content_model";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class TeaLounge {
  id: number;
  title: MultiLanguage;
  content_1: QuoteContentModel;
  content_2: QuoteContentModel;
  header_image_url: string;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.content_1 = new QuoteContentModel(
      data.content_1 ? data.content_1 : emptyQuoteContent
    );
    this.content_2 = new QuoteContentModel(
      data.content_2 ? data.content_2 : emptyQuoteContent
    );
    this.header_image_url = data.header_image_url;
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyTeaLounge = {
  title: emptyMultiLang,
  content_1: emptyQuoteContent,
  content_2: emptyQuoteContent,
  seo: emptySEOComponent,
};
