import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import { siteMapGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";

//New
import SiteMap, { emptySiteMap } from "../../models/site_map";
import SiteMapSection, {
  emptySiteMapSection,
} from "../../models/site_map_section";
import SiteMapSectionComponent from "../../components/site_map_section/site_map_section";
import {
  updateSiteMapSectionArray,
  updateSeoItem,
} from "../../services/api_service/components_update";
import SEOComponent from "../../components/seo_component/seo_component";

export default function SiteMapPage() {
  const [pageData, setPageData] = useState<SiteMap>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, siteMapGraph, "siteMapPages").then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(new SiteMap(data.data ? data.data.page : emptySiteMap));
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: SiteMap) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;

      dataToSave.site_map_sections = await updateSiteMapSectionArray(
        pageData.site_map_sections
      );
      dataToSave.seo = await updateSeoItem(pageData.seo);

      await updateContentTypeCall(dataToSave, "site-maps").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "site-map-pages"
          ).then((data) => {
            console.log(data);
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewSection = () => {
    const prev = pageData;
    if (prev) {
      prev.site_map_sections.push(new SiteMapSection(emptySiteMapSection));
      setPageData({ ...prev });
    }
  };
  const removeSection = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.site_map_sections.splice(index, 1);
      setPageData(new SiteMap(emptySiteMap));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.siteMap}
      parentRoutes={[routeNameObjects.other]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"Site Map Sections"} onClick={addNewSection}>
            {pageData.site_map_sections.map((content, index) => (
              <SiteMapSectionComponent
                key={index}
                section={content}
                index={index}
                onRemove={removeSection}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
