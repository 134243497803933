import { makeStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(3),
  },
  doubleNested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    backgroundColor: myColors.appBarColor,
    padding: "0px !important",
  },
  listItemText: {
    color: myColors.appBarTextColor,
    
  },
  listItemTextPrimary: {
    fontSize: "13px",
    fontWeight: "bold",
  },
  listItem: {
    transition: "0.5s",
    color: "white !important",
    paddingLeft: "0px !important",
    height: "50px",
    "&:hover": {
      backgroundColor: `${myColors.appBarColor} !important`,
      color: `${myColors.drawerSideBarColor} !important`,
    },
  },

  listItemTextHover: {
    color: `${myColors.drawerSideBarColor} !important`,
  },
  sideLine: {
    width: "5px",
    height: "50px",
  },
  sideLineActive: {
    backgroundColor: myColors.drawerSideBarColor,
  },
  listItemOuter: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  notchOuter: {
    width: "23px",
    height: "50px",
    position: "absolute",
    right: "-11.5px"
  },
 
  elementToClip: {
    height: "50px",
    width:'25px',
    backgroundColor: myColors.appBarColor,
    clipPath: "url(#myCurve)",
  },
}));

export default useStyles;
