import { Actions, useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import Select from "react-select";
import MultiLanguage, { emptyMultiLang } from "../../models/multi_language";
import { StoreModel } from "../../store models/model";
import { countries } from "./countries";
import useStyles from "./country_select_style";

export default function CountrySelect(props: {
  addBtn: any;
  onCountryChange: Function;
  selectedCountry: { label: string; value: string };
}) {
  const classes = useStyles();
  const selectedLanguageIndex = useStoreState(
    (state: StoreModel) => state.currentData.selectedLanguageIndex
  );
  const setSelectedLanguageIndex = useStoreActions(
    (actions: Actions<StoreModel>) =>
      actions.currentData.setSelectedLanguageIndex
  );
  return (
    <div className={classes.outerRow}>
      <div className={classes.textField}>
        <div className={classes.label}>Country</div>
        <Select
          classNamePrefix="select country"
          isSearchable={true}
          name="country"
          options={countries}
          value={props.selectedCountry}
          onChange={(selected) => {
            props.onCountryChange(selected);
          }}
        />
      </div>

      <div className={classes.textField}>
        <div className={classes.label}>Language</div>
        <Select
          classNamePrefix="select language"
          isSearchable={true}
          name="language"
          options={Object.keys(new MultiLanguage(emptyMultiLang))
            .slice(1)
            .map((item, index) => {
              return { label: item, value: index };
            })}
          value={Object.keys(new MultiLanguage(emptyMultiLang))
            .slice(1)
            .map((item, index) => {
              return { label: item, value: index };
            })[selectedLanguageIndex]}
          onChange={(selected) => {
            if(selected){
              setSelectedLanguageIndex(selected.value)
            }
            
          }}
        />
      </div>
      {props.addBtn}
    </div>
  );
}
