import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class ContactUsComponentModel {
    id:number
    contact_no_1:string
    contact_no_2:string
    longitude:number
    latitude:number
    address:MultiLanguage
    email:string
    constructor(data:any){
        this.id=data.id
        this.contact_no_1 = data.contact_no_1
        this.contact_no_2 = data.contact_no_2
        this.longitude = data.longitude
        this.latitude = data.latitude
        this.address = new MultiLanguage(data.address ? data.address : emptyMultiLang)
        this.email=data.email
    }
}

export const emptyContactUsComponent = {
    address:emptyMultiLang
}