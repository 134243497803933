import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class CertificateItem {
    id: number
    description: MultiLanguage
    image_url: string
    file_url: string
    sub_heading: MultiLanguage
    constructor(data: any) {
        this.id = data.id
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.image_url = data.image_url
        this.file_url = data.file_url
        this.sub_heading = new MultiLanguage(data.sub_heading ? data.sub_heading : emptyMultiLang)
    }
}

export const emptyCertificate = {
    description: emptyMultiLang,
    image_url: '',
    file_url: '',
    sub_heading: emptyMultiLang
}
