import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import EBookElement, { emptyEBookElement } from "../../models/e_book_element";
import EBookSection, { emptyEBookSection } from "../../models/e_book_section";
import AttributeItem from "../attribute_item/attribute_item";
import EBookItemComponent from "../e_book_item/e_book_item";
import IconBtn from "../icon_btn/icon_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function EBookSectionComponent(props: {
  section: EBookSection;
  index: number;
  onRemove: Function;
}) {
  const [section, setSection] = useState(props.section);

  const addNewBook = () => {
    const prev = section;
    if (prev) {
      prev.e_book_elements.push(new EBookElement(emptyEBookElement));
      setSection({ ...prev });
    }
  };
  const removeBook = (index: number) => {
    const prev = section;
    if (prev) {
      prev.e_book_elements.splice(index, 1);
      setSection(new EBookSection(emptyEBookSection));
      setTimeout(() => {
        setSection({ ...prev });
      }, 0.01);
    }
  };

  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <MultiLangEdit label={"Title"} isShowLabel value={section.title} />

      <AttributeItem removeMargin title={"E Book Items"} onClick={addNewBook}>
        {section.e_book_elements.map((book, index) => (
          <EBookItemComponent
            key={index}
            index={index}
            content={book}
            onRemove={removeBook}
          />
        ))}
      </AttributeItem>
    </Card>
  );
}
