import CeylonIcedTeaGlassBottleModel, { emptyCeylonIcedTeaGlassBottleModel } from "../../models/pages/ceylon_iced_tea_glass_bottle"
import CeylonIcedTeaTetraPackModel, { emptyCeylonIcedTeaTetraPackModel } from '../../models/pages/ceylon_iced_tea_tetra_pack'
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, {emptySEOComponent} from "./seo_component";
import ContentModel, { emptyContent } from "../content_model";


export default class CeylonIcedTeaModel {
    id: number;
    banner_image_url: string;
    header_image_url: string;
    intro_image_url: string;
    content_1: ContentModel;
    glass_bottle: CeylonIcedTeaGlassBottleModel;
    tetra_pack: CeylonIcedTeaTetraPackModel;
    title: MultiLanguage;
    seo: SEOComponent;

    constructor(data: any) {
        this.id = data.id;
        this.glass_bottle = new CeylonIcedTeaGlassBottleModel(data.glass_bottle ? data.glass_bottle : emptyCeylonIcedTeaGlassBottleModel)
        this.tetra_pack = new CeylonIcedTeaTetraPackModel(data.tetra_pack ? data.tetra_pack : emptyCeylonIcedTeaTetraPackModel)
        this.banner_image_url = data.banner_image_url ? data.banner_image_url : null
        this.header_image_url = data.header_image_url ? data.header_image_url : null
        this.intro_image_url = data.intro_image_url ? data.intro_image_url : null
        this.content_1 = new ContentModel(data.content_1 ? data.content_1 : emptyContent);
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyCeylonIcedTeaModel = {
    title: emptyMultiLang,
    header: emptyMultiLang,
    glass_bottle: emptyCeylonIcedTeaGlassBottleModel,
    tetra_pack: emptyCeylonIcedTeaTetraPackModel,
    content_1: emptyContent,
    seo: emptySEOComponent,
};