import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../store models/model";

export default function SideMessageService() {
  const setSideMessageOpen = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.setSideMessageOpen
  );

  const setSideMessageClose = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.setSideMessageClose
  );

  const showSideMessage = (isSuccess: boolean) => {
    setSideMessageOpen({ isSuccess: isSuccess });
    setTimeout(() => {
      setSideMessageClose();
    }, 2000);
  };

  return {
    showSideMessage:showSideMessage
  }
}
