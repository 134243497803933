import MultiLanguage, { emptyMultiLang } from "../multi_language";
import TeaLocationModel from "../tea_location.ts";


export default class WatteTeaRegionsModel {
    id: number;
    header_image_url: string
    sub_header_image_url: string
    header:MultiLanguage
    tea_locations:TeaLocationModel[]=[]
    topic:MultiLanguage

    constructor(data: any) {
        this.id = data.id;
        this.header_image_url = data.header_image_url
        this.sub_header_image_url = data.sub_header_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.topic = new MultiLanguage(data.topic?data.topic:emptyMultiLang)
        data.tea_locations.forEach((x:any)=>this.tea_locations.push(new TeaLocationModel(x)))
    }
}

export const emptyWatteTeaRegionsModel = {
    header:emptyMultiLang,
    topic:emptyMultiLang,
    tea_locations:[]
};