export default class MultiLanguage {
  id: number;
  Global: string;
  English: string;
  Dutch: string;
  Ukrainian: string;
  Moldavian: string;
  Russian: string;
  Lithuanian: string;
  Hungarian: string;
  Mandarin: string;
  Chinese: string;
  Thai: string;
  Swedish: string;
  French: string;
  Arabic: string;

  constructor(data: any) {
    this.id = data.id;
    this.Global = data.text_global ? data.text_global : '';
    this.English = data.text_en ? data.text_en : '';
    this.Dutch = data.text_du ? data.text_du : '';
    this.Ukrainian = data.text_uk ? data.text_uk : '';
    this.Moldavian = data.text_mo ? data.text_mo : '';
    this.Russian = data.text_ru ? data.text_ru : '';
    this.Lithuanian = data.text_li ? data.text_li : '';
    this.Hungarian = data.text_hu ? data.text_hu : '';
    this.Mandarin = data.text_ma ? data.text_ma : '';
    this.Chinese = data.text_ch ? data.text_ch : '';
    this.Thai = data.text_th ? data.text_th : '';
    this.Swedish = data.text_sw ? data.text_sw : '';
    this.French = data.text_fr ? data.text_fr : '';
    this.Arabic = data.text_ar ? data.text_ar : '';
  }

  getAvailableText(){
    var foundValue = ''
    Object.values(this).slice(1).forEach((val)=>{
      if(val){
        foundValue = val
      }
    })
    if(foundValue){
      return foundValue
    } else {
      return this.Global
    }
    
  }
}

export const emptyMultiLang = {
  Global: "",
  English: "",
  Dutch: "",
  Ukrainian: "",
  Moldavian: "",
  Russian: "",
  Lithuanian: "",
  Hungarian: "",
  Mandarin: "",
  Chinese: "",
  Thai: "",
  Swedish: "",
  French: "",
  Arabic: "",
};

export const toMultilanguage = (lang: MultiLanguage) => {
  return {
    id: lang.id,
    text_global: lang.Global,
    text_en: lang.English,
    text_du: lang.Dutch,
    text_uk: lang.Ukrainian,
    text_mo: lang.Moldavian,
    text_ru: lang.Russian,
    text_li: lang.Lithuanian,
    text_hu: lang.Hungarian,
    text_ma: lang.Mandarin,
    text_ch: lang.Chinese,
    text_th: lang.Thai,
    text_sw: lang.Swedish,
    text_fr: lang.French,
    text_ar: lang.Arabic,
  };
};
