import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import useStyles from "./login_page_styles";
import { loginCall } from "../../services/api_service/api_service";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import MyNavigator from "../../services/navigation";
import { Card } from "@material-ui/core";

export default function LoginPage() {
  const classes = useStyles();

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const [error, setErrors] = useState({ email: "", password: "" });

  const showMessage = useStoreActions(
    (actions: Actions<StoreModel>) => actions.message.showMessage
  );
  const setToken = useStoreActions(
    (actions: Actions<StoreModel>) => actions.tokenModel.setToken
  );

  const saveOnLocalStorage = (email: string, password: string) => {
    localStorage.setItem("DILMAH_ADMIN_PANEL_EMAIL", email);
    localStorage.setItem("DILMAH_ADMIN_PANEL_PASSWORD", password);
  };

  const getCredsFromStorage = () => {
    return {
      email: localStorage.getItem("DILMAH_ADMIN_PANEL_EMAIL"),
      password: localStorage.getItem("DILMAH_ADMIN_PANEL_PASSWORD"),
    };
  };

  const loginSilent = () => {
    const creds = getCredsFromStorage();
    if (creds.email && creds.password) {
      loginAction(creds.email,creds.password)
    }
  };
  const { replaceNamed } = MyNavigator();

  const loginAction = (email: string, password: string) => {
    loginCall(email, password).then((data) => {
      if (data?.success) {
        saveOnLocalStorage(email, password);
        setToken(data.data.jwt);
        replaceNamed("/home/home");
      } else {
        showMessage({
          head: "Login Failed",
          body: data?.data?.data[0].messages[0].message
            ? data?.data?.data[0].messages[0].message
            : "Something went wrong. Please try again!",
        });
        localStorage.clear()
      }
    });
  };

  const onLogin = () => {
    if (!loginDetails.email) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter email or username",
      }));
      return;
    }
    setErrors((prev) => ({ ...prev, email: "" }));
    if (!loginDetails.password) {
      setErrors((prev) => ({ ...prev, password: "Please enter password" }));
      return;
    }
    setErrors((prev) => ({ ...prev, password: "" }));

    loginAction(loginDetails.email, loginDetails.password);
  };

  useEffect(() => {
    loginSilent();
  });
  return (
    <div className={classes.outer}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Card elevation={10} className={classes.paper}>
          <img height="100px" src="images/logo.png" alt="logo" />

          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Username or Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={loginDetails.email}
              onChange={(event) => {
                setErrors((prev) => ({ ...prev, email: "" }));
                const prev = loginDetails;
                prev.email = event.target.value;
                setLoginDetails({ ...prev });
              }}
              error={error.email !== ""}
              helperText={error.email}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onLogin();
                }
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              value={loginDetails.password}
              onChange={(event) => {
                setErrors((prev) => ({ ...prev, password: "" }));
                const prev = loginDetails;
                prev.password = event.target.value;
                setLoginDetails({ ...prev });
              }}
              error={error.password !== ""}
              helperText={error.password}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onLogin();
                }
              }}
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onLogin}
            >
              Sign In
            </Button>
          </form>
        </Card>
      </Container>
    </div>
  );
}
