import { Card, TextField } from "@material-ui/core";
import { Add, NavigateBefore, NavigateNext } from "@material-ui/icons";
import React, {useState} from "react";
import IconBtn from "../icon_btn/icon_btn";
import IconBtnPagination from "../icon_btn/icon_btn_pagination";


export default function AttributeItem(props: { children: any; title: string,  onClick?:Function, onNext? :Function,
   onPrevious?: Function, removeMargin?:boolean, multiPage?:boolean, currentPage? : number, onPageChange? : Function, totalPages?:number }) {
    const [currentPage, setCurrentPage] = useState(props.currentPage)
 
    return (
    <Card
      elevation={3}
      style={{
        margin: props.removeMargin ? "1%" : "5% 5% 5% 5%",
        padding: "3px",
        width:props.removeMargin ? "98%" : "90%",
      }}
    >
      <div
        style={{
          fontSize: "18px",
          color: "grey",
          fontWeight: "bold",
          marginLeft: "10px",
          marginRight: "10px",
          marginBottom: "10px",
          position: "relative",
          marginTop:'5px'
        }}
      >
        {props.title}
        { props.onPrevious && <IconBtnPagination icon={NavigateBefore} onClick={props.onPrevious} /> }
        { props.currentPage && <div
          style={{
            fontSize: "15px",
            color: "grey",
            fontWeight: "bold",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "10px", 
            display: "inline",
            position: "relative",
            marginTop:'5px'
          }}
        >
          <TextField
            style={{
              width: "70px",
              marginBottom: "20px",
            }}
            type="number"
            label="Page"
            variant="outlined"
            value={currentPage}
            onChange={(event) => {
              if(parseInt(event.target.value) !== 0){
                setCurrentPage(parseInt(event.target.value))
                if (props.onPageChange && event.target.value.length >0
                ) props.onPageChange(parseInt(event.target.value))
              } 
            }}
          />
          </div>}
        
        { props.onNext && <IconBtnPagination icon={NavigateNext} onClick={props.onNext} /> }
        { props.totalPages && 
          <div
            style={{
            fontSize: "15px",
            color: "grey",
            fontWeight: "bold",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "10px", 
            display: "inline",
            position: "relative",
            marginTop:'5px'
          }}>
            Total Pages: {props.totalPages}
          </div>}
        { props.onClick && <IconBtn icon={Add} onClick={props.onClick} /> }
      </div>
      <div style={{ padding: "10px" }}>{props.children}</div>
    </Card>
  );
}
