import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import ContentItem from "../../components/content_item/content_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import DilmahPodcast, {
  emptyDilmahPodcast,
} from "../../models/pages/dilmah_podast";
import PodcastSection, {
  emptyPodcastSection,
} from "../../models/podcast_section";
import PodcastSectionComponent from "../../components/podcast_section/podcast_section";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateContentItem,
  updateMultiLangItem,
  updatePodcastSectionArray,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { dilmahPodcastGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";

export default function DilmahPodcastPage() {
  const [pageData, setPageData] = useState<DilmahPodcast>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, dilmahPodcastGraph, "dilmahPodcastPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new DilmahPodcast(data.data ? data.data.page : emptyDilmahPodcast)
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: DilmahPodcast) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.podcast_sections = await updatePodcastSectionArray(
        pageData.podcast_sections
      );
      dataToSave.title = await updateMultiLangItem(pageData.title);
      dataToSave.seo = await updateSeoItem(pageData.seo)
      dataToSave.feature_podcast = await updateContentItem(
        pageData.feature_podcast
      );

      await updateContentTypeCall(dataToSave, "dilmah-podcasts").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "dilmah-podcast-pages"
            ).then((data) => {
              console.log(data)
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const addNewSection = () => {
    const prev = pageData;
    if (prev) {
      prev.podcast_sections.push(new PodcastSection(emptyPodcastSection));
      setPageData({ ...prev });
    }
  };
  const removeSection = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.podcast_sections.splice(index, 1);
      setPageData(new DilmahPodcast(emptyDilmahPodcast));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.dilmahPodcast}
      parentRoutes={[routeNameObjects.allAboutTea]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Title"}>
            <MultiLangEdit value={pageData.title} />
          </AttributeItem>
          <AttributeItem title={"Feature Podcast"}>
            <ContentItem content={pageData.feature_podcast} />
          </AttributeItem>
          <AttributeItem title={"Podcast Section"} onClick={addNewSection}>
            {pageData.podcast_sections.map((content, index) => (
              <PodcastSectionComponent
                key={index}
                section={content}
                index={index}
                onRemove={removeSection}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
