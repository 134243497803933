import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import Slider from "../slider";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class DilmahInHospitalityModel {
  id: number;
  sliders: Slider[] = [];
  title: MultiLanguage;
  invitation: ContentModel;
  mid_title: ContentModel;
  values: ContentModel[] = [];
  mid_quote: ContentModel;
  hospitality: ContentModel[] = [];
  end_quote_with_link: ContentModel;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.invitation = new ContentModel(
      data.invitation ? data.invitation : emptyContent
    );
    this.mid_title = new ContentModel(
      data.mid_title ? data.mid_title : emptyContent
    );
    data.values.forEach((x: any) => this.values.push(new ContentModel(x)));
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    this.mid_quote = new ContentModel(
      data.mid_quote ? data.mid_quote : emptyContent
    );
    data.hospitality.forEach((x: any) =>
      this.hospitality.push(new ContentModel(x))
    );
    this.end_quote_with_link = new ContentModel(
      data.end_quote_with_link ? data.end_quote_with_link : emptyContent
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyDilmahInHospitality = {
  sliders: [],
  title: emptyMultiLang,
  invitation: emptyContent,
  mid_title: emptyContent,
  mid_quote: emptyContent,
  end_quote_with_link: emptyContent,
  values: [],
  hospitality: [],
  seo: emptySEOComponent,
};
