import IngredientItemModel from "../ingredient_item";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class IngredientPageModel {
  id: number;
  ingredient_items: IngredientItemModel[]=[]
  seo: SEOComponent

  constructor(data: any) {
    this.id = data.id;
    data.ingredients_items?.forEach((x: any) => this.ingredient_items.push(new IngredientItemModel(x)));
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)

  }
}

export const emptyIngredientPage = {
    ingredient_items:[],
    seo: emptySEOComponent
};
