
import { socialWallIconTypes } from "../components/social_wall_item/social_wall_item"
import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class SocialWallItemModel{
    id:number
    description:MultiLanguage
    image_url:string
    icon_type:string
    link:string

    constructor(data:any){
        this.id=data.id
        this.description=new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.image_url = data.image_url
        this.icon_type = data.icon_type
        this.link = data.link
    }
}

export const emptySocialWallItem = {
    description: emptyMultiLang,
    icon_type:socialWallIconTypes[0]
}