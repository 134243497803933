import EBookElement from "./e_book_element";
import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class EBookSection {
  id: number;
  title: MultiLanguage;
  e_book_elements: EBookElement[] = [];
  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title);
    data.e_book_elements.forEach((x: any) =>
      this.e_book_elements.push(new EBookElement(x))
    );
  }
}

export const emptyEBookSection = {
  title: emptyMultiLang,
  e_book_elements: [],
};
