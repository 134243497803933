import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  row:{
    display:'flex',
    flexDirection:'row',
    marginLeft:'10px',
    alignItems:'center'
  }
});

export default useStyles;
