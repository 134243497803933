import HeaderWithContent, { emptyHeaderWithContent } from "../header_with_content";
import MapLocationModel from "../map_location_component";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class WhereToBuy {
  id: number;
  title: MultiLanguage;
  header_image_url: string;
  header_descrpiton: HeaderWithContent;
  map_location: MapLocationModel[] = []
  seo: SEOComponent

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.header_image_url = data.header_image_url;
    this.header_descrpiton = new HeaderWithContent(data.header_descrpiton ? data.header_descrpiton : emptyHeaderWithContent);
    data.map_location.forEach((x:any) => this.map_location.push(new MapLocationModel(x)))
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)

  }
}

export const emptyWhereToBuy = {
  title: emptyMultiLang,
  header_descrpiton: emptyHeaderWithContent,
  map_location:[],
  seo: emptySEOComponent

};
