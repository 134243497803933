import MultiLanguage, { emptyMultiLang } from "../multi_language";
import TeaStateItemModel from "../tea_state_item_model";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class TeaGardens {
    id:number
    header:MultiLanguage
    description:MultiLanguage
    tea_state_items:TeaStateItemModel[]=[]
    seo: SEOComponent
    constructor(data:any){
        this.id=data.id
        this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        data.tea_state_items.forEach((x:any)=>this.tea_state_items.push(new TeaStateItemModel(x)))
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyTeaGarden = {
    tea_state_items:[],
    seo: emptySEOComponent
}