import { IconButton } from "@material-ui/core";
import React from "react";
import useStyles from "./icon_btn_style";

export default function IconBtn(props: {
  onClick: Function;
  icon: any;
  isCenter?: boolean;
}) {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.addIcon}
      onClick={() => (props.onClick ? props.onClick() : null)}
      style={props.isCenter ? {} : { top: "4px" }}
    >
      <props.icon />
    </IconButton>
  );
}
