import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class ElixirItem {
    id: number
    image_url: string
    title: MultiLanguage
    description: MultiLanguage
    constructor(data: any) {
        this.id = data.id
        this.image_url = data.image_url
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
    }
}

export const emptyElixirItem = {
    title: emptyMultiLang,
    description: emptyMultiLang
}