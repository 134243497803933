import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import Slider from "../slider";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class MixologistAndTeaMakerModel {
  id: number;
  sliders: Slider[] = [];
  title: MultiLanguage;
  content_1: ContentModel;
  content_2: ContentModel;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.content_1 = new ContentModel(
      data.content_1 ? data.content_1 : emptyContent
    );
    this.content_2 = new ContentModel(
      data.content_2 ? data.content_2 : emptyContent
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyMixologistAndTeaMaker = {
  sliders: [],
  title: emptyMultiLang,
  content_1: emptyContent,
  content_2: emptyContent,
  seo: emptySEOComponent,
};
