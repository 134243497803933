import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    alignItems: 'center'
  },
  title: {
    marginLeft: '10px',
    fontWeight: 'bold',
    color: '#716666',
    textAlign: 'center',
  }
});

export default useStyles;
