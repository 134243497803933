import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateMultiLangItem,
  updateContentItem,
  updateDilmahTradePremiumRetailers,
  updateDilmahTradeFineFoodAndGourmet,
  updateDilmahHighQualityCasualDining,
  updateDilmahTradeFineDining,
  updateDilmahTradeOurProducts,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import ImageWithLinkComponent from "../../components/image_with_link/image_with_link";
import ImageWithLink, {
  emptyImageWithLink,
} from "../../models/image_with_link";

import DilmahTrade, { emptyDilmahTrade } from "../../models/pages/dilmah_trade";
import { dilmahTradeGraph } from "../../services/api_service/graphql_queries";
import ContentItem from "../../components/content_item/content_item";
import SEOComponent from "../../components/seo_component/seo_component";

export default function DilmahTradePage() {
  const [pageData, setPageData] = useState<DilmahTrade>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, dilmahTradeGraph, "dilmahTradePages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new DilmahTrade(data.data ? data.data.page : emptyDilmahTrade)
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: DilmahTrade) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.title = await updateMultiLangItem(pageData.title);
      dataToSave.header_content = await updateContentItem(
        pageData.header_content
      );
      dataToSave.premium_retailers = await updateDilmahTradePremiumRetailers(
        pageData.premium_retailers
      );
      dataToSave.fine_food_and_gourmet =
        await updateDilmahTradeFineFoodAndGourmet(
          pageData.fine_food_and_gourmet
        );
      dataToSave.high_quality_casual_dining =
        await updateDilmahHighQualityCasualDining(
          pageData.high_quality_casual_dining
        );
      dataToSave.fine_dining = await updateDilmahTradeFineDining(
        pageData.fine_dining
      );
      dataToSave.our_product = await updateDilmahTradeOurProducts(
        pageData.our_product
      );
      dataToSave.seo = await updateSeoItem(pageData.seo);

      await updateContentTypeCall(dataToSave, "dilmah-trades").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "dilmah-trade-pages"
          ).then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const setBannerImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.banner_image_url = url;
      setPageData({ ...prev });
    }
  };

  const addNewImageWithTextPremiumRetailers = () => {
    const prev = pageData;
    if (prev) {
      prev.premium_retailers.items.push(new ImageWithLink(emptyImageWithLink));
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextPremiumRetailers = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.premium_retailers.items.splice(index, 1);
      setPageData(new DilmahTrade(emptyDilmahTrade));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextForFineFoodAndGourmet = () => {
    const prev = pageData;
    if (prev) {
      prev.fine_food_and_gourmet.items.push(
        new ImageWithLink(emptyImageWithLink)
      );
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextForFineFoodAndGourmet = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.fine_food_and_gourmet.items.splice(index, 1);
      setPageData(new DilmahTrade(emptyDilmahTrade));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextForHighQualityDining = () => {
    const prev = pageData;
    if (prev) {
      prev.high_quality_casual_dining.items.push(
        new ImageWithLink(emptyImageWithLink)
      );
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextForHighQualityDining = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.high_quality_casual_dining.items.splice(index, 1);
      setPageData(new DilmahTrade(emptyDilmahTrade));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextForFineDining = () => {
    const prev = pageData;
    if (prev) {
      prev.fine_dining.items.push(new ImageWithLink(emptyImageWithLink));
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextForFineDining = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.fine_dining.items.splice(index, 1);
      setPageData(new DilmahTrade(emptyDilmahTrade));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextForOurProduct = () => {
    const prev = pageData;
    if (prev) {
      prev.our_product.our_products_items.push(
        new ImageWithLink(emptyImageWithLink)
      );
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextForOurProduct = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.our_product.our_products_items.splice(index, 1);
      setPageData(new DilmahTrade(emptyDilmahTrade));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const setOurProductBannerImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_product.banner_image_url = url;
      setPageData({ ...prev });
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.dilmahTrade}
      parentRoutes={[routeNameObjects.other]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Banner Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setBannerImage}
                mediaType="IMAGE"
                mediaUrl={pageData.banner_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>

          <AttributeItem title={"Title"}>
            <MultiLangEdit value={pageData.title} />
          </AttributeItem>

          <AttributeItem title={"Header Content"}>
            <ContentItem content={pageData.header_content} />
          </AttributeItem>

          <AttributeItem title={"Our Products"}>
            <AttributeItem title={"Banner Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setOurProductBannerImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.our_product.banner_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>

            <AttributeItem
              title="Our Product Items"
              onClick={addNewImageWithTextForOurProduct}
            >
              {pageData.our_product.our_products_items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextForOurProduct}
                />
              ))}
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"Premium Retailers"}>
            <AttributeItem title={"Header Description"}>
              <MultiLangEdit
                value={pageData.premium_retailers.header_description}
              />
            </AttributeItem>

            <AttributeItem
              title={"Premium Retailer Items"}
              onClick={addNewImageWithTextPremiumRetailers}
            >
              {pageData.premium_retailers.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextPremiumRetailers}
                />
              ))}
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"Fine Food & Gourmet"}>
            <AttributeItem title={"Header Description"}>
              <MultiLangEdit
                value={pageData.fine_food_and_gourmet.header_description}
              />
            </AttributeItem>

            <AttributeItem
              title="Fine Food & Gourmet Items"
              onClick={addNewImageWithTextForFineFoodAndGourmet}
            >
              {pageData.fine_food_and_gourmet.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextForFineFoodAndGourmet}
                />
              ))}
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"High Quality Casual Dining"}>
            <AttributeItem title={"Title"}>
              <MultiLangEdit
                value={pageData.high_quality_casual_dining.header_description}
              />
            </AttributeItem>
            <AttributeItem
              title={"High Quality Casual Dining Items"}
              onClick={addNewImageWithTextForHighQualityDining}
            >
              {pageData.high_quality_casual_dining.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextForHighQualityDining}
                />
              ))}
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"Fine Dining"}>
            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.fine_dining.header_description} />
            </AttributeItem>
            <AttributeItem
              title={"Fine Dining Items"}
              onClick={addNewImageWithTextForFineDining}
            >
              {pageData.fine_dining.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextForFineDining}
                />
              ))}
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
