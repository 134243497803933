import ContentModel, { emptyContent } from "../content_model";
import ImageWithTitle from "../image_with_title";
import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class ElixirOfCeylonTeaBrewingGuidelineModal {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header: MultiLanguage
    topic: MultiLanguage
    content_1: ContentModel
    content_2: ContentModel
    videos: ContentModel[] = []
    images:ImageWithTitle[] = []
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.topic = new MultiLanguage(data.topic ? data.topic : emptyMultiLang)
        this.content_1 = new ContentModel(data.content_1 ? data.content_1 : emptyContent)
        this.content_2 = new ContentModel(data.content_2 ? data.content_2 : emptyContent)
        data.videos.forEach((x: any) => this.videos.push(new ContentModel(x)))
        data.images.forEach((x: any) => this.images.push(new ImageWithTitle(x)))
    }
}

export const emptyElixirOfCeylonTeaBrewingGuideline = {
    header: emptyMultiLang,
    topic: emptyMultiLang,
    content_1: emptyContent,
    content_2: emptyContent,
    videos: [],
    images:[]
}