import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from "../../services/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    line: {
      width: "100%",
      height: "1.5px",
      backgroundColor: "#ababab",
      marginBottom:'10px'
    },
    namefield: {
      flexGrow: 1,
      fontSize: "19px",
      color: myColors.drawerColor,
      fontWeight: 550,
      textTransform: "capitalize",
      float:'left',
      cursor:'pointer'
    },
    center: {
      flexGrow: 1,
      fontSize: "19px",
      color: myColors.drawerColor,
      fontWeight: 550,
      textAlign:'left',
      textTransform: "capitalize",
      cursor:'pointer',
      marginBottom:'10px',
      
    }
  })
);

export default useStyles;
