import CareerUserItem from "../career_user_item";
import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import Slider from "../slider";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class CareersModel {
  id: number;
  sliders: Slider[] = [];
  title_content: ContentModel;
  intern_content: ContentModel;
  vacancy_btn_text: MultiLanguage;
  submit_cv_btn_text: MultiLanguage;
  submit_cv_btn_intern_text: MultiLanguage;
  career_user_items: CareerUserItem[] = [];
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    this.title_content = new ContentModel(
      data.title_content ? data.title_content : emptyContent
    );
    this.intern_content = new ContentModel(
      data.intern_content ? data.intern_content : emptyContent
    );
    this.vacancy_btn_text = new MultiLanguage(
      data.vacancy_btn_text ? data.vacancy_btn_text : emptyMultiLang
    );
    this.submit_cv_btn_text = new MultiLanguage(
      data.submit_cv_btn_text ? data.submit_cv_btn_text : emptyMultiLang
    );
    this.submit_cv_btn_intern_text = new MultiLanguage(
      data.submit_cv_btn_intern_text
        ? data.submit_cv_btn_intern_text
        : emptyMultiLang
    );
    data.career_user_items.forEach((x: any) =>
      this.career_user_items.push(new CareerUserItem(x))
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyCareers = {
  sliders: [],
  title_content: emptyContent,
  intern_content: emptyContent,
  vacancy_btn_text: emptyMultiLang,
  submit_cv_btn_text: emptyMultiLang,
  submit_cv_btn_intern_text: emptyMultiLang,
  career_user_items: [],
  seo: emptySEOComponent,
};
