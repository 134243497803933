import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class CookieDisclaimerModel {
  id: number;
  title: MultiLanguage;
  description: MultiLanguage;
  link: string;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.description = new MultiLanguage(
      data.description ? data.description : emptyMultiLang
    );
    this.link = data.link;
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyCookieDisclaimer = {
  title: emptyMultiLang,
  description: emptyMultiLang,
  seo: emptySEOComponent,
};
