import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class Slider {
  id:number
  slider_has_video: boolean;
  video_url: string;
  header: MultiLanguage;
  image_url: string;
  imageFile: any;
  slider_has_link: boolean;
  link: string;

  constructor(data: any) {
    this.id=data.id
    this.slider_has_video = data.slider_has_video;
    this.video_url = data.video_url;
    this.header = new MultiLanguage(data.header);
    this.image_url = data.image_url;
    this.slider_has_link = data.slider_has_link;
    this.link = data.link
  }
}

export const emptySlider = {
  slider_has_video: false,
  video_url: "",
  header: emptyMultiLang,
  image_url: "",
  slider_has_link: false,
  link: ""
};
