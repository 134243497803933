import HeaderWithContent, {
  emptyHeaderWithContent,
} from "../header_with_content";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class PrivacyAndCookiePolicy {
  id: number;
  title: MultiLanguage;
  note: MultiLanguage;
  header_image_url: string;
  policy_content1: HeaderWithContent[] = [];
  policy_content2: HeaderWithContent[] = [];
  what_we_collect_content: MultiLanguage;
  mobile_app_policy_content: MultiLanguage;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.note = new MultiLanguage(data.note ? data.note : emptyMultiLang);
    this.header_image_url = data.header_image_url;
    data.policy_content1.forEach((x: any) =>
      this.policy_content1.push(new HeaderWithContent(x))
    );
    data.policy_content2.forEach((x: any) =>
      this.policy_content2.push(new HeaderWithContent(x))
    );
    this.what_we_collect_content = new MultiLanguage(
      data.what_we_collect_content
        ? data.what_we_collect_content
        : emptyMultiLang
    );
    this.mobile_app_policy_content = new MultiLanguage(
      data.mobile_app_policy_content
        ? data.mobile_app_policy_content
        : emptyMultiLang
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyPrivacyAndCookiePolicy = {
  title: emptyMultiLang,
  note: emptyMultiLang,
  header_description: emptyHeaderWithContent,
  what_we_collect_content: emptyMultiLang,
  mobile_app_policy_content: emptyMultiLang,
  policy_content1: [],
  policy_content2: [],
  seo: emptySEOComponent,
};
