import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import PodcastSection from "../podcast_section";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class DilmahPodcast{
    id:number
    feature_podcast:ContentModel
    podcast_sections: PodcastSection[] = []
    title:MultiLanguage
    header_image_url:string
    seo: SEOComponent
    constructor(data:any){
        this.id=data.id
        this.feature_podcast = new ContentModel(data.feature_podcast ? data.feature_podcast : emptyContent)
        data.podcast_sections.forEach((x:any)=>this.podcast_sections.push(new PodcastSection(x)))
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.header_image_url = data.header_image_url
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyDilmahPodcast  = {
    podcast_sections : [],
    title: emptyMultiLang,
    feature_podcast:emptyContent,
    seo: emptySEOComponent
}