import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateHeaderWithContentItem,
  updateMultiLangItem,
  updateOurTeaCategoriesTeaByFlavor,
  updateOurTeaCategoriesTeaByMood,
  updateOurTeaCategoriesTeaFormats,
  updateOurTeaCategoriesTeaRange,
  updateOurTeaCategoriesTeaTypes,
  updateSeoItem
} from "../../services/api_service/components_update";
import { ourTeaCategoriesGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import OurTeaCategories, {
  emptyOurTeaCategories,
} from "../../models/pages/our_tea_categories";
import HeaderWithContentComponent from "../../components/header_with_content/header_with_content";
import ImageWithLinkComponent from "../../components/image_with_link/image_with_link";
import ImageWithLink, {
  emptyImageWithLink,
} from "../../models/image_with_link";
import SEOComponent from "../../components/seo_component/seo_component";

export default function OurTeaCategoriesPage() {
  const [pageData, setPageData] = useState<OurTeaCategories>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(
      country,
      ourTeaCategoriesGraph,
      "ourTeaCategoriesPages"
    ).then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new OurTeaCategories(
            data.data ? data.data.page : emptyOurTeaCategories
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: OurTeaCategories) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.title = await updateMultiLangItem(pageData.title);
      dataToSave.header_descrpiton = await updateHeaderWithContentItem(
        pageData.header_descrpiton
      );
      dataToSave.seo = await updateSeoItem(pageData.seo)
      dataToSave.tea_ranges = await updateOurTeaCategoriesTeaRange(
        pageData.tea_ranges
      );
      dataToSave.tea_types = await updateOurTeaCategoriesTeaTypes(
        pageData.tea_types
      );
      dataToSave.tea_formats = await updateOurTeaCategoriesTeaFormats(
        pageData.tea_formats
      );
      dataToSave.tea_by_flavors = await updateOurTeaCategoriesTeaByFlavor(
        pageData.tea_by_flavors
      );
      dataToSave.tea_by_moods = await updateOurTeaCategoriesTeaByMood(
        pageData.tea_by_moods
      );

      await updateContentTypeCall(dataToSave, "our-tea-categories").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "our-tea-categories-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setTeaRangeHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.tea_ranges.header_image = url;
      setPageData({ ...prev });
    }
  };

  const setTeaTypesHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.tea_types.header_image = url;
      setPageData({ ...prev });
    }
  };

  const setTeaFormatsHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.tea_formats.header_image = url;
      setPageData({ ...prev });
    }
  };

  const setTeaByFlavorHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.tea_by_flavors.header_image = url;
      setPageData({ ...prev });
    }
  };

  const setTeaByMoodHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.tea_by_moods.header_image = url;
      setPageData({ ...prev });
    }
  };

  const addNewImageWithText = () => {
    const prev = pageData;
    if (prev) {
      prev.tea_ranges.items.push(new ImageWithLink(emptyImageWithLink));
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithText = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.tea_ranges.items.splice(index, 1);
      setPageData(new OurTeaCategories(emptyOurTeaCategories));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextForTeaTypes = () => {
    const prev = pageData;
    if (prev) {
      prev.tea_types.items.push(new ImageWithLink(emptyImageWithLink));
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextForTeaTypes = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.tea_types.items.splice(index, 1);
      setPageData(new OurTeaCategories(emptyOurTeaCategories));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextForTeaByFlavor = () => {
    const prev = pageData;
    if (prev) {
      prev.tea_by_flavors.items.push(new ImageWithLink(emptyImageWithLink));
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextForTeaByFlavor = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.tea_by_flavors.items.splice(index, 1);
      setPageData(new OurTeaCategories(emptyOurTeaCategories));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextForTeaByMood = () => {
    const prev = pageData;
    if (prev) {
      prev.tea_by_moods.items.push(new ImageWithLink(emptyImageWithLink));
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextForTeaByMood = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.tea_by_moods.items.splice(index, 1);
      setPageData(new OurTeaCategories(emptyOurTeaCategories));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageWithTextForTeaFormats = () => {
    const prev = pageData;
    if (prev) {
      prev.tea_formats.items.push(new ImageWithLink(emptyImageWithLink));
      setPageData({ ...prev });
    }
  };

  const removeNewImageWithTextForTeaFormats = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.tea_formats.items.splice(index, 1);
      setPageData(new OurTeaCategories(emptyOurTeaCategories));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.ourTeaCategories}
      parentRoutes={[routeNameObjects.ourProduct]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>

          <AttributeItem title={"Title"}>
            <MultiLangEdit value={pageData.title} />
          </AttributeItem>

          <AttributeItem title={"Header with Description"}>
            <HeaderWithContentComponent item={pageData.header_descrpiton} />
          </AttributeItem>

          <AttributeItem title={"Tea Ranges"}>
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setTeaRangeHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.tea_ranges.header_image}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>

            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.tea_ranges.title} />
            </AttributeItem>

            <AttributeItem title={"Header with Description"}>
              <HeaderWithContentComponent
                item={pageData.tea_ranges.header_descrpiton}
              />
            </AttributeItem>
            <AttributeItem title="Tea Ranges" onClick={addNewImageWithText}>
              {pageData.tea_ranges.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithText}
                />
              ))}
            </AttributeItem>
            <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.tea_ranges.seo} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"Tea Types"}>
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setTeaTypesHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.tea_types.header_image}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>

            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.tea_types.title} />
            </AttributeItem>

            <AttributeItem title={"Header with Description"}>
              <HeaderWithContentComponent
                item={pageData.tea_types.header_descrpiton}
              />
            </AttributeItem>
            <AttributeItem
              title={"Tea Types Items"}
              onClick={addNewImageWithTextForTeaTypes}
            >
              {pageData.tea_types.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextForTeaTypes}
                  isSeo={true}
                />
              ))}
            </AttributeItem>
            <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.tea_types.seo} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"Tea Formats"}>
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setTeaFormatsHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.tea_formats.header_image}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>

            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.tea_formats.title} />
            </AttributeItem>

            <AttributeItem title={"Header with Description"}>
              <HeaderWithContentComponent
                item={pageData.tea_formats.header_descrpiton}
              />
            </AttributeItem>
            <AttributeItem
              title="Tea Formats Items"
              onClick={addNewImageWithTextForTeaFormats}
            >
              {pageData.tea_formats.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextForTeaFormats}
                  isSeo={true}
                />
              ))}
            </AttributeItem>
            <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.tea_formats.seo} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"Tea By Flavor"}>
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setTeaByFlavorHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.tea_by_flavors.header_image}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>

            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.tea_by_flavors.title} />
            </AttributeItem>

            <AttributeItem title={"Header with Description"}>
              <HeaderWithContentComponent
                item={pageData.tea_by_flavors.header_descrpiton}
              />
            </AttributeItem>
            <AttributeItem
              title={"Tea By Flavor Items"}
              onClick={addNewImageWithTextForTeaByFlavor}
            >
              {pageData.tea_by_flavors.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextForTeaByFlavor}
                />
              ))}
            </AttributeItem>
            <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.tea_by_flavors.seo} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"Tea By Moods"}>
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setTeaByMoodHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.tea_by_moods.header_image}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>

            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.tea_by_moods.title} />
            </AttributeItem>

            <AttributeItem title={"Header with Description"}>
              <HeaderWithContentComponent
                item={pageData.tea_by_moods.header_descrpiton}
              />
            </AttributeItem>
            <AttributeItem
              title={"Tea By Moods Items"}
              onClick={addNewImageWithTextForTeaByMood}
            >
              {pageData.tea_by_moods.items.map((item, index) => (
                <ImageWithLinkComponent
                  item={item}
                  index={index}
                  key={index}
                  onRemove={removeNewImageWithTextForTeaByMood}
                />
              ))}
            </AttributeItem>
            <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.tea_by_moods.seo} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
