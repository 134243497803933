import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class GeneralTeaRangeModel {
    id: number;
    title: MultiLanguage;
    content_1: ContentModel;
    header_image_url : string;
    banner_image_url : string;
    intro_image_url : string;
    seo: SEOComponent
    constructor(data: any) {
        this.id = data.id;
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
        this.header_image_url = data.header_image_url
        this.banner_image_url = data.banner_image_url
        this.intro_image_url = data.intro_image_url
        this.content_1 = new ContentModel(
            data.content_1 ? data.content_1 : emptyContent
        );
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyGeneralTeaRangeModel = {
    title: emptyMultiLang,
    content_1: emptyContent,
    seo: emptySEOComponent
};