import { Card } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React from "react";
import IngredientItemModel from "../../models/ingredient_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";
import HeaderWithContentComponent from "../header_with_content/header_with_content";
import AttributeItem from "../attribute_item/attribute_item";
import ContentItem from "../content_item/content_item";

export default function IngredientItem(props: {
  onRemove: Function;
  index: number;
  ingredientItem: IngredientItemModel;
}) {
  
  // const [type, setType] = useState(props.socialWallItem.icon_type? props.socialWallItem.icon_type : socialWallIconTypes[0]);
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.ingredientItem.header_image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.ingredientItem.header_image_url = url;
          }}
        />
      </div>
      <MultiLangEdit
        isShowLabel
        label="Title"
        value={props.ingredientItem.title}
      />

      <AttributeItem title={"About Ingredient"}>
        <HeaderWithContentComponent item={props.ingredientItem.about_ingredient}/>
      </AttributeItem>
      <AttributeItem title={"Medicinal Claims"}>
          <HeaderWithContentComponent item={props.ingredientItem.medicinal_claims}/>
      </AttributeItem>
      <AttributeItem title="Possible Side Effects">
        <HeaderWithContentComponent item={props.ingredientItem.possible_side_effects}/>
      </AttributeItem>

      <MultiLangEdit
        isShowLabel
        label="Research Links"
        value={props.ingredientItem.research_links}
        isMarkdown
      />
      <AttributeItem title={"Video"}>
        <ContentItem content={props.ingredientItem.video_item}/>
      </AttributeItem>
    </Card>
  );
}
