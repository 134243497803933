import { Card} from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React from "react";
import HeaderWithContent from "../../models/header_with_content";
import IconBtn from "../icon_btn/icon_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function HeaderWithContentComponent(props: {
  item: HeaderWithContent;
  index?: number;
  onRemove?: Function;
}) {
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      {props.onRemove && (
        <IconBtn
          icon={Remove}
          onClick={() => {
            if (props.onRemove && props.index != null) {
              props.onRemove(props.index);
            }
          }}
        />
      )}
      
      <MultiLangEdit label={"Title"} isShowLabel value={props.item.title}/>

      <MultiLangEdit label={"Description"} isShowLabel value={props.item.description} isMarkdown={true}/>
    
    </Card>
  );
}
