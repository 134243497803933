import { Card, ListItem, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import SocialWallItemModel from "../../models/social_wall_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export const socialWallIconTypes = ["facebook", "twitter", "instagram"];

export default function SocialWallItem(props: {
  onRemove: Function;
  index: number;
  socialWallItem: SocialWallItemModel;
}) {
  
  const [type, setType] = useState(props.socialWallItem.icon_type? props.socialWallItem.icon_type : socialWallIconTypes[0]);
  const [link, setlink] = useState(props.socialWallItem.link? props.socialWallItem.link : '')

  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.socialWallItem.image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.socialWallItem.image_url = url;
          }}
        />
      </div>
      <TextField
        style={{
          width: "200px",
          marginBottom: "20px",
          marginLeft:"20px"
        }}
        label="Type"
        variant="outlined"
        value={type}
        select
        onChange={(event) => {
          setType(event.target.value);
          props.socialWallItem.icon_type = event.target.value;
        }}
      >
        {socialWallIconTypes.map((type, index) => (
          <ListItem key={index} value={type}>
            {type}
          </ListItem>
        ))}
      </TextField>
      <MultiLangEdit
        isShowLabel
        label="Description"
        value={props.socialWallItem.description}
      />
            <TextField
        style={{
          margin: '20px',
          width: '50%'
        }}
        label="Link"
        variant="outlined"
        value={link}
        onChange={(event) => {
          setlink(event.target.value);
          props.socialWallItem.link = event.target.value;
        }}
      ></TextField>
    </Card>
  );
}
