const myColors = {

    mainColor:'#244564',
    tableDataText:'grey',
    appBarColor:'#244564',
    drawerColor:'#193248',
    appBarTextColor:'#d9d9d9',
    drawerSideBarColor:'#0cd3b6',
    oddRowColor:'#f8f9fb'
}

export default myColors;
