import WatteTeaRangeRegionModel, { emptyWatteTeaRangeRegionModel } from "../../models/pages/watte_tea_range_region"
import WatteTeaRangeEstateModel, { emptyWatteTeaRangeEstateModel } from '../../models/pages/watte_tea_range_estate'
import WatteTeaRegionsModel, {emptyWatteTeaRegionsModel } from "./watte_tea_resgion_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import ExploreDilmahItemModel from "../explore_dilmah_item";
import SEOComponent, {emptySEOComponent} from "./seo_component";


export default class WatteTeaRangeModel {
    id: number;
    banner_image_url: string;
    header_image_url: string;
    region_teas: WatteTeaRangeRegionModel;
    estate_teas: WatteTeaRangeEstateModel;
    watte_boutique_region: WatteTeaRegionsModel
    header: MultiLanguage;
    title: MultiLanguage;
    description: MultiLanguage
    links: ExploreDilmahItemModel[] = []
    seo: SEOComponent

    constructor(data: any) {
        this.id = data.id;
        this.region_teas = new WatteTeaRangeRegionModel(data.region_teas ? data.region_teas : emptyWatteTeaRangeRegionModel)
        this.estate_teas = new WatteTeaRangeEstateModel(data.estate_teas ? data.estate_teas : emptyWatteTeaRangeEstateModel)
        this.watte_boutique_region = new WatteTeaRegionsModel(data.watte_boutique_region ? data.watte_boutique_region : emptyWatteTeaRegionsModel)
        this.banner_image_url = data.banner_image_url ? data.banner_image_url : null
        this.header_image_url = data.header_image_url ? data.header_image_url : null
        this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        data.links.forEach((x: any) => this.links.push(new ExploreDilmahItemModel(x)))
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyWatteTeaRangeModel = {
    title: emptyMultiLang,
    header: emptyMultiLang,
    description: emptyMultiLang,
    region_teas: emptyWatteTeaRangeRegionModel,
    estate_teas: emptyWatteTeaRangeEstateModel,
    links: [],
    seo: emptySEOComponent
};