
import ImageItem from "./image_item"
import MultiLanguage, { emptyMultiLang } from "./multi_language"
import SEOComponent, { emptySEOComponent } from "./pages/seo_component"

export default class PhotographerModal{
    id:number
    name:MultiLanguage
    description:MultiLanguage
    image_url:string
    images: ImageItem[] = []
    seo: SEOComponent;

    constructor(data:any){
        this.id=data.id
        this.name=new MultiLanguage(data.name ? data.name : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.image_url = data.image_url
        data.images.forEach((x:any)=>this.images.push(new ImageItem(x)))
        this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
    }
}

export const emptyPhotographer = {
    name: new MultiLanguage(emptyMultiLang),
    description: new MultiLanguage(emptyMultiLang),
    images:[],
    seo: emptySEOComponent,
}