import { Card } from "@material-ui/core";
import React from "react";
import DilmahPodcastCategoryModal from "../../models/dilmah_podcast_category";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function DilmahPodcastCategoryItem(props: {
    category: DilmahPodcastCategoryModal;
}) {
    return (
        <Card
            elevation={5}
            style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
        >
            <MultiLangEdit isShowLabel label="Name" value={props.category.name} />
        </Card>
    );
}