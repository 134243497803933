
import ImageItem from "./image_item"
import MultiLanguage, { emptyMultiLang } from "./multi_language"
import SEOComponent, { emptySEOComponent } from "./pages/seo_component"

export default class ContentWithImagesModel{
    id:number
    cover_image_url:string
    title:MultiLanguage
    short_description:MultiLanguage
    long_description:MultiLanguage
    images: ImageItem[] = []
    seo: SEOComponent;

    constructor(data:any){
        this.id=data.id
        this.title=new MultiLanguage(data.title ? data.title : emptyMultiLang)
        this.short_description = new MultiLanguage(data.short_description ? data.short_description : emptyMultiLang)
        this.long_description = new MultiLanguage(data.long_description ? data.long_description : emptyMultiLang)
        this.cover_image_url = data.cover_image_url
        data.images.forEach((x:any)=>this.images.push(new ImageItem(x)))
        this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
    }
}

export const emptyContentWithImages = {
    title: new MultiLanguage(emptyMultiLang),
    short_description: new MultiLanguage(emptyMultiLang),
    long_description: new MultiLanguage(emptyMultiLang),
    images:[],
    seo: emptySEOComponent,
}