import QuizModel from "../quiz_model";
import SEOComponent, {emptySEOComponent} from "./seo_component";


export default class DilmahTeaQuiz {
  id: number;
  quiz: QuizModel [] = [];
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.quiz.forEach((x:any) => this.quiz.push(new QuizModel(x)))
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)

  }
}

export const emptyDilmahTeaQuiz = {
  quiz: [],
  seo: emptySEOComponent
};
