import React, { useCallback, useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import { graphQuery } from "../../services/api_service/api_service";
import {
  contactUsSubmitsGraph,
  countContactUsSubmitsGraph,
} from "../../services/api_service/graphql_queries";
import ContactUsSubmitModal from "../../models/contact_us_submit";
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import CsvDownloader from "react-csv-downloader";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  header: {
    fontWeight: "bold",
  },
});

export default function ContactUsSubmitView() {
  const rowsPerPageSet = [3, 10, 25, 100];
  const [pageData, setPageData] = useState<ContactUsSubmitModal[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageSet[0]);
  const [allRecordsCount, setAllRecordsCount] = useState(0);

  const getPageData = useCallback(
    (page: number) => {
      graphQuery(contactUsSubmitsGraph(rowsPerPage, page + 1)).then((data) => {
        if (data?.success) {
          const p: ContactUsSubmitModal[] = [];
          data.data.contactUsSubmits.forEach((x: any) =>
            p.push(new ContactUsSubmitModal(x))
          );
          setPageData(p);
        }
      });

      graphQuery(countContactUsSubmitsGraph).then((data) => {
        if (data?.success) {
          setAllRecordsCount(data.data.contactUsSubmits.length);
        }
      });
    },
    [setPageData, rowsPerPage]
  );

  useEffect(() => {
    getPageData(pageNumber);
  }, [pageNumber, getPageData]);

  const classes = useRowStyles();
  return (
    <CommonPage
      currentRoute={routeNameObjects.contactUsSubmits}
      parentRoutes={[routeNameObjects.contactUsSubmits]}
    >
      <div>
        <CsvDownloader
          datas={pageData.map((contact, index) => {
            return {
              name: contact.name,
              email: contact.email,
              contact_no: contact.contact_no,
              company: contact.company,
              subject: contact.subject,
              comment: contact.comment,
              country: contact.country
            };
          })}
          columns={[
            {
              id: "name",
              displayName: "Name",
            },
            {
              id: "email",
              displayName: "Email Address",
            },
            {
              id: "contact_no",
              displayName: "Contact No",
            },
            {
              id: "company",
              displayName: "Company",
            },
            {
              id: "subject",
              displayName: "Subject",
            },
            {
              id: "other_subject",
              displayName: "Other_Subject",
            },
            {
              id: "country",
              displayName: "Country",
            },
            {
              id: "comment",
              displayName: "Comment",
            },
          ]}
          filename={`Contact Supports page - ${pageNumber + 1}`}
        >
          <AddItemBtn title={"download"} onClick={() => {}} />
        </CsvDownloader>
      </div>

      <TableContainer style={{ marginTop: "50px" }} component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Name</TableCell>
              <TableCell className={classes.header}>Email</TableCell>
              <TableCell className={classes.header}>Contact No</TableCell>
              <TableCell className={classes.header}>Company</TableCell>
              <TableCell width={100} />
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData.map((contact, index) => (
              <Row key={index} row={contact} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageSet}
        component="div"
        count={allRecordsCount}
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        onChangePage={(event: unknown, newPage: number) => {
          setPageNumber(newPage);
        }}
        onChangeRowsPerPage={(event: any) => {
          setRowsPerPage(event.target.value);
          setPageNumber(0);
        }}
      />
    </CommonPage>
  );
}

function Row(props: { row: ContactUsSubmitModal }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.contact_no}</TableCell>
        <TableCell>{row.company}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold" }}
                gutterBottom
                component="div"
              >
                {row.subject}
              </Typography>
              <Typography variant="h6" gutterBottom component="div">
                {row.comment}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
