import ImageItem from "./image_item"
import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class TeaStateItemModel {
    id: number
    type: string
    video_link: string
    longitude: number
    latitude: number
    name: MultiLanguage
    description: MultiLanguage
    video_thumbnail_url: string
    images: ImageItem[] = []
    cover_image_url: string
    tour_: string
    tour_link_multilang: MultiLanguage
    constructor(data: any) {
        this.id = data.id
        this.type = data.type
        this.video_link = data.video_link
        this.longitude = data.longitude
        this.latitude = data.latitude
        this.name = new MultiLanguage(data.name ? data.name : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.video_thumbnail_url = data.video_thumbnail_url
        data.images.forEach((x: any) => this.images.push(new ImageItem(x)))
        this.cover_image_url = data.cover_image_url
        this.tour_ = data.tour_
        this.tour_link_multilang = new MultiLanguage(data.tour_link_multilang ? data.tour_link_multilang : emptyMultiLang)
    }
}

export const emptyTeaStateItem = {
    type: 'low',
    video_link: '',
    video_thumbnail_url: '',
    images: [],
    tour_: ''
}