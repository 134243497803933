import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
    getPageDataCall,
    updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
    updateSeoItem,
    updateWatteBoutiqueTeaEstate, updateWatteBoutiqueTeaRegion, updateMultiLangItem,
    updateExploreDilmahItemArray,
    updateWatteBoutiqueTeaRegions
} from "../../services/api_service/components_update";
import { watteBoutiqueTeaGraph } from "../../services/api_service/graphql_queries";
import WatteTeaRangeModel, { emptyWatteTeaRangeModel } from '../../models/pages/watte_tea_range_model'
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import IconUploadBtn from '../../components/icon_upload_btn/icon_upload_btn'
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import TeaLocationItem from "../../components/tea_location_item/tea_location_item";
import TeaLocationModel, { emptyTeaLocation } from "../../models/tea_location.ts";
import ExploreDilmahItem from "../../components/explore_dilmah_item/explore_dilmah_item";
import ExploreDilmahItemModel, { emptyExploreDilmahItem } from "../../models/explore_dilmah_item";
import SEOComponent from "../../components/seo_component/seo_component";

export default function Arana() {
    const [pageData, setPageData] = useState<WatteTeaRangeModel>();

    const { showSideMessage } = SideMessageService();

    const getPageData = (country: string) => {
        getPageDataCall(
            country,
            watteBoutiqueTeaGraph,
            "watteBoutiqueTeaPages"
        ).then((data) => {
            if (data?.success) {
                setCurrentPageId(data.data?.id);
                setPageData(undefined);
                setPageData(
                    new WatteTeaRangeModel(
                        data.data ? data.data.page : emptyWatteTeaRangeModel
                    )
                );
            }
        });
    };

    const [currentPageId, setCurrentPageId] = useState();
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const onChangeCountry = (selected: { label: string; value: string }) => {
        setSelectedCountry(selected);
    };

    useEffect(() => {
        getPageData(selectedCountry.value);
    }, [selectedCountry]);
    const setLoading = useStoreActions(
        (actions: Actions<StoreModel>) => actions.loader.showLoader
    );
    const onSave = async (pageData: WatteTeaRangeModel) => {
        if (pageData) {
            setLoading(true);
            const dataToSave: any = pageData;
            dataToSave.title = await updateMultiLangItem(pageData.title);
            dataToSave.header = await updateMultiLangItem(pageData.header);
            dataToSave.description = await updateMultiLangItem(pageData.description);
            dataToSave.links = await updateExploreDilmahItemArray(pageData.links)
            dataToSave.estate_teas = await updateWatteBoutiqueTeaEstate(pageData.estate_teas);
            dataToSave.region_teas = await updateWatteBoutiqueTeaRegion(pageData.region_teas);
            dataToSave.watte_boutique_region = await updateWatteBoutiqueTeaRegions(pageData.watte_boutique_region);
            dataToSave.seo = await updateSeoItem(pageData.seo)

            await updateContentTypeCall(dataToSave, "watte-boutique-teas").then(
                (data) => {
                    if (data?.success) {
                        updateContentTypeCall(
                            {
                                id: currentPageId,
                                country: selectedCountry.value,
                                page: data.data.id,
                            },
                            "watte-boutique-tea-pages"
                        ).then((data) => {
                            if (data?.success) {
                                getPageData(selectedCountry.value);
                                showSideMessage(true);
                            } else {
                                showSideMessage(false);
                            }
                        });
                    }
                }
            );
        }
    };

    const setHeaderImageEstate = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.estate_teas.header_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setBannerImageEstate = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.estate_teas.banner_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setIntroImageEstate = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.estate_teas.intro_image_url = url;
            setPageData({ ...prev });
        }
    };


    const setHeaderImageRegion = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.region_teas.header_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setBannerImageRegion = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.region_teas.banner_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setIntroImageRegion = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.region_teas.intro_image_url = url;
            setPageData({ ...prev });
        }
    };

    const addNewTeaLocation = () => {
        const prev = pageData;
        if (prev) {
          prev.watte_boutique_region.tea_locations.push(new TeaLocationModel(emptyTeaLocation));
          setPageData({ ...prev });
        }
      };
    
      const removeTeaLocation= (index: number) => {
        const prev = pageData;
        if (prev) {
          prev.watte_boutique_region.tea_locations.splice(index, 1);
          setPageData(new WatteTeaRangeModel(emptyWatteTeaRangeModel));
          setTimeout(() => {
            setPageData({ ...prev });
          }, 0.01);
        }
      };

      const setBannerImage = (url: string) => {
        const prev = pageData;
        if (prev) {
          prev.banner_image_url = url;
          setPageData({ ...prev });
        }
      };

      const setHeaderImage = (url: string) => {
        const prev = pageData;
        if (prev) {
          prev.header_image_url = url;
          setPageData({ ...prev });
        }
      };

      const addNewLink = () => {
          const prev = pageData;
          if (prev) {
            prev.links.push(new ExploreDilmahItemModel(emptyExploreDilmahItem));
            setPageData({ ...prev });
          }
      }

      const removeLink = (index: number) => {
        const prev = pageData;
        if (prev) {
          prev.links.splice(index, 1);
          setPageData(new WatteTeaRangeModel(emptyWatteTeaRangeModel));
          setTimeout(() => {
            setPageData({ ...prev });
          }, 0.01);
        }
      };

      const setTeaLocationsHeaderImage = (url: string) => {
        const prev = pageData;
        if (prev) {
          prev.watte_boutique_region.header_image_url = url;
          setPageData({ ...prev });
        }
      };
    
      const setTeaLocationsSubHeaderImage = (url: string) => {
        const prev = pageData;
        if (prev) {
          prev.watte_boutique_region.sub_header_image_url = url;
          setPageData({ ...prev });
        }
      };

    return (
        <CommonPage
            currentRoute={nestedRouteNameObject1.dilmahWatteBoutiqueTea}
            parentRoutes={[routeNameObjects.teaRanges]}
        >
            {pageData ? (
                <div>
                    <CountrySelect
                        selectedCountry={selectedCountry}
                        onCountryChange={onChangeCountry}
                        addBtn={
                            <AddItemBtn
                                title={"Save"}
                                onClick={() => onSave(clonedeep(pageData))}
                            />
                        }
                    />
                    <AttributeItem title="Watte Boutique Tea">
                    <AttributeItem title={"Banner Image"}>
                        <div style={{ textAlign: "center" }}>
                            <IconUploadBtn
                                getFileUrl={setBannerImage}
                                mediaType="IMAGE"
                                mediaUrl={pageData.banner_image_url}
                                height={150}
                                isEditable
                            />
                        </div>
                    </AttributeItem>
                    <AttributeItem title={"Header Image"}>
                        <div style={{ textAlign: "center" }}>
                            <IconUploadBtn
                                getFileUrl={setHeaderImage}
                                mediaType="IMAGE"
                                mediaUrl={pageData.header_image_url}
                                height={150}
                                isEditable
                            />
                        </div>
                    </AttributeItem>
                    <AttributeItem title={"Title"}>
                        <MultiLangEdit
                            value={pageData.title}
                        />
                    </AttributeItem>
                    <AttributeItem title={"Header"}>
                        <MultiLangEdit
                            value={pageData.header}
                        />
                    </AttributeItem>
                    <AttributeItem title={"Description"}>
                        <MultiLangEdit
                            value={pageData.description}
                        />
                    </AttributeItem>
                    <AttributeItem title={"Links"} onClick={addNewLink}>
                        {pageData.links.map((link, index) => (
                        <ExploreDilmahItem
                            key={index}
                            explore={link}
                            index={index}
                            onRemove={removeLink}
                        />
                        ))}
                    </AttributeItem>
                    </AttributeItem>
                    <AttributeItem title="Watte Estate Teas">
                        <AttributeItem title="Header Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setHeaderImageEstate}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.estate_teas.header_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Banner Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setBannerImageEstate}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.estate_teas.banner_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Intro Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setIntroImageEstate}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.estate_teas.intro_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title={"Title"}>
                            <MultiLangEdit value={pageData.estate_teas.title} />
                        </AttributeItem>
                        <AttributeItem title={"Description"}>
                            <MultiLangEdit value={pageData.estate_teas.description} />
                        </AttributeItem>
                    </AttributeItem>
                    <AttributeItem title="Watte Region Teas">
                        <AttributeItem title="Header Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setHeaderImageRegion}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.region_teas.header_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Banner Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setBannerImageRegion}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.region_teas.banner_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Intro Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setIntroImageRegion}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.region_teas.intro_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title={"Title"}>
                            <MultiLangEdit value={pageData.region_teas.title} />
                        </AttributeItem>
                        <AttributeItem title={"Description"}>
                            <MultiLangEdit value={pageData.region_teas.description} />
                        </AttributeItem>
                    </AttributeItem>
                    <AttributeItem title="Regions">
                    <div style={{ textAlign: "center" }}>
                        <IconUploadBtn
                            getFileUrl={setTeaLocationsHeaderImage}
                            mediaType="IMAGE"
                            mediaUrl={pageData.watte_boutique_region.header_image_url}
                            height={150}
                            isEditable
                        />
                        </div>
                        <MultiLangEdit isShowLabel label="Header" value={pageData.watte_boutique_region.header} />
                        <AttributeItem title={"Sub Header Image"}>
                            <div style={{ textAlign: "center" }}>
                            <IconUploadBtn
                                getFileUrl={setTeaLocationsSubHeaderImage}
                                mediaType="IMAGE"
                                mediaUrl={pageData.watte_boutique_region.sub_header_image_url}
                                height={150}
                                isEditable
                            />
                            </div>
                        </AttributeItem>
                        <MultiLangEdit isShowLabel label="Topic" value={pageData.watte_boutique_region.topic} />
                        <AttributeItem title={'Tea Locations'} removeMargin onClick={addNewTeaLocation}>
                                {
                                    pageData.watte_boutique_region.tea_locations.map((location, index) => <TeaLocationItem key={index} item={location} onRemove={() => { 
                                    removeTeaLocation(index)
                                }} 
                                />)
                                }
                        </AttributeItem>
                    </AttributeItem>
                    <AttributeItem title={"SEO Component"}>
                        <SEOComponent seoItem={pageData.seo}/>
                    </AttributeItem>
                </div>
            ) : (
                <MyLoader />
            )}
        </CommonPage>
    );
}