import HeaderWithContent, { emptyHeaderWithContent } from "../header_with_content";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class GiftOfTeas {
  id: number;
  title: MultiLanguage;
  header_image_url: string;
  header_descrpiton: HeaderWithContent;
  seo: SEOComponent
  

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.header_image_url = data.header_image_url;
    this.header_descrpiton = new HeaderWithContent(data.header_descrpiton ? data.header_descrpiton : emptyHeaderWithContent);
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)

  }
}

export const emptyGiftOfTeas = {
  title: emptyMultiLang,
  header_descrpiton: emptyHeaderWithContent,
  seo: emptySEOComponent
};
