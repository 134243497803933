import { dynamicTypes } from "../components/dynamic_content_section/dynamic_content_section";
import MultiLanguage, { emptyMultiLang } from "./multi_language";
import SubContentSectionModal from "./sub_content_section";

export default class DynamicContentSectionModal {
  id: number;
  rule_number: number;
  has_header: boolean;
  headers: MultiLanguage[] = [];
  content: MultiLanguage;
  image_url: string;
  has_link: boolean;
  link_text: MultiLanguage;
  link: string;
  sub_contents: SubContentSectionModal[] = [];
  imageFile: any;
  type:string

  constructor(data: any) {
    this.id = data.id;
    this.rule_number = data.rule_number
    this.image_url = data.image_url;
    data.headers.forEach((x: any) => this.headers.push(new MultiLanguage(x)));
    this.content = new MultiLanguage(data.content);
    this.link_text = new MultiLanguage(
      data.link_text ? data.link_text : emptyMultiLang
    );
    this.link = data.link;
    this.has_header = data.has_header;
    this.has_link = data.has_link;
    data.sub_contents.forEach((x: any) => this.sub_contents.push(new SubContentSectionModal(x)));
    this.type=data.type?data.type:dynamicTypes[0]
  }
}

export const emptyDynamicContentSection = {
  headers: [],
  content: emptyMultiLang,
  link_text: emptyMultiLang,
  has_header: false,
  has_link: false,
  sub_contents:[],
  type:dynamicTypes[0]
};
