import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import { graphQuery } from "../../services/api_service/api_service";
import clonedeep from "lodash.clonedeep";
import SideMessageService from "../../services/side_message_service";

import { teaBlogCategoriesGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import TeaBlogCategoryModal, {
  emptyTeaBlogCategory,
} from "../../models/tea_blog_category";
import DilmahTeaBlogCategoryItem from "../../components/dilmah_tea_blog/dilmah_tea_blog_category";
import { updateTeaBlogCategoryItemArray } from "../../services/api_service/components_update";
export default function TeaBlogCategoriesPage() {
  const [teaBlogCategories, setTeaBlogCategories] = useState<
    TeaBlogCategoryModal[]
  >([]);

  const { showSideMessage } = SideMessageService();

  const getPageData = () => {
    graphQuery(teaBlogCategoriesGraph).then((data) => {
      if (data?.success) {
        const t: TeaBlogCategoryModal[] = [];
        data.data["teaBlogCategories"].forEach((x: any) =>
          t.push(new TeaBlogCategoryModal(x))
        );
        setTeaBlogCategories(t);
      }
    });
  };

  useEffect(() => {
    getPageData();
  }, []);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageDataToSave: TeaBlogCategoryModal[]) => {
    console.log(pageDataToSave);
    setLoading(true);
    updateTeaBlogCategoryItemArray(pageDataToSave);
    getPageData();
    showSideMessage(true);
    setLoading(false);
  };

  const addNewTeaBlogCategory = () => {
    const prev = teaBlogCategories;
    if (prev) {
      prev.push(new TeaBlogCategoryModal(emptyTeaBlogCategory));
      setTeaBlogCategories([...prev]);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.teaBlogCategories}
      parentRoutes={[routeNameObjects.sections]}
    >
      <div>
        <AddItemBtn
          title={"Save"}
          onClick={() => onSave(clonedeep(teaBlogCategories))}
        />

        <AttributeItem
          title={"Tea Blog Categories"}
          onClick={addNewTeaBlogCategory}
        >
          {teaBlogCategories.map((c, index) => (
            <DilmahTeaBlogCategoryItem key={index} category={c} />
          ))}
        </AttributeItem>
        {/* <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
        </AttributeItem> */}
      </div>
    </CommonPage>
  );
}
