import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class ALifeDevotedToTea {
  id: number;
  image_contents: ContentModel[] = [];
  banner_image_url: string;
  intro_content: ContentModel;
  banner_title: MultiLanguage;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.image_contents.forEach((x: any) =>
      this.image_contents.push(new ContentModel(x))
    );
    this.banner_image_url = data.banner_image_url;
    this.banner_title = new MultiLanguage(
      data.banner_title ? data.banner_title : emptyMultiLang
    );
    this.intro_content = new ContentModel(
      data.intro_content ? data.intro_content : emptyContent
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyALifeDevotedToTea = {
  image_contents: [],
  intro_content: emptyContent,
  banner_title: emptyMultiLang,
  seo: emptySEOComponent,
};
