import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import ContentItem from "../../components/content_item/content_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateSeoItem ,updateContentItem, updateElixirOfCeylonTeaBrewingGuideline, updateElixirOfCeylonTeaElixirRecipes, updateElixirOfCeylonTeaOurElixir, updateElixirOfCeylonTeaRealTea, updateExploreDilmahItemArray, updateMultiLangItem,
} from "../../services/api_service/components_update";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import ExploreDilmahItem from "../../components/explore_dilmah_item/explore_dilmah_item";
import ExploreDilmahItemModel, { emptyExploreDilmahItem } from "../../models/explore_dilmah_item";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import ContentModel, { emptyContent } from "../../models/content_model";
import { elixirOfCeylonTeaGraph } from "../../services/api_service/graphql_queries";
import ElixirOfCeylonTeaModel, { emptyElixirOfCeylonTea } from "../../models/pages/elixir_of_ceylon_tea";
import ElixirItemComponent from "../../components/elixir_item/elixir_item";
import ElixirItem, { emptyElixirItem } from "../../models/elixir_item";
import ImageWithTitle, { emptyImageWithTitle } from "../../models/image_with_title";
import ImageWithTitleComponent from "../../components/image_with_title/image_with_title";
import SEOComponent from "../../components/seo_component/seo_component"

export default function ElixirOfCeylonTea() {
  const [pageData, setPageData] = useState<ElixirOfCeylonTeaModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, elixirOfCeylonTeaGraph, "elixirOfCeylonTeaPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new ElixirOfCeylonTeaModel(data.data ? data.data.page : emptyElixirOfCeylonTea)
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: ElixirOfCeylonTeaModel) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.header = await updateMultiLangItem(pageData.header)
      dataToSave.topic = await updateMultiLangItem(pageData.topic)
      dataToSave.content = await updateContentItem(pageData.content);
      dataToSave.links = await updateExploreDilmahItemArray(pageData.links)
      dataToSave.seo = await updateSeoItem(pageData.seo)
      dataToSave.our_elixir = await updateElixirOfCeylonTeaOurElixir(pageData.our_elixir)
      dataToSave.real_tea = await updateElixirOfCeylonTeaRealTea(pageData.real_tea)
      dataToSave.brewing_guideline = await updateElixirOfCeylonTeaBrewingGuideline(pageData.brewing_guideline)
      dataToSave.elixir_recipes = await updateElixirOfCeylonTeaElixirRecipes(pageData.elixir_recipes)

      await updateContentTypeCall(dataToSave, "elixir-of-ceylon-teas").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "elixir-of-ceylon-tea-pages"
          ).then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };



  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };


  const setOurElixirHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_elixir.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setOurElixirHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_elixir.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setBrewingHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.brewing_guideline.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setBrewingHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.brewing_guideline.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };


  const setRealTeaHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.real_tea.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setRealTeaHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.real_tea.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };


  const setRecipesHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.elixir_recipes.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setRecipesHeaderSubImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.elixir_recipes.header_sub_image_url = url;
      setPageData({ ...prev });
    }
  };



  const addNewLink = () => {
    const prev = pageData;
    if (prev) {
      prev.links.push(new ExploreDilmahItemModel(emptyExploreDilmahItem));
      setPageData({ ...prev });
    }
  };

  const removeLink = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.links.splice(index, 1);
      setPageData(new ElixirOfCeylonTeaModel(emptyElixirOfCeylonTea));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };


  const addNewElixirItem = () => {
    const prev = pageData;
    if (prev) {
      prev.our_elixir.elixir_items.push(new ElixirItem(emptyElixirItem));
      setPageData({ ...prev });
    }
  };

  const removeElixirItem = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.our_elixir.elixir_items.splice(index, 1);
      setPageData(new ElixirOfCeylonTeaModel(emptyElixirOfCeylonTea));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewVideoContent = () => {
    const prev = pageData;
    if (prev) {
      prev.brewing_guideline.videos.push(new ContentModel(emptyContent));
      setPageData({ ...prev });
    }
  };

  const removeVideoContent = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.brewing_guideline.videos.splice(index, 1);
      setPageData(new ElixirOfCeylonTeaModel(emptyElixirOfCeylonTea));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewImageItem = () => {
    const prev = pageData;
    if (prev) {
      prev.brewing_guideline.images.push(new ImageWithTitle(emptyImageWithTitle));
      setPageData({ ...prev });
    }
  };

  const removeImageItem = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.brewing_guideline.images.splice(index, 1);
      setPageData(new ElixirOfCeylonTeaModel(emptyElixirOfCeylonTea));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addRealTeaContent = () => {
    const prev = pageData;
    if (prev) {
      prev.real_tea.contents.push(new ContentModel(emptyContent));
      setPageData({ ...prev });
    }
  };

  const removeRealTeaContent = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.real_tea.contents.splice(index, 1);
      setPageData(new ElixirOfCeylonTeaModel(emptyElixirOfCeylonTea));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.elixir}
      parentRoutes={[routeNameObjects.teaRanges]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Header Sub Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderSubImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_sub_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Header"}>
            <MultiLangEdit
              value={pageData.header}
            />
          </AttributeItem>
          <AttributeItem title={"Topic"}>
            <MultiLangEdit
              value={pageData.topic}
            />
          </AttributeItem>
          <AttributeItem title={"Content"}>
            <ContentItem
              content={pageData.content}
            />
          </AttributeItem>
          <AttributeItem title={"Links"} onClick={addNewLink}>
            {pageData.links.map((link, index) => (
              <ExploreDilmahItem
                key={index}
                explore={link}
                index={index}
                onRemove={removeLink}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={'Our Elixir'}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setOurElixirHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.our_elixir.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setOurElixirHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.our_elixir.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit isShowLabel label="Header" value={pageData.our_elixir.header} />
            <MultiLangEdit isShowLabel label="Topic" value={pageData.our_elixir.topic} />
            <MultiLangEdit isShowLabel label="Elixir Topic" value={pageData.our_elixir.elixir_topic} />
            <AttributeItem title={'General Information'} removeMargin onClick={addNewElixirItem}>
              {
                pageData.our_elixir.elixir_items.map((item, index) => <ElixirItemComponent item={item} index={index} key={index} onRemove={removeElixirItem} />)
              }
            </AttributeItem>
          </AttributeItem>


          <AttributeItem title={'Brewing Guideline'}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setBrewingHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.brewing_guideline.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setBrewingHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.brewing_guideline.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit isShowLabel label="Header" value={pageData.brewing_guideline.header} />
            <MultiLangEdit isShowLabel label="Topic" value={pageData.brewing_guideline.topic} />
            <AttributeItem title={'Content 1'} removeMargin >
              <ContentItem content={pageData.brewing_guideline.content_1} />
            </AttributeItem>
            <AttributeItem title={'Content 2'} removeMargin >
              <ContentItem content={pageData.brewing_guideline.content_2} />
            </AttributeItem>
            <AttributeItem title={'Videos'} removeMargin onClick={addNewVideoContent}>
              {
                pageData.brewing_guideline.videos.map((v, index) => <ContentItem content={v} index={index} key={index} onRemove={removeVideoContent} />)
              }
            </AttributeItem>
            <AttributeItem title={'Images'} removeMargin onClick={addNewImageItem}>
              {
                pageData.brewing_guideline.images.map((v, index) => <ImageWithTitleComponent item={v} index={index} key={index} onRemove={removeImageItem} />)
              }
            </AttributeItem>
          </AttributeItem>


          <AttributeItem title={'Real Tea'}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setRealTeaHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.real_tea.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setRealTeaHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.real_tea.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit isShowLabel label="Header" value={pageData.real_tea.header} />
            <MultiLangEdit isShowLabel label="Topic" value={pageData.real_tea.topic} />
            <AttributeItem title={'Title Content'} removeMargin >
              <ContentItem content={pageData.real_tea.title_content}/>
            </AttributeItem>
            <AttributeItem title={'Contents'} removeMargin onClick={addRealTeaContent}>
              {
                pageData.real_tea.contents.map((v, index) => <ContentItem content={v} index={index} key={index} onRemove={removeRealTeaContent}/>)
              }
            </AttributeItem>
          </AttributeItem>


          <AttributeItem title={'Elixir Recipes'}>
            <AttributeItem title={"Header Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setRecipesHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.elixir_recipes.header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Header Sub Image"} removeMargin>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setRecipesHeaderSubImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.elixir_recipes.header_sub_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <MultiLangEdit isShowLabel label="Header" value={pageData.elixir_recipes.header} />
            <MultiLangEdit isShowLabel label="Topic" value={pageData.elixir_recipes.topic} />
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}