import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import { DilmahQuizGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import DilmahTeaQuiz, {
  emptyDilmahTeaQuiz,
} from "../../models/pages/dilmah_tea_quiz";
import DilmahQuizItem from "../../components/dilmah_quiz/dilmah_quiz";
import QuizModel, { emptyQuizModel } from "../../models/quiz_model";
import {
  updateQuizContentArray,
  updateSeoItem,
} from "../../services/api_service/components_update";
import SEOComponent from "../../components/seo_component/seo_component";

export default function DilmahQuizePage() {
  const [pageData, setPageData] = useState<DilmahTeaQuiz>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, DilmahQuizGraph, "dilmahQuizPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new DilmahTeaQuiz(data.data ? data.data.page : emptyDilmahTeaQuiz)
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: DilmahTeaQuiz) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.quiz = await updateQuizContentArray(pageData.quiz);
      dataToSave.seo = await updateSeoItem(pageData.seo);

      await updateContentTypeCall(dataToSave, "dilmah-quizs").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "dilmah-quiz-pages"
          ).then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addQuizContent = () => {
    const prev = pageData;
    if (prev) {
      prev.quiz.push(new QuizModel(emptyQuizModel));
      setPageData({ ...prev });
    }
  };

  const removeQuizContent = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.quiz.splice(index, 1);
      setPageData(new DilmahTeaQuiz(emptyDilmahTeaQuiz));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.quiz}
      parentRoutes={[routeNameObjects.ourProduct]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Dilmah Quiz"} onClick={addQuizContent}>
            {pageData.quiz.map((content, index) => (
              <DilmahQuizItem
                content={content}
                index={index}
                onRemove={removeQuizContent}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
