import { Card, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import FeaturedVideoModel from "../../models/featured_video";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function FeaturedVideoItem(props: {
  onRemove: Function;
  index: number;
  video: FeaturedVideoModel;
}) {

  const [url,setUrl] = useState(props.video.video_url)

  const onUrlChange = (u:any)=>{
    setUrl(u)
    props.video.video_url = u
  }
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={props.video.image_url}
          mediaType={"IMAGE"}
          getFileUrl={(url: any) => {
            props.video.image_url = url;
          }}
        />
      </div>
      <TextField
          style={{ marginLeft: "1%", width:'98%' }}
          
          label="Video Url"
          variant="outlined"
          value={url}
          onChange={ (event)=> onUrlChange(event.target.value)}
        />
      <MultiLangEdit isShowLabel label="Header" value={props.video.header} />
      <MultiLangEdit isShowLabel label="Description" value={props.video.description} />
      
    </Card>
  );
}
