import HeaderWithContent, {
  emptyHeaderWithContent,
} from "../header_with_content";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class BusinessEthicsPolicy {
  id: number;
  title: MultiLanguage;
  header_image_url: string;
  policy_content1: HeaderWithContent[] = [];
  policy_content2: HeaderWithContent[] = [];
  policy_content1_description: MultiLanguage;
  policy_content2_description: MultiLanguage;
  note: MultiLanguage;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.note = new MultiLanguage(data.note ? data.note : emptyMultiLang);
    this.header_image_url = data.header_image_url;
    data.policy_content1.forEach((x: any) =>
      this.policy_content1.push(new HeaderWithContent(x))
    );
    data.policy_content2.forEach((x: any) =>
      this.policy_content2.push(new HeaderWithContent(x))
    );
    this.policy_content1_description = new MultiLanguage(
      data.policy_content1_description
        ? data.policy_content1_description
        : emptyMultiLang
    );
    this.policy_content2_description = new MultiLanguage(
      data.policy_content2_description
        ? data.policy_content2_description
        : emptyMultiLang
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyBusinessEthicsPolicy = {
  title: emptyMultiLang,
  note: emptyMultiLang,
  header_description: emptyHeaderWithContent,
  policy_content1_description: emptyMultiLang,
  policy_content2_description: emptyMultiLang,
  policy_content1: [],
  policy_content2: [],
  seo: emptySEOComponent,
};
