import MediaCoverModel, { emptyMediaCover } from "../media_cover";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class MediaPageModel {
  id: number;
  latest_news_cover: MediaCoverModel;
  events_cover: MediaCoverModel;
  tea_blog_cover: MediaCoverModel;
  dilmah_in_press_cover: MediaCoverModel;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.latest_news_cover = new MediaCoverModel(
      data.latest_news_cover ? data.latest_news_cover : emptyMediaCover
    );
    this.events_cover = new MediaCoverModel(
      data.events_cover ? data.events_cover : emptyMediaCover
    );
    this.tea_blog_cover = new MediaCoverModel(
      data.tea_blog_cover ? data.tea_blog_cover : emptyMediaCover
    );
    this.dilmah_in_press_cover = new MediaCoverModel(
      data.dilmah_in_press_cover ? data.dilmah_in_press_cover : emptyMediaCover
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyMediaPage = {
  latest_news_cover: emptyMediaCover,
  events_cover: emptyMediaCover,
  tea_blog_cover: emptyMediaCover,
  dilmah_in_press_cover: emptyMediaCover,
  seo: emptySEOComponent,
};
