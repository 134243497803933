import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import clonedeep from "lodash.clonedeep";
import CertificatesAndAwards, {
  emptyCertificatePage,
} from "../../models/pages/certificates_and_awards";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import CertificateSection from "../../components/certificate_section/certificate_section";
import CertificateAndAwardSection, {
  emptyCertificateAndAwardSection,
} from "../../models/certificate_and_award_section";
import MyLoader from "../../components/my_loader/my_loader";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateCertificateSectionArray,
  updateMultiLangItem,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { certificatesAndAwardsGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";

export default function CertificatesAndAwardPage() {
  const [certificates, setCertificates] = useState<CertificatesAndAwards>();
  const { showSideMessage } = SideMessageService();

  const getCertificates = (country: string) => {
    getPageDataCall(country,certificatesAndAwardsGraph,'certificatesAndAwardsPages').then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setCertificates(undefined);
        setCertificates(
          new CertificatesAndAwards(
            data.data ? data.data.page : emptyCertificatePage
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getCertificates(selectedCountry.value);
  }, [selectedCountry]);

  const setLoading = useStoreActions((actions:Actions<StoreModel>)=>actions.loader.showLoader)

  const onSave = async (certificates: CertificatesAndAwards) => {
    if (certificates) {
      setLoading(true)
      const pageData: any = certificates;

      pageData.certificates = await updateCertificateSectionArray(
        certificates.certificates
      );

      pageData.awards = await updateCertificateSectionArray(
        certificates.awards
      );

      pageData.header = await updateMultiLangItem(certificates.header);
      pageData.description = await updateMultiLangItem(
        certificates.description
      );
      pageData.award_btn_text = await updateMultiLangItem(
        certificates.award_btn_text
      );
      pageData.certificate_btn_text = await updateMultiLangItem(
        certificates.certificate_btn_text
      );
      pageData.seo = await updateSeoItem(certificates.seo)

      await updateContentTypeCall(pageData, "certificates-and-awards").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "certificates-and-awards-pages"
            ).then((data) => {
              if (data?.success) {
                getCertificates(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const addNewCertificateSection = () => {
    const prev = certificates;
    if (prev) {
      prev.certificates.push(
        new CertificateAndAwardSection(emptyCertificateAndAwardSection)
      );
      setCertificates({ ...prev });
    }
  };

  const removeCertificateSection = (index: number) => {
    const prev = certificates;
    if (prev) {
      prev.certificates.splice(index, 1);
      setCertificates(new CertificatesAndAwards(emptyCertificatePage));
      setTimeout(() => {
        setCertificates({ ...prev });
      }, 0.01);
    }
  };

  const addNewAwardSection = () => {
    const prev = certificates;
    if (prev) {
      prev.awards.push(
        new CertificateAndAwardSection(emptyCertificateAndAwardSection)
      );
      setCertificates({ ...prev });
    }
  };

  const removeAwardSection = (index: number) => {
    const prev = certificates;
    if (prev) {
      prev.awards.splice(index, 1);
      setCertificates(new CertificatesAndAwards(emptyCertificatePage));
      setTimeout(() => {
        setCertificates({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.certificatesAndAwards}
      parentRoutes={[routeNameObjects.familyPassion]}
    >
      {certificates ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(certificates))}
              />
            }
          />

          <AttributeItem
            title={"Certificates"}
            onClick={addNewCertificateSection}
          >
            {certificates.certificates.map((section, index) => (
              <CertificateSection
                section={section}
                key={index}
                onRemove={() => {
                  removeCertificateSection(index);
                }}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"Awards"} onClick={addNewAwardSection}>
            {certificates.awards.map((section, index) => (
              <CertificateSection
                section={section}
                key={index}
                onRemove={() => {
                  removeAwardSection(index);
                }}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"Header"}>
            <MultiLangEdit value={certificates.header} />
          </AttributeItem>
          <AttributeItem title={"Description"}>
            <MultiLangEdit value={certificates.description} />
          </AttributeItem>
          <AttributeItem title={"Award Button Text"}>
            <MultiLangEdit value={certificates.award_btn_text} />
          </AttributeItem>
          <AttributeItem title={"Certificate Button Text"}>
            <MultiLangEdit value={certificates.certificate_btn_text} />
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={certificates.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
