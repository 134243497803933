import HeaderWithContent, {
  emptyHeaderWithContent,
} from "../header_with_content";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class TermsAndCondition {
  id: number;
  title: MultiLanguage;
  header_image_url: string;
  header_description_1: MultiLanguage;
  header_description_2: MultiLanguage;
  policy_content: HeaderWithContent[] = [];
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.header_image_url = data.header_image_url;
    this.header_description_1 = new MultiLanguage(
      data.header_description_1 ? data.header_description_1 : emptyMultiLang
    );
    this.header_description_2 = new MultiLanguage(
      data.header_description_2 ? data.header_description_2 : emptyMultiLang
    );
    data.policy_content.forEach((x: any) =>
      this.policy_content.push(new HeaderWithContent(x))
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyTermsAndCondition = {
  title: emptyMultiLang,
  header_description_1: emptyMultiLang,
  header_description_2: emptyMultiLang,
  header_description: emptyHeaderWithContent,
  policy_content: [],
  seo: emptySEOComponent,
};
