import ContentModel from "../content_model";
import ExploreDilmahItemModel from "../explore_dilmah_item";
import FeaturedVideoModel from "../featured_video";
import Slider from "../slider";
import SEOComponent, {emptySEOComponent} from "./seo_component"; 

export default class HomeModel {
  id: number;
  sliders: Slider[] = [];
  mobile_sliders: Slider[] = [];
  contents: ContentModel[] = [];
  explore_dilmah_items: ExploreDilmahItemModel[] = []
  featured_videos: FeaturedVideoModel[] = []
  configurable_video_url: string
  seo: SEOComponent
  constructor(data: any) {
    this.id = data.id;
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    data.mobile_sliders.forEach((x: any) => this.mobile_sliders.push(new Slider(x)));
    data.contents.forEach((x: any) => this.contents.push(new ContentModel(x)));
    data.explore_dilmah_items.forEach((x: any) => this.explore_dilmah_items.push(new ExploreDilmahItemModel(x)));
    data.featured_videos.forEach((x: any) => this.featured_videos.push(new FeaturedVideoModel(x)));
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    this.configurable_video_url = data.configurable_video_url
  }
}

export const emptyHome = {
  sliders: [],
  mobile_sliders: [],
  contents: [],
  explore_dilmah_items: [],
  featured_videos: [],
  seo: emptySEOComponent
};
