import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateHeaderWithContentItemArray,
  updateMultiLangItem,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { businessEthicsPolicyGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import HeaderWithContentComponent from "../../components/header_with_content/header_with_content";
import BusinessEthicsPolicy, {
  emptyBusinessEthicsPolicy,
} from "../../models/pages/business_ethics_policy";
import HeaderWithContent, {
  emptyHeaderWithContent,
} from "../../models/header_with_content";
import SEOComponent from "../../components/seo_component/seo_component";

export default function BusinessEthicsPolicyPage() {
  const [pageData, setPageData] = useState<BusinessEthicsPolicy>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(
      country,
      businessEthicsPolicyGraph,
      "businessEthicsPolicyPages"
    ).then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new BusinessEthicsPolicy(
            data.data ? data.data.page : emptyBusinessEthicsPolicy
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: BusinessEthicsPolicy) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.title = await updateMultiLangItem(pageData.title);
      dataToSave.policy_content1 = await updateHeaderWithContentItemArray(
        pageData.policy_content1
      );
      dataToSave.policy_content2 = await updateHeaderWithContentItemArray(
        pageData.policy_content2
      );
      dataToSave.policy_content1_description = await updateMultiLangItem(
        pageData.policy_content1_description
      );
      dataToSave.policy_content2_description = await updateMultiLangItem(
        pageData.policy_content2_description
      );
      dataToSave.note = await updateMultiLangItem(pageData.note);
      dataToSave.seo = await updateSeoItem(pageData.seo);

      await updateContentTypeCall(dataToSave, "business-ethics-policies").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "business-ethics-policy-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const addNewContentPolicies = () => {
    const prev = pageData;
    if (prev) {
      prev.policy_content1.push(new HeaderWithContent(emptyHeaderWithContent));
      setPageData({ ...prev });
    }
  };

  const removeContentPolicies = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.policy_content1.splice(index, 1);
      setPageData(new BusinessEthicsPolicy(emptyBusinessEthicsPolicy));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewMobilePolicies = () => {
    const prev = pageData;
    if (prev) {
      prev.policy_content2.push(new HeaderWithContent(emptyHeaderWithContent));
      setPageData({ ...prev });
    }
  };

  const removeMobilePolicies = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.policy_content2.splice(index, 1);
      setPageData(new BusinessEthicsPolicy(emptyBusinessEthicsPolicy));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.businessEthicsPolicy}
      parentRoutes={[routeNameObjects.ourPlocics]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>

          <AttributeItem title={"Title"}>
            <MultiLangEdit value={pageData.title} />
          </AttributeItem>

          <AttributeItem title={"Note"}>
            <MultiLangEdit value={pageData.note} isMarkdown />
          </AttributeItem>

          <AttributeItem title={"Policy Content 1 - Description"}>
            <MultiLangEdit
              value={pageData.policy_content1_description}
              isMarkdown
            />
          </AttributeItem>

          <AttributeItem
            title="Policy Content 1 - Items"
            onClick={addNewContentPolicies}
          >
            {pageData.policy_content1.map((item, index) => (
              <HeaderWithContentComponent
                item={item}
                index={index}
                key={index}
                onRemove={removeContentPolicies}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"Policy Content 2 - Description"}>
            <MultiLangEdit
              value={pageData.policy_content2_description}
              isMarkdown
            />
          </AttributeItem>

          <AttributeItem
            title="Policy Content 2 - Items"
            onClick={addNewMobilePolicies}
          >
            {pageData.policy_content2.map((item, index) => (
              <HeaderWithContentComponent
                item={item}
                index={index}
                key={index}
                onRemove={removeMobilePolicies}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
