class CommonResponse {
  success: boolean;
  data: any;
  status: number;
  message: string;
  constructor(data: {
    success: boolean;
    data: any;
    status: number;
    message: string;
  }) {
    this.success = data.success;
    this.data = data.data;
    this.status = data.status;
    this.message = data.message;
  }
}

export default CommonResponse;
