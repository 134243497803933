import { Card, TextField } from "@material-ui/core";
import React, { useState } from "react";
import ContactUsComponentModel from "../../models/contact_us_component";
import LeafletMapView from "../map/leaflet_map";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function ContactUsComponent(props: {
  item: ContactUsComponentModel;
}) {
  const [contact_no_1, setContactNo1] = useState(props.item.contact_no_1);
  const [contact_no_2, setContactNo2] = useState(props.item.contact_no_2);
  const [email, setEmail] = useState(props.item.email);

  const [location, setLocation] = useState({
    lat: props.item.latitude ? props.item.latitude : 0,
    lon: props.item.longitude ? props.item.longitude : 0,
  });

  const changeLocation = (location: any) => {
    props.item.latitude = location.lat;
    props.item.longitude = location.lon;
    setLocation({ lat: location.lat, lon: location.lon });
  };
  return (
    <Card
      elevation={5}
      style={{
        margin: "10px 0px",
        paddingTop: "30px",
        position: "relative",
      }}
    >

      <MultiLangEdit value={props.item.address} label="Address" isShowLabel />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            type="text"
            label="Contact No 1"
            variant="outlined"
            value={contact_no_1}
            onChange={(event) => {
              setContactNo1(event.target.value);
              props.item.contact_no_1 = event.target.value;
            }}
          />

          <TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            type="text"
            label="Contact No 1"
            variant="outlined"
            value={contact_no_2}
            onChange={(event) => {
              setContactNo2(event.target.value);
              props.item.contact_no_2 = event.target.value;
            }}
          />

          <TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            type="text"
            label="Email Address"
            variant="outlined"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              props.item.email = event.target.value;
            }}
          />

<TextField
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            type="number"
            label="Latitude"
            variant="outlined"
            value={location.lat}
            onChange={(event) => {
              const prev = location;
              prev.lat = Number(event.target.value);
              setLocation({ ...prev });
              props.item.latitude = Number(event.target.value);
            }}
            InputLabelProps={{
              shrink: location.lat !== null,
            }}
          />
          <TextField
            style={{
              width: "100%",
            }}
            type="number"
            label="Longitude"
            variant="outlined"
            value={location.lon}
            onChange={(event) => {
              const prev = location;
              prev.lon = Number(event.target.value);
              setLocation({ ...prev });
              props.item.longitude = Number(event.target.value);
            }}
            InputLabelProps={{
              shrink: location.lon !== null,
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
        >
          <LeafletMapView location={location} setLocation={changeLocation} />
        </div>
      </div>
    </Card>
  );
}
