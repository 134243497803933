
import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class FeaturedVideoModel{
    id:number
    header:MultiLanguage
    description:MultiLanguage
    image_url:string
    video_url:string

    constructor(data:any){
        this.id=data.id
        this.header=new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.description = new MultiLanguage(data.description ? data.description : emptyMultiLang)
        this.image_url = data.image_url
        this.video_url = data.video_url
    }
}

export const emptyFeaturedVideo = {
    header:emptyMultiLang,
    description: emptyMultiLang,
}