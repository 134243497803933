import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import ImageItem from "../../models/image_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";

export default function ImageList(props: { images: ImageItem[] }) {
  const uploadImage = (url: string, index: number) => {
    
    const prev = images;
    prev[index].image_url = url;
    setImages([...prev]);
    props.images[index].image_url = url;
  };

  const addNewImage = () => {
    props.images.push(new ImageItem({ image_url: "" }));
    setImages([...props.images]);
  };

  const removeImage = (index: number) => {
    const prev = images;
    prev.splice(index, 1);
    setImages([]);
    setTimeout(() => {
      setImages([...prev]);
    }, 0.01);

    props.images.splice(index, 1);
  };
  const [images, setImages] = useState(props.images);
  return (
    <div
      style={{
        margin: "10px",
        padding: "10px",
        borderRadius: "10px",
        border: "1px solid grey",
        position: "relative",
      }}
    >
      <IconBtn icon={Add} onClick={addNewImage} />
      <div
        style={{
          textAlign: "center",
          margin: "2px",
          fontWeight: "bold",
          color: "#716666",
        }}
      >
        Images
      </div>
      <div
        style={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {images.map((image, index) => (
          <div key={index} style={{ marginLeft: "10px", marginRight: "10px" }}>
            <IconUploadBtn
              height={150}
              isEditable
              mediaUrl={image.image_url}
              mediaType={"IMAGE"}
              getFileUrl={(url: string) => uploadImage(url, index)}
              onRemove={removeImage}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
