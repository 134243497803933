import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
    getPageDataCall,
    updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
    updateSeoItem,
    updateContentItem,
    updateMultiLangItem,
} from "../../services/api_service/components_update";
import { organicGraph } from "../../services/api_service/graphql_queries";
import GeneralTeaRangeModel, { emptyGeneralTeaRangeModel } from '../../models/pages/general_tea_ranges'
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import IconUploadBtn from '../../components/icon_upload_btn/icon_upload_btn'
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import SEOComponent from "../../components/seo_component/seo_component";

export default function DilmahOrganic() {
    const [pageData, setPageData] = useState<GeneralTeaRangeModel>();

    const { showSideMessage } = SideMessageService();

    const getPageData = (country: string) => {
        getPageDataCall(
            country,
            organicGraph,
            "dilmahOrganicPages"
        ).then((data) => {
            if (data?.success) {
                setCurrentPageId(data.data?.id);
                setPageData(undefined);
                setPageData(
                    new GeneralTeaRangeModel(
                        data.data ? data.data.page : emptyGeneralTeaRangeModel
                    )
                );
            }
        });
    };

    const [currentPageId, setCurrentPageId] = useState();
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const onChangeCountry = (selected: { label: string; value: string }) => {
        setSelectedCountry(selected);
    };

    useEffect(() => {
        getPageData(selectedCountry.value);
    }, [selectedCountry]);
    const setLoading = useStoreActions(
        (actions: Actions<StoreModel>) => actions.loader.showLoader
    );
    const onSave = async (pageData: GeneralTeaRangeModel) => {
        if (pageData) {
            setLoading(true);
            const dataToSave: any = pageData;
            dataToSave.title = await updateMultiLangItem(pageData.title);
            dataToSave.content_1 = await updateContentItem(pageData.content_1);
            dataToSave.seo = await updateSeoItem(pageData.seo)

            await updateContentTypeCall(dataToSave, "dilmah-organics").then(
                (data) => {
                    if (data?.success) {
                        updateContentTypeCall(
                            {
                                id: currentPageId,
                                country: selectedCountry.value,
                                page: data.data.id,
                            },
                            "dilmah-organic-pages"
                        ).then((data) => {
                            if (data?.success) {
                                getPageData(selectedCountry.value);
                                showSideMessage(true);
                            } else {
                                showSideMessage(false);
                            }
                        });
                    }
                }
            );
        }
    };

    const setHeaderImage = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.header_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setBannerImage = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.banner_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setIntroImage = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.intro_image_url= url;
            setPageData({ ...prev });
        }
    };

    return (
        <CommonPage
            currentRoute={nestedRouteNameObject1.dilmahOrganic}
            parentRoutes={[routeNameObjects.teaRanges]}
        >
            {pageData ? (
                <div>
                    <CountrySelect
                        selectedCountry={selectedCountry}
                        onCountryChange={onChangeCountry}
                        addBtn={
                            <AddItemBtn
                                title={"Save"}
                                onClick={() => onSave(clonedeep(pageData))}
                            />
                        }
                    />
                                        <AttributeItem title={"Banner Image"}>
                        <div style={{ textAlign: "center" }}>
                            <IconUploadBtn
                                getFileUrl={setBannerImage}
                                mediaType="IMAGE"
                                mediaUrl={pageData.banner_image_url}
                                height={150}
                                isEditable
                            />
                        </div>
                    </AttributeItem>
                    <AttributeItem title={"Header Image"}>
                        <div style={{ textAlign: "center" }}>
                            <IconUploadBtn
                                getFileUrl={setHeaderImage}
                                mediaType="IMAGE"
                                mediaUrl={pageData.header_image_url}
                                height={150}
                                isEditable
                            />
                        </div>
                    </AttributeItem>
                    <AttributeItem title={"Intro Image"}>
                        <div style={{ textAlign: "center" }}>
                            <IconUploadBtn
                                getFileUrl={setIntroImage}
                                mediaType="IMAGE"
                                mediaUrl={pageData.intro_image_url}
                                height={150}
                                isEditable
                            />
                        </div>
                    </AttributeItem>
                    <AttributeItem title={"Topic"}>
                        <MultiLangEdit
                        value={pageData.title}
                        />
                    </AttributeItem>
                    <AttributeItem title={"SEO Component"}>
                        <SEOComponent seoItem={pageData.seo}/>
                    </AttributeItem>
                </div>
            ) : (
                <MyLoader />
            )}
        </CommonPage>
    );
}