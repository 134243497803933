import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class NaturalInfusionOurInfusionsModal {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header:MultiLanguage
    topic:MultiLanguage
    content:ContentModel
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.topic = new MultiLanguage(data.topic?data.topic:emptyMultiLang)
        this.content = new ContentModel(data.content?data.content:emptyContent)
    }
}

export const emptyNaturalInfusionOurInfusions = {
    header:emptyMultiLang,
    topic:emptyMultiLang,
    content:emptyContent,
}