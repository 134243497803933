import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import SideMessageService from "../../services/side_message_service";

import CountrySelect from "../../components/country_select/country_select";
import { countries } from "../../components/country_select/countries";

import {
  updateSocialWallItemArray,
  updateSeoItem,
} from "../../services/api_service/components_update";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SocialWallItem from "../../components/social_wall_item/social_wall_item";
import SocialWallItemModel, {
  emptySocialWallItem,
} from "../../models/social_wall_item";
import SocialWallPageModel, {
  emptySocialWallPage,
} from "../../models/pages/social_wall_page";
import { socialWallPageGraph } from "../../services/api_service/graphql_queries";
import SEOComponent from "../../components/seo_component/seo_component";
export default function SocialWallPage() {
  const [pageData, setPageData] = useState<SocialWallPageModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, socialWallPageGraph, "socialWallPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new SocialWallPageModel(
              data.data ? data.data.page : emptySocialWallPage
            )
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageDataToSave: SocialWallPageModel) => {
    if (pageDataToSave) {
      setLoading(true);
      var dataToSend: any = pageDataToSave;
      dataToSend.social_wall_items = await updateSocialWallItemArray(
        pageDataToSave.social_wall_items
      );
      dataToSend.seo = await updateSeoItem(pageDataToSave.seo);

      await updateContentTypeCall(dataToSend, "social-wall-section-pages").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "social-wall-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const addNewSocialWall = () => {
    const prev = pageData;
    if (prev) {
      prev.social_wall_items.push(new SocialWallItemModel(emptySocialWallItem));
      setPageData({ ...prev });
    }
  };

  const removeSocialWall = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.social_wall_items.splice(index, 1);
      setPageData(new SocialWallPageModel(emptySocialWallPage));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.socialWall}
      parentRoutes={[routeNameObjects.sections]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />

          <AttributeItem title={"Social Wall"} onClick={addNewSocialWall}>
            {pageData.social_wall_items.map((content, index) => (
              <SocialWallItem
                key={index}
                socialWallItem={content}
                index={index}
                onRemove={removeSocialWall}
              />
            ))}
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
