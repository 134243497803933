import ContentModel from "./content_model";
import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class PodcastSection {
  id: number;
  title: MultiLanguage;
  contents: ContentModel[] = [];
  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title);
    data.contents.forEach((x: any) =>
      this.contents.push(new ContentModel(x))
    );
  }
}

export const emptyPodcastSection = {
  title: emptyMultiLang,
  contents: [],
};
