import { ArrowRight } from "@material-ui/icons";
import React from "react";
import { RouteNames } from "../../services/route_name/route_names";
import useStyles from "./show_path_styles";

interface Props {
  currentRoute: RouteNames;
  parentRoutes: RouteNames[];
}
export default function ShowPath(props: Props) {
  const classes = useStyles();

  return props.parentRoutes[0] === props.currentRoute ? (
    <div />
  ) : (
    <div className={classes.showCOntent}>
      {props.parentRoutes.map((item, index) => (
        <div key={index} className={classes.ab}>
          <span className={classes.text}>{item.name}</span>
          <ArrowRight className={classes.arrow} />
        </div>
      ))}
      <span className={classes.text}>{props.currentRoute.name}</span>
    </div>
  );
}
