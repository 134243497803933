import MultiLanguage, { emptyMultiLang } from "./multi_language"

export default class QuizModel {
    id:number
    question_image_url:string
    question_header: MultiLanguage
    question:MultiLanguage
    anwsers:MultiLanguage[] = []

    constructor(data:any){
        this.id=data.id
        this.question=new MultiLanguage(data.question ? data.question : emptyMultiLang)
        this.question_header=new MultiLanguage(data.question_header ? data.question_header : emptyMultiLang)
        this.question_image_url = data.question_image_url
        data.anwsers.forEach((x:any)=>this.anwsers.push(new MultiLanguage(x)))
    }
}

export const emptyQuizModel = {
    question: emptyMultiLang,
    question_header: emptyMultiLang,
    anwsers: []
}