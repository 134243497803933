import ContactUsComponentModel, {
  emptyContactUsComponent,
} from "../contact_us_component";
import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class ContactUsModel {
  id: number;
  header_image_url: string;
  header: MultiLanguage;
  content: ContentModel;
  contact_details: ContactUsComponentModel;
  agreement_text: MultiLanguage;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.header_image_url = data.header_image_url;
    this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang);
    this.contact_details = new ContactUsComponentModel(
      data.contact_details ? data.contact_details : emptyContactUsComponent
    );
    this.content = new ContentModel(data.content ? data.content : emptyContent);
    this.agreement_text = new MultiLanguage(
      data.agreement_text ? data.agreement_text : emptyMultiLang
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyContactUs = {
  header: emptyMultiLang,
  content: emptyContent,
  contact_details: emptyContactUsComponent,
  agreement_text: emptyMultiLang,
  seo: emptySEOComponent,
};
