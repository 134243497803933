import ContentModel, { emptyContent } from "../content_model";
import SEOComponent, {emptySEOComponent} from "./seo_component";
// import TimeLineElement from "../time_line_element";

export default class DilmahStory {
  id:number
  contents: ContentModel[] = [];
  title_content: ContentModel;
  // time_line: TimeLineElement[] = []
  video_url:string
  seo: SEOComponent
  constructor(data: any) {
    this.id=data.id
    data.contents.forEach((x: any) => this.contents.push(new ContentModel(x)));
    // data.time_line.forEach((x: any) => this.time_line.push(new TimeLineElement(x)));
    this.video_url = data.video_url
    this.title_content = new ContentModel(data.title_content?data.title_content:emptyContent)
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
  }
}


export const emptyDilmahStory = {
  contents:[],
  // time_line:[]
  title_content:emptyContent,
  seo: emptySEOComponent
}