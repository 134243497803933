import ContentModel, { emptyContent } from "../content_model";
import Slider from "../slider";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class OurValues {
  id: number;
  sliders: Slider[] = [];
  contents: ContentModel[] = [];
  title_content: ContentModel;
  video_content: ContentModel;
  seo: SEOComponent
  constructor(data: any) {
    this.id = data.id;
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    data.contents.forEach((x: any) => this.contents.push(new ContentModel(x)));
    this.title_content = new ContentModel(
      data.title_content ? data.title_content : emptyContent
    );
    this.video_content = new ContentModel(
      data.video_content ? data.video_content : emptyContent
    );
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
  }
}

export const emptyOurValues = {
  sliders: [],
  contents: [],
  title_content: emptyContent,
  video_content: emptyContent,
  seo: emptySEOComponent
};
