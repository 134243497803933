import { Card, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import TimeLineElement from "../../models/time_line_element";
import IconBtn from "../icon_btn/icon_btn";
import ImageList from "../image_list/image_list";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function TimeLineItem(props: {
  timeLineItem: TimeLineElement;
  index: number;
  onRemove: Function;
}) {
  const [timeLineItem, setTimeLineItem] = useState(props.timeLineItem);

  return (
    <Card elevation={5} style={{ marginTop: "10px", position: "relative" }}>
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />

      <TextField
        style={{ margin: "10px 10px 5px 10px", width: "200px" }}
        variant="outlined"
        label="Year"
        type="number"
        value={timeLineItem.year}
        onChange={(event) => {
          const text = event.target.value;
          setTimeLineItem((val) => ({ ...val, year: Number(text) }));
          props.timeLineItem.year = Number(text);
        }}
      />

      <MultiLangEdit label={"Title"} isShowLabel value={timeLineItem.title} />

      <MultiLangEdit
        label={"Content"}
        isShowLabel
        value={timeLineItem.content}
      />

      <ImageList images={timeLineItem.images} />
    </Card>
  );
}
