import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class ElixirOfCeylonTeaElixirRecipesModal {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header:MultiLanguage
    topic:MultiLanguage
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.topic = new MultiLanguage(data.topic?data.topic:emptyMultiLang)
    }
}

export const emptyElixirOfCeylonTeaElixirRecipes = {
    header:emptyMultiLang,
    topic:emptyMultiLang,
}