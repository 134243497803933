import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class SubContentSectionModal {
  id: number;
  has_header: boolean;
  header: MultiLanguage;
  contents: MultiLanguage[] = [];
  image_url: string;

  constructor(data: any) {
    this.id = data.id;
    this.image_url = data.image_url;
    this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang);
    data.contents.forEach((x: any) => this.contents.push(new MultiLanguage(x)));
    this.has_header = data.has_header;
  }
}

export const emptySubContentSection = {
  header: emptyMultiLang,
  contents: [],
  has_header: false,
};
