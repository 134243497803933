import { Action, action } from "easy-peasy";

export const MessageTypes = {
  message: "message",
  confirmation: "confirmation",
};

export interface Message {
  isOpen: boolean;
  head: string;
  body: string;
  type: string;
  color: string;
  onConfirm: Function;
  showMessage: Action<Message, { head: string; body: string; color?: string }>;
  showConfirmMessage: Action<
    Message,
    { head: string; body: string; onConfirm: Function; color?: string }
  >;
  hideMessage: Action<Message>;
  isSideMessageOpen: boolean;
  isSideMessageSuccess: boolean;
  setSideMessageOpen: Action<Message, { isSuccess: boolean }>;
  setSideMessageClose: Action<Message>;
}

const message: Message = {
  isOpen: false,
  head: "",
  body: "",
  type: MessageTypes.message,
  color: "black",
  onConfirm: () => {},
  showMessage: action((state, payload) => {
    state.type = MessageTypes.message;
    state.isOpen = true;
    state.color = payload.color ? payload.color : "black";
    state.head = payload.head;
    state.body = payload.body;
    state.onConfirm = () => {};
  }),
  showConfirmMessage: action((state, payload) => {
    state.type = MessageTypes.confirmation;
    state.isOpen = true;
    state.color = payload.color ? payload.color : "black";
    state.head = payload.head;
    state.body = payload.body;
    state.onConfirm = payload.onConfirm;
  }),
  hideMessage: action((state) => {
    state.isOpen = false;
    state.head = "";
    state.body = "";
    state.type = MessageTypes.message;
    state.onConfirm = () => {};
  }),
  isSideMessageOpen: false,
  isSideMessageSuccess: true,
  setSideMessageOpen: action((state, payload) => {
    state.isSideMessageOpen = true;
    state.isSideMessageSuccess = payload.isSuccess;
  }),
  setSideMessageClose: action((state) => {
    state.isSideMessageOpen = false;
  }),
};

export default message;
