import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import ContentItem from "../../components/content_item/content_item";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import ContentModel, { emptyContent } from "../../models/content_model";
import clonedeep from "lodash.clonedeep";
import DilmahStory, { emptyDilmahStory } from "../../models/pages/dilmah_story";
// import TimeLineItem from "../../components/time_line_item/time_line_item";
// import TimeLineElement, { emptyTimeLine } from "../../models/time_line_element";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateContentArray, updateContentItem, updateSeoItem,
  // updateTimeLineElementArray,
} from "../../services/api_service/components_update";
import { dilmahStoryGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import { TextField } from "@material-ui/core";
import SEOComponent from "../../components/seo_component/seo_component";

export default function DilmahStoryPage() {
  const [story, setStory] = useState<DilmahStory>();

  const { showSideMessage } = SideMessageService();

  const getDilmahStory = (country: string) => {
    getPageDataCall(country, dilmahStoryGraph, "dilmahStoryPages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setStory(undefined);
          setStory(
            new DilmahStory(data.data ? data.data.page : emptyDilmahStory)
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getDilmahStory(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (story: DilmahStory) => {
    if (story) {
      setLoading(true);
      var storyData: any = story;
      storyData.contents = await updateContentArray(story.contents);
      storyData.title_content = await updateContentItem(story.title_content)
      // storyData.time_line = await updateTimeLineElementArray(story.time_line);
      storyData.seo = await updateSeoItem(story.seo)

      await updateContentTypeCall(storyData, "dilmah-stories").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "dilmah-story-pages"
          ).then((data) => {
            if (data?.success) {
              getDilmahStory(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewContent = () => {
    const prev = story;
    if (prev) {
      prev.contents.push(new ContentModel(emptyContent));

      setStory({ ...prev });
    }
  };

  const removeContent = (index: number) => {
    const prev = story;
    if (prev) {
      prev.contents.splice(index, 1);
      setStory(new DilmahStory(emptyDilmahStory));
      setTimeout(() => {
        setStory({ ...prev });
      }, 0.01);
    }
  };

  // const addNewTimeLine = () => {
  //   const prev = story;
  //   if (prev) {
  //     prev.time_line.push(new TimeLineElement(emptyTimeLine));
  //     setStory({ ...prev });
  //   }
  // };

  // const removeTimeLine = (index: number) => {
  //   const prev = story;
  //   if (prev) {
  //     prev.time_line.splice(index, 1);
  //     setStory(new DilmahStory(emptyDilmahStory));
  //     setTimeout(() => {
  //       setStory({ ...prev });
  //     }, 0.01);
  //   }
  // };

  const onVideoUrlChange = (event: any) => {
    const prev = story;
    if (prev) {
      prev.video_url = event.target.value;
      setStory({ ...prev });
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.dilmahStory}
      parentRoutes={[routeNameObjects.familyPassion]}
    >
      {story ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(story))}
              />
            }
          />
          <AttributeItem title={"Title Content"}>
            <ContentItem content={story.title_content} />
          </AttributeItem>
          <AttributeItem title={"Video Url"}>
            <TextField
              fullWidth
              variant="outlined"
              value={story.video_url}
              onChange={onVideoUrlChange}
            />
          </AttributeItem>
          <AttributeItem title={"Content"} onClick={addNewContent}>
            {story.contents.map((content, index) => (
              <ContentItem
                key={index}
                content={content}
                index={index}
                onRemove={removeContent}
              />
            ))}
          </AttributeItem>

          {/* <AttributeItem title={"Time Line"} onClick={addNewTimeLine}>
            {story.time_line.map((timeLineItem, index) => (
              <TimeLineItem
                key={index}
                timeLineItem={timeLineItem}
                index={index}
                onRemove={removeTimeLine}
              />
            ))}
          </AttributeItem> */}
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={story.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
