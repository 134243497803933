import ContentModel, { emptyContent } from "../content_model";
import Slider from "../slider";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class SustainabilityModel{
    id:number
    sliders:Slider[]=[]
    contents:ContentModel[]=[]
    annual_report:ContentModel
    seo: SEOComponent
    constructor(data:any){
        this.id=data.id
        data.sliders.forEach((x:any)=>this.sliders.push(new Slider(x)))
        data.contents.forEach((x:any)=>this.contents.push(new ContentModel(x)))
        this.annual_report =  new ContentModel(data.annual_report ? data.annual_report : emptyContent) 
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptySustainability = {
    sliders:[],
    contents:[],
    annual_report:emptyContent,
    seo: emptySEOComponent
}