import HeaderWithContent, {
  emptyHeaderWithContent,
} from "../header_with_content";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import OurTeaCategoriesTeaByFlavors, {
  emptyOurTeaCategoriesTeaByFlavors,
} from "./our_tea_categories_tea_by_flavors";
import OurTeaCategoriesTeaByMoods, {
  emptyOurTeaCategoriesTeaByMoods,
} from "./our_tea_categories_tea_by_moods";
import OurTeaCategoriesTeaFormats, {
  emptyOurTeaCategoriesTeaFormats,
} from "./our_tea_categories_tea_formats";
import OurTeaCategoriesTeaRange, {
  emptyOurTeaCategoriesTeaRange,
} from "./our_tea_categories_tea_range";
import OurTeaCategoriesTeaTypes, {
  emptyOurTeaCategoriesTeaTypes,
} from "./our_tea_categories_tea_types";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class OurTeaCategories {
  id: number;
  title: MultiLanguage;
  header_image_url: string;
  header_descrpiton: HeaderWithContent;

  seo: SEOComponent;
  tea_ranges: OurTeaCategoriesTeaRange;
  tea_types: OurTeaCategoriesTeaTypes;
  tea_formats: OurTeaCategoriesTeaFormats;
  tea_by_flavors: OurTeaCategoriesTeaByFlavors;
  tea_by_moods: OurTeaCategoriesTeaByMoods;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.header_image_url = data.header_image_url;
    this.header_descrpiton = new HeaderWithContent(
      data.header_descrpiton ? data.header_descrpiton : emptyHeaderWithContent
    );
    this.tea_ranges = new OurTeaCategoriesTeaRange(
      data.tea_ranges ? data.tea_ranges : emptyOurTeaCategoriesTeaRange
    );
    this.tea_types = new OurTeaCategoriesTeaTypes(
      data.tea_types ? data.tea_types : emptyOurTeaCategoriesTeaTypes
    );
    this.tea_formats = new OurTeaCategoriesTeaFormats(
      data.tea_formats ? data.tea_formats : emptyOurTeaCategoriesTeaFormats
    );
    this.tea_by_flavors = new OurTeaCategoriesTeaByFlavors(
      data.tea_by_flavors
        ? data.tea_by_flavors
        : emptyOurTeaCategoriesTeaByFlavors
    );
    this.tea_by_moods = new OurTeaCategoriesTeaByMoods(
      data.tea_by_moods ? data.tea_by_moods : emptyOurTeaCategoriesTeaByMoods
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyOurTeaCategories = {
  title: emptyMultiLang,
  header_descrpiton: emptyHeaderWithContent,
  tea_ranges: emptyOurTeaCategoriesTeaRange,
  tea_types: emptyOurTeaCategoriesTeaTypes,
  tea_formats: emptyOurTeaCategoriesTeaFormats,
  tea_by_flavors: emptyOurTeaCategoriesTeaByFlavors,
  tea_by_moods: emptyOurTeaCategoriesTeaByMoods,
  seo: emptySEOComponent,
};
