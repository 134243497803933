import { Action, action } from "easy-peasy";

export interface TokenModel {
  token: string | null;
  setToken: Action<TokenModel, string | null>;
  removeToken: Action<TokenModel>;
}

const tokenModel: TokenModel = {
  token: sessionStorage.getItem("dilmah_admin_token"),
  setToken: action((state, token) => {
    sessionStorage.setItem("dilmah_admin_token", token ? token : '');
    state.token = token;
  }),
  removeToken: action((state) => {
    sessionStorage.clear();
    state.token = null;
  }),
};

export default tokenModel;
