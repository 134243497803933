import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class TeaBlogCategoryModal {
  id: number;
  name: MultiLanguage;

  constructor(data: any) {
    this.id = data.id;
    this.name = new MultiLanguage(data.name ? data.name : emptyMultiLang);
  }
}

export const emptyTeaBlogCategory = {
  name: emptyMultiLang,
};
