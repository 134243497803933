import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import DilmahTradeFineDining, {
  emptyDilmahTradeFineDining,
} from "./dilmah_trade_fine_dining";
import DilmahTradeFineFoodAndGourmet, {
  emptyDilmahTradeFineFoodAndGourmet,
} from "./dilmah_trade_fine_food_and_gourmet";
import DilmahTradeHighQualityCasualDining, {
  emptyDilmahTradeHighQualityCasualDining,
} from "./dilmah_trade_high_quality_casual_dining";
import DilmahTradePremiumRetailers, {
  emptyDilmahTradePremiumRetailers,
} from "./dilmah_trade_premium_retailers";
import DilmahTradeOurProducts, {
  emptyDilmahTradeOurProducts,
} from "./dilmah_trade_our_products";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class DilmahTrade {
  id: number;
  title: MultiLanguage;
  banner_image_url: string;
  our_product: DilmahTradeOurProducts;
  premium_retailers: DilmahTradePremiumRetailers;
  fine_food_and_gourmet: DilmahTradeFineFoodAndGourmet;
  high_quality_casual_dining: DilmahTradeHighQualityCasualDining;
  fine_dining: DilmahTradeFineDining;
  header_content: ContentModel;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.banner_image_url = data.banner_image_url;
    this.our_product = new DilmahTradeOurProducts(
      data.our_product ? data.our_product : emptyDilmahTradeOurProducts
    );
    this.premium_retailers = new DilmahTradePremiumRetailers(
      data.premium_retailers
        ? data.premium_retailers
        : emptyDilmahTradePremiumRetailers
    );
    this.fine_food_and_gourmet = new DilmahTradeFineFoodAndGourmet(
      data.fine_food_and_gourmet
        ? data.fine_food_and_gourmet
        : emptyDilmahTradeFineFoodAndGourmet
    );
    this.high_quality_casual_dining = new DilmahTradeHighQualityCasualDining(
      data.high_quality_casual_dining
        ? data.high_quality_casual_dining
        : emptyDilmahTradeHighQualityCasualDining
    );
    this.fine_dining = new DilmahTradeFineDining(
      data.fine_dining ? data.fine_dining : emptyDilmahTradeFineDining
    );
    this.header_content = new ContentModel(
      data.header_content ? data.header_content : emptyContent
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyDilmahTrade = {
  title: emptyMultiLang,
  our_product: emptyDilmahTradeOurProducts,
  premium_retailers: emptyDilmahTradePremiumRetailers,
  fine_food_and_gourmet: emptyDilmahTradeFineFoodAndGourmet,
  high_quality_casual_dining: emptyDilmahTradeHighQualityCasualDining,
  fine_dining: emptyDilmahTradeFineDining,
  header_content: emptyContent,
  seo: emptySEOComponent,
};
