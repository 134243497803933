import ContentModel from "../content_model";
import Slider from "../slider";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class TeaInFiveSensesModel {
  id: number;
  sliders: Slider[] = [];
  contents: ContentModel[] = [];
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.sliders.forEach((x: any) => this.sliders.push(new Slider(x)));
    data.contents.forEach((x: any) => this.contents.push(new ContentModel(x)));
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyTeaInFiveSenses = {
  sliders: [],
  contents: [],
  seo: emptySEOComponent,
};
