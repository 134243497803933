import MultiLanguage, { emptyMultiLang } from "./multi_language";

export default class QuoteContentModel {
  id: number;
  image_url: string;
  header: MultiLanguage;
  content: MultiLanguage;
  link_text: MultiLanguage;
  link: string;
  has_header: boolean;
  has_link: boolean;
  imageFile: any;
  has_sub_heading:boolean;
  sub_heading:MultiLanguage;
  quote:MultiLanguage;
  author: MultiLanguage;
  has_video:boolean;
  video_url:string

  constructor(data: any) {
    this.id = data.id;
    this.image_url = data.image_url;
    this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang);
    this.content = new MultiLanguage(data.content);
    this.link_text = new MultiLanguage(
      data.link_text ? data.link_text : emptyMultiLang
    );
    this.link = data.link;
    this.has_header = data.has_header;
    this.has_link = data.has_link;
    this.has_sub_heading = data.has_sub_heading
    this.sub_heading = new MultiLanguage(data.sub_heading ? data.sub_heading : emptyMultiLang);
    this.quote = new MultiLanguage(data.quote ? data.quote : emptyMultiLang);
    this.author = new MultiLanguage(data.author ? data.author : emptyMultiLang);
    this.has_video = data.has_video;
    this.video_url=data.video_url
  }
}

export const emptyQuoteContent = {
  image_url: "",
  header: emptyMultiLang,
  content: emptyMultiLang,
  link_text: emptyMultiLang,
  link: "",
  has_header: false,
  has_link: false,
  has_sub_heading:false,
  quote: emptyMultiLang,
  author: emptyMultiLang,
  sub_heading:emptyMultiLang,
  has_video:false
};
