export const countries = [
    {
        label:'Global',
        value:'GLOBAL'
    },
    {
        label:'Australia',
        value:'AU'
    },
    {
        label:'Netherland',
        value:'NL'
    },
    {
        label:'Singapore',
        value:'SG'
    },
    {
        label:'United Kingdom',
        value:'GB'
    },
    {
        label:'Ukraine',
        value:'UA'
    },
    {
        label:'Moldova',
        value:'MD'
    },
    {
        label:'Russia',
        value:'RU'
    },
    {
        label:'Lituania',
        value:'LT'
    },
    {
        label:'New Zealand',
        value:'NZ'
    },
    {
        label:'Hungary',
        value:'HU'
    },
    {
        label:'China',
        value:'CN'
    },
    {
        label:'South Africa',
        value:'ZA'
    },
    {
        label:'Thailand',
        value:'TH'
    },
    {
        label:'Sweden',
        value:'SE'
    },
    {
        label:'Canada',
        value:'CA'
    },
    {
        label:'USA',
        value:'US'
    },
    {
        label:'France',
        value:'FR'
    },
    {
        label:'MENA',
        value:'SA'
    },
    
]