import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";

export default class TeamakersPrivateReserveTeaModel {
    id: number
    header_image_url: string
    sub_header_image_url: string
    sub_content:ContentModel
    header:MultiLanguage
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.sub_header_image_url = data.sub_header_image_url
        this.header = new MultiLanguage(data.header?data.header:emptyMultiLang)
        this.sub_content = new ContentModel(data.sub_content?data.sub_content:emptyContent)
    }
}

export const emptyTemakersPrivateReserveTea = {
    sub_content:emptyContent,
    header:emptyMultiLang,
}