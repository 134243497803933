import React from "react";
import "./App.css";
import { StoreProvider } from "easy-peasy";
import store from "./store models/store_model";
import Routings from "./routings/routings";
import MyLoader from "./components/my_loader/my_loader";
import MyDialogBox from "./components/dialog_box/dialog_box";
import SideMessage from "./components/side_message/side_message";

function App() {
  return (
    <StoreProvider store={store}>
      <Routings />
      <MyLoader />
      <MyDialogBox />
      <SideMessage />
    </StoreProvider>
  );
}

export default App;
