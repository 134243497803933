import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class BannerCustomizationModel {
  id: number;

  audio_list_header_image_url: string;
  audio_title: MultiLanguage;
  audio_seo: SEOComponent;

  video_list_header_image_url: string;
  video_title: MultiLanguage;
  video_seo: SEOComponent;

  image_list_header_image_url: string;
  image_title: MultiLanguage;
  image_seo: SEOComponent;

  blog_list_header_image_url: string;
  blog_title: MultiLanguage;

  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;

    this.audio_list_header_image_url = data.audio_list_header_image_url;
    this.audio_title = new MultiLanguage(
      data.audio_title ? data.audio_title : emptyMultiLang
    );

    this.video_list_header_image_url = data.video_list_header_image_url;
    this.video_title = new MultiLanguage(
      data.video_title ? data.video_title : emptyMultiLang
    );

    this.image_list_header_image_url = data.image_list_header_image_url;
    this.image_title = new MultiLanguage(
      data.image_title ? data.image_title : emptyMultiLang
    );

    this.blog_list_header_image_url = data.blog_list_header_image_url;
    this.blog_title = new MultiLanguage(
      data.blog_title ? data.blog_title : emptyMultiLang
    );

    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);

    this.audio_seo = new SEOComponent(data.audio_seo ? data.audio_seo : emptySEOComponent);
    this.video_seo = new SEOComponent(data.video_seo ? data.video_seo : emptySEOComponent);
    this.image_seo = new SEOComponent(data.image_seo ? data.image_seo : emptySEOComponent);
  }
}

export const emptyBannerCustomizationModel = {
  audio_title: emptyMultiLang,
  video_title: emptyMultiLang,
  image_title: emptyMultiLang,
  blog_title: emptyMultiLang,
  seo: emptySEOComponent,
  audio_seo: emptySEOComponent,
  video_seo: emptySEOComponent,
  image_seo: emptySEOComponent,
};
