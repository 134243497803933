import { Card } from "@material-ui/core";
import React from "react";
import TeaBlogCategoryModal from "../../models/tea_blog_category";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function DilmahTeaBlogCategoryItem(props: {
  category: TeaBlogCategoryModal;
}) {
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <MultiLangEdit isShowLabel label="Name" value={props.category.name} />
    </Card>
  );
}
