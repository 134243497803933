import loader, { Loader } from "./loader";
import tokenModel, { TokenModel } from "./token";
import message, { Message } from "./message";
import currentData, { CurrentData } from "./current_data";

export interface StoreModel {
  loader: Loader;
  tokenModel: TokenModel;
  message: Message;
  currentData: CurrentData;
}

const model: StoreModel = {
  loader,
  tokenModel,
  message,
  currentData
};

export default model;
