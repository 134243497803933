import { Card, TextField } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import EBookElement from "../../models/e_book_element";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import ImageList from "../image_list/image_list";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function EBookItemComponent(props: {
  content: EBookElement;
  index: number;
  onRemove: Function;
}) {
  const [content, setContent] = useState(props.content);

  const getImageUrl = (url: string) => {
    props.content.image_url = url;
  };
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      <IconBtn
        icon={Remove}
        onClick={() => {
          props.onRemove(props.index);
        }}
      />
      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={content.image_url}
          mediaType={"IMAGE"}
          getFileUrl={getImageUrl}
        />
      </div>

      <MultiLangEdit label={"Title"} isShowLabel value={content.title} />

      <MultiLangEdit label={"Content"} isShowLabel value={content.content} />

      <TextField
        style={{ margin: "5px 1%", width: "98%" }}
        variant="outlined"
        label="Book Url"
        value={content.book_url}
        onChange={(event) => {
          const text = event.target.value;
          setContent((val) => ({ ...val, book_url: text }));
          props.content.book_url = text;
        }}
      />
      <ImageList images={props.content.book_images} />
    </Card>
  );
}
