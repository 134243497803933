import ImageWithLink from "../image_with_link";

export default class DilmahTradeOurProducts {
    id: number;
    banner_image_url: string;
    our_products_items: ImageWithLink[]=[];

    constructor(data:any){
        this.id = data.id;
        this.banner_image_url = data.banner_image_url;
        data.our_products_items.forEach((x:any) => this.our_products_items.push(new ImageWithLink(x)));
    }
}

export const emptyDilmahTradeOurProducts = {
    our_products_items:[],
}