import { Card} from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import React, { useState } from "react";
import ElixirItem from "../../models/elixir_item";
import IconBtn from "../icon_btn/icon_btn";
import IconUploadBtn from "../icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../multi_lang_edit/multi_lang_edit";

export default function ElixirItemComponent(props: {
  item: ElixirItem;
  index?: number;
  onRemove?: Function;
}) {

  const [imageUrl, setUrl] = useState(props.item.image_url);
  const getImageUrl = (url: string) => {
    props.item.image_url = url;
    setUrl(url)
  };
  return (
    <Card
      elevation={5}
      style={{ marginTop: "10px", position: "relative", paddingTop: "30px" }}
    >
      {props.onRemove && (
        <IconBtn
          icon={Remove}
          onClick={() => {
            if (props.onRemove && props.index != null) {
              props.onRemove(props.index);
            }
          }}
        />
      )}

      <div style={{ textAlign: "center" }}>
        <IconUploadBtn
          height={150}
          isEditable
          mediaUrl={imageUrl}
          mediaType={"IMAGE"}
          getFileUrl={getImageUrl}
        />
      </div>
      
      <MultiLangEdit label={"Title"} isShowLabel value={props.item.title} />
      <MultiLangEdit label={"Description"} isShowLabel value={props.item.description} />
    
    </Card>
  );
}
