import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import { bannerCustormzationGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import BannerCustomizationModel, {
  emptyBannerCustomizationModel,
} from "../../models/pages/banner_custormization";
import {
  updateMultiLangItem,
  updateSeoItem,
} from "../../services/api_service/components_update";
import SEOComponent from "../../components/seo_component/seo_component";

export default function BannerCustomizationPage() {
  const [pageData, setPageData] = useState<BannerCustomizationModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(
      country,
      bannerCustormzationGraph,
      "bannerCustomizationPages"
    ).then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new BannerCustomizationModel(
            data.data ? data.data.page : emptyBannerCustomizationModel
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: BannerCustomizationModel) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.audio_title = await updateMultiLangItem(pageData.audio_title);
      dataToSave.video_title = await updateMultiLangItem(pageData.video_title);
      dataToSave.image_title = await updateMultiLangItem(pageData.image_title);
      dataToSave.blog_title = await updateMultiLangItem(pageData.blog_title);
      dataToSave.seo = await updateSeoItem(pageData.seo);
      dataToSave.audio_seo = await updateSeoItem(pageData.audio_seo);
      dataToSave.video_seo = await updateSeoItem(pageData.video_seo);
      dataToSave.image_seo = await updateSeoItem(pageData.image_seo);

      await updateContentTypeCall(dataToSave, "banner-customizations").then(
        (data) => {
          if (data?.success) {
            updateContentTypeCall(
              {
                id: currentPageId,
                country: selectedCountry.value,
                page: data.data.id,
              },
              "banner-customization-pages"
            ).then((data) => {
              if (data?.success) {
                getPageData(selectedCountry.value);
                showSideMessage(true);
              } else {
                showSideMessage(false);
              }
            });
          }
        }
      );
    }
  };

  const setAudioHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.audio_list_header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setVideoHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.video_list_header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setImageHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.image_list_header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setBlogHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.blog_list_header_image_url = url;
      setPageData({ ...prev });
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.bannerCustormzation}
      parentRoutes={[routeNameObjects.media]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title="Audio Listing Page">
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setAudioHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.audio_list_header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.audio_title} />
            </AttributeItem>
            <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.audio_seo} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title="Video Listing Page">
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setVideoHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.video_list_header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.video_title} />
            </AttributeItem>
            <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.video_seo} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title="Image Listing Page">
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setImageHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.image_list_header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.image_title} />
            </AttributeItem>
            <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={pageData.image_seo} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title="Blog Listing Page">
            <AttributeItem title={"Header Image"}>
              <div style={{ textAlign: "center" }}>
                <IconUploadBtn
                  getFileUrl={setBlogHeaderImage}
                  mediaType="IMAGE"
                  mediaUrl={pageData.blog_list_header_image_url}
                  height={150}
                  isEditable
                />
              </div>
            </AttributeItem>
            <AttributeItem title={"Title"}>
              <MultiLangEdit value={pageData.blog_title} />
            </AttributeItem>
          </AttributeItem>

          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
