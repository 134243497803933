import ContentModel, { emptyContent } from "../content_model";
import ExploreDilmahItemModel from "../explore_dilmah_item";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import NaturalInfusionEveryMoodModal, { emptyNaturalInfusionEveryMood } from "./natural_infusion_every_mood";
import NaturalInfusionOurInfusionsModal, { emptyNaturalInfusionOurInfusions } from "./natural_infusion_our_infusions";
import NaturalInfusionOurIngredientsModal, { emptyNaturalInfusionOurIngredients } from "./natural_infusion_our_ingredients";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class NaturalInfusionModel {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header: MultiLanguage
    content: ContentModel
    links: ExploreDilmahItemModel[] = []
    seo: SEOComponent
    our_infusions:NaturalInfusionOurInfusionsModal
    our_ingredients:NaturalInfusionOurIngredientsModal
    for_every_mood:NaturalInfusionEveryMoodModal
    
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.content = new ContentModel(data.content ? data.content : emptyContent)
        data.links.forEach((x: any) => this.links.push(new ExploreDilmahItemModel(x)))
        this.our_infusions = new NaturalInfusionOurInfusionsModal(data.our_infusions?data.our_infusions:emptyNaturalInfusionOurInfusions)
        this.our_ingredients = new NaturalInfusionOurIngredientsModal(data.our_ingredients?data.our_ingredients:emptyNaturalInfusionOurIngredients)
        this.for_every_mood = new NaturalInfusionEveryMoodModal(data.for_every_mood?data.for_every_mood:emptyNaturalInfusionEveryMood)
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyNaturalInfusion = {
    content: emptyContent,
    header: emptyMultiLang,
    links: [],
    our_infusions:emptyNaturalInfusionOurInfusions,
    our_ingredients:emptyNaturalInfusionOurIngredients,
    for_every_mood:emptyNaturalInfusionEveryMood,
    seo: emptySEOComponent
}