import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import ContentModel, { emptyContent } from "../../models/content_model";
import ContentItem from "../../components/content_item/content_item";
import LeisureAndHospitality, {
  emptyLeisureAndHospitality,
} from "../../models/pages/leisure_and_hospitality";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import { updateContentArray, updateSeoItem } from "../../services/api_service/components_update";
import { leisureAndHospitalityGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";

export default function LeisureAndHospitalityPage() {
  const [pageData, setPageData] = useState<LeisureAndHospitality>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, leisureAndHospitalityGraph, 'leisureAndHospitalityPages').then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setPageData(undefined);
        setPageData(
          new LeisureAndHospitality(
            data.data ? data.data.page : emptyLeisureAndHospitality
          )
        );
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: LeisureAndHospitality) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData
      dataToSave.contents = await updateContentArray(pageData.contents)
      dataToSave.seo = await updateSeoItem(pageData.seo)

      await updateContentTypeCall(dataToSave, 'leisure-and-hospitalities').then((data) => {
        if (data?.success) {
          updateContentTypeCall({
            id: currentPageId,
            country: selectedCountry.value,
            page: data.data.id,
          }, 'leisure-and-hospitality-pages').then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewContent = () => {
    const prev = pageData;
    if (prev) {
      prev.contents.push(new ContentModel(emptyContent));
      setPageData({ ...prev });
    }
  };

  const removeContent = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.contents.splice(index, 1);
      setPageData(new LeisureAndHospitality(emptyLeisureAndHospitality));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };
  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.leisureAndHospitality}
      parentRoutes={[routeNameObjects.familyPassion]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />


          <AttributeItem title={"Content"} onClick={addNewContent}>
            {pageData.contents.map((content, index) => (
              <ContentItem
                key={index}
                content={content}
                index={index}
                onRemove={removeContent}
                hasLogo={true}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo} />
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
