import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
    getPageDataCall,
    updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
    updateSeoItem,
    updateContentItem,
    updateMultiLangItem,
    updateCeylonIcedTeaGlassBottle,
    updateCeylonIcedTeaTetraPack
} from "../../services/api_service/components_update";
import { ceylonIcedTeaGraph } from "../../services/api_service/graphql_queries";
import CeylonIcedTeaModel, { emptyCeylonIcedTeaModel } from '../../models/pages/ceylon_iced_tea'
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import IconUploadBtn from '../../components/icon_upload_btn/icon_upload_btn'
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import SEOComponent from "../../components/seo_component/seo_component";

export default function CeylonIcedTea() {
    const [pageData, setPageData] = useState<CeylonIcedTeaModel>();

    const { showSideMessage } = SideMessageService();

    const getPageData = (country: string) => {
        getPageDataCall(
            country,
            ceylonIcedTeaGraph,
            "ceylonIcedTeaPages"
        ).then((data) => {
            if (data?.success) {
                setCurrentPageId(data.data?.id);
                setPageData(undefined);
                setPageData(
                    new CeylonIcedTeaModel(
                        data.data ? data.data.page : emptyCeylonIcedTeaModel
                    )
                );
            }
        });
    };

    const [currentPageId, setCurrentPageId] = useState();
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const onChangeCountry = (selected: { label: string; value: string }) => {
        setSelectedCountry(selected);
    };

    useEffect(() => {
        getPageData(selectedCountry.value);
    }, [selectedCountry]);
    const setLoading = useStoreActions(
        (actions: Actions<StoreModel>) => actions.loader.showLoader
    );
    const onSave = async (pageData: CeylonIcedTeaModel) => {
        if (pageData) {
            setLoading(true);
            const dataToSave: any = pageData;
            dataToSave.title = await updateMultiLangItem(pageData.title);
            dataToSave.content_1 = await updateContentItem(pageData.content_1);
            dataToSave.seo = await updateSeoItem(pageData.seo)

            dataToSave.glass_bottle = await updateCeylonIcedTeaGlassBottle(pageData.glass_bottle);
            dataToSave.tetra_pack = await updateCeylonIcedTeaTetraPack(pageData.tetra_pack);


            await updateContentTypeCall(dataToSave, "ceylon-iced-teas").then(
                (data) => {
                    if (data?.success) {
                        updateContentTypeCall(
                            {
                                id: currentPageId,
                                country: selectedCountry.value,
                                page: data.data.id,
                            },
                            "ceylon-iced-tea-pages"
                        ).then((data) => {
                            if (data?.success) {
                                getPageData(selectedCountry.value);
                                showSideMessage(true);
                            } else {
                                showSideMessage(false);
                            }
                        });
                    }
                }
            );
        }
    };


    const setHeaderImageGlassBottle = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.glass_bottle.header_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setBannerImageGlassBottle = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.glass_bottle.banner_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setIntroImageGlassBottle = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.glass_bottle.intro_image_url = url;
            setPageData({ ...prev });
        }
    };


    const setHeaderImageTetraPack = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.tetra_pack.header_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setBannerImageTetraPack = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.tetra_pack.banner_image_url = url;
            setPageData({ ...prev });
        }
    };

    const setIntroImageTetraPack = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.tetra_pack.intro_image_url = url;
            setPageData({ ...prev });
        }
    };


    const setBannerImage = (url: string) => {
    const prev = pageData;
    if (prev) {
        prev.banner_image_url = url;
        setPageData({ ...prev });
    }
    };

    const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
        prev.header_image_url = url;
        setPageData({ ...prev });
    }
    };

    const setIntroImage = (url: string) => {
        const prev = pageData;
        if (prev) {
            prev.intro_image_url = url;
            setPageData({ ...prev });
        }
    };

    return (
        <CommonPage
            currentRoute={nestedRouteNameObject1.dilmahCeylonIcedTea}
            parentRoutes={[routeNameObjects.teaRanges]}
        >
            {pageData ? (
                <div>
                    <CountrySelect
                        selectedCountry={selectedCountry}
                        onCountryChange={onChangeCountry}
                        addBtn={
                            <AddItemBtn
                                title={"Save"}
                                onClick={() => onSave(clonedeep(pageData))}
                            />
                        }
                    />

                    <AttributeItem title="Ceylon Iced Tea">
                        <AttributeItem title={"Banner Image"}>
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setBannerImage}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.banner_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title={"Header Image"}>
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setHeaderImage}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.header_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Intro Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setIntroImage}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.intro_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title={"Title"}>
                            <MultiLangEdit
                                value={pageData.title}
                            />
                        </AttributeItem>
                    </AttributeItem>

                    <AttributeItem title="Ceylon Iced Tea Glass Bottle">
                        <AttributeItem title="Header Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setHeaderImageGlassBottle}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.glass_bottle.header_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Banner Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setBannerImageGlassBottle}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.glass_bottle.banner_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Intro Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setIntroImageGlassBottle}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.glass_bottle.intro_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title={"Title"}>
                            <MultiLangEdit value={pageData.glass_bottle.title} />
                        </AttributeItem>
                        <AttributeItem title={"Description"}>
                            <MultiLangEdit value={pageData.glass_bottle.description} />
                        </AttributeItem>
                    </AttributeItem>

                    <AttributeItem title="Ceylon Iced Tea Tetra">
                        <AttributeItem title="Header Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setHeaderImageTetraPack}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.tetra_pack.header_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Banner Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setBannerImageTetraPack}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.tetra_pack.banner_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title="Intro Image">
                            <div style={{ textAlign: "center" }}>
                                <IconUploadBtn
                                    getFileUrl={setIntroImageTetraPack}
                                    mediaType="IMAGE"
                                    mediaUrl={pageData.tetra_pack.intro_image_url}
                                    height={150}
                                    isEditable
                                />
                            </div>
                        </AttributeItem>
                        <AttributeItem title={"Title"}>
                            <MultiLangEdit value={pageData.tetra_pack.title} />
                        </AttributeItem>
                        <AttributeItem title={"Description"}>
                            <MultiLangEdit value={pageData.tetra_pack.description} />
                        </AttributeItem>
                    </AttributeItem>

                    <AttributeItem title={"SEO Component"}>
                        <SEOComponent seoItem={pageData.seo}/>
                    </AttributeItem>
                </div>
            ) : (
                <MyLoader />
            )}
        </CommonPage>
    );

    // const setHeaderImage = (url: string) => {
    //     const prev = pageData;
    //     if (prev) {
    //         prev.header_image_url = url;
    //         setPageData({ ...prev });
    //     }
    // };

    // const setBannerImage = (url: string) => {
    //     const prev = pageData;
    //     if (prev) {
    //         prev.banner_image_url = url;
    //         setPageData({ ...prev });
    //     }
    // };

    // const setIntroImage = (url: string) => {
    //     const prev = pageData;
    //     if (prev) {
    //         prev.intro_image_url = url;
    //         setPageData({ ...prev });
    //     }
    // };

    // return (
    //     <CommonPage
    //         currentRoute={nestedRouteNameObject1.dilmahCeylonIcedTea}
    //         parentRoutes={[routeNameObjects.teaRanges]}
    //     >
    //         {pageData ? (
    //             <div>
    //                 <CountrySelect
    //                     selectedCountry={selectedCountry}
    //                     onCountryChange={onChangeCountry}
    //                     addBtn={
    //                         <AddItemBtn
    //                             title={"Save"}
    //                             onClick={() => onSave(clonedeep(pageData))}
    //                         />
    //                     }
    //                 />
    //                 <AttributeItem title={"Banner Image"}>
    //                     <div style={{ textAlign: "center" }}>
    //                         <IconUploadBtn
    //                             getFileUrl={setBannerImage}
    //                             mediaType="IMAGE"
    //                             mediaUrl={pageData.banner_image_url}
    //                             height={150}
    //                             isEditable
    //                         />
    //                     </div>
    //                 </AttributeItem>
    //                 <AttributeItem title={"Header Image"}>
    //                     <div style={{ textAlign: "center" }}>
    //                         <IconUploadBtn
    //                             getFileUrl={setHeaderImage}
    //                             mediaType="IMAGE"
    //                             mediaUrl={pageData.header_image_url}
    //                             height={150}
    //                             isEditable
    //                         />
    //                     </div>
    //                 </AttributeItem>
    //                 <AttributeItem title={"Intro Image"}>
    //                     <div style={{ textAlign: "center" }}>
    //                         <IconUploadBtn
    //                             getFileUrl={setIntroImage}
    //                             mediaType="IMAGE"
    //                             mediaUrl={pageData.intro_image_url}
    //                             height={150}
    //                             isEditable
    //                         />
    //                     </div>
    //                 </AttributeItem>
    //                 <AttributeItem title={"Topic"}>
    //                     <MultiLangEdit
    //                         value={pageData.title}
    //                     />
    //                 </AttributeItem>
    //                 <AttributeItem title={"SEO Component"}>
    //                     <SEOComponent seoItem={pageData.seo} />
    //                 </AttributeItem>
    //             </div>
    //         ) : (
    //             <MyLoader />
    //         )}
    //     </CommonPage>
    // );
}