import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import { TextField } from "@material-ui/core";
export default function ImageUploadPage() {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl("");
  }, []);

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.imageUpload}
      parentRoutes={[routeNameObjects.other]}
    >
      <div>
        <AttributeItem title={"Image"}>
          <div style={{ textAlign: "center" }}>
            <IconUploadBtn
              getFileUrl={(url: any) => {
                setUrl(url);
              }}
              mediaType="IMAGE"
              mediaUrl={url}
              height={150}
              isEditable
            />
          </div>
        </AttributeItem>
        <AttributeItem title={'Image Url'} >
          <TextField
            style={{ margin: " 20px 1%", width: "98%" }}
            fullWidth
            variant="outlined"
            value={url}
            InputProps={{ readOnly: true }}
          />
        </AttributeItem>
      </div>
    </CommonPage>
  );
}
