import L from 'leaflet';

export const VenueLocationIcon = L.icon({
  iconUrl: '/images/marker.png',
  iconRetinaUrl: '/images/marker.png',
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: 'leaflet-venue-icon'
});
