import React, {useRef} from "react";
import { Box, Container, CssBaseline } from "@material-ui/core";
import useStyles from "./common_page_style";
import MyAppBar from "../app_bar/app_bar";
import MyDrawer from "../drawer/my_drawer";
import { RouteNames } from "../../services/route_name/route_names";
import PageName from "../page_name/page_name";
import ShowPath from "../show_path/show_path";
import IconBtn from '../icon_btn/icon_btn'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

interface Props {
  children: any;
  currentRoute: RouteNames;
  parentRoutes: RouteNames[];
}

export default function CommonPage(props: Props) {
  const classes = useStyles();
  const divRef = useRef<HTMLDivElement>(null);

  const handleTopScroll = () => {
    if( divRef.current !== null){
      divRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MyAppBar
        currentRoute={props.currentRoute}
        parentRoutes={props.parentRoutes}
      />
      <MyDrawer currentRoute={props.currentRoute.route} />
      <main className={classes.content}>
      <div ref={divRef}/>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <ShowPath
            currentRoute={props.currentRoute}
            parentRoutes={props.parentRoutes}
          />
          <PageName title={props.currentRoute.name} />
          <Box height={'10px'} />
          {props.children}
        </Container>
        <div style={{
            position: 'fixed',
            margin: "10px",
            bottom: '20px',
            right: '30px',
            display: 'block',
            color: 'white',
            zIndex: 99,
            marginBottom: '20px'
        }}>
          <IconBtn icon={ArrowUpwardIcon} onClick={handleTopScroll} isCenter={true}/>
        </div>
        
      </main>
    </div>
  );
}
