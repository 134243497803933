import EBookElement from "../e_book_element";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class EBook {
  id: number;
  title: MultiLanguage;
  e_book_elements: EBookElement[] = [];
  header_description: MultiLanguage;
  header_image_url: string;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    this.title = new MultiLanguage(data.title);
    data.e_book_elements.forEach((x: any) =>
      this.e_book_elements.push(new EBookElement(x))
    );
    this.header_description = new MultiLanguage(data.header_description);
    this.header_image_url = data.header_image_url;
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyEBook = {
  title: emptyMultiLang,
  header_description: emptyMultiLang,
  e_book_elements: [],
  seo: emptySEOComponent,
};
