import { Backdrop } from "@material-ui/core";
import { DoneRounded, WarningRounded } from "@material-ui/icons";
import { animated, useSpring } from "react-spring";
import React from "react";
import { useStoreState } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import useStyles from "./side_message_style";

export default function SideMessage() {
  const classes = useStyles();
  const isSideBarOpen = useStoreState(
    (state: StoreModel) => state.message.isSideMessageOpen
  );
  const isSideBarSuccess = useStoreState(
    (state: StoreModel) => state.message.isSideMessageSuccess
  );
  const messageAnimation = useSpring({
    transition: "0.3s",
    opacity: 1,
    from: { opacity: 0 },
  });

  return (
    <Backdrop
      open={isSideBarOpen}
      style={{ backgroundColor: "transparent", zIndex: 100 }}
    >
      <animated.div style={messageAnimation}>
        <div className={classes.sideMessage}>
          {isSideBarSuccess ? (
            <DoneRounded
              className={classes.successIcon}
              style={{
                backgroundColor: "green",
              }}
            />
          ) : (
            <WarningRounded
              className={classes.successIcon}
              style={{
                backgroundColor: "red",
              }}
            />
          )}
          <div className={classes.message}>
            {isSideBarSuccess ? "Successfully Updated" : "Something went wrong"}
          </div>
        </div>
      </animated.div>
    </Backdrop>
  );
}
