import ContentModel, { emptyContent } from "../content_model";
import ExploreDilmahItemModel from "../explore_dilmah_item";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import TSeriesGourmetRareTeas, { emptyTSeriesGourmetRareTea } from "./t_series_gourmet_rare_teas";
import TSeriesGourmetSeasonalFlush, { emptyTSeriesGourmetSeasonalFlush } from "./t_series_gourmet_seasonal_flush";
import TSeriesGourmetTeas, { emptyTSeriesGourmetTeas } from "./t_series_gourmet_teas";
import SEOComponent, {emptySEOComponent} from "./seo_component";

export default class TSeriesGourmetModel {
    id: number
    header_image_url: string
    header_sub_image_url: string
    header: MultiLanguage
    content: ContentModel
    links: ExploreDilmahItemModel[] = []
    gourmet_teas: TSeriesGourmetTeas
    very_special_rare_teas: TSeriesGourmetRareTeas
    seasonal_flush: TSeriesGourmetSeasonalFlush
    seo: SEOComponent
    
    constructor(data: any) {
        this.id = data.id
        this.header_image_url = data.header_image_url
        this.header_sub_image_url = data.header_sub_image_url
        this.header = new MultiLanguage(data.header ? data.header : emptyMultiLang)
        this.content = new ContentModel(data.content ? data.content : emptyContent)
        data.links.forEach((x: any) => this.links.push(new ExploreDilmahItemModel(x)))
        this.gourmet_teas = new TSeriesGourmetTeas(data.gourmet_teas ? data.gourmet_teas : emptyTSeriesGourmetTeas)
        this.very_special_rare_teas = new TSeriesGourmetRareTeas(data.very_special_rare_teas ? data.very_special_rare_teas : emptyTSeriesGourmetRareTea)
        this.seasonal_flush = new TSeriesGourmetSeasonalFlush(data.seasonal_flush ? data.seasonal_flush : emptyTSeriesGourmetSeasonalFlush)
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
    }
}

export const emptyTSeriesGourmet = {
    content: emptyContent,
    header: emptyMultiLang,
    links: [],
    gourmet_teas: emptyTSeriesGourmetTeas,
    very_special_rare_teas: emptyTSeriesGourmetRareTea,
    seasonal_flush: emptyTSeriesGourmetSeasonalFlush,
    seo: emptySEOComponent
}