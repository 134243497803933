import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding:'10px'
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    paddingLeft: '40px'
  }
}));

export default useStyles;
