import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import clonedeep from "lodash.clonedeep";
import AttributeItem from "../../components/attribute_item/attribute_item";
import ContentItem from "../../components/content_item/content_item";
import TeamakersPrivateReserveModel, { emptyTemakersPrivateReserve } from '../../models/pages/teamakers_private_reserve'
import IconUploadBtn from "../../components/icon_upload_btn/icon_upload_btn";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateSeoItem, updateContentItem, updateExploreDilmahItemArray, updateMultiLangItem, updateTeaMakersPrivateAbout, updateTeaMakersPrivateTeaLocation, updateTeaMakersPrivateTeas,
} from "../../services/api_service/components_update";
import { teamakersPrivateReserveGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import ExploreDilmahItem from "../../components/explore_dilmah_item/explore_dilmah_item";
import ExploreDilmahItemModel, { emptyExploreDilmahItem } from "../../models/explore_dilmah_item";
import MultiLangEdit from "../../components/multi_lang_edit/multi_lang_edit";
import ContentModel, { emptyContent } from "../../models/content_model";
import TeaLocationItem from "../../components/tea_location_item/tea_location_item";
import TeaLocationModel, { emptyTeaLocation } from "../../models/tea_location.ts";
import SEOComponent from "../../components/seo_component/seo_component";

export default function TeamakersPrivateReserve() {
  const [pageData, setPageData] = useState<TeamakersPrivateReserveModel>();

  const { showSideMessage } = SideMessageService();

  const getPageData = (country: string) => {
    getPageDataCall(country, teamakersPrivateReserveGraph, "teaMakersPrivateReservePages").then(
      (data) => {
        if (data?.success) {
          setCurrentPageId(data.data?.id);
          setPageData(undefined);
          setPageData(
            new TeamakersPrivateReserveModel(data.data ? data.data.page : emptyTemakersPrivateReserve)
          );
        }
      }
    );
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getPageData(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (pageData: TeamakersPrivateReserveModel) => {
    if (pageData) {
      setLoading(true);
      const dataToSave: any = pageData;
      dataToSave.content = await updateContentItem(pageData.content);
      dataToSave.links = await updateExploreDilmahItemArray(pageData.links)
      dataToSave.header = await updateMultiLangItem(pageData.header)
      dataToSave.about = await updateTeaMakersPrivateAbout(pageData.about)
      dataToSave.our_teas = await updateTeaMakersPrivateTeas(pageData.our_teas)
      dataToSave.tea_locations = await updateTeaMakersPrivateTeaLocation(pageData.tea_locations)
      dataToSave.seo = await updateSeoItem(pageData.seo)

      await updateContentTypeCall(dataToSave, "tea-makers-private-reserves").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "tea-makers-private-reserve-pages"
          ).then((data) => {
            if (data?.success) {
              getPageData(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };



  const setHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setSubHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.sub_header_image_url = url;
      setPageData({ ...prev });
    }
  };


  const setAboutHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.about.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setAboutSubHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.about.sub_header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setOurTeasHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_teas.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setOurTeasSubHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.our_teas.sub_header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setTeaLocationsHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.tea_locations.header_image_url = url;
      setPageData({ ...prev });
    }
  };

  const setTeaLocationsSubHeaderImage = (url: string) => {
    const prev = pageData;
    if (prev) {
      prev.tea_locations.sub_header_image_url = url;
      setPageData({ ...prev });
    }
  };




  const addNewLink = () => {
    const prev = pageData;
    if (prev) {
      prev.links.push(new ExploreDilmahItemModel(emptyExploreDilmahItem));
      setPageData({ ...prev });
    }
  };

  const removeLink = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.links.splice(index, 1);
      setPageData(new TeamakersPrivateReserveModel(emptyTemakersPrivateReserve));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewAboutContent = () => {
    const prev = pageData;
    if (prev) {
      prev.about.contents.push(new ContentModel(emptyContent));
      setPageData({ ...prev });
    }
  };

  const removeAboutContent = (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.about.contents.splice(index, 1);
      setPageData(new TeamakersPrivateReserveModel(emptyTemakersPrivateReserve));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  const addNewTeaLocation = () => {
    const prev = pageData;
    if (prev) {
      prev.tea_locations.tea_locations.push(new TeaLocationModel(emptyTeaLocation));
      setPageData({ ...prev });
    }
  };

  const removeTeaLocation= (index: number) => {
    const prev = pageData;
    if (prev) {
      prev.tea_locations.tea_locations.splice(index, 1);
      setPageData(new TeamakersPrivateReserveModel(emptyTemakersPrivateReserve));
      setTimeout(() => {
        setPageData({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.dilmahTeamakersPrivateReserve}
      parentRoutes={[routeNameObjects.teaRanges]}
    >
      {pageData ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(pageData))}
              />
            }
          />
          <AttributeItem title={"Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Header"}>
            <MultiLangEdit
              value={pageData.header}
            />
          </AttributeItem>
          <AttributeItem title={"Sub Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setSubHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.sub_header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
          <AttributeItem title={"Content"}>
            <ContentItem
              content={pageData.content}
            /></AttributeItem>
          <AttributeItem title={"Links"} onClick={addNewLink}>
            {pageData.links.map((link, index) => (
              <ExploreDilmahItem
                key={index}
                explore={link}
                index={index}
                onRemove={removeLink}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={'About'}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setAboutHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.about.header_image_url}
                height={150}
                isEditable
              />
            </div>
            <MultiLangEdit isShowLabel label="header" value={pageData.about.header} />
            <AttributeItem title={"Sub Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setAboutSubHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.about.sub_header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
             <AttributeItem title={"Initial content"}>
              <ContentItem content={pageData.about.initial_content} />
            </AttributeItem>
            <AttributeItem title={'Contents'} onClick={addNewAboutContent} removeMargin >
              {
                pageData.about.contents.map((cont, index) => <ContentItem content={cont} index={index} key={index} onRemove={removeAboutContent} />)
              }
            </AttributeItem>
          </AttributeItem>


          <AttributeItem title={'Our Teas'}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setOurTeasHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.our_teas.header_image_url}
                height={150}
                isEditable
              />
            </div>
            <MultiLangEdit isShowLabel label="header" value={pageData.our_teas.header} />
            <AttributeItem title={"Sub Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setOurTeasSubHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.our_teas.sub_header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
            <AttributeItem title={'Content'} removeMargin >
              <ContentItem content={pageData.our_teas.sub_content} />
            </AttributeItem>
          </AttributeItem>


          <AttributeItem title={'Tea Locations'}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setTeaLocationsHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.tea_locations.header_image_url}
                height={150}
                isEditable
              />
            </div>
            <MultiLangEdit isShowLabel label="Header" value={pageData.tea_locations.header} />
            <AttributeItem title={"Sub Header Image"}>
            <div style={{ textAlign: "center" }}>
              <IconUploadBtn
                getFileUrl={setTeaLocationsSubHeaderImage}
                mediaType="IMAGE"
                mediaUrl={pageData.tea_locations.sub_header_image_url}
                height={150}
                isEditable
              />
            </div>
          </AttributeItem>
            <MultiLangEdit isShowLabel label="Topic" value={pageData.tea_locations.topic} />
            <AttributeItem title={'Tea Locations'} removeMargin onClick={addNewTeaLocation}>
              {
                pageData.tea_locations.tea_locations.map((location, index) => <TeaLocationItem key={index} item={location} onRemove={() => { 
                  removeTeaLocation(index)
                }} />)
              }
            </AttributeItem>
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
            <SEOComponent seoItem={pageData.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}