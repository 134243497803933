import React, { useEffect, useState } from "react";
import CommonPage from "../../components/common_page/common_page";
import routeNameObjects from "../../services/route_name/route_name_objects";
import nestedRouteNameObject1 from "../../services/route_name/nested_route_name_objects1";
import AttributeItem from "../../components/attribute_item/attribute_item";
import SliderItem from "../../components/slider_item/slider_item";
import AddItemBtn from "../../components/add_item_btn/add_item_btn";
import ContentItem from "../../components/content_item/content_item";
import {
  getPageDataCall,
  updateContentTypeCall,
} from "../../services/api_service/api_service";
import MyLoader from "../../components/my_loader/my_loader";
import Slider, { emptySlider } from "../../models/slider";
import ContentModel, { emptyContent } from "../../models/content_model";
import clonedeep from "lodash.clonedeep";
import OurValues, { emptyOurValues } from "../../models/pages/our_values";
import SideMessageService from "../../services/side_message_service";
import { countries } from "../../components/country_select/countries";
import CountrySelect from "../../components/country_select/country_select";
import {
  updateContentArray,
  updateContentItem,
  updateSeoItem,
  updateSliderArray,
} from "../../services/api_service/components_update";
import { ourValuesGraph } from "../../services/api_service/graphql_queries";
import { Actions, useStoreActions } from "easy-peasy";
import { StoreModel } from "../../store models/model";
import SEOComponent from "../../components/seo_component/seo_component";
export default function OurValuesPage() {
  const [ourValue, setOurValue] = useState<OurValues>();

  const { showSideMessage } = SideMessageService();

  const getOurValues = (country: string) => {
    getPageDataCall(country, ourValuesGraph, "ourValuesPages").then((data) => {
      if (data?.success) {
        setCurrentPageId(data.data?.id);
        setOurValue(undefined);
        setOurValue(new OurValues(data.data ? data.data.page : emptyOurValues));
      }
    });
  };

  const [currentPageId, setCurrentPageId] = useState();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const onChangeCountry = (selected: { label: string; value: string }) => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    getOurValues(selectedCountry.value);
  }, [selectedCountry]);
  const setLoading = useStoreActions(
    (actions: Actions<StoreModel>) => actions.loader.showLoader
  );
  const onSave = async (ourValue: OurValues) => {
    if (ourValue) {
      setLoading(true);
      var value: any = ourValue;
      value.sliders = await updateSliderArray(ourValue.sliders);
      value.title_content = await updateContentItem(ourValue.title_content);
      value.video_content = await updateContentItem(ourValue.video_content);
      value.contents = await updateContentArray(ourValue.contents);
      value.seo = await updateSeoItem(ourValue.seo)

      await updateContentTypeCall(value, "our-values").then((data) => {
        if (data?.success) {
          updateContentTypeCall(
            {
              id: currentPageId,
              country: selectedCountry.value,
              page: data.data.id,
            },
            "our-values-pages"
          ).then((data) => {
            if (data?.success) {
              getOurValues(selectedCountry.value);
              showSideMessage(true);
            } else {
              showSideMessage(false);
            }
          });
        }
      });
    }
  };

  const addNewSlider = () => {
    const prev = ourValue;
    if (prev) {
      prev.sliders.push(new Slider(emptySlider));
      setOurValue({ ...prev });
    }
  };

  const removeSlider = (index: number) => {
    const prev = ourValue;
    if (prev) {
      prev.sliders.splice(index, 1);
      setOurValue(new OurValues(emptyOurValues));
      setTimeout(() => {
        setOurValue({ ...prev });
      }, 0.01);
    }
  };

  const addNewContent = () => {
    const prev = ourValue;
    if (prev) {
      prev.contents.push(new ContentModel(emptyContent));
      setOurValue({ ...prev });
    }
  };

  const removeContent = (index: number) => {
    const prev = ourValue;
    if (prev) {
      prev.contents.splice(index, 1);
      setOurValue(new OurValues(emptyOurValues));
      setTimeout(() => {
        setOurValue({ ...prev });
      }, 0.01);
    }
  };

  return (
    <CommonPage
      currentRoute={nestedRouteNameObject1.ourValues}
      parentRoutes={[routeNameObjects.familyPassion]}
    >
      {ourValue ? (
        <div>
          <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={onChangeCountry}
            addBtn={
              <AddItemBtn
                title={"Save"}
                onClick={() => onSave(clonedeep(ourValue))}
              />
            }
          />

          <AttributeItem title={"Sliders"} onClick={addNewSlider}>
            {ourValue.sliders.map((slider, index) => (
              <SliderItem
                key={index}
                slider={slider}
                index={index}
                onRemove={removeSlider}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"Title Content"}>
            <ContentItem content={ourValue.title_content} />
          </AttributeItem>
          <AttributeItem title={"Video Content"}>
            <ContentItem content={ourValue.video_content} />
          </AttributeItem>
          <AttributeItem title={"Content"} onClick={addNewContent}>
            {ourValue.contents.map((content, index) => (
              <ContentItem
                key={index}
                content={content}
                index={index}
                onRemove={removeContent}
              />
            ))}
          </AttributeItem>
          <AttributeItem title={"SEO Component"}>
              <SEOComponent seoItem={ourValue.seo}/>
          </AttributeItem>
        </div>
      ) : (
        <MyLoader />
      )}
    </CommonPage>
  );
}
