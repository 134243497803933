import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import routeNameObjects from "./route_name_objects";


export interface RouteNames {
  name: string;
  route: string;
  component: any;
  children: RouteNames[];
  isHide?: boolean;
  nestedLevel: number;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

export const routeNames: RouteNames[] = [
  routeNameObjects.login,
  routeNameObjects.home,

  routeNameObjects.familyPassion,
  routeNameObjects.allAboutTea,
  routeNameObjects.ourPurpose,
  routeNameObjects.ourProduct,
  routeNameObjects.teaInspired,
  routeNameObjects.media,
  routeNameObjects.other,
  routeNameObjects.sections,
  routeNameObjects.teaRanges,
  routeNameObjects.ourPlocics,
  routeNameObjects.contactUsSubmits
];

export const firstRoute = '/'
