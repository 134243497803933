import MultiLanguage, { emptyMultiLang } from "./multi_language"
import SEOComponent, {emptySEOComponent} from "./pages/seo_component"

export default class DilmahPodcastCategoryModal {
    id: number
    name: MultiLanguage
    seo: SEOComponent

    constructor(data: any) {
        this.id = data.id
        this.name = new MultiLanguage(data.name ? data.name : emptyMultiLang)
        this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)

    }
}

export const emptyDilmahPodcastCategory = {
    name: emptyMultiLang,
    seo: emptySEOComponent
}