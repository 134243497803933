import TestimonialItemModel from "../testimonial_item";
import SEOComponent,{emptySEOComponent} from "./seo_component";

export default class Testimonials {
  id:number
  testimonial_items: TestimonialItemModel[] = [];
  seo: SEOComponent
  constructor(data: any) {
    this.id=data.id
    // data.testimonial_items.forEach((x: any) =>
    //   this.testimonial_items.push(new TestimonialItemModel(x))
    // );
    this.seo = new SEOComponent(data.seo ? data.seo: emptySEOComponent)
  }
}

export const emptyTestimonials = {
  // testimonial_items: [],
  seo: emptySEOComponent
};
