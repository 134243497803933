import ContentWithImagesModel from "../content_with_images";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import PhotographerModal from "../photographer";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class TeaInspiredImages {
  id: number;
  photographers: PhotographerModal[] = [];
  title: MultiLanguage;
  header_image_url: string;
  albums: ContentWithImagesModel[] = [];
  tea_inspired_me: ContentWithImagesModel[] = [];
  artists: ContentWithImagesModel[] = [];
  seo: SEOComponent;
  photographers_seo: SEOComponent;
  albums_seo: SEOComponent;
  tea_inspired_me_seo: SEOComponent;
  artists_seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.photographers.forEach((x: any) =>
      this.photographers.push(new PhotographerModal(x))
    );
    this.title = new MultiLanguage(data.title ? data.title : emptyMultiLang);
    this.header_image_url = data.header_image_url;
    data.albums.forEach((x: any) =>
      this.albums.push(new ContentWithImagesModel(x))
    );
    data.tea_inspired_me.forEach((x: any) =>
      this.tea_inspired_me.push(new ContentWithImagesModel(x))
    );
    data.artists.forEach((x: any) =>
      this.artists.push(new ContentWithImagesModel(x))
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
    this.photographers_seo = new SEOComponent(data.photographers_seo ? data.photographers_seo : emptySEOComponent);
    this.albums_seo = new SEOComponent(data.albums_seo ? data.albums_seo : emptySEOComponent);
    this.tea_inspired_me_seo = new SEOComponent(data.tea_inspired_me_seo ? data.tea_inspired_me_seo : emptySEOComponent);
    this.artists_seo = new SEOComponent(data.artists_seo ? data.artists_seo : emptySEOComponent);
  }
}

export const emptyTeaInspiredImages = {
  photographers: [],
  title: emptyMultiLang,
  artists: [],
  albums: [],
  tea_inspired_me: [],
  seo: emptySEOComponent,
  photographers_seo: emptySEOComponent,
  albums_seo: emptySEOComponent,
  tea_inspired_me_seo: emptySEOComponent,
  artists_seo: emptySEOComponent,
};
