import ContentModel, { emptyContent } from "../content_model";
import MultiLanguage, { emptyMultiLang } from "../multi_language";
import SEOComponent, { emptySEOComponent } from "./seo_component";

export default class TeamakersPhilosophy {
  id: number;
  contents: ContentModel[] = [];
  banner_image_url: string;
  banner_title: MultiLanguage;
  title_content: ContentModel;
  seo: SEOComponent;

  constructor(data: any) {
    this.id = data.id;
    data.contents.forEach((x: any) => this.contents.push(new ContentModel(x)));
    this.banner_image_url = data.banner_image_url;
    this.banner_title = new MultiLanguage(
      data.banner_title ? data.banner_title : emptyMultiLang
    );
    this.title_content = new ContentModel(
      data.title_content ? data.title_content : emptyContent
    );
    this.seo = new SEOComponent(data.seo ? data.seo : emptySEOComponent);
  }
}

export const emptyTeamakersPhilosophy = {
  contents: [],
  title_content: emptyContent,
  banner_title: emptyMultiLang,
  seo: emptySEOComponent,
};
