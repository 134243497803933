export default class ContactUsSubmitModal {
  id: number;
  name: string;
  email: string;
  contact_no: string;
  company: string;
  subject: string;
  comment: string;
  country: string;
  other_subject: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.contact_no = data.contact_no;
    this.company = data.company;
    this.subject = data.subject;
    this.comment = data.comment;
    this.country = data.country;
    this.other_subject = data.other_subject
  }
}

